import { useState } from 'react';

import { useDispatch } from 'react-redux';
import {
    fetchTracking,
    resetTracking,
} from '../../../modules/trackings/features/trackingsSlice';

export default function TrackingListForm() {
    const dispatch = useDispatch();
    const [inputValue, setInputValue] = useState('');

    const handleChangeInput = (e: React.SyntheticEvent<EventTarget>) => {
        const inputData = (e.target as HTMLInputElement).value;
        setInputValue(inputData);
    };

    const handleSubmit = (e: React.SyntheticEvent<EventTarget>) => {
        e.preventDefault();
        dispatch(resetTracking());
        dispatch(fetchTracking(`${inputValue}`));
    };
    return (
        <form className="w-5/6 mx-auto mt-4" onSubmit={handleSubmit}>
            <div className="form-outline mb-4">
                <input
                    type="text"
                    id="form4Example1"
                    className="form-control"
                    placeholder="Tracking Number"
                    name="data"
                    onChange={handleChangeInput}
                    value={inputValue}
                />
            </div>
        </form>
    );
}
