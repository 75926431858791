import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
// import { IUser } from '../interfaces';
import { BranchOnUserRole } from '../../branches/enums/BranchOnUserRole';
import { IUserBadge } from '../interfaces/index';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface InviteUserState {
    user: IUserBadge | null;
    status: Status;
    error: string | null;
}

interface IUserInvite {
    id?: string;
    authProvider?: string;
    email: string;
    branchId: string;
    branchRole: BranchOnUserRole;
    attributes: {
        firstName: string;
        lastName: string;
        phone_number: string;
        role: string;
    };
}

const initialState: InviteUserState = {
    user: null,
    status: Status.idle,
    error: null,
};

export const updateUserAction = createAsyncThunk(
    'user/updateUser',
    async ({
        id,
        authProvider,
        email,
        branchId,
        branchRole,
        attributes,
    }: IUserInvite) => {
        const response = await APIClient.postData(`/users/update/${id}`, {
            id,
            authProvider,
            email,
            branchId,
            branchRole,
            attributes,
        });
        return response.data;
    }
);

export const updateUserSlice = createSlice({
    name: 'updateUser',
    initialState,
    reducers: {
        resetUpdate: (state) => {
            state.user = null;
            state.status = Status.idle;
            state.error = null;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(updateUserAction.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(updateUserAction.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched users to the array
                state.user = action.payload;
            })
            .addCase(updateUserAction.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            });
    },
});

export const { resetUpdate } = updateUserSlice.actions;
