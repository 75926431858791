/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
import { useState, useEffect, useCallback, ClassAttributes, InputHTMLAttributes } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@hookform/error-message';
import InputMask from 'react-input-mask';

import { RootState } from '../../../../store';
import emailPattern from '../../../../utils/emailPattern';
import { fetchRegions } from '../../../../modules/geo/features/regionSlice';
import { fetchComunas } from '../../../../modules/geo/features/comunaSlice';
import {
    createBranch,
    fetchBranchById,
    resetBranch,
    updateBranch,
} from '../../../../modules/branches/features/branchSlice';
import { IComuna } from '../../../../modules/geo/interfaces';
import LoadingAnimated from '../../../../components/elements/Loading';
import { fetchBranches } from '../../../../modules/branches/features';
import { fetchUsers } from '../../../../modules/users/features/usersSlice';
import { fetchCouriers } from '../../../../modules/couriers/features/couriersSlice';

import AutoComplete from '../../../../components/Autocomplete';
import { IUser } from '../../../../modules/users/interfaces/index';
import {
    branchesOnUsesrDeleteById,
    resetBranchOnUserDelete,
} from '../../../../modules/branches/features/branchesOnUsersDeleteByIdSlice';

import { resetBranchOnUser } from '../../../../modules/branches/features/branchesOnUsersSlice';
import { IBranchOnUser } from '../../../../modules/branches/interfaces/IBranchOnUser';
// import { ICourierAPIKey } from '../../../../modules/branches/interfaces/IBranch';
import { BranchOnUserRole } from '../../../../modules/branches/enums';
import { IApiKeys } from '../../../../modules/branches/interfaces/IBranch';

function AutoCompleteItem({
    item,
    onClick,
}: {
    item: IUser;
    onClick: () => void;
}) {
    return (
        <li>
            <button
                type="button"
                className="text-left hover:bg-slate-200 flex-col gap-4 p-4 w-full"
                onClick={onClick}
            >
                <p>
                    {item.firstName} {item.lastName}
                </p>
            </button>
        </li>
    );
}
type FormData = {
    id: string;
    name: string;
    email: string;
    phone: string;
    address: string;
    addressNumber: string;
    city: string;
    comunaId: string;
    regionId: string;
    userId: IUser[];
    branchId: string[];
    apiKeys: {
        id?: string;
        apiKeyName: string;
        apiKeyValue: string;
        apiKeyExp: Date;
        apiKeyCourier: Date;
    }[];
    users: {
        userId: string;
        role: BranchOnUserRole.OWNER;
        // branchId: string;
    }[];
    couriersFee: {
        id?: string;
        courierId: string;
        fee: number;
        percentage: number;
    }[];
    courierAPIKey: {
        courierId: string;
        courierName: string;
        apiKeys: {
            id?: string;
            name: string;
            description: string;
            value: string;
        }[];
    }[];
};

export default function BranchesForm() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id: params } = useParams<{ id: string }>();
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        watch,
        setValue,
    } = useForm<FormData>();

    const [alertSucess, setAlertSucess] = useState(false);
    const branchMain = useSelector((state: RootState) => state.branch);
    const branchDelete = useSelector(
        (state: RootState) => state.branchesOnUsersDeleteByIdgSlice
    );

    const clearFormData = useCallback(() => {
        dispatch(resetBranch());
        dispatch(resetBranchOnUser());
        dispatch(resetBranchOnUserDelete());
        reset();
    }, [dispatch, reset]);

    // GEO  ///////////////////////////////////////////////////////////////////////////////////////////////
    const selectedOriginRegion = watch('regionId');
    const [comunasByRegionId, setComunasByRegionId] = useState<IComuna[]>([]);
    const regions = useSelector((state: RootState) => state.regions);
    const comunas = useSelector((state: RootState) => state.comunas);

    const setSelectedComuna = useCallback(
        (comunaSelectedId: string) => {
            const comunasFiltered = comunas.data.filter(
                (comuna) => comuna.regionId.toString() === comunaSelectedId
            );
            setComunasByRegionId(comunasFiltered);
        },
        [comunas.data, setComunasByRegionId]
    );

    useEffect(() => {
        if (regions.status === 'idle') {
            dispatch(fetchRegions());
        }
        if (comunas.status === 'idle') {
            dispatch(fetchComunas());
        }
    }, [comunas.status, regions.status, dispatch]);

    useEffect(() => {
        setSelectedComuna(selectedOriginRegion);
    }, [selectedOriginRegion, setSelectedComuna]);
    // GEO -> FINISH  ///////////////////////////////////////////////////////////////////////////////////////////////

    // BRANCH EDIT DATA ///////////////////////////////////////////////////////////////////////////////////////////////

    const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);
    // const apikey = useSelector((state: RootState) => state.couriersAPIKeys);

    const branchId = watch('id');

    useEffect(() => {
        if (
            branchMain.status === 'success_get' &&
            branchMain.data &&
            branchMain.data.id &&
            !branchId
        ) {
            setValue('id', branchMain.data.id);
            setValue('name', branchMain.data.name);
            setValue('email', branchMain.data.email);
            setValue('phone', branchMain.data.phone || "");
            setValue('address', branchMain.data.address);
            setValue('addressNumber', branchMain.data.addressNumber ?? '');
            setValue('city', branchMain.data.city);
            setValue('regionId', branchMain.data.regionId.toString());
            setSelectedComuna(branchMain.data.regionId.toString());
            setTimeout(() => {
                setValue(
                    'comunaId',
                    branchMain.data?.comunaId?.toString() ?? ''
                );
            }, 300);

            setValue('userId', selectedUsers as []);
            setValue('couriersFee', branchMain.data.branchFeeByCourier);
            setSelectedUsers([]);
        }
    }, [
        branchMain,
        dispatch,
        selectedUsers,
        setSelectedComuna,
        setValue,
        branchId,
    ]);

    useEffect(() => {
        if (branchMain.status === 'success_get') {
            dispatch(fetchBranches());
        }
    }, [branchMain, dispatch]);

    // BRANCH EDIT DATA -> FINISH ///////////////////////////////////////////////////////////////////////////////////////////////

    // BRANCHES ON USERS///////////////////////////////////////////////////////////////////////////////////
    const users = useSelector((state: RootState) => state.users);
    const [getDelete, setGetDelete] = useState(false);

    const branchesOnUsers = useSelector(
        (state: RootState) => state.branchesOnUsers
    );

    const [branchUser, setBranchUser] = useState<IBranchOnUser[]>([]);

    useEffect(() => {
        if (users.status === 'idle') {
            dispatch(fetchUsers());
        }
    }, [users.status, dispatch]);

    const getSelectedUser = users.users.filter((o1) =>
        branchesOnUsers.branchOnUser.some(
            (o2) => o1.id === o2.userId.toString()
        )
    );

    const handleDelete = (id: string, isBranch: boolean) => {
        if (isBranch) {
            dispatch(branchesOnUsesrDeleteById(id));

            const newUsers = branchUser.filter((b) => b.id.toString() !== id);
            setBranchUser(newUsers);

            if (!branchDelete.error) {
                setGetDelete(false);
            }
            setGetDelete(true);
        }
        const newUsers = selectedUsers.filter((user) => user.id !== id);
        setSelectedUsers(newUsers);
    };

    useEffect(() => {
        if (
            branchesOnUsers.branchOnUser.length > 0 &&
            branchUser.length === 0
        ) {
            setBranchUser(branchesOnUsers.branchOnUser);
        }
    }, [branchesOnUsers.branchOnUser, branchUser]);

    // END BRANCHES ON USERS///////////////////////////////////////////////////////////////////////////////////


    // COURIERS ///////////////////////////////////////////////////////////////////////////////////////////////
    const couriers = useSelector((state: RootState) => state.couriers);
    const courierAPIKey = watch('courierAPIKey');

    useEffect(() => {
        if (couriers.status === 'idle') {
            dispatch(fetchCouriers());
        }
    }, [couriers, couriers.status, dispatch]);

    useEffect(() => {
        if (couriers.status === 'success' && couriers.data.length > 0) {
            let courierAPIKeyMaped: { [key: string]: IApiKeys[] } = {};
            let result: any[] = [];
            if (params) {
                if (branchMain.status === 'success_get' && branchMain.data && branchMain.data.courierAPIKey.length > 0) {
                    courierAPIKeyMaped = branchMain.data.courierAPIKey
                        .reduce(
                            (acc: { [key: string]: IApiKeys[] }, curr) =>
                                ({ ...acc, [curr.courierId]: curr.apiKeys }),
                            {});
                    result = couriers.data.map(({ id: courierId, apiCredential, name: courierName }) => {
                        const courierAPIKeyData: IApiKeys[] = courierAPIKeyMaped[courierId];
                        let apiKeysData = JSON.parse(apiCredential);
                        if (courierAPIKeyData !== undefined) {
                            apiKeysData = apiKeysData.map((keyData: any) => {
                                const currentValue = courierAPIKeyData.find((item) => item.name === keyData.name);
                                return {
                                    description: keyData.description,
                                    expires: null,
                                    id: currentValue ? currentValue.id : '',
                                    name: keyData.name,
                                    value: currentValue ? currentValue.value : ''
                                }
                            })
                            return {
                                courierId,
                                courierName,
                                apiKeys: apiKeysData
                            }
                        }
                        return {
                            courierId,
                            courierName,
                            apiKeys: JSON.parse(apiCredential)
                        }
                    });
                }
            } else {
                result = couriers.data.map(({ id: courierId, apiCredential, name: courierName }) => ({
                    courierId,
                    courierName,
                    apiKeys: JSON.parse(apiCredential)

                }));
            }
            if (!courierAPIKey && result.length > 0) {
                setValue('courierAPIKey', result);
            }

        }
    }, [couriers, courierAPIKey, branchMain, setValue, params])

    // END COURIERS ///////////////////////////////////////////////////////////////////////////////////////////////

    // FORM SUBMIT ///////////////////////////////////////////////////////////////////////////////////////////////
    const onSubmit = async (data: FormData) => {
        setValue('userId', selectedUsers as IUser[]);
        const usersId = watch('userId');

        const newPayload = {
            ...data,
        };
        newPayload.courierAPIKey = data.courierAPIKey.filter((c) => c !== null);
        newPayload.couriersFee = data.couriersFee.map(
            ({ fee, percentage, ...rest }, index) => ({
                ...rest,
                fee: +fee,
                percentage: +percentage,
                courierId: couriers.data[index].id,
            })
        );
        // Create APIKEY
        newPayload.users = usersId.map(({ id }) => ({
            userId: id,
            role: BranchOnUserRole.OWNER,
        }));
        if (!data.id) {
            dispatch(createBranch(newPayload));
        } else {
            dispatch(updateBranch(newPayload));
        }
    };

    useEffect(() => {
        if (branchMain.status === 'success_action') {
            setAlertSucess(true);
            dispatch(fetchBranches());
            clearFormData();
            if (branchMain.data) {
                if (params) {
                    dispatch(fetchBranchById(branchMain.data.id));
                } else {
                    setTimeout(() => {
                        navigate(`/branches/${branchMain.data?.id}`);
                    }, 3000);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [branchMain.status, reset, dispatch, clearFormData]);
    // END FORM SUBMIT ///////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (alertSucess) {
            setTimeout(() => {
                setAlertSucess(false);
            }, 3000);
        }
    }, [alertSucess, branchId]);

    const closeAlertSucess = () => {
        setAlertSucess(false);
    };
    return (
        <div className="relative mb-40">
            {branchMain && branchMain.status === 'loading' ? (
                <LoadingAnimated message={t('common.loading')} />
            ) : (
                <form onSubmit={handleSubmit(onSubmit)}>
                    {/* NAME */}
                    <div>
                        <label
                            htmlFor="name"
                            className="block text-sm font-medium text-gray-700"
                        >
                            {t('branches.name')} *
                            <div className="mt-1">
                                <input
                                    id="name"
                                    type="text"
                                    className={`${errors.name
                                        ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                        : ''
                                        }`}
                                    autoComplete="name"
                                    {...register('name', {
                                        required: true,
                                    })}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="name"
                                    render={() => (
                                        <p className="text-red-600 ml-2">
                                            {t('common.errors.invalidEmail')}
                                        </p>
                                    )}
                                />
                            </div>
                        </label>
                    </div>
                    {/* EMAIL */}
                    <div className="mt-4">
                        <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700"
                        >
                            {t('branches.email')} *
                            <div className="mt-1">
                                <input
                                    id="email"
                                    type="text"
                                    className={`${errors.name
                                        ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                        : ''
                                        }`}
                                    autoComplete="email"
                                    {...register('email', {
                                        required: true,
                                        pattern: emailPattern
                                    })}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="email"
                                    render={() => (
                                        <p className="text-red-600 ml-2">
                                            {t('common.errors.fieldRequired')}
                                        </p>
                                    )}
                                />
                            </div>
                        </label>
                    </div>
                    {/* PHONE */}
                    <div className="mt-4">
                        <label
                            htmlFor="phone"
                            className="block text-sm font-medium text-gray-700"
                        >
                            {t('branches.phone')} *
                            <div className="mt-1">
                                <InputMask
                                    mask="+56 9 9999 9999"
                                    {...register('phone', {
                                        required: true,
                                        validate: (value) =>
                                            value !== '',
                                    })}
                                >
                                    {(
                                        inputProps: JSX.IntrinsicAttributes &
                                            ClassAttributes<HTMLInputElement> &
                                            InputHTMLAttributes<HTMLInputElement>
                                    ) => (
                                        <input
                                            id="phone"
                                            type="text"
                                            {...inputProps}
                                            className={`mt-2 ${errors.phone
                                                ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                : ''
                                                }`}
                                        />
                                    )}
                                </InputMask>
                                <ErrorMessage
                                    errors={errors}
                                    name="phone"
                                    render={() => (
                                        <p className="text-red-600 ml-2">
                                            {t('common.errors.fieldRequired')}
                                        </p>
                                    )}
                                />
                            </div>
                        </label>
                    </div>

                    {/* REGION */}
                    <div className="mt-4">
                        <label
                            htmlFor="regionId"
                            className="block text-sm font-medium text-gray-700"
                        >
                            {t('branches.region')} *
                            <select
                                id="regionId"
                                {...register('regionId', {
                                    required: true,
                                })}
                            >
                                <option value="">
                                    {regions.status === 'loading'
                                        ? t('common.loading')
                                        : t('quotes.create.regionSelect')}
                                </option>
                                {regions.data.map((region) => (
                                    <option key={region.id} value={region.id}>
                                        {region.name}
                                    </option>
                                ))}
                            </select>
                        </label>
                        <ErrorMessage
                            errors={errors}
                            name="regionId"
                            render={() => (
                                <p className="text-red-600 ml-2">
                                    {t('common.errors.fieldRequired')}
                                </p>
                            )}
                        />
                    </div>

                    {/* COMUNA */}
                    <div className="mt-4">
                        <label
                            htmlFor="comunaOrigin"
                            className="block text-sm font-medium text-gray-700"
                        >
                            {t('branches.comuna')} *
                            <select
                                id="comunaOrigin"
                                disabled={selectedOriginRegion === ''}
                                className={`mt-2 ${selectedOriginRegion === ''
                                    ? 'cursor-not-allowed'
                                    : ''
                                    }`}
                                {...register('comunaId', {
                                    required: true,
                                    validate: (value) => value !== '',
                                })}
                                placeholder={t('branches.comuna')}
                            >
                                {comunas.status === 'loading' && (
                                    <option value="">
                                        {t('common.loading')}
                                    </option>
                                )}
                                {!selectedOriginRegion && (
                                    <option value="">
                                        {t('quotes.create.firstSelectRegion')}
                                    </option>
                                )}
                                {selectedOriginRegion && (
                                    <option value="">
                                        {t('quotes.create.comunaSelect')}
                                    </option>
                                )}

                                {selectedOriginRegion &&
                                    comunasByRegionId.length > 0 ? (
                                    comunasByRegionId.map((comuna) => (
                                        <option
                                            key={comuna.id}
                                            value={comuna.id}
                                        >
                                            {comuna.name}
                                        </option>
                                    ))
                                ) : (
                                    <option>
                                        {t('quotes.create.regionSelect')}
                                    </option>
                                )}
                            </select>
                        </label>
                        <ErrorMessage
                            errors={errors}
                            name="comunaId"
                            render={() => (
                                <p className="text-red-600 ml-2">
                                    {t('common.errors.fieldRequired')}
                                </p>
                            )}
                        />
                    </div>
                    {/* ADDRESS */}
                    <div className="mt-4">
                        <label
                            htmlFor="address"
                            className="block text-sm font-medium text-gray-700"
                        >
                            {t('branches.address')} *
                            <div className="mt-1">
                                <input
                                    id="address"
                                    type="text"
                                    className={`${errors.address
                                        ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                        : ''
                                        }`}
                                    autoComplete="address"
                                    {...register('address', {
                                        required: true,
                                    })}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="address"
                                    render={() => (
                                        <p className="text-red-600 ml-2">
                                            {t('common.errors.fieldRequired')}
                                        </p>
                                    )}
                                />
                            </div>
                        </label>
                    </div>
                    {/* ADDRESS NUMBER */}
                    <div className="mt-4">
                        <label
                            htmlFor="addressNumber"
                            className="block text-sm font-medium text-gray-700"
                        >
                            {t('branches.addressNumber')} *
                            <div className="mt-1">
                                <input
                                    id="addressNumber"
                                    type="text"
                                    className={`${errors.addressNumber
                                        ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                        : ''
                                        }`}
                                    autoComplete="addressNumber"
                                    {...register('addressNumber', {
                                        required: true,
                                    })}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="addressNumber"
                                    render={() => (
                                        <p className="text-red-600 ml-2">
                                            {t('common.errors.fieldRequired')}
                                        </p>
                                    )}
                                />
                            </div>
                        </label>
                    </div>

                    {/* CITY */}
                    <div className="mt-4">
                        <label
                            htmlFor="city"
                            className="block text-sm font-medium text-gray-700"
                        >
                            {t('branches.city')} *
                            <div className="mt-1">
                                <input
                                    id="city"
                                    type="text"
                                    className={`${errors.city
                                        ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                        : ''
                                        }`}
                                    autoComplete="city"
                                    {...register('city', {
                                        required: true,
                                    })}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="city"
                                    render={() => (
                                        <p className="text-red-600 ml-2">
                                            {t('common.errors.fieldRequired')}
                                        </p>
                                    )}
                                />
                            </div>
                        </label>
                    </div>
                    {/* USER */}
                    {branchesOnUsers.status === 'loading' && (
                        <LoadingAnimated message={t('common.loading')} />
                    )}
                    <div className="mt-4">
                        <div className="mt-1">
                            <AutoComplete
                                placeholder={t('common.search')}
                                getItems={({ query }: { query: string }) => {
                                    const items = users.users
                                        .filter((group) =>
                                            group.firstName
                                                .toLowerCase()
                                                .includes(query.toLowerCase())
                                        )
                                        .sort((a, b) =>
                                            a.firstName.localeCompare(
                                                b.firstName
                                            )
                                        );
                                    if (!items) {
                                        return [];
                                    }
                                    return items;
                                }}
                                sourceId="users"
                                onClick={(id: string) => {
                                    const exist = selectedUsers.find(
                                        (sg) => sg.id.toString() === id
                                    );

                                    const existBranchOnUser =
                                        getSelectedUser.find(
                                            (user) => user.id === id
                                        );

                                    if (existBranchOnUser) {
                                        return;
                                    }
                                    if (exist) {
                                        return;
                                    }
                                    const newGroup = users.users.find(
                                        (pg) => pg.id === id
                                    );

                                    if (!newGroup) {
                                        return;
                                    }

                                    setSelectedUsers([
                                        ...selectedUsers,
                                        newGroup,
                                    ]);
                                }}
                                Item={AutoCompleteItem}
                            />
                        </div>
                    </div>
                    {/* End User */}

                    {/* listar select user */}
                    <div className="my-6 bg-gray-100">
                        {branchMain.status === 'success_get' &&
                            branchMain.data &&
                            branchMain.data.users.map((u) => (
                                <div
                                    className="flex space-x-2 p-2 text-black"
                                    key={u.id}
                                >
                                    <svg
                                        onClick={() => handleDelete(u.id, true)}
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6 hover:cursor-pointer "
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                        />
                                    </svg>
                                    <p>
                                        {u.user.firstName} {u.user.lastName}
                                    </p>
                                </div>
                            ))}
                        {selectedUsers.map((u) => (
                            <div
                                className="flex space-x-2 p-2 text-black bg-gray-300"
                                key={u.id}
                            >
                                <svg
                                    onClick={() => handleDelete(u.id, true)}
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6 hover:cursor-pointer "
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                    />
                                </svg>
                                <p>
                                    {u.firstName} {u.lastName}
                                </p>
                            </div>
                        ))}
                        <div className="w-1/2 mx-auto text-center  font-bold py-2">
                            {branchDelete.status === 'idle' ? (
                                ''
                            ) : (
                                <p
                                    className={`${branchDelete.status === 'loading'
                                        ? 'text-black'
                                        : 'text-green-700'
                                        }`}
                                >
                                    {t(
                                        branchDelete.status === 'loading'
                                            ? t('wait.message')
                                            : t(
                                                getDelete === true
                                                    ? 'Eliminado correctamente'
                                                    : 'Algo salió mal'
                                            )
                                    )}
                                </p>
                            )}
                        </div>
                    </div>








                    {/*  APIKEY */}
                    <table className="table-auto w-full mx-auto mt-2">
                        <thead className="px-2 py-2 bg-slate-500 border-2 border-white rounded-lg text-white">
                            <tr>
                                <th
                                    colSpan={params ? 2 : 4}
                                    className="px-2 py-2 bg-slate-500 border-2 border-white rounded-lg"
                                >
                                    <div className="flex justify-between">
                                        Configuración de ApiKey
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        {(courierAPIKey && courierAPIKey.length > 0) && (
                            <tbody className="px-2 py-2">
                                {courierAPIKey.map(({ courierId, courierName, apiKeys }, courierIndex) => (


                                    <table className="table-auto w-full mx-auto mt-2">
                                        <thead className="px-2 py-2 bg-slate-400 border-2 border-white rounded-lg text-white">
                                            <tr key={`thead-${courierId}`}>
                                                <th
                                                    colSpan={params ? 2 : 4}
                                                    className="px-2 py-2 bg-slate-500 border-2 border-white rounded-lg"
                                                >
                                                    <div className="flex justify-between">
                                                        {t(
                                                            'branchFeeByCourier.name'
                                                        )}
                                                        : {courierName}
                                                    </div>
                                                </th>
                                            </tr>
                                            <tr
                                                key={`sub-thead-${courierId}`}
                                            >
                                                <th className="px-2 py-2 bg-slate-500 border-2 border-white rounded-lg">
                                                    <div className="flex justify-between">
                                                        Descripción
                                                    </div>
                                                </th>
                                                <th className="px-2 py-2 bg-slate-500 border-2 border-white rounded-lg">
                                                    <div className="flex justify-between">
                                                        Valor ApiKey
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {apiKeys.map(({ description }, apiKeyIndex) => (
                                                <tr>
                                                    <td>
                                                        <label
                                                            htmlFor="credentialName"
                                                            className="block text-sm font-medium text-gray-700 ml-2"
                                                        >
                                                            {description}
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            autoComplete="value"
                                                            {...register(
                                                                `courierAPIKey.${courierIndex}.apiKeys.${apiKeyIndex}.value`,
                                                                { required: true }
                                                            )}
                                                        />
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name={`courierAPIKey.${courierIndex}.apiKeys.${apiKeyIndex}.value`}
                                                            render={() => (
                                                                <p className="text-red-600 ml-2">
                                                                    {t('common.errors.fieldRequired')}
                                                                </p>
                                                            )}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>

                                    </table>
                                ))}


                            </tbody>
                        )}
                    </table>
                    {/*  END APIKEY */}













                    {/* Couriers fee config */}
                    {couriers.status === 'success' && (
                        <table className="table-auto w-full mx-auto">
                            <thead className="px-2 py-2 bg-slate-500 border-2 border-white rounded-lg text-white">
                                <tr>
                                    <th
                                        colSpan={3}
                                        className="px-2 py-2 bg-slate-500 border-2 border-white rounded-lg"
                                    >
                                        <div className="flex justify-between">
                                            {t('branchFeeByCourier.title')}
                                        </div>
                                    </th>
                                </tr>
                                <tr>
                                    <th className="px-2 py-2 bg-slate-500 border-2 border-white rounded-lg">
                                        <div className="flex justify-between">
                                            {t('branchFeeByCourier.name')}
                                        </div>
                                    </th>
                                    <th className="px-2 py-2 bg-slate-500 border-2 border-white rounded-lg">
                                        <div className="flex justify-between">
                                            {t('branchFeeByCourier.fee')}
                                        </div>
                                    </th>
                                    <th className="px-2 py-2 bg-slate-500 border-2 border-white rounded-lg">
                                        <div className="flex justify-between">
                                            {t('branchFeeByCourier.percentage')}
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="px-2 py-2">
                                {couriers.data.map((courier, index) => (
                                    <tr key={courier.id}>
                                        <td>
                                            <label
                                                htmlFor="courierName"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                {courier.name}
                                            </label>
                                        </td>
                                        <td>
                                            <input
                                                id={`fee${index}`}
                                                type="number"
                                                min="0"
                                                autoComplete="fee"
                                                {...register(
                                                    `couriersFee.${index}.fee`,
                                                    {
                                                        required: true,
                                                    }
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name={`couriersFee.${index}.fee`}
                                                render={() => (
                                                    <p className="text-red-600 ml-2">
                                                        {t(
                                                            'common.errors.fieldRequired'
                                                        )}
                                                    </p>
                                                )}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                id={`percentage.${index}`}
                                                type="number"
                                                min="0"
                                                autoComplete="percentage"
                                                {...register(
                                                    `couriersFee.${index}.percentage`,
                                                    {
                                                        required: true,
                                                    }
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name={`couriersFee.${index}.percentage`}
                                                render={() => (
                                                    <p className="text-red-600 ml-2">
                                                        {t(
                                                            'common.errors.fieldRequired'
                                                        )}
                                                    </p>
                                                )}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}

                    <div className="mt-4">
                        {branchMain.status === 'error' && (
                            <p className="text-red-600 mb-2">
                                {t('branches.error')}
                            </p>
                        )}

                        <button
                            disabled={branchMain.status === 'loading_action'}
                            type="submit"
                            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                        >
                            {branchMain &&
                                branchMain.status === 'loading_action'
                                ? t('wait.message')
                                : t(
                                    params
                                        ? 'branches.edit.buttonLabel'
                                        : 'branches.create.buttonLabel'
                                )}
                        </button>
                    </div>
                </form>
            )}
            {alertSucess && (
                <div
                    className="z-50 fixed right-10 top-24  bg-green-100 inline-flex w-1/2  border border-green-400 text-green-700 px-4 py-3 rounded"
                    role="alert"
                >
                    <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="check-circle"
                        className="w-4 h-auto mr-2 fill-current"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                    >
                        <path
                            fill="currentColor"
                            d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
                        />
                    </svg>
                    <strong className="font-bold mr-2">Sucess!</strong>
                    <span className="block sm:inline">
                        {params
                            ? t('branches.edit.success')
                            : t('branches.create.success')}
                    </span>
                    <span
                        className="absolute top-0 bottom-0 right-0 px-4 py-3"
                        onClick={() => closeAlertSucess()}
                        onKeyPress={() => closeAlertSucess()}
                        role="button"
                        tabIndex={0}
                    >
                        <svg
                            className="fill-current h-6 w-6 text-green-500"
                            role="button"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                        >
                            <title>Close</title>
                            <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                        </svg>
                    </span>
                </div>
            )}
        </div>
    );
}
