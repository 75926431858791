/* eslint-disable @typescript-eslint/no-explicit-any */
import { API, Auth } from 'aws-amplify';

const apiName = process.env.REACT_APP_API_NAME;
export default class APIClient {
    public static async postData(path: string, body: any) {
        const myInit = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession())
                    .getIdToken()
                    .getJwtToken()}`,
            },
            body,
        };

        return API.post(apiName, path, myInit);
    }

    public static async putData(path: string, body?: any) {
        const myInit = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession())
                    .getIdToken()
                    .getJwtToken()}`,
            },
            body,
        };

        return API.put(apiName, path, myInit);
    }

    public static async getData(path: string) {
        const myInit = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession())
                    .getIdToken()
                    .getJwtToken()}`,
            },
        };

        return API.get(apiName, path, myInit);
    }

    public static async deleteData(path: string) {
        const myInit = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession())
                    .getIdToken()
                    .getJwtToken()}`,
            },
        };

        return API.del(apiName, path, myInit);
    }
    
    public static async getDataAny(path: string) {
        const myInit = {

        };

        return API.get(apiName, path, myInit);
    }
}
