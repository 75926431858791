import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../../../store';
import { ShipmentStatus } from '../../../../modules/shipments/enums/ShipmentStatus';
import { IUpdateShipmentStatus } from '../../../../modules/shipments/interfaces/IShipment';
import { resetShipment, updateShipment } from '../../../../modules/shipments/features/shipmentCreateSlice';
import { fetchShipmentById } from '../../../../modules/shipments/features/singleShipmentSlice';
import { fetchShipmentsComents } from '../../../../modules/shipmentComments/features/ShipmentsCommentsSlice';
import { SingleShipmentStatusChange } from '../../../../modules/shipments/interfaces/ISingleShipment';
import formatDate from '../../../../utils/formatDate';

type FormData = {
    status: ShipmentStatus;
    comment: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
};

const status = [
    { "value": ShipmentStatus.PENDING, "name": "Pendiente" },
    { "value": ShipmentStatus.IN_TRANSIT, "name": "En transito" },
    { "value": ShipmentStatus.DELIVERED, "name": "Entregado" },
    { "value": ShipmentStatus.CANCELLED, "name": "Cancelado" },
    { "value": ShipmentStatus.FAILED, "name": "Fallo" },

]

export function ChangeStatus(params: { statusChanges: SingleShipmentStatusChange[] }) {
    const { register, handleSubmit, reset } = useForm<FormData>();
    const { statusChanges } = params

    const dispatch = useDispatch();

    const shipmentUpdate = useSelector(
        (state: RootState) => state.shipmentCreate
    );

    const { id } = useParams();


    const [createSuccess, setCreateSuccess] = useState(false);

    const onSubmit = async (data: FormData) => {
        const payload: IUpdateShipmentStatus = {
            id: id || '',
            status: data.status,
            statusChange: true,
            description: data.comment
        };

        dispatch(updateShipment(payload));
    };

    const navigate = useNavigate();
    useEffect(() => {
        if (shipmentUpdate.status === 'success' && id) {
            reset();
            setCreateSuccess(true);
            dispatch(resetShipment());
            setTimeout(() => {
                setCreateSuccess(false);
                dispatch(fetchShipmentById(id));
                dispatch(fetchShipmentsComents(id))
            }, 2000);
        }
    }, [reset, shipmentUpdate.status, dispatch, id, navigate]);

    return (
        <div className="grid grid-cols-2 gap-2 text-black shadow-md bg-gray-100 p-6 mb-36 mt-5">
            <div>
                <div className="w-full ">
                    <div className="mb-4 text-lg">
                        <h3 className="font-bold">Cambio de estado:
                            <p>
                                <span className='text-orange-600'>
                                    ADVERTENCIA! Al hacer el cambio de estado a CANCELADO, las guías no serán trackeadas de forma automática.</span>
                            </p>
                        </h3>
                    </div>
                    <div className="mt-5">
                        <div className="mt-4">
                            {/* onSubmit={handleSubmit(onSubmit)} */}
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <label
                                    htmlFor="status"
                                    className="block text-sm font-medium text-gray-700 mt-5"
                                >
                                    Estado *

                                    <select
                                        id="status"
                                        autoComplete="status"
                                        maxLength={200}
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...register('status', {
                                        })}
                                    >
                                        <option>Selecciona un estado</option>
                                        {
                                            status.map((s) => (
                                                <option value={s.value}> {s.name}</option>
                                            ))
                                        }

                                    </select>

                                </label>
                                <label
                                    className="block mb-2 text-sm font-medium text-gray-700 mt-5"
                                    htmlFor="large_size"
                                >
                                    Comentario
                                    <textarea
                                        className="block mb-5 mt-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:outline-none"
                                        id="default_size"
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...register('comment', {
                                            required: true,
                                        })}
                                    />
                                </label>


                                <div className="mt-5">
                                    <button
                                        disabled={
                                            shipmentUpdate.status === 'loading'
                                        }
                                        type="submit"
                                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                                    >
                                        {shipmentUpdate.status === 'loading'
                                            ? 'Loading...'
                                            : 'Cambiar Estado'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>


                {createSuccess && (
                    <div
                        className="z-50 fixed right-10 top-24  bg-green-100 inline-flex w-1/2  border border-green-400 text-green-700 px-4 py-3 rounded"
                        role="alert"
                    >
                        <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="check-circle"
                            className="w-4 h-auto mr-2 fill-current"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                        >
                            <path
                                fill="currentColor"
                                d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
                            />
                        </svg>
                        <strong className="font-bold mr-2">Sucess!</strong>
                        <span className="block sm:inline">
                            Estado Actualizado
                            {/* {params
															? t('branches.edit.success')
															: t('branches.create.success')} */}
                        </span>
                        <span
                            className="absolute top-0 bottom-0 right-0 px-4 py-3"
                            onClick={() => setCreateSuccess(false)}
                            onKeyPress={() => ''}
                            role="button"
                            tabIndex={0}
                        >
                            <svg
                                className="fill-current h-6 w-6 text-green-500"
                                role="button"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                            >
                                <title>Close</title>
                                <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                            </svg>
                        </span>
                    </div>
                )}
            </div>
            <div>


                <table className="table-auto w-full mx-auto" role="table">
                    <thead className="text-white"><tr className="text-left" role="row">
                        <th className="px-2 py-2 bg-slate-500 border-2 border-white rounded-lg" >
                            <div className="flex justify-between">Status</div>
                        </th>
                        <th className="px-2 py-2 bg-slate-500 border-2 border-white rounded-lg" role="columnheader">
                            <div className="flex justify-between">Fecha</div>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                        {statusChanges.map((item) => (
                            <tr>
                                <td>{item.comments}</td>
                                <td>{formatDate(new Date(item.createdAt), 'dd/MM/yyyy HH:mm')}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
