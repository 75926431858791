/* eslint-disable @typescript-eslint/no-explicit-any */

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as XLSX from 'xlsx';

import { getReportGeneralData } from '../../../../modules/report/features/reportGeneralDataSlice';
import { RootState } from '../../../../store';

interface GetDataParams {
    endDate: string;
    startDate: string;
    branches: string[];
    couriers: string[];
}

const columnsTitle: { [key: string]: string } = {
    mbeGuideNumber: 'MBE Nº. Guía',
    courierGuideNumber: 'Courier Nº. Guía',
    courierName: 'Courier',
    courierDeliveryType: 'Tipo de envío',
    status: 'Estatus del envío',

    originStreet: 'Origen Calle / Avenida / Pasaje',
    originNumber: 'Origen Casa / Edif / Torre',
    originComuna: 'Origen comuna',
    originRegion: 'Origen región',
    senderPhoneNumber: 'Teléfono remitente',
    senderEmail: 'Email remitente',
    senderName: 'Nombre del remitente',

    destinationStreet: 'Destino Calle / Avenida / Pasaje',
    destinationNumber: 'Destino Casa / Edif / Torre',
    destinationComuna: 'Destino comuna',
    destinationRegion: 'Destino región',
    receiverPhoneNumber: 'Teléfono destinatario',
    receiverEmail: 'Email destinatario',
    receiverName: 'Nombre del destinatario',

    height: 'Alto',
    length: 'Largo',
    weight: 'Peso',
    width: 'Ancho',
    finalPrice: 'Precio',
    branchName: 'Tienda',
};

export default () => {
    const dispatch = useDispatch();
    const reportGeneralData = useSelector(
        (state: RootState) => state.reportGeneralData
    );

    const [reportGeneralDataSeted, setReportGeneralDataSeted] = useState(false);

    useEffect(() => {
        if (
            reportGeneralData.status === 'success' &&
            reportGeneralDataSeted === false
        ) {
            const data = reportGeneralData.data.map((item: any) => {
                const keysValues = Object.keys(item);
                const values = keysValues.reduce(
                    (acc, currKey) => ({
                        ...acc,
                        [columnsTitle[currKey]]: item[currKey],
                    }),
                    {}
                );
                return values;
            });
            const worksheet = XLSX.utils.json_to_sheet(data);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(
                workbook,
                worksheet,
                'Reporte general'
            );
            XLSX.writeFile(workbook, 'reporte-general.xlsx');

            setReportGeneralDataSeted(true);
        }
    }, [
        reportGeneralData.data,
        reportGeneralData.status,
        reportGeneralDataSeted,
    ]);

    const exportData = (seacrhParams: GetDataParams) => {
        dispatch(getReportGeneralData(seacrhParams));
        setReportGeneralDataSeted(false);
    };

    return {
        exportData,
        status: reportGeneralData.status,
    };
};
