/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { validateRut, formatRut, RutFormat } from '@fdograph/rut-utilities';
import { ErrorMessage } from '@hookform/error-message';
import LoadingAnimated from '../../../../components/elements/Loading';
import { RootState, useAppDispatch } from '../../../../store';
import { fetchRegions } from '../../../../modules/geo/features/regionSlice';
import { fetchComunas } from '../../../../modules/geo/features/comunaSlice';
import { fetchCouriers } from '../../../../modules/couriers/features/couriersSlice';
import {
    createClient,
    updateClient,
    fetchClients,
} from '../../../../modules/clients/features';
import { IComuna } from '../../../../modules/geo/interfaces';
import AlertSuccess from '../../../../components/Alerts/Success';

type FormData = {
    id: string;
    dni: string;
    identifier?: string;
    isNotLocal: boolean;
    name: string;
    address: string;
    addressNumber: string;
    comunaId: string;
    regionId: string;
    couriersFee: {
        id?: string;
        courierId: string;
        fee: number;
        percentage: number;
    }[];
    warehouses: {
        id?: string;
        warehouseAddress: string;
        warehouseAddressNumber: string;
        warehouseRegionId: string;
        warehouseComunaId: string;
        forDelete?: boolean;
    }[];
};

export default function ClientsForm() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        watch,
        setValue,
        control,
    } = useForm<FormData>();

    // GEO  ///////////////////////////////////////////////////////////////////////////////////////////////
    const selectedOriginRegion = watch('regionId');
    const [comunasByRegionId, setComunasByRegionId] = useState<IComuna[]>([]);
    const [comunasMaster, setComunasMaster] = useState<IComuna[]>([]);
    const regions = useSelector((state: RootState) => state.regions);
    const comunas = useSelector((state: RootState) => state.comunas);
    const client = useSelector((state: RootState) => state.client);

    useEffect(() => {
        if (regions.status === 'idle') {
            dispatch(fetchRegions());
        }
        if (comunas.status === 'idle') {
            dispatch(fetchComunas());
        }
        if (comunas.status === 'success' && comunasMaster.length === 0) {
            setComunasMaster(comunas.data);
        }
    }, [
        comunas.status,
        regions.status,
        comunas.data,
        comunasMaster.length,
        dispatch,
    ]);

    useEffect(() => {
        if (
            selectedOriginRegion &&
            comunasByRegionId[0]?.regionId.toString() !== selectedOriginRegion
        ) {
            const filteredComunas = comunas.data.filter(
                (c) => c.regionId.toString() === selectedOriginRegion
            );
            setComunasByRegionId(filteredComunas);
        }
    }, [selectedOriginRegion, comunas, comunasByRegionId]);

    useEffect(() => {
        if (
            client.status === 'success' &&
            client.data !== null &&
            client.data.id
        ) {
            if (client) {
                setValue('dni', client.data.dni);
                setValue('name', client.data.name);
                setValue('identifier', client.data.identifier);
                setValue('isNotLocal', !client.data.isLocal);
                setValue('address', client.data.address);
                setValue('addressNumber', client.data.addressNumber || '');
                setValue('regionId', client.data.regionId.toString() || '');
                setValue('couriersFee', client.data.branchClientFeeByCourier);
                setTimeout(() => {
                    if (client.data) {
                        setValue(
                            'comunaId',
                            client.data.comunaId.toString() || ''
                        );
                        setValue(
                            'warehouses',
                            client.data.branchClientWarehouse.map(
                                ({
                                    warehouseRegionId,
                                    warehouseComunaId,
                                    ...rest
                                }) => ({
                                    ...rest,
                                    warehouseRegionId:
                                        warehouseRegionId.toString(),
                                    warehouseComunaId:
                                        warehouseComunaId.toString(),
                                })
                            )
                        );
                    }
                }, 100);
            }
        }
    }, [client, setValue]);
    // GEO -> FINISH  ///////////////////////////////////////////////////////////////////////////////////////////////

    // COURIERS ///////////////////////////////////////////////////////////////////////////////////////////////
    const couriers = useSelector((state: RootState) => state.couriers);
    useEffect(() => {
        if (couriers.status === 'idle') {
            dispatch(fetchCouriers());
        }
    }, [couriers.status, dispatch]);

    // END COURIERS ///////////////////////////////////////////////////////////////////////////////////////////////

    // CREATE CLIENT ///////////////////////////////////////////////////////////////////////////////////////////////
    const [createSuccess, setCreateSuccess] = useState(false);
    const [clientCreateStatus, setClientCreateStatus] = useState('idle');

    const onSubmit = async (data: FormData) => {
        try {
            setClientCreateStatus('loading');
            const branchClientFeeByCourier = data.couriersFee.map(
                ({ fee, percentage, ...rest }, index) => ({
                    ...rest,
                    fee: +fee,
                    percentage: +percentage,
                    courierId: couriers.data[index].id,
                })
            );
            const branchClientWarehouse = data.warehouses.map(
                ({ warehouseRegionId, warehouseComunaId, ...rest }) => ({
                    ...rest,
                    warehouseCountryId: 1,
                    warehouseRegionId: +warehouseRegionId,
                    warehouseComunaId: +warehouseComunaId,
                })
            );
            await dispatch(
                createClient({
                    dni: data.dni,
                    name: data.name,
                    address: data.address,
                    addressNumber: data.addressNumber,
                    countryId: 1,
                    regionId: +data.regionId,
                    comunaId: +data.comunaId,
                    branchClientFeeByCourier,
                    branchClientWarehouse,
                    isLocal: !data.isNotLocal,
                })
            ).unwrap();
            setCreateSuccess(true);
            setClientCreateStatus('success');
            reset({
                name: '',
                address: '',
                addressNumber: '',
                regionId: '',
                comunaId: '',
            });
            dispatch(fetchClients());
            setTimeout(() => {
                navigate('/clients');
            }, 3000);
        } catch (error) {
            setClientCreateStatus('error');
        }
    };

    const onUpdate = async (data: FormData) => {
        try {
            setClientCreateStatus('loading');
            const branchClientFeeByCourier = data.couriersFee.map(
                ({ fee, percentage, courierId }, index) => ({
                    fee: +fee,
                    percentage: +percentage,
                    courierId: courierId ?? couriers.data[index].id,
                })
            );
            const branchClientWarehouse = data.warehouses.map(
                ({
                    warehouseRegionId,
                    warehouseComunaId,
                    warehouseAddress,
                    warehouseAddressNumber,
                    forDelete,
                }) => ({
                    warehouseCountryId: 1,
                    warehouseRegionId: +warehouseRegionId,
                    warehouseComunaId: +warehouseComunaId,
                    warehouseAddress,
                    warehouseAddressNumber,
                    forDelete,
                })
            );
            await dispatch(
                updateClient({
                    id: client.data?.id ?? '',
                    branchId: 'cl1doay5n0007pfd86vhihped',
                    dni: data.dni,
                    isLocal: !data.isNotLocal,
                    name: data.name,
                    address: data.address,
                    addressNumber: data.addressNumber,
                    countryId: 1,
                    regionId: +data.regionId,
                    comunaId: +data.comunaId,
                    branchClientFeeByCourier,
                    branchClientWarehouse,
                })
            ).unwrap();
            setCreateSuccess(true);
            setClientCreateStatus('success');
            dispatch(fetchClients());
            setTimeout(() => {
                setCreateSuccess(false);
            }, 3000);
        } catch (error) {
            setClientCreateStatus('error');
        }
    };

    // CREATE CLIENT FINISH ////////////////////////////////////////////////////////////////////////////////////////

    // WAREHOUSES /////////////////////////////////////////////////////////////////////////////////////////////////////////
    const { fields, append: addWarehouse } = useFieldArray({
        control,
        name: 'warehouses',
    });
    const watchWarehouses = watch('warehouses');
    const controlledWarehouses = fields.map((field, index) => ({
        ...field,
        ...watchWarehouses[index],
    }));

    const addWarehouses = () => {
        addWarehouse({
            warehouseAddress: '',
            warehouseAddressNumber: '',
            warehouseRegionId: '',
            warehouseComunaId: '',
        });
    };

    const removeWarehouses = (index: number) => {
        setValue(`warehouses.${index}.forDelete`, true);
    };

    // WAREHOUSES FINISH /////////////////////////////////////////////////////////////////////////////////////////////////////////

    // IS LOCAL /////////////////////////////////////////////////////////////////////////////////////////////////////////
    const isNotLocal = watch('isNotLocal');
    useEffect(() => {
        if (isNotLocal) {
            setValue('dni', '55555555-5');
        }
        if (
            !isNotLocal &&
            (!client.data?.dni || client.data?.dni === '55555555-5')
        ) {
            setValue('dni', '');
        }
    }, [client.data?.dni, isNotLocal, setValue]);
    // IS LOCAL FINISH /////////////////////////////////////////////////////////////////////////////////////////////////////////

    return (
        <div className="mb-40">
            {client.status === 'loading' && (
                <LoadingAnimated message={t('common.loading')} />
            )}

            {client.status !== 'loading' && (
                <form
                    onSubmit={
                        client.data && client.data.id !== ''
                            ? handleSubmit(onUpdate)
                            : handleSubmit(onSubmit)
                    }
                >
                    {/*  isNotLocal */}
                    <fieldset className="space-y-5 mb-8">
                        <legend className="sr-only">Notifications</legend>
                        <div className="relative flex items-start">
                            <div className="flex h-5 items-center">
                                <input
                                    id="isNotLocal"
                                    aria-describedby="comments-description"
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                    {...register('isNotLocal')}
                                />
                            </div>
                            <div className="ml-3 text-sm">
                                <label
                                    htmlFor="isNotLocal"
                                    className="font-medium text-gray-700"
                                >
                                    {t('clients.isLocal')}
                                </label>
                                <p
                                    id="comments-description"
                                    className="text-gray-500"
                                >
                                    {t('clients.isLocalLabel')}
                                </p>
                            </div>
                        </div>
                    </fieldset>
                    {/* NAME */}
                    <div>
                        <label
                            htmlFor="dni"
                            className="block text-sm font-medium text-gray-700"
                        >
                            {t('clients.dni')} *
                            <div className="mt-1">
                                <Controller
                                    control={control}
                                    name="dni"
                                    render={({ field: { value } }) => (
                                        <input
                                            id="receiverRut"
                                            type="text"
                                            disabled={isNotLocal}
                                            className={`mt-2 ${
                                                errors.dni
                                                    ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                    : ''
                                            }`}
                                            {...register('dni', {
                                                required: true,
                                                value: formatRut(
                                                    value,
                                                    RutFormat.DOTS_DASH
                                                ),
                                                validate: (v) => {
                                                    if (isNotLocal) {
                                                        validateRut(v);
                                                    }
                                                    return true;
                                                },
                                            })}
                                        />
                                    )}
                                />
                                {errors.dni && (
                                    <p className="text-red-600 ml-2">
                                        {errors.dni.type === 'required' &&
                                            t('common.errors.fieldRequired')}
                                        {errors.dni.type === 'validate' &&
                                            t('common.errors.invalidRut')}
                                    </p>
                                )}
                            </div>
                        </label>
                    </div>
                    {/* NAME */}
                    <div>
                        <label
                            htmlFor="name"
                            className="block text-sm font-medium text-gray-700"
                        >
                            {t('branches.name')} *
                            <div className="mt-1">
                                <input
                                    id="name"
                                    type="text"
                                    className={`${
                                        errors.name
                                            ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                            : ''
                                    }`}
                                    autoComplete="name"
                                    {...register('name', {
                                        required: true,
                                    })}
                                />
                                {errors.name && (
                                    <p className="text-red-600 ml-2">
                                        {errors.name.type === 'required' &&
                                            t('common.errors.fieldRequired')}
                                    </p>
                                )}
                            </div>
                        </label>
                    </div>

                    {/* REGION */}
                    <div className="mt-4">
                        <label
                            htmlFor="regionId"
                            className="block text-sm font-medium text-gray-700"
                        >
                            {t('branches.region')} *
                            <select
                                id="regionId"
                                {...register('regionId', {
                                    required: true,
                                })}
                            >
                                <option value="">
                                    {regions.status === 'loading'
                                        ? t('common.loading')
                                        : t('quotes.create.regionSelect')}
                                </option>
                                {regions.data.map((region) => (
                                    <option key={region.id} value={region.id}>
                                        {region.name}
                                    </option>
                                ))}
                            </select>
                        </label>
                        {errors.regionId && (
                            <p className="text-red-600 ml-2">
                                {errors.regionId.type === 'required' &&
                                    t('common.errors.fieldRequired')}
                            </p>
                        )}
                    </div>

                    {/* COMUNA */}
                    <div className="mt-4">
                        <label
                            htmlFor="comunaOrigin"
                            className="block text-sm font-medium text-gray-700"
                        >
                            {t('branches.comuna')} *
                            <select
                                id="comunaOrigin"
                                disabled={selectedOriginRegion === ''}
                                className={`mt-2 ${
                                    selectedOriginRegion === ''
                                        ? 'cursor-not-allowed'
                                        : ''
                                }`}
                                {...register('comunaId', {
                                    required: true,
                                    validate: (value) => value !== '',
                                })}
                                placeholder={t('branches.comuna')}
                            >
                                {comunas.status === 'loading' && (
                                    <option value="">
                                        {t('common.loading')}
                                    </option>
                                )}
                                {!selectedOriginRegion && (
                                    <option value="">
                                        {t('quotes.create.firstSelectRegion')}
                                    </option>
                                )}
                                {selectedOriginRegion && (
                                    <option value="">
                                        {t('quotes.create.comunaSelect')}
                                    </option>
                                )}

                                {selectedOriginRegion &&
                                comunasByRegionId.length > 0 ? (
                                    comunasByRegionId.map((comuna) => (
                                        <option
                                            key={comuna.id}
                                            value={comuna.id}
                                        >
                                            {comuna.name}
                                        </option>
                                    ))
                                ) : (
                                    <option>
                                        {t('quotes.create.regionSelect')}
                                    </option>
                                )}
                            </select>
                        </label>
                        {errors.comunaId && (
                            <p className="text-red-600 ml-2">
                                {errors.comunaId.type === 'required' &&
                                    t('common.errors.fieldRequired')}
                            </p>
                        )}
                    </div>
                    {/* ADDRESS */}
                    <div className="mt-4">
                        <label
                            htmlFor="address"
                            className="block text-sm font-medium text-gray-700"
                        >
                            {t('branches.address')} *
                            <div className="mt-1">
                                <input
                                    id="address"
                                    type="text"
                                    className={`${
                                        errors.address
                                            ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                            : ''
                                    }`}
                                    autoComplete="address"
                                    {...register('address', {
                                        required: true,
                                    })}
                                />
                                {errors.address && (
                                    <p className="text-red-600 ml-2">
                                        {errors.address.type === 'required' &&
                                            t('common.errors.fieldRequired')}
                                    </p>
                                )}
                            </div>
                        </label>
                    </div>

                    {/* ADDRESS NUMBER */}
                    <div className="mt-4">
                        <label
                            htmlFor="addressNumber"
                            className="block text-sm font-medium text-gray-700"
                        >
                            {t('clients.addressNumber')}
                            <div className="mt-1">
                                <input
                                    id="addressNumber"
                                    type="text"
                                    className={`${
                                        errors.addressNumber
                                            ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                            : ''
                                    }`}
                                    autoComplete="addressNumber"
                                    {...register('addressNumber', {
                                        required: false,
                                    })}
                                />
                                {errors.addressNumber && (
                                    <p className="text-red-600 ml-2">
                                        {errors.addressNumber.type ===
                                            'required' &&
                                            t('common.errors.fieldRequired')}
                                    </p>
                                )}
                            </div>
                        </label>
                    </div>
                    {/* Couriers fee config */}
                    {couriers.status === 'success' && (
                        <div className="mt-4">
                            <table className="table-auto w-full mx-auto">
                                <thead className="px-2 py-2 bg-slate-500 border-2 border-white rounded-lg text-white">
                                    <tr>
                                        <th
                                            colSpan={3}
                                            className="px-2 py-2 bg-slate-500 border-2 border-white rounded-lg"
                                        >
                                            <div className="flex justify-between">
                                                {t('branchFeeByCourier.title')}
                                            </div>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th className="px-2 py-2 bg-slate-500 border-2 border-white rounded-lg">
                                            <div className="flex justify-between">
                                                {t('branchFeeByCourier.name')}
                                            </div>
                                        </th>
                                        <th className="px-2 py-2 bg-slate-500 border-2 border-white rounded-lg">
                                            <div className="flex justify-between">
                                                {t('branchFeeByCourier.fee')}
                                            </div>
                                        </th>
                                        <th className="px-2 py-2 bg-slate-500 border-2 border-white rounded-lg">
                                            <div className="flex justify-between">
                                                {t(
                                                    'branchFeeByCourier.percentage'
                                                )}
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="px-2 py-2">
                                    {couriers.data.map((courier, index) => (
                                        <tr key={courier.id}>
                                            <td>
                                                <label
                                                    htmlFor="courierName"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    {courier.name}
                                                </label>
                                            </td>
                                            <td>
                                                <input
                                                    id={`fee${index}`}
                                                    type="number"
                                                    autoComplete="fee"
                                                    {...register(
                                                        `couriersFee.${index}.fee`,
                                                        {
                                                            required: true,
                                                        }
                                                    )}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name={`fee${index}`}
                                                    render={() => (
                                                        <p className="text-red-600 ml-2">
                                                            {t(
                                                                'common.errors.fieldRequired'
                                                            )}
                                                        </p>
                                                    )}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    id={`percentage.${index}`}
                                                    type="number"
                                                    autoComplete="percentage"
                                                    {...register(
                                                        `couriersFee.${index}.percentage`,
                                                        {
                                                            required: true,
                                                        }
                                                    )}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name={`couriersFee.${index}.percentage`}
                                                    render={() => (
                                                        <p className="text-red-600 ml-2">
                                                            {t(
                                                                'common.errors.fieldRequired'
                                                            )}
                                                        </p>
                                                    )}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}

                    {/* Warehouse */}
                    <div className="mt-4">
                        <table className="table-auto w-full mx-auto">
                            <thead className="px-2 py-2 bg-slate-500 border-2 border-white rounded-lg text-white">
                                <tr>
                                    <th
                                        colSpan={4}
                                        className="px-2 py-2 bg-slate-500 border-2 border-white rounded-lg"
                                    >
                                        <div className="flex justify-between">
                                            {t('warehouse.title')}
                                        </div>
                                    </th>
                                    <th className="px-2 py-2 bg-slate-500 border-2 border-white rounded-lg">
                                        <button
                                            onClick={addWarehouses}
                                            type="button"
                                            className="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-6 w-6"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                strokeWidth={2}
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M12 4v16m8-8H4"
                                                />
                                            </svg>
                                        </button>
                                    </th>
                                </tr>
                                <tr>
                                    <th className="px-2 py-2 bg-slate-500 border-2 border-white rounded-lg">
                                        <div className="flex justify-between">
                                            {t('warehouse.address')}
                                        </div>
                                    </th>
                                    <th className="px-2 py-2 bg-slate-500 border-2 border-white rounded-lg">
                                        <div className="flex justify-between">
                                            {t('warehouse.addressNumber')}
                                        </div>
                                    </th>
                                    <th className="px-2 py-2 bg-slate-500 border-2 border-white rounded-lg">
                                        <div className="flex justify-between">
                                            {t('warehouse.region')}
                                        </div>
                                    </th>
                                    <th className="px-2 py-2 bg-slate-500 border-2 border-white rounded-lg">
                                        <div className="flex justify-between">
                                            {t('warehouse.comuna')}
                                        </div>
                                    </th>
                                    <th className="px-2 py-2 bg-slate-500 border-2 border-white rounded-lg">
                                        <div className="flex justify-between">
                                            {t('warehouse.remove')}
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="px-2 py-2">
                                {controlledWarehouses
                                    .filter((item) => !item.forDelete)
                                    .map((warehouse, index) => (
                                        <tr key={warehouse.id}>
                                            <td>
                                                <input
                                                    id={`warehouseAddress${index}`}
                                                    type="text"
                                                    autoComplete="address"
                                                    {...register(
                                                        `warehouses.${index}.warehouseAddress`,
                                                        {
                                                            required: true,
                                                        }
                                                    )}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name={`warehouses.${index}.warehouseAddress`}
                                                    render={() => (
                                                        <p className="text-red-600 ml-2">
                                                            {t(
                                                                'common.errors.fieldRequired'
                                                            )}
                                                        </p>
                                                    )}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    id={`warehouseAddressNumber${index}`}
                                                    type="text"
                                                    autoComplete="addressNumber"
                                                    {...register(
                                                        `warehouses.${index}.warehouseAddressNumber`,
                                                        {
                                                            required: true,
                                                        }
                                                    )}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name={`warehouses.${index}.warehouseAddressNumber`}
                                                    render={() => (
                                                        <p className="text-red-600 ml-2">
                                                            {t(
                                                                'common.errors.fieldRequired'
                                                            )}
                                                        </p>
                                                    )}
                                                />
                                            </td>
                                            <td>
                                                <select
                                                    id="warehouseRegionId"
                                                    {...register(
                                                        `warehouses.${index}.warehouseRegionId`,
                                                        {
                                                            required: true,
                                                        }
                                                    )}
                                                >
                                                    <option value="">
                                                        {regions.status ===
                                                        'loading'
                                                            ? t(
                                                                  'common.loading'
                                                              )
                                                            : t(
                                                                  'quotes.create.regionSelect'
                                                              )}
                                                    </option>
                                                    {regions.data.map(
                                                        (region) => (
                                                            <option
                                                                key={region.id}
                                                                value={
                                                                    region.id
                                                                }
                                                            >
                                                                {region.name}
                                                            </option>
                                                        )
                                                    )}
                                                </select>
                                                <ErrorMessage
                                                    errors={errors}
                                                    name={`warehouses.${index}.warehouseRegionId`}
                                                    render={() => (
                                                        <p className="text-red-600 ml-2">
                                                            {t(
                                                                'common.errors.fieldRequired'
                                                            )}
                                                        </p>
                                                    )}
                                                />
                                            </td>
                                            <td>
                                                <select
                                                    id="warehouseComunaId"
                                                    {...register(
                                                        `warehouses.${index}.warehouseComunaId`,
                                                        {
                                                            required: true,
                                                        }
                                                    )}
                                                >
                                                    <option value="">
                                                        {warehouse.warehouseRegionId
                                                            ? t(
                                                                  'common.selectComuna'
                                                              )
                                                            : t(
                                                                  'common.selectRegion'
                                                              )}
                                                    </option>
                                                    {comunasMaster
                                                        .filter(
                                                            (comuna) =>
                                                                comuna.regionId ===
                                                                +warehouse.warehouseRegionId
                                                        )
                                                        .map((region) => (
                                                            <option
                                                                key={region.id}
                                                                value={
                                                                    region.id
                                                                }
                                                            >
                                                                {region.name}
                                                            </option>
                                                        ))}
                                                </select>
                                                <ErrorMessage
                                                    errors={errors}
                                                    name={`warehouses.${index}.warehouseComunaId`}
                                                    render={() => (
                                                        <p className="text-red-600 ml-2">
                                                            {t(
                                                                'common.errors.fieldRequired'
                                                            )}
                                                        </p>
                                                    )}
                                                />
                                            </td>
                                            <td>
                                                <button
                                                    onClick={() =>
                                                        removeWarehouses(index)
                                                    }
                                                    type="button"
                                                    className="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="h-6 w-6"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                        strokeWidth={2}
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M20 12H4"
                                                        />
                                                    </svg>
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="mt-4">
                        {clientCreateStatus === 'error' && (
                            <p className="text-red-600 mb-2">
                                {t('branches.error')}
                            </p>
                        )}

                        {client.data && client.data.id !== '' ? (
                            <button
                                disabled={clientCreateStatus === 'loading'}
                                type="submit"
                                className="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                            >
                                {clientCreateStatus === 'loading'
                                    ? t('wait.message')
                                    : t('common.update')}
                            </button>
                        ) : (
                            <button
                                disabled={clientCreateStatus === 'loading'}
                                type="submit"
                                className="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                            >
                                {clientCreateStatus === 'loading'
                                    ? t('wait.message')
                                    : t(
                                          clientCreateStatus === 'success'
                                              ? 'common.update'
                                              : 'common.create'
                                      )}
                            </button>
                        )}
                        {createSuccess && (
                            <AlertSuccess
                                title={t('clients.success')}
                                message=""
                                onClick={() => setCreateSuccess(false)}
                                fixed={true}
                            />
                        )}
                    </div>
                </form>
            )}
        </div>
    );
}
