/* eslint-disable @typescript-eslint/no-explicit-any */

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getReportOrdersByCourier } from '../../../../modules/report/features/reportOrdersByCourierSlice';
import { RootState } from '../../../../store';

interface GetDataParams {
    endDate: string;
    startDate: string;
    branches: string[];
    couriers: string[];
}

const ordersByCouriersDefaultData: any = {
    chart: {
        type: 'column',
    },
    title: {
        text: '',
    },
    legend: { enabled: false },
    xAxis: [],
    yAxis: [
        {
            // Secondary yAxis
            title: {
                text: 'Cantidad de órdenes',
            },
            opposite: false,
        },
    ],
    tooltip: {
        shared: false,
    },
    series: [],
};

export default () => {
    const dispatch = useDispatch();
    const reportOrdersByCourier = useSelector(
        (state: RootState) => state.reportOrdersByCourier
    );

    const [ordersByCouriersData, setOrdersByCouriersData] = useState({
        ...ordersByCouriersDefaultData,
    });

    const [ordersByCouriersDataSeted, setOrdersByCouriersDataSeted] =
        useState(false);

    useEffect(() => {
        if (
            reportOrdersByCourier.status === 'success' &&
            ordersByCouriersDataSeted === false
        ) {
            const series = [
                {
                    yAxis: 0,
                    name: 'Órdenes',
                    data: reportOrdersByCourier.data.map(
                        ({ value, ...rest }) => ({ ...rest, y: value })
                    ),
                },
            ];
            const xAxis = [
                {
                    categories: reportOrdersByCourier.data.map(
                        ({ name }) => name
                    ),
                    crosshair: true,
                },
            ];
            setOrdersByCouriersData({ ...ordersByCouriersData, series, xAxis });
            setOrdersByCouriersDataSeted(true);
        }
    }, [
        ordersByCouriersData,
        ordersByCouriersDataSeted,
        reportOrdersByCourier.data,
        reportOrdersByCourier.status,
    ]);

    const getData = (seacrhParams: GetDataParams) => {
        dispatch(getReportOrdersByCourier(seacrhParams));
        setOrdersByCouriersDataSeted(false);
    };

    return {
        getData,
        data: ordersByCouriersData,
        status: reportOrdersByCourier.status,
    };
};
