import { Button } from '../../../../components/elements/Buttons';

export default function Box({
    title,
    actions,
    image,
    alt,
}: {
    title: string;
    actions: { label: string; action: () => void }[];
    image: string;
    alt?: string;
}) {
    return (
        <div className="w-full md:w-96  text-center">
            <h1 className="text-sym-mbe-primary font-normal text-2xl py-4">
                {title}
            </h1>
            <div className="w-full py-6 bg-sym-mbe-seconday rounded-3xl  shadow-md h-72 p-6 justify-center item-center text-center">
                <div className="flex justify-center">
                    <img src={image} alt={alt} width={115} />
                </div>
                <div
                    className={`flex-col  ${
                        actions && actions.length === 1 ? 'pt-12' : 'pt-6'
                    }`}
                >
                    {actions.map(({ label, action }) => (
                        <Button
                            key={label}
                            label={label}
                            className="w-3/4 font-bold my-2 text-lg rounded-3xl "
                            onClick={action}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

Box.defaultProps = {
    alt: 'Icon',
};
