import { CognitoUser } from '@aws-amplify/auth';
import { Auth, Amplify } from 'aws-amplify';
// import awsconfig from '../../../aws-exports';
import { User } from '../../../context/interfaces';
import { UserRoles } from '../../../modules/users/enums';

type CognitoInfo = {
    attributes: {
        'custom:userRole': UserRoles;
    };
};

const awsconfig = {
    // OPTIONAL - if your API requires authentication
    Auth: {
        // REQUIRED - Amazon Cognito Identity Pool ID
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
        // REQUIRED - Amazon Cognito Region
        region: process.env.REACT_APP_AWS_REGION,
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    },
    API: {
        endpoints: [
            {
                name: process.env.REACT_APP_API_NAME,
                endpoint: process.env.REACT_APP_API_URL,
                region: process.env.REACT_APP_AWS_REGION,
            },
            {
                name: process.env.REACT_APP_API_NAME_2,
                endpoint: process.env.REACT_APP_API_URL_2,
                region: process.env.REACT_APP_AWS_REGION,
            },
        ],
    },
};

Amplify.configure(awsconfig);

export async function signIn(username: string, password: string) {
    const result = await Auth.signIn(username, password);
    if (result.challengeName === 'NEW_PASSWORD_REQUIRED') {
        throw new Error('NEW_PASSWORD_REQUIRED');
    }
    return result;
}

export async function currentUser(): Promise<User | null> {
    const user: CognitoUser = await Auth.currentAuthenticatedUser();
    const info: CognitoInfo = await Auth.currentUserInfo();

    return {
        id: user.getUsername(),
        email: user.getSignInUserSession()?.getIdToken().payload.email,
        firstName: user.getSignInUserSession()?.getIdToken().payload.given_name,
        lastName: user.getSignInUserSession()?.getIdToken().payload.family_name,
        role: info.attributes['custom:userRole'],
    };
}

export async function signOut() {
    await Auth.signOut();
}

export async function sendForgotPassword(email: string) {
    await Auth.forgotPassword(email);
}

export async function setForgotPassword(
    email: string,
    code: string,
    password: string
) {
    await Auth.forgotPasswordSubmit(email, code, password);
}

export async function changeTemporaryPassword(
    email: string,
    oldPassword: string,
    newPassword: string
) {
    const user = await Auth.signIn(email, oldPassword);
    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        const updatedUser = await Auth.completeNewPassword(
            user, // the Cognito User Object
            newPassword // the new password
        );
        return updatedUser;
    }
    return null;
}
