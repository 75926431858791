/* eslint-disable react/jsx-props-no-spreading */
import {
    useEffect,
    useState,
} from 'react';

import { useForm } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ErrorMessage } from '@hookform/error-message';

import { RootState } from '../../../../store';

import LoadingAnimated from '../../../../components/elements/Loading/index';
import { deleteCategory, resetUpdateCategory, updateCategory } from '../../../../modules/category/features/categorySlice';
import { fetchCategories } from '../../../../modules/category/features/categoriesSlice';

type FormData = {
	name: string;
};

const nameRegex = /^[a-zA-Z ]{2,30}$/;

export default function FormUpdate() {
    const dispatch = useDispatch();

    const categoryUpdate = useSelector((state: RootState) => state.category);
    const [alertSucess, setAlertSucess] = useState(false);

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<FormData>();

    const { t } = useTranslation();
    const { id } = useParams();


    const onSubmit = (data: FormData) => {
        // dispatch(resetInvite());
        dispatch(resetUpdateCategory());

        dispatch(
            updateCategory({
                id,
                name: data.name,

            })
        );
    };

    useEffect(() => {
        if (categoryUpdate.status === 'success' && categoryUpdate.data) {
            setValue('name', categoryUpdate.data.name);
        }
    }, [categoryUpdate.status,categoryUpdate.data, dispatch, setValue]);

    const closeAlertSucess = () => {
        setAlertSucess(false);
    };
	const [artDelete, setArtDelete] = useState(false);

	const handleDelete = () => {
		dispatch(deleteCategory(id || ''))
		setArtDelete(true)
	}
	const navigate = useNavigate();
    useEffect(() => {
        if (categoryUpdate.data  === null && categoryUpdate.status === 'success') {
            setAlertSucess(true);
            dispatch(resetUpdateCategory());
            setTimeout(() => {
                setAlertSucess(false);
				dispatch(fetchCategories())
                navigate('/category');
            }, 2000);
            // dispatch(fetchUsers());
        }
    }, [alertSucess, dispatch, categoryUpdate.status, categoryUpdate.data, navigate]);
    useEffect(() => {
        if (categoryUpdate.status === 'success' && artDelete) {
            setAlertSucess(true);
            dispatch(resetUpdateCategory());
            setTimeout(() => {
                setAlertSucess(false);
				dispatch(fetchCategories())
                navigate('/category');
            }, 2000);
            // dispatch(fetchUsers());
        }
    }, [alertSucess, dispatch, categoryUpdate.status, categoryUpdate.data, navigate, artDelete]);

	return categoryUpdate.data === null && categoryUpdate.status === 'loading' ? (
        <LoadingAnimated message={t('common.loading')} />
    ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
       
            <div className="flex-none w-full md:flex md:justify-evenly md:space-x-4">
                <div className="mt-2 w-full">
                    <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Nombre categoría
                        <div className="mt-1">
                            <input
                                id="name"
                                type="text"
                                className={`${errors.name
                                    ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                    : ''
                                    }`}
                                autoComplete="name"
                                {...register('name', {
                                    required: true,
                                    validate: (value) => nameRegex.test(value),
                                })}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="name"
                                render={() =>
                                    errors.name?.type === 'validate' ? (
                                        <p className="text-red-600 ml-2">
                                            Ingrese un nombre valido
                                        </p>
                                    ) : (
                                        <p className="text-red-600 ml-2">
                                            {t('common.errors.fieldRequired')}
                                        </p>
                                    )
                                }
                            />
                        </div>
                    </label>
                </div>

            </div>


            {/*  END BRANCHES */}
            <div className="mt-4">
                {categoryUpdate.status === 'error' && (
                    <ErrorMessage
                        name="User invited"
                        errors={errors}
                        render={() => (
                            <p className="text-red-600 ml-2">
                                {categoryUpdate.error}
                            </p>
                        )}
                    />
                )}
                {/* {userInvite.status === 'success' && (
													<p className="text-green-600 mb-2">
															{t('common.done')}
													</p>
											)} */}
				<div className='flex'>

                <button
				
                    disabled={categoryUpdate.status === 'loading'}
                    type="submit"
                    className="w-1/2 mr-2 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                >
                    {
                        // eslint-disable-next-line no-nested-ternary
                        categoryUpdate.status === 'loading'
                            ? t('wait.message')
                            : 'Actualizar'
                    }
                </button>
				<button
                    disabled={categoryUpdate.status === 'loading'}
                    type="button"
					onClick={handleDelete}
                    className="w-1/2 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                >
                    {
                        // eslint-disable-next-line no-nested-ternary
                        categoryUpdate.status === 'loading'
                            ? t('wait.message')
                            : 'Eliminar'
                    }
                </button>
			</div>
            </div>

            {categoryUpdate.status === 'error' && (
                <p className="text-red-600 ml-2 mt-4">Algo salio mal</p>
            )}
            {alertSucess && (
                <div
                    className="z-50 fixed right-10 top-24  bg-green-100 inline-flex w-1/2  border border-green-400 text-green-700 px-4 py-3 rounded"
                    role="alert"
                >
                    <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="check-circle"
                        className="w-4 h-auto mr-2 fill-current"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                    >
                        <path
                            fill="currentColor"
                            d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
                        />
                    </svg>
                    <strong className="font-bold mr-2">Sucess!</strong>
                    <span className="block sm:inline"> { artDelete ? 'Eliminado correctamente': 'Actualizado exitosamente '}</span>
                    <span
                        className="absolute top-0 bottom-0 right-0 px-4 py-3"
                        onClick={() => closeAlertSucess()}
                        onKeyPress={() => closeAlertSucess()}
                        role="button"
                        tabIndex={0}
                    >
                        <svg
                            className="fill-current h-6 w-6 text-green-500"
                            role="button"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                        >
                            <title>Close</title>
                            <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                        </svg>
                    </span>
                </div>
            )}
        </form>
    );
}
