import { useTranslation } from 'react-i18next';
import Loader from '../../components/elements/Loaders/Loader';

export default function SignIn() {
    const { t } = useTranslation();
    return (
        <div>
            <div className="min-h-screen flex flex-col justify-center items-center mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <Loader />
                <h1>{t('loading.message')}</h1>
            </div>
        </div>
    );
}
