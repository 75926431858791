// import { useState } from 'react';

import Items, { IItems } from './Items';

interface ISideBarProps {
    className?: string;
    currentPath: string | undefined;
    items?: IItems[];
    goTo: (path: string) => void;
}

export default function SideBar({
    className,
    currentPath,
    items,
    goTo,
}: ISideBarProps) {
    // const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <div className="relative flex flex-col h-full z-50">
            <nav
                className={`space-y-2 pt-16 pb-4 text-white symlab-header bg-sym-mbe h-full shadow-md  shadow-sym-sidebar-600 rounded-lg rounded-tr-none rounded-br-none border border-gray-400 overflow-x-hidden overflow-y-hidden hover:overflow-y-auto ${className}`}
            >
                <ul role="menubar" aria-label="main menu">
                    {items?.map((item) => (
                        <Items
                            key={item.label}
                            label={item.label}
                            icon={item.icon}
                            className={item.className}
                            labelClassName={item.labelClassName}
                            currentPath={currentPath}
                            disabled={item.disabled}
                            subItems={item.subItems}
                            route={item.route}
                            goTo={goTo}
                        />
                    ))}
                </ul>
            </nav>
            <div className="absolute bottom-2 left-0 text-center w-full justify-center">
                <p className="text-sm text-white">
                    Made by{' '}
                    <a
                        className="text-sym-mbe-primary"
                        href="https://symlab.io"
                        title="SymLab"
                    >
                        @symlab
                    </a>
                </p>
            </div>
        </div>
    );
}

SideBar.defaultProps = {
    className: '',
    items: [],
};
