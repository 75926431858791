import { ShipmentStatus } from '../../shipments/enums';

export enum NotificationsType {
    EMAIL = 'EMAIL',
}

export interface INotification {
    id: string;
    statusToNotify: ShipmentStatus;
    type: NotificationsType;
    active: boolean;
    sender: boolean;
    addressee: boolean;
    textTemplate?: string;
    createdAt: string;
    updatedAt: string;
}

export interface INotificationTags {
    tag: string;
    description: string;
}

export interface INotificationCreateUpdatePayload
    extends Pick<INotification, 'active' | 'textTemplate'> {
    statusToNotify: string;
    type: string;
    id?: string;
    sender: boolean;
    addressee: boolean;
}
