import { Routes, Route } from 'react-router-dom';

import useAuth from './context/useAuth';

/** UnAuthenticated Pages */
import Landing from './pages/Landing/Landing';
import SignIn from './pages/Landing/SignIn';
import SignUp from './pages/Landing/SignUp';
import ForgotSend from './pages/Landing/Forgot/Send';
import ForgotChange from './pages/Landing/Forgot/Change';
import Loading from './pages/Landing/Loading';

/** Authenticated Pages */
import Dashboard from './pages/Dashboard/Dashboard';
import Home from './pages/Dashboard/Home';
import Users from './pages/Dashboard/Users';
import ListUsers from './pages/Dashboard/Users/List';
import Shipments from './pages/Dashboard/Shipments';
import ListShipments from './pages/Dashboard/Shipments/List';
import CreateShipments from './pages/Dashboard/Shipments/Create';
import SingleShipment from './pages/Dashboard/Shipments/Single';
import Quotes from './pages/Dashboard/Quotes';
import CreateQuote from './pages/Dashboard/Quotes/Create';
import CreateBulkQuote from './pages/Dashboard/Quotes/Bulk';
import NotFound from './pages/Dashboard/NotFound';
import Branches from './pages/Dashboard/Branches';
import ListBranches from './pages/Dashboard/Branches/List';

import Tracking from './pages/Dashboard/Tracking';
import ListTracking from './pages/Dashboard/Tracking/List';

import Clients from './pages/Dashboard/Clients';
import ListClients from './pages/Dashboard/Clients/List';
import CreateUpdateClients from './pages/Dashboard/Clients/CreateUpdate';
import ListWidget from './pages/Landing/Widget/ListWidget';

import ConfigSystem from './pages/Dashboard/ConfigSystem';
import ConfigSystemUpdateForm from './pages/Dashboard/ConfigSystem/components/UpdateForm';
import CreateUpdateBranches from './pages/Dashboard/Branches/CreateUpdate';
import CreateUpdate from './pages/Dashboard/Users/CreateUpdate';

import Geo from './pages/Dashboard/Geo';
import GeoRegionAndComuna from './pages/Dashboard/Geo/RegionAndComuna';

import Category from './pages/Dashboard/Category';
import ListCategory from './pages/Dashboard/Category/List';
import CreateUpdateCategory from './pages/Dashboard/Category/CreateUpdate';

import Article from './pages/Dashboard/Article';
import ListArticle from './pages/Dashboard/Article/List';
import CreateUpdateArticle from './pages/Dashboard/Article/CreateUpdate';

import FAQ from './pages/Dashboard/Faq';
import ListFAQ from './pages/Dashboard/Faq/List';

import Notify from './pages/Dashboard/Notify';
import NotifyList from './pages/Dashboard/Notify/List';
import NotifyCreateForm from './pages/Dashboard/Notify/Create';

import Report from './pages/Dashboard/Report';
import Reports from './pages/Dashboard/Report/Reports';

function App() {
    const { user, initialLoading } = useAuth();

    if (initialLoading) {
        return <Loading />;
    }
    if (user) {
        return (
            <Routes>
                <Route path="/" element={<Dashboard />}>
                    <Route index element={<Home />} />
                    <Route path="users" element={<Users />}>
                        <Route index element={<ListUsers />} />
                        <Route path=":id" element={<CreateUpdate />} />
                    </Route>
                    <Route path="quotes" element={<Quotes />}>
                        <Route index element={<CreateQuote />} />
                        <Route path="bulk" element={<CreateBulkQuote />} />
                    </Route>
                    <Route path="shipments" element={<Shipments />}>
                        <Route index element={<ListShipments />} />
                        <Route
                            path="create/:id"
                            element={<CreateShipments />}
                        />
                        <Route path="detail/:id" element={<SingleShipment />} />
                    </Route>
                    <Route path="branches" element={<Branches />}>
                        <Route index element={<ListBranches />} />
                        <Route path=":id" element={<CreateUpdateBranches />} />
                    </Route>
                    <Route path="branches/create" element={<Clients />}>
                        <Route index element={<CreateUpdateBranches />} />
                    </Route>

                    {/* CLIENTS */}
                    <Route path="clients" element={<Clients />}>
                        <Route index element={<ListClients />} />
                        <Route path=":id" element={<CreateUpdateClients />} />
                    </Route>
                    <Route path="clients/create" element={<Clients />}>
                        <Route index element={<CreateUpdateClients />} />
                    </Route>
                    {/* CLIENTS END */}

                    {/* CONFIG SYSTEM  */}
                    <Route path="config/system" element={<ConfigSystem />}>
                        <Route index element={<ConfigSystemUpdateForm />} />
                    </Route>
                    {/* CONFIG SYSTEM END */}

                    <Route path="tracking" element={<Tracking />}>
                        <Route index element={<ListTracking />} />
                    </Route>

                    {/* GEO */}
                    <Route path="geo" element={<Geo />}>
                        <Route index element={<GeoRegionAndComuna />} />
                    </Route>
                    {/* CATEGORY */}
                    <Route path="category" element={<Category />}>
                        <Route index element={<ListCategory />} />
                        <Route path=":id" element={<CreateUpdateCategory />} />
                    </Route>
                    {/* ARTICLE */}
                    <Route path="article" element={<Article />}>
                        <Route index element={<ListArticle />} />
                        <Route path=":id" element={<CreateUpdateArticle />} />
                    </Route>
                    <Route path="faq" element={<FAQ />}>
                        <Route index element={<ListFAQ />} />
                    </Route>
                    {/* NOTIFICATIONS */}
                    <Route path="notify" element={<Notify />}>
                        <Route index element={<NotifyList />} />
                        <Route path="create" element={<NotifyCreateForm />} />
                        <Route path="update/:id" element={<NotifyCreateForm />} />
                    </Route>
                    {/* REPORT */}
                    <Route path="report" element={<Report />}>
                        <Route index element={<Reports />} />
                    </Route>

                    {/* END APIKEY */}
                    <Route path="*" element={<NotFound />} />
                </Route>
            </Routes>
        );
    }

    return (
        <Routes>
            <Route path="/" element={<Landing />}>
                <Route index element={<SignIn />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/forgot" element={<ForgotSend />} />
                <Route path="/forgot/code" element={<ForgotChange />} />
                <Route path="*" element={<SignIn />} />
            </Route>
            <Route path="tracking" element={<ListWidget />} />
        </Routes>
    );
}

export default App;
