/* eslint-disable no-console */
/* eslint-disable no-empty-pattern */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */

import {
    ChangeEvent,
    Dispatch,
    forwardRef,
    PropsWithChildren,
    SetStateAction,
    useEffect,
    useMemo,
    useRef,
    useState,
    LegacyRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Column,
    useTable,
    usePagination,
    useRowSelect,
    HeaderProps,
    Row,
} from 'react-table';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { jsPDF } from 'jspdf';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { RootState } from '../../../store';
import formatDate from '../../../utils/formatDate';
import {
    IShipment,
    IShipmentCourie,
} from '../../../modules/shipments/interfaces';
import LoadingAnimated from '../../../components/elements/Loading';
import { Bagde } from '../../../components/Shipment/Badges';
import {
    shipmentMergeLabels,
    clearShipmentMergeLabel,
} from '../../../modules/shipments/features';
import { ShipmentMergeLabelsState } from '../../../modules/shipments/features/shipmentMergeLabelsSlice';
import { ShipmentStatus } from '../../../modules/shipments/enums/ShipmentStatus';
import { IUpdateShipmentStatusBulk } from '../../../modules/shipments/interfaces/IShipment';
import {
    resetShipment,
    updateShipmentBulk,
} from '../../../modules/shipments/features/shipmentCreateSlice';
import { fetchShipments } from '../../../modules/shipments/features/shipmentsSlice';
import {
    sendEmail,
    shipmentSendEmailClear,
} from '../../../modules/shipments/features/shipmentSendEmailSlice';
import emailPattern from '../../../utils/emailPattern';
import Dialog from '../../../components/Dialog';

type FormData = {
    status: ShipmentStatus;
    comment: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
};
type FormNotify = {
    email?: string;
    description: string;
};

const status = [
    { value: ShipmentStatus.PENDING, name: 'Pendiente' },
    { value: ShipmentStatus.IN_TRANSIT, name: 'En transito' },
    { value: ShipmentStatus.DELIVERED, name: 'Entregado' },
    { value: ShipmentStatus.CANCELLED, name: 'Cancelado' },
    { value: ShipmentStatus.FAILED, name: 'Fallo' },
];

interface PaginationPropos {
    gotoPage: (updater: number | ((pageIndex: number) => number)) => void;
    canPreviousPage: boolean;
    previousPage: () => void;
    nextPage: () => void;
    pageIndex: number;
    pageOptions: number[];
    setPageSize: (pageSize: number) => void;
    pageCount: number;
    pageSize: number;
    canNextPage: boolean;
    setChangePage: React.Dispatch<React.SetStateAction<boolean>>;
    loading: boolean;
    setDownloadFile: Dispatch<SetStateAction<boolean>>;
    setDownloadLabels: Dispatch<SetStateAction<boolean>>;
    shipmentsLabelsMerged: ShipmentMergeLabelsState;
    handleDownloadPDF: () => void;
    setShowChangeStatus: Dispatch<SetStateAction<boolean>>;
    selectedFlatRows: Row<IShipment>[];
    setShowSendNotification: Dispatch<SetStateAction<boolean>>;
}

function Pagination(props: PaginationPropos) {
    const {
        setDownloadFile,
        handleDownloadPDF,
        setDownloadLabels,
        shipmentsLabelsMerged,
        loading,
        gotoPage,
        setChangePage,
        canPreviousPage,
        previousPage,
        nextPage,
        pageIndex,
        pageOptions,
        setPageSize,
        pageCount,
        canNextPage,
        pageSize,
        setShowChangeStatus,
        selectedFlatRows,
        setShowSendNotification,
    } = props;
    return (
        <div className="pagination flex space-x-6 mt-4 mb-4 justify-end">
            <div className="flex space-x-4 items-center">
                <button
                    type="button"
                    onClick={() => {
                        gotoPage(0);
                        setChangePage(true);
                    }}
                    disabled={!canPreviousPage || loading}
                >
                    {'<<'}
                </button>{' '}
                <button
                    type="button"
                    onClick={() => {
                        previousPage();
                        setChangePage(true);
                    }}
                    disabled={!canPreviousPage || loading}
                >
                    {'<'}
                </button>{' '}
                <button
                    type="button"
                    onClick={() => {
                        nextPage();
                        setChangePage(true);
                    }}
                    disabled={!canNextPage || loading}
                >
                    {'>'}
                </button>{' '}
                <button
                    type="button"
                    onClick={() => {
                        gotoPage(pageCount - 1);
                        setChangePage(true);
                    }}
                    disabled={!canNextPage || loading}
                >
                    {'>>'}
                </button>{' '}
                <span>
                    Página{' '}
                    <strong>
                        {pageIndex + 1} de {pageOptions.length}
                    </strong>{' '}
                </span>
            </div>
            <div>
                <select
                    value={pageSize}
                    disabled={loading}
                    onChange={(e) => {
                        setPageSize(Number(e?.target.value));
                        setChangePage(true);
                    }}
                >
                    {[10, 20, 50].map((pageS) => (
                        <option key={pageS} value={pageS}>
                            Mostrar {pageS}
                        </option>
                    ))}
                </select>
            </div>
            {selectedFlatRows.length > 0 && (
                <>
                    <div>
                        <button
                            onClick={() => setDownloadFile(true)}
                            disabled={selectedFlatRows.length === 0 || loading}
                            type="button"
                            className="group relative w-full flex justify-center py-2 px-4 border  text-sm font-medium rounded-md"
                        >
                            <div className="flex space-x-6 items-center">
                                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                                        />
                                    </svg>
                                </span>
                                <span>Descargar Excel</span>
                            </div>
                        </button>
                    </div>

                    <div>
                        <button
                            onClick={handleDownloadPDF}
                            disabled={selectedFlatRows.length === 0 || loading}
                            type="button"
                            className="group relative w-full flex justify-center py-2 px-4 border  text-sm font-medium rounded-md"
                        >
                            <div className="flex space-x-6 items-center">
                                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                                        />
                                    </svg>
                                </span>
                                <span>Descargar PDF</span>
                            </div>
                        </button>
                    </div>
                    <div>
                        <button
                            onClick={() => setDownloadLabels(true)}
                            type="button"
                            disabled={
                                shipmentsLabelsMerged.status === 'loading' ||
                                selectedFlatRows.length === 0 ||
                                loading
                            }
                            className="group relative w-full flex justify-center py-2 px-4 border  text-sm font-medium rounded-md"
                        >
                            <div className="flex space-x-6 items-center">
                                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                                        />
                                    </svg>
                                </span>
                                {shipmentsLabelsMerged.status === 'loading' ? (
                                    <span>Cargando...</span>
                                ) : (
                                    <span>Descargar etiquetas</span>
                                )}
                            </div>
                        </button>
                    </div>
                    <div>
                        <button
                            onClick={() => setShowChangeStatus(true)}
                            type="button"
                            disabled={selectedFlatRows.length === 0 || loading}
                            className="group relative w-full flex justify-center py-2 px-4 border  text-sm font-medium rounded-md"
                        >
                            <div className="flex space-x-6 items-center">
                                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-6 h-6"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                                        />
                                    </svg>
                                </span>
                                <span>Cambiar estatus</span>
                            </div>
                        </button>
                    </div>
                </>
            )}
            <div>
                <button
                    onClick={() => setShowSendNotification(true)}
                    type="button"
                    className="group relative w-full flex justify-center py-2 px-4 border  text-sm font-medium rounded-md"
                >
                    <div className="flex space-x-6 items-center">
                        <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                                />
                            </svg>
                        </span>
                        <span>Enviar notificación</span>
                    </div>
                </button>
            </div>
        </div>
    );
}

export interface TableCommonProps {
    style?: any | undefined;
    className?: string | undefined;
    role?: string | undefined;
}
export interface TableToggleCommonProps extends TableCommonProps {
    onChange?: ((e: ChangeEvent) => void) | undefined;
    checked?: boolean | undefined;
    title?: string | undefined;
    indeterminate?: boolean | undefined;
}

export type TableToggleAllRowsSelectedProps = TableToggleCommonProps;

export const IndeterminateCheckbox = forwardRef(
    (
        props: Partial<TableToggleAllRowsSelectedProps>,
        ref: LegacyRef<HTMLInputElement>
    ) => {
        const { ...rest } = props;
        const defaultRef = useRef<any>();
        const resolvedRef = ref || defaultRef;
        return <input type="checkbox" ref={resolvedRef} {...rest} />;
    }
);

export default function ShipmentListTable({
    head,
    sort,
    clickHeader,
    data,
    count,
    loading,
    paginateData,
}: {
    head: string;
    sort: string;
    clickHeader: (data: string | undefined) => any;
    data: IShipment[];
    loading: boolean;
    count: number;
    paginateData: (page: string, limit: string) => void;
}) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // let locale = 'en-US';
    // if (i18n.language === 'es') {
    //     locale = 'es-ES';
    // }

    // TABLE CONFIG
    const columns = useMemo<Column<IShipment>[]>(
        () => [
            {
                Header: t('shipments.guideNumber').toString(),
                accessor: 'guideNumber',
                Cell: ({ cell: { value } }: any) => {
                    if (value) {
                        return value;
                    }
                    return '-';
                },
            },
            {
                Header: t('common.branch').toString(),
                accessor: 'branch',
                Cell: ({ cell: { value } }: any) => {
                    if (value) {
                        return value.name;
                    }
                    return '-';
                },
            },
            {
                Header: 'Cliente',
                accessor: 'client',
                Cell: ({ cell: { value } }: any) => {
                    if (value) {
                        return value.name;
                    }
                    return '-';
                },
            },
            {
                Header: t('shipments.to').toString(),
                Cell: (rowData: any) => {
                    const { destinationComuna } = rowData.row
                        .original as IShipment;
                    return destinationComuna.name;
                },
            },
            {
                Header: t('couriers.title').toString(),
                accessor: 'courier',
                Cell: ({ cell: { value } }: any) => {
                    if (value) {
                        return value.name;
                    }
                    return ' ';
                },
            },
            {
                Header: t('couriers.trackingNumber').toString(),
                accessor: 'shipmentCourie',
                Cell: ({ cell: { value } }: any) => {
                    // eslint-disable-next-line no-unused-expressions
                    if (value) {
                        return value[0].trackingNumber;
                    }
                    return '-';
                },
            },
            {
                Header: 'F.Creación',
                accessor: 'createdAt',
                Cell: ({ cell: { value } }: any) => {
                    if (value) {
                        return formatDate(new Date(value), 'dd/MM/yyyy');
                    }
                    return ' ';
                },
            },
            {
                Header: t('common.startDateShort').toString(),
                accessor: 'deliveryEstimateDate',
                Cell: ({ cell: { value } }: any) => {
                    if (value) {
                        return formatDate(new Date(value), 'dd/MM/yyyy');
                    }
                    return ' ';
                },
            },
            {
                Header: t('common.endDateShort').toString(),
                accessor: 'endDate',
                Cell: ({ cell: { value } }: any) => {
                    if (value) {
                        return formatDate(new Date(value), 'dd/MM/yyyy');
                    }
                    return ' ';
                },
            },
            {
                Header: t('common.status').toString(),
                accessor: 'status',
                Cell: ({ cell: { value } }: any) => {
                    if (value) {
                        return <Bagde status={value} />;
                    }
                    return '-';
                },
            },
        ],
        [t]
    );
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        selectedFlatRows,
        // Get the state from the instance
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 }, // Pass our hoisted table state
            manualPagination: true, // Tell the usePagination
            // hook that we'll handle our own data fetching
            // This means we'll also have to provide our own
            // pageCount.
            pageCount: Math.ceil(count / 10),
        },
        usePagination,
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((Columns) => [
                // Let's make a column for selection
                {
                    id: 'selection',
                    // The header can use the table's getToggleAllRowsSelectedProps method
                    // to render a checkbox
                    // eslint-disable-next-line react/prop-types
                    Header: ({
                        getToggleAllRowsSelectedProps,
                    }: // ,
                    PropsWithChildren<HeaderProps<IShipment>>) => (
                        <div>
                            <IndeterminateCheckbox
                                {...getToggleAllRowsSelectedProps()}
                            />
                        </div>
                    ),
                    // The cell can use the individual row's getToggleRowSelectedProps method
                    // to the render a checkbox
                    Cell: ({ row }: any) => (
                        <div>
                            <IndeterminateCheckbox
                                {...row.getToggleRowSelectedProps()}
                            />
                        </div>
                    ),
                },
                ...Columns,
                {
                    id: 'viewDetails',
                    // The header can use the table's getToggleAllRowsSelectedProps method
                    // to render a checkbox
                    // eslint-disable-next-line react/prop-types
                    Header: ({}: PropsWithChildren<HeaderProps<IShipment>>) => (
                        <div> </div>
                    ),
                    // The cell can use the individual row's getToggleRowSelectedProps method
                    // to the render a checkbox
                    Cell: ({ row }: any) => (
                        <button
                            onClick={() => {
                                navigate(
                                    `/shipments/detail/${row.original.id}`
                                );
                            }}
                            type="button"
                            className="group cursor-pointer relative w-full flex justify-center py-2 px-4 text-sm font-medium rounded-md"
                        >
                            <div className="flex space-x-6 items-center">
                                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                        />
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                        />
                                    </svg>
                                </span>
                            </div>
                        </button>
                    ),
                },
            ]);
        }
    );
    // TABLE CONFIG FINISH
    // NEX OR PREV PAGE HANDLER
    const [changePage, setChangePage] = useState(false);
    useEffect(() => {
        if (changePage) {
            paginateData(pageIndex.toString(), pageSize.toString());
            setChangePage(false);
        }
    }, [pageIndex, changePage, paginateData, pageSize]);
    // NEX OR PREV PAGE HANDLER - FINISH

    // ********** DOWNLOAD SHIPMENTS TO EXCEL   **********
    const [downloadFile, setDownloadFile] = useState(false);
    useEffect(() => {
        if (selectedFlatRows.length === 0 || downloadFile === false) return;
        const mapData = selectedFlatRows.map((row: Row<IShipment>) => {
            const item: IShipment = row.original;
            const itemData = {
                'MBE Nº': item.guideNumber,
                Precio: item.finalPrice,
                Courier: item.courier.name,
                'Courier tracking': item.shipmentCourie
                    .map((i: IShipmentCourie) => i.trackingNumber)
                    .join(', '),
                'Región Origen': item.originRegion.name,
                'Comuna Origen': item.originComuna.name,
                'Calle Origen': item.originStreet,
                'Número Casa/Edificio Origen': item.originNumber,
                'Región Destino': item.destinationRegion.name,
                'Comuna Destino': item.destinationComuna.name,
                'Calle Destino': item.destinationStreet,
                'Número Casa/Edificio Destino': item.destinationNumber,
                'Largo (cm)': item.packages[0].length,
                'Ancho (cm)': item.packages[0].width,
                'Altura (cm)': item.packages[0].height,
                'Peso (kg)': item.packages[0].weight,
                'Nombre remitente': item.senderName,
                'Email Remitente': item.senderEmail,
                'Teléfono Remitente': item.senderPhoneNumber,
                'Nombre Destinatario': item.receiverName,
                'Email Destinatario': item.receiverEmail,
                'Teléfono Destinatario': item.receiverPhoneNumber,
                'Fecha de envío': new Date(
                    item.deliveryEstimateDate
                ).toLocaleDateString('es'),
                Estado: item.status,
                Comentarios: item.observations,
            };
            return itemData;
        });
        const worksheet = XLSX.utils.json_to_sheet(mapData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Dates');
        XLSX.writeFile(workbook, 'envios.xlsx');
        setDownloadFile(false);
    }, [downloadFile, selectedFlatRows]);
    // ********** DOWNLOAD SHIPMENTS TO EXCEL - FINISH  **********

    // **********   DOWNLOAD SHIPMENTS LABELS   **********
    const shipmentsLabelsMerged = useSelector(
        (state: RootState) => state.shipmentsLabelsMerged
    );

    const [downloadLabels, setDownloadLabels] = useState(false);

    useEffect(() => {
        if (downloadLabels === false || selectedFlatRows.length === 0) return;
        dispatch(
            shipmentMergeLabels(
                selectedFlatRows.map((row: Row<IShipment>) => row.original.id)
            )
        );
        setDownloadLabels(false);
    }, [downloadLabels, data, dispatch, selectedFlatRows]);

    useEffect(() => {
        if (shipmentsLabelsMerged.status === 'success') {
            const a = document.createElement('a');
            a.href = `data:application/pdf;base64,${shipmentsLabelsMerged.data}`;
            a.download = 'etiquetas.pdf';
            a.click();
            dispatch(clearShipmentMergeLabel());
        }
    }, [shipmentsLabelsMerged, dispatch]);
    // **********   DOWNLOAD SHIPMENTS LABELS - FINISH  **********

    // **********   DOWNLOAD PDF  **********
    const createHeaders = (keys: any) => {
        const result = [];
        for (let i = 0; i < keys.length; i += 1) {
            result.push({
                id: keys[i],
                name: keys[i],
                prompt: keys[i],
                width: 1200,
                align: 'center',
                padding: 0,
            });
        }
        return result;
    };

    const setHeaders = () => {
        const listData: any = [];

        const result = createHeaders([
            'Courier',
            'Tracking',
            'Dirección Origen',
            'Dirección Destino',
            'Guía',
            'Estado',
            'Precio',
            'Tienda',
            'Fecha de envío',
        ]);

        selectedFlatRows
            .map((row: Row<IShipment>) => row.original)
            .forEach((e: any) => {
                listData.push({
                    Courier: e.courier.name || '',
                    Tracking:
                        `${e.shipmentCourie
                            .map((tracking: any) => tracking.trackingNumber)
                            .join(', ')}` || '-',
                    'Dirección Origen':
                        `${e.originStreet},${e.originComuna?.name}, ${e.originRegion?.name} ` ||
                        '-',
                    'Dirección Destino':
                        `${e.destinationStreet},${e.destinationRegion?.name}, ${e.destinationComuna?.name} ` ||
                        '-',
                    Guía: e.guideNumber || '-',
                    Estado: e.status || '-',
                    Precio: e.finalPrice.toString() || '-',

                    Tienda: e.branch?.name || '-',
                    'Fecha de envío':
                        new Date(e.deliveryEstimateDate).toLocaleDateString(
                            'es'
                        ) || '-',
                });
            });

        return { result, listData };
    };

    const handleDownloadPDF = () => {
        const JsPDF = jsPDF;
        const doc = new JsPDF({
            orientation: 'l', // landscape
            unit: 'pt', // points, pixels won't work properly
            format: [612, 1200],
        });
        const { result, listData } = setHeaders();
        doc.table(1, 1, listData as any, result as any, { autoSize: true });
        doc.save('Envios.pdf');
    };

    // **********   DOWNLOAD PDF - FINISH  **********

    // SORT DATA
    const {
        register,
        handleSubmit,
        formState: { errors: changeStatusFormErrors },
    } = useForm<FormData>();
    const {
        register: registerNotify,
        handleSubmit: handleSubmitNotify,
        watch: watchNotify,
        formState: { errors: sendMailErrors },
        reset,
    } = useForm<FormNotify>();

    const shipmentUpdate = useSelector(
        (state: RootState) => state.shipmentCreate
    );

    const [showChangeStatus, setShowChangeStatus] = useState(false);
    const onSubmit = async (dataForm: FormData) => {
        const idsSelcteds = selectedFlatRows.map((sf) => sf.original.id);

        const payload: IUpdateShipmentStatusBulk = {
            id: idsSelcteds,
            status: dataForm.status,
            statusChange: true,
            description: dataForm.comment,
        };

        dispatch(updateShipmentBulk(payload));
    };

    useEffect(() => {
        if (shipmentUpdate.status === 'success') {
            setShowChangeStatus(false);
            dispatch(resetShipment());

            dispatch(fetchShipments());
        }
    }, [dispatch, shipmentUpdate.status]);

    // }, [shipmentUpdate.status, dispatch ])

    // GET EMAILS

    const watchSenderEmail = watchNotify('email');

    const [validsEmail, setValidsEmail] = useState('');
    const [showSendNotification, setShowSendNotification] = useState(false);
    const emailsState = useSelector((state: RootState) => state.sendEmail);

    useEffect(() => {
        if (emailsState.status === 'success' && showSendNotification === true) {
            setShowSendNotification(false);
            reset({ description: '', email: '' });
            dispatch(shipmentSendEmailClear());
        }
    }, [emailsState, showSendNotification, dispatch, reset]);

    const onSubmitNotify = (dataForm: FormNotify) => {
        // const senderEmail = selectedFlatRows.map((e) => e.original.senderEmail);
        const receiverEmail = selectedFlatRows.map(
            (e) => e.original.receiverEmail
        );
        if (dataForm.email) {
            receiverEmail.push(dataForm.email);
        }
        const payload = {
            to: receiverEmail,
            // subject: 'test',
            message: dataForm.description,
        };

        dispatch(sendEmail(payload));
    };

    useEffect(() => {
        if (selectedFlatRows.length > 0 || watchSenderEmail) {
            const receiverEmail = selectedFlatRows.map(
                (e) => e.original.receiverEmail
            );
            const countFilterEmail = receiverEmail.filter((e) => e === '');

            if (countFilterEmail.length > 0) {
                setValidsEmail(
                    `${countFilterEmail.length} elemento(s), no posee email`
                );
            } else {
                setValidsEmail('');
            }
        }
        if (selectedFlatRows.length === 0) {
            setValidsEmail('');
        }
    }, [selectedFlatRows, watchSenderEmail]);

    return (
        <div className="relative mb-20">
            {showChangeStatus && (
                <Dialog>
                    <div className="shadow-md bg-gray-100 text-darjk p-4  p-4 my-4 rounded-2xl ">
                        <div className="mb-4 text-lg">
                            <h3 className="font-bold">
                                Cambio de estado:
                                <span className="p-4 text-orange-600">
                                    ADVERTENCIA! Al hacer el cambio de estado a
                                    CANCELADO, las guías no serán trackeadas de
                                    forma automática.
                                </span>
                            </h3>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div>
                                <label
                                    htmlFor="status"
                                    className="block text-sm font-medium text-white-700 mt-5"
                                >
                                    Estado *
                                    <select
                                        className="text-gray-700"
                                        id="status"
                                        autoComplete="status"
                                        maxLength={200}
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...register('status', {
                                            required: true,
                                        })}
                                    >
                                        <option>Selecciona un estado</option>
                                        {status.map((s) => (
                                            <option value={s.value}>
                                                {' '}
                                                {s.name}
                                            </option>
                                        ))}
                                    </select>
                                </label>
                                <ErrorMessage
                                    errors={changeStatusFormErrors}
                                    name="status"
                                    render={() => (
                                        <p className="text-red-600 ml-2">
                                            {t('common.errors.fieldRequired')}
                                        </p>
                                    )}
                                />
                            </div>

                            <div>
                                <label
                                    className="block text-sm font-medium text-white-700 mt-5"
                                    htmlFor="large_size"
                                >
                                    Comentario *
                                    <textarea
                                        className="block mb-5 mt-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 dark:text-gray-400 focus:outline-none dark:border-gray-600 dark:placeholder-gray-400"
                                        id="default_size"
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...register('comment', {
                                            required: true,
                                        })}
                                    />
                                </label>
                                <ErrorMessage
                                    errors={changeStatusFormErrors}
                                    name="comment"
                                    render={() => (
                                        <p className="text-red-600 ml-2">
                                            {t('common.errors.fieldRequired')}
                                        </p>
                                    )}
                                />
                            </div>

                            <div className="mt-5 grid grid-cols-2">
                                <div className="m-4">
                                    <button
                                        disabled={
                                            shipmentUpdate.status ===
                                                'loading' ||
                                            selectedFlatRows.length === 0
                                        }
                                        type="submit"
                                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                                    >
                                        {shipmentUpdate.status === 'loading'
                                            ? 'Loading...'
                                            : 'Cambiar Estado'}
                                    </button>
                                </div>
                                <div className="m-4">
                                    <button
                                        onClick={() =>
                                            setShowChangeStatus(false)
                                        }
                                        type="button"
                                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                                    >
                                        Cancelar
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Dialog>
            )}

            {showSendNotification && (
                <Dialog>
                    <div className="shadow-md bg-gray-100 text-darjk  p-4 my-4 rounded-2xl ">
                        <div className="mb-4 text-lg">
                            <h3 className="font-bold">Enviar notifiaciones</h3>
                        </div>
                        <form
                            className="justify-content-center"
                            onSubmit={handleSubmitNotify(onSubmitNotify)}
                        >
                            <div>
                                <label
                                    htmlFor="email"
                                    className="block text-sm font-medium text-white-700 mt-5"
                                >
                                    Email *
                                    <input
                                        className="block mb-5 mt-2 w-full text-sm bg-gray-50 rounded-lg border border-gray-300 dark:text-gray-400 focus:outline-none dark:border-gray-600 dark:placeholder-gray-400"
                                        type="text"
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...registerNotify('email', {
                                            required: true,
                                            pattern: emailPattern,
                                        })}
                                    />
                                </label>
                                <ErrorMessage
                                    errors={sendMailErrors}
                                    name="email"
                                    render={() => (
                                        <p className="text-red-600 ml-2">
                                            {sendMailErrors &&
                                                sendMailErrors.email &&
                                                sendMailErrors.email.type ===
                                                    'required' &&
                                                t(
                                                    'common.errors.fieldRequired'
                                                )}
                                            {sendMailErrors &&
                                                sendMailErrors.email &&
                                                sendMailErrors.email.type ===
                                                    'pattern' &&
                                                t('common.errors.invalidEmail')}
                                        </p>
                                    )}
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="description"
                                    className="block text-sm font-medium text-white-700 mt-5"
                                >
                                    Mensaje *
                                    <textarea
                                        id="description"
                                        className="block mb-5 mt-2 w-full text-sm bg-gray-50 rounded-lg border border-gray-300 dark:text-gray-400 focus:outline-none dark:border-gray-600 dark:placeholder-gray-400"
                                        autoComplete="description"
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...registerNotify('description', {
                                            required: true,
                                        })}
                                    />
                                </label>
                                <ErrorMessage
                                    errors={sendMailErrors}
                                    name="description"
                                    render={() => (
                                        <p className="text-red-600 ml-2">
                                            {t('common.errors.fieldRequired')}
                                        </p>
                                    )}
                                />
                            </div>

                            <div className="mt-5 grid grid-cols-2">
                                <div className="m-4">
                                    <button
                                        type="submit"
                                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                                        disabled={
                                            emailsState.status === 'loading'
                                        }
                                    >
                                        {emailsState.status === 'loading'
                                            ? 'Loading...'
                                            : 'Enviar notificación'}
                                    </button>
                                </div>
                                <div className="m-4">
                                    <button
                                        onClick={() => {
                                            setShowSendNotification(false);
                                        }}
                                        type="button"
                                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                                    >
                                        Cancelar
                                    </button>
                                </div>
                            </div>
                        </form>
                        <div className="text-rose-800		mt-5">{validsEmail}</div>
                    </div>
                </Dialog>
            )}

            <div className="shadow-md mb-10 overflow-y-scroll">
                <Pagination
                    {...{
                        shipmentsLabelsMerged,
                        setDownloadFile,
                        setDownloadLabels,
                        loading,
                        setChangePage,
                        gotoPage,
                        canPreviousPage,
                        previousPage,
                        nextPage,
                        pageIndex,
                        pageOptions,
                        setPageSize,
                        pageCount,
                        canNextPage,
                        pageSize,
                        handleDownloadPDF,
                        setShowChangeStatus,
                        selectedFlatRows,
                        setShowSendNotification,
                    }}
                />
                {loading ? (
                    // Use our custom loading state to show a loading indicator
                    <div>
                        <LoadingAnimated message={t('common.loading')} />
                    </div>
                ) : (
                    <table
                        className="table-auto w-full mx-auto"
                        {...getTableProps()}
                    >
                        <thead className="text-white ">
                            {headerGroups.map((headerGroup) => (
                                <tr
                                    className="text-left "
                                    {...headerGroup.getHeaderGroupProps()}
                                >
                                    {headerGroup.headers.map((column) => (
                                        <th
                                            className="px-2 py-2 border-2 border-white rounded-lg  text-center"
                                            style={{ minWidth: '2%' }}
                                            {...column.getHeaderProps()}
                                            onClick={() =>
                                                column.id?.toString() !==
                                                    'selection' &&
                                                column.id?.toString() !==
                                                    'viewDetails' &&
                                                clickHeader(
                                                    column.Header?.toString()
                                                )
                                            }
                                        >
                                            <div className="flex justify-between bg-sym-mbe rounded-lg p-2 text-center">
                                                {column.render('Header')}

                                                {
                                                    // eslint-disable-next-line no-nested-ternary
                                                    sort === 'desc' &&
                                                    column.Header?.toString() ===
                                                        head
                                                        ? column.id?.toString() !==
                                                              'selection' &&
                                                          column.id?.toString() !==
                                                              'viewDetails' && (
                                                              <span className="text-sym-primary-500">
                                                                  <svg
                                                                      xmlns="http://www.w3.org/2000/svg"
                                                                      className="h-6 w-6"
                                                                      fill="none"
                                                                      viewBox="0 0 24 24"
                                                                      stroke="currentColor"
                                                                  >
                                                                      <path
                                                                          strokeLinecap="round"
                                                                          strokeLinejoin="round"
                                                                          strokeWidth="2"
                                                                          d="M5 15l7-7 7 7"
                                                                      />
                                                                  </svg>
                                                              </span>
                                                          )
                                                        : sort === 'asc' &&
                                                          column.Header?.toString() ===
                                                              head &&
                                                          column.Header?.toString() !==
                                                              ''
                                                        ? column.id?.toString() !==
                                                              'selection' &&
                                                          column.id?.toString() !==
                                                              'viewDetails' && (
                                                              <span className="text-sym-primary-500">
                                                                  <svg
                                                                      xmlns="http://www.w3.org/2000/svg"
                                                                      className="h-6 w-6"
                                                                      fill="none"
                                                                      viewBox="0 0 24 24"
                                                                      stroke="currentColor"
                                                                  >
                                                                      <path
                                                                          strokeLinecap="round"
                                                                          strokeLinejoin="round"
                                                                          strokeWidth="2"
                                                                          d="M19 9l-7 7-7-7"
                                                                      />
                                                                  </svg>
                                                              </span>
                                                          )
                                                        : column.id?.toString() !==
                                                              'selection' &&
                                                          column.id?.toString() !==
                                                              'viewDetails' && (
                                                              <span>
                                                                  <svg
                                                                      xmlns="http://www.w3.org/2000/svg"
                                                                      className="h-6 w-6"
                                                                      fill="none"
                                                                      viewBox="0 0 24 24"
                                                                      stroke="currentColor"
                                                                  >
                                                                      <path
                                                                          strokeLinecap="round"
                                                                          strokeLinejoin="round"
                                                                          strokeWidth="2"
                                                                          d="M5 15l7-7 7 7"
                                                                      />
                                                                  </svg>
                                                              </span>
                                                          )
                                                }
                                            </div>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row) => {
                                prepareRow(row);
                                const trBackground = row.original.deliveryDelay
                                    ? 'tooltip bg-red-600 text-white'
                                    : 'odd:bg-sym-mbe-seconday hover:bg-gray-300 tooltip';
                                return (
                                    <tr
                                        className={trBackground}
                                        {...row.getRowProps()}
                                    >
                                        {row.cells.map((cell) => (
                                            <td
                                                className="py-4 px-2 "
                                                {...cell.getCellProps()}
                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
}
