/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import PageHeader from '../../../components/PageHeader';
import ClientsForm from './components/ClientsForm';
import { fetchClientById } from '../../../modules/clients/features';

export default function CreateUpdate() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();

  useEffect(() => {
    if (params.id) {
      dispatch(fetchClientById(params.id));
    }
  }, [params, dispatch]);

  return (
    <div className="relative">
      <PageHeader title={t('clients.title')} />
      <ClientsForm />

    </div>
  );
}
