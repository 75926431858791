/* eslint-disable @typescript-eslint/no-explicit-any */

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getReportOrdersDeliveriesPercentageIssues } from '../../../../modules/report/features/reportOrdersDeliveriesPercentageOnTimeIssues';
import { RootState } from '../../../../store';

interface GetDataParams {
    endDate: string;
    startDate: string;
    branches: string[];
    couriers: string[];
}

const incidentPercentageDefaultData: any = {
    chart: {
        type: 'column',
    },
    legend: { enabled: false },
    title: {
        text: '',
    },
    xAxis: [],
    yAxis: [
        {
            labels: {
                format: '{value}%',
            },
            title: {
                text: 'Porcentaje',
            },
        },
    ],
    series: [],
};

export default () => {
    const dispatch = useDispatch();
    const reportOrdersDeliveriesPercentageIssues = useSelector(
        (state: RootState) => state.reportOrdersDeliveriesPercentageIssues
    );

    const [percentageDeliveryIssuesData, setPercentageDeliveryIssuesData] =
        useState({
            ...incidentPercentageDefaultData,
        });

    const [
        percentageDeliveryOnTimeDataSeted,
        setPercentageDeliveryOnTimeDataSeted,
    ] = useState(false);

    useEffect(() => {
        if (
            reportOrdersDeliveriesPercentageIssues.status === 'success' &&
            percentageDeliveryOnTimeDataSeted === false
        ) {
            const series = [
                {
                    yAxis: 0,
                    name: 'Órdenes',
                    data: reportOrdersDeliveriesPercentageIssues.data.map(
                        ({ value, ...rest }) => ({ ...rest, y: value })
                    ),
                },
            ];
            const xAxis = [
                {
                    categories: reportOrdersDeliveriesPercentageIssues.data.map(
                        ({ name }) => name
                    ),
                    crosshair: true,
                },
            ];
            setPercentageDeliveryIssuesData({
                ...percentageDeliveryIssuesData,
                series,
                xAxis,
            });
            setPercentageDeliveryOnTimeDataSeted(true);
        }
    }, [
        percentageDeliveryIssuesData,
        percentageDeliveryOnTimeDataSeted,
        reportOrdersDeliveriesPercentageIssues.data,
        reportOrdersDeliveriesPercentageIssues.status,
    ]);

    const getData = (seacrhParams: GetDataParams) => {
        dispatch(getReportOrdersDeliveriesPercentageIssues(seacrhParams));
        setPercentageDeliveryOnTimeDataSeted(false);
    };

    return {
        getData,
        data: percentageDeliveryIssuesData,
        status: reportOrdersDeliveriesPercentageIssues.status,
    };
};
