/* eslint-disable react/jsx-props-no-spreading */
import { useEffect } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { RootState, useAppDispatch } from '../../../../store';
import {
    fetchConfigSystem,
    updateConfigSystem,
} from '../../../../modules/configSystem/features';
import LoadingAnimated from '../../../../components/elements/Loading';
import { ConfigSystemType } from '../../../../modules/configSystem/enums';

type FormData = {
    items: {
        id: string;
        type: string;
        value:
            | string
            | {
                  courierCode: string;
                  fee: number;
                  percentage: number;
              }[];
    }[];
};

export default function ClientsForm() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { setValue, control, watch, register, reset, handleSubmit } =
        useForm<FormData>();

    const configSystem = useSelector((state: RootState) => state.configSystem);

    const { fields, append } = useFieldArray({
        control,
        name: 'items',
    });
    const watchWarehouses = watch('items');
    const controlledConfigSystem = fields.map((field, index) => ({
        ...field,
        ...watchWarehouses[index],
    }));

    useEffect(() => {
        dispatch(fetchConfigSystem());
    }, [dispatch]);

    useEffect(() => {
        if (configSystem.status === 'success') {
            reset();
            configSystem.data.forEach((item) => {
                if (item.type === ConfigSystemType.SHIPPING_FEE_BY_COURIERS) {
                    append({
                        id: item.id,
                        type: item.type,
                        value: JSON.parse(item.value),
                    });
                } else {
                    append({
                        id: item.id,
                        type: item.type,
                        value: item.value,
                    });
                }
            });
        }
    }, [
        append,
        configSystem.data,
        configSystem.status,
        dispatch,
        reset,
        setValue,
    ]);

    const updateConfigSystemHandler = (data: FormData) => {
        const payload = data.items.map((item) => {
            const value: string =
                item.type === ConfigSystemType.SHIPPING_FEE_BY_COURIERS
                    ? JSON.stringify(item.value)
                    : (item.value as string);
            return {
                ...item,
                value,
            };
        });
        dispatch(updateConfigSystem(payload));
    };

    return (
        <div>
            {configSystem.status === 'loading' && (
                <LoadingAnimated message={t('common.loading')} />
            )}

            {configSystem.status !== 'loading' && (
                <form onSubmit={handleSubmit(updateConfigSystemHandler)}>
                    <table className="table-auto w-full mx-auto">
                        <thead className="px-2 py-2 bg-slate-500 border-2 border-white rounded-lg text-white">
                            <tr>
                                <th
                                    colSpan={3}
                                    className="px-2 py-2 bg-slate-500 border-2 border-white rounded-lg"
                                >
                                    {t('configSystem.title')}
                                </th>
                            </tr>
                        </thead>
                        <thead className="px-2 py-2 bg-slate-500 border-2 border-white rounded-lg text-white">
                            <tr>
                                <th className="px-2 py-2 bg-slate-500 border-2 border-white rounded-lg">
                                    {t('configSystem.courier')}
                                </th>
                                <th className="px-2 py-2 bg-slate-500 border-2 border-white rounded-lg">
                                    {t('configSystem.monto')}
                                </th>
                                <th className="px-2 py-2 bg-slate-500 border-2 border-white rounded-lg">
                                    {t('configSystem.percentage')}
                                </th>
                            </tr>
                        </thead>
                        <tbody className="px-2 py-2">
                            {/* NAME */}
                            {controlledConfigSystem.map(
                                (configSystemItem, index) => (
                                    <>
                                        {configSystemItem.type !==
                                            ConfigSystemType.SHIPPING_FEE_BY_COURIERS && (
                                            <tr
                                                key={configSystemItem.id}
                                                className="mt-4"
                                            >
                                                <td>
                                                    <label
                                                        htmlFor="systemConfig"
                                                        className="block text-sm font-medium text-gray-700"
                                                    >
                                                        {t(
                                                            `configSystem.type.${configSystemItem.type}`
                                                        )}
                                                    </label>
                                                </td>
                                                <td>
                                                    <label
                                                        htmlFor="systemConfig"
                                                        className="block text-sm font-medium text-gray-700"
                                                    >
                                                        {configSystemItem.value}
                                                    </label>
                                                </td>
                                                <td />
                                            </tr>
                                        )}
                                        {configSystemItem.type ===
                                            ConfigSystemType.SHIPPING_FEE_BY_COURIERS &&
                                            typeof configSystemItem.value !==
                                                'string' &&
                                            configSystemItem.value.map(
                                                (item, index2: number) => (
                                                    <tr key={item.courierCode}>
                                                        <td>
                                                            <label
                                                                htmlFor="courierName"
                                                                className="block text-sm font-medium text-gray-700"
                                                            >
                                                                {
                                                                    item.courierCode
                                                                }
                                                            </label>
                                                        </td>
                                                        <td>
                                                            <input
                                                                id={`fee${index}`}
                                                                type="text"
                                                                autoComplete="fee"
                                                                {...register(
                                                                    `items.${index}.value.${index2}.fee`,
                                                                    {
                                                                        required:
                                                                            true,
                                                                    }
                                                                )}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                id={`percentage${index}`}
                                                                type="text"
                                                                autoComplete="percentage"
                                                                {...register(
                                                                    `items.${index}.value.${index2}.percentage`,
                                                                    {
                                                                        required:
                                                                            true,
                                                                    }
                                                                )}
                                                            />
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                    </>
                                )
                            )}
                            <tr>
                                <td colSpan={2} className="px-2 py-2">
                                    <button
                                        type="submit"
                                        className="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                                    >
                                        {t('common.update')}
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            )}
        </div>
    );
}
