/* eslint-disable react/jsx-props-no-spreading */
// import { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';

// import { useForm } from 'react-hook-form';
// import { useTranslation } from 'react-i18next';
// import { registerLocale, setDefaultLocale } from 'react-datepicker';
// import { es, enUS } from 'date-fns/locale';

// import { useAppDispatch } from '../../../store';
// import 'react-datepicker/dist/react-datepicker.css';
// import { ShipmentType } from '../../../modules/shipments/enums';

// import SideCreate from '../../../../components/elements/SideCreate';
// import { fetchBranches } from '../../../../modules/branches/features/branchesSlice';
// import { addNewBid } from '../../../../modules/bids/features/bidsSlice';

// type FormData = {
//     guideNumber: string;
//     type: ShipmentType;
//     originRegionId: string;
//     originComunaId: string;
//     destinationRegionId: string;
//     destinationComunaId: string;
// };

export default function CreateBid() {
    // const params = useParams();
    // const { id } = params;
    // const {
    //     register,
    //     handleSubmit,
    //     formState: { errors },
    //     reset,
    //     setValue,
    //     watch,
    // } = useForm<FormData>();

    // const { t, i18n } = useTranslation();

    // const dispatch = useAppDispatch();
    // // const branches = useSelector((state: RootState) => state.branches);
    // // const ports = useSelector((state: RootState) => state.ports);
    // const [addRequestStatus, setAddRequestStatus] = useState('idle');

    // // const definitionDateFrom = watch('definitionDateFrom');
    // // const definitionDateTo = watch('definitionDateTo');
    // // const bidDateFrom = watch('bidDateFrom');
    // // const bidDateTo = watch('bidDateTo');
    // // const branchesCheck = watch('branchId');
    // const startDate = new Date();
    // const endDate = new Date();

    // // useEffect(() => {
    // //     if (branches.status === 'idle') {
    // //         dispatch(fetchBranches());
    // //     }
    // // }, [branches, dispatch]);

    // // useEffect(() => {
    // //     register('definitionDateFrom', { required: true });
    // //     register('definitionDateTo', { required: true });
    // //     register('bidDateFrom', { required: true });
    // //     register('bidDateTo', { required: true });
    // //     register('branchId', { required: true });
    // // }, [register]);

    // useEffect(() => {
    //     if (i18n.language === 'es') {
    //         registerLocale('es', es);
    //     } else {
    //         registerLocale('en', enUS);
    //     }
    //     setDefaultLocale(i18n.language);
    // }, [i18n.language]);

    // const onSubmit = async (data: FormData) => {
    //     try {
    //         setAddRequestStatus('loading');
    //         // await dispatch(addNewBid(data)).unwrap();
    //         // setAddRequestStatus('success');
    //         // reset();
    //     } catch (error) {
    //         // console.log(error);
    //         setAddRequestStatus('error');
    //     }
    // };

    return (
        <div>
            <h1>Hello</h1>
        </div>
    );
}
