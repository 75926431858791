/* eslint-disable @typescript-eslint/no-explicit-any */

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getReportOrdersDeliveriesProgress } from '../../../../modules/report/features/reportOrdersDeliveriesProgressSlice';
import { ReportShipmentsProgress } from '../../../../modules/report/interfaces';
import { RootState } from '../../../../store';

interface GetDataParams {
    endDate: string;
    startDate: string;
    branches: string[];
    couriers: string[];
}

export default () => {
    const dispatch = useDispatch();
    const reportOrdersDeliveriesProgressIssues = useSelector(
        (state: RootState) => state.reportOrdersDeliveriesProgressIssues
    );

    const [shipmentsProgress, setShipmentsProgress] =
        useState<ReportShipmentsProgress>(
            reportOrdersDeliveriesProgressIssues.data
        );

    const [shipmentsProgressSeted, setShipmentsProgressSeted] = useState(false);

    useEffect(() => {
        if (
            reportOrdersDeliveriesProgressIssues.status === 'success' &&
            shipmentsProgressSeted === false
        ) {
            setShipmentsProgress(reportOrdersDeliveriesProgressIssues.data);
            setShipmentsProgressSeted(true);
        }
    }, [
        reportOrdersDeliveriesProgressIssues.data,
        reportOrdersDeliveriesProgressIssues.status,
        shipmentsProgressSeted,
    ]);

    const getData = (seacrhParams: GetDataParams) => {
        dispatch(getReportOrdersDeliveriesProgress(seacrhParams));
        setShipmentsProgressSeted(false);
    };

    return {
        getData,
        data: shipmentsProgress,
        status: reportOrdersDeliveriesProgressIssues.status,
    };
};
