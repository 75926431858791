import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { ISingleShipment } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface ShipmentsState {
    data: ISingleShipment | null;
    status: Status;
    error: string | null;
}

const initialState: ShipmentsState = {
    data: null,
    status: Status.idle,
    error: null,
};

export const fetchShipmentLabelById = createAsyncThunk(
    'shipmentSingle/getLabel',
    async (id: string) => {
        const response = await APIClient.getData(`/shipments/${id}/label`);
        return response.data;
    }
);

export const singleShipmentGetLabelSlice = createSlice({
    name: 'shipmentSingle',
    initialState,
    reducers: {
        resetSingleShipmentGetLabel: (state) => {
            state.data = null;
            state.error = null;
            state.status = Status.idle;
        },
    },
    extraReducers(builder) {
        builder
            // GET SHIPMENT LABEL
            .addCase(fetchShipmentLabelById.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(fetchShipmentLabelById.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = action.payload;
            })
            .addCase(fetchShipmentLabelById.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            });
    },
});
export const { resetSingleShipmentGetLabel } =
    singleShipmentGetLabelSlice.actions;
