import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IShipment } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

interface IShipmentQueryParams {
    [key: string]: string;
}

export interface ShipmentsState {
    data: IShipment[];
    count: number;
    status: Status;
    error: string | null;
}

const initialState: ShipmentsState = {
    data: [],
    count: 0,
    status: Status.idle,
    error: null,
};

export const fetchShipments = createAsyncThunk(
    'shipments/fetchShipments',
    async (queryParams?: IShipmentQueryParams) => {
        let params = '';
        if (queryParams) {
            params = Object.keys(queryParams)
                .filter((key) => queryParams[key] !== undefined)
                .map((key) => `${key}=${queryParams[key]}`)
                .join('&');
        }
        const { data, count } = await APIClient.getData(`/shipments?${params}`);
        // console.log('ESTE DE AQUI', { data, count });
        return { data, count };
    }
);

export const shipmentsSlice = createSlice({
    name: 'shipments',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchShipments.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(fetchShipments.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = action.payload.data;
                state.count = action.payload.count;
            })
            .addCase(fetchShipments.rejected, (state, action) => {
                state.status = Status.success;
                state.error = action.error.message ?? null;
            });
    },
});
