import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
// import { IUser } from '../interfaces';
import { BranchOnUserRole } from '../../branches/enums/BranchOnUserRole';
import { IUserBadge } from '../interfaces/index';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface InviteUserState {
    user: IUserBadge | null;
    status: Status;
    error: string | null;
}

interface IUserInvite {
    id?: string;
    authProvider?: string;
    email: string;
    branchId: string;
    branchRole: BranchOnUserRole;
    attributes: {
        firstName: string;
        lastName: string;
        phone_number: string;
        role: string;
    };
}

const initialState: InviteUserState = {
    user: null,
    status: Status.idle,
    error: null,
};

export const findUserByIdAction = createAsyncThunk(
    'user/findUserByIdAction',
    async (id: string) => {
        const response = await APIClient.getData(`/user/${id}`);
        return response.data;
    }
);

export const inviteUserAction = createAsyncThunk(
    'user/inviteUser',
    async ({ email, branchId, branchRole, attributes }: IUserInvite) => {
        const response = await APIClient.postData('/users/invite', {
            email,
            branchId,
            branchRole,
            attributes,
        });
        return response.data;
    }
);

export const inviteUserSlice = createSlice({
    name: 'inviteUser',
    initialState,
    reducers: {
        resetInvite: (state) => {
            state.user = null;
            state.status = Status.idle;
            state.error = null;
        },
        resetUserById: (state) => {
            state.user = null;
            state.status = Status.idle;
            state.error = null;
        },
        resetUpdate: (state) => {
            state.user = null;
            state.status = Status.idle;
            state.error = null;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(inviteUserAction.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(inviteUserAction.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched users to the array
                state.user = action.payload;
            })
            .addCase(inviteUserAction.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message || null;
            })
            .addCase(findUserByIdAction.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(findUserByIdAction.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.error = null;
                state.user = action.payload;
            })
            .addCase(findUserByIdAction.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            });
    },
});

export const { resetInvite, resetUpdate, resetUserById } =
    inviteUserSlice.actions;
