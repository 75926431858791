/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Column,
    useTable,
    useSortBy,
    usePagination,
    useFilters,
    useGlobalFilter,
} from 'react-table';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../store';
// import { IUser } from '../../../modules/users/interfaces';
import LoadingAnimated from '../../../components/elements/Loading';

import PageHeader from '../../../components/PageHeader';
import { Button } from '../../../components/elements/Buttons';
import TableSearch from '../../../components/Table/TableSearch';
import { ICategory } from '../../../modules/category/interfaces/ICategory';
import { IArticle } from '../../../modules/article/interfaces';
import { fetchArticles } from '../../../modules/article/features/articlesSlice';
import CreateArticle from './components/Create';
import formatDate from '../../../utils/formatDate';

export default function ListCategories() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const articles = useSelector((state: RootState) => state.articles);
    const [showInvite, setShowInvite] = useState(false);


    useEffect(() => {
        if (articles.status === 'idle') {
            dispatch(fetchArticles());
        }
    }, [articles.status, dispatch]);

    const columns = useMemo<Column<IArticle>[]>(
        () => [
            {
                Header: 'Nombre',
                accessor: 'name',
            },
            {
                Header: 'Fecha creación',
                accessor: 'createdAt',
				Cell: ({ cell: { value } }) => 
				value ?
				formatDate(new Date(value), 'dd-mm-yyyy')
				:
				'-'
			},
            {
                Header: 'Categoría',
                accessor: 'category',
                Cell: ({ cell: { value } }) => 
				value ?
				value.name
				:
				'-'
			},
            {
                Header: 'Descripción',
                accessor: 'description',
			},			
            
        ],
        []
    );

    const tableInstance = useTable(
        {
            columns,
            data: articles.data,
            defaultColumn: {
                minWidth: 30,
                width: 150,
                maxWidth: 400,
            },
            initialState: {
                pageSize: 100,
                pageIndex: 0,
            },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,

        preGlobalFilteredRows,
        setGlobalFilter,
        state: { globalFilter },
    } = tableInstance;

    // TABLE ROW CLICK ///////////////////////////////////////////////////////////////
    const navigate = useNavigate();

    const tableOnclickHandler = (article: ICategory) => {
        // setUserId(user.id);
        navigate(`/article/${article.id}`);
    };

    // TABLE ROW CLICK FINISH ///////////////////////////////////////////////////////////////
    return (
        <div className="relative mb-40">
            <CreateArticle show={showInvite} toggle={() => setShowInvite(false)} />
            <PageHeader title='Artículos'/>

            <div className="mb-2">
                <TableSearch
                    title={t('common.search').toString()}
                    placeholder={t('common.search').toString()}
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={globalFilter}
                    setGlobalFilter={(filter: string) =>
                        setGlobalFilter(filter)
                    }
                />
            </div>
            <Button
                className="absolute right-0 top-0 z-10 rounded-3xl font-bold"
                label='Crear Artículo'
                type="button"
                onClick={() => setShowInvite(!showInvite)}
            />

            {articles.status === 'loading' && (
                <LoadingAnimated message={t('common.loading')} />
            )}
			{articles.status === 'success' &&
                articles.data.length === 0 && (
                    <div className="w-full text-center">
                        No se encontraron datos
                    </div>
                )}
            {articles.status === 'success' && articles.data.length > 0 && (
                <div className="overflow-x-auto shadow-md">
                    <table
                        className="table-auto w-full mx-auto"
                        {...getTableProps()}
                    >
                        <thead className="text-white ">
                            {
                                // Loop over the header rows

                                headerGroups.map((headerGroup) => (
                                    // Apply the header row props

                                    <tr
                                        className="text-center"
                                        {...headerGroup.getHeaderGroupProps()}
                                    >
                                        {
                                            // Loop over the headers in each row

                                            headerGroup.headers.map(
                                                (column) => (
                                                    // Apply the header cell props

                                                    <th
                                                        className="px-2 py-2  border-2 border-white rounded-lg text-center"
                                                        {...column.getHeaderProps(
                                                            column.getSortByToggleProps()
                                                        )}
                                                        style={{
                                                            minWidth: '2%',
                                                        }}
                                                    >
                                                        <div className="flex justify-between bg-sym-mbe rounded-lg p-2 text-center">
                                                            {
                                                                // Render the header

                                                                column.render(
                                                                    'Header'
                                                                )
                                                            }
                                                            {column.canSort ? (
                                                                <span
                                                                    className={`${
                                                                        column.isSorted &&
                                                                        'text-sym-primary-500'
                                                                    }`}
                                                                >
                                                                    {column.isSortedDesc && (
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            className="h-6 w-6"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                            stroke="currentColor"
                                                                        >
                                                                            <path
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                strokeWidth={
                                                                                    2
                                                                                }
                                                                                d="M19 9l-7 7-7-7"
                                                                            />
                                                                        </svg>
                                                                    )}
                                                                    {!column.isSortedDesc && (
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            className="h-6 w-6"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                            stroke="currentColor"
                                                                        >
                                                                            <path
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                strokeWidth={
                                                                                    2
                                                                                }
                                                                                d="M5 15l7-7 7 7"
                                                                            />
                                                                        </svg>
                                                                    )}
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    </th>
                                                )
                                            )
                                        }
                                    </tr>
                                ))
                            }
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {
                                // Loop over the table rows

                                rows.map((row) => {
                                    // Prepare the row for display

                                    prepareRow(row);

                                    return (
                                        // Apply the row props

                                        <tr
                                            className="odd:bg-sym-mbe-seconday hover:bg-gray-300"
                                            {...row.getRowProps()}
                                            onClick={() =>
                                                tableOnclickHandler(
                                                    row.original
                                                )
                                            }
                                        >
                                            {
                                                // Loop over the rows cells

                                                row.cells.map((cell) => (
                                                    // Apply the cell props
                                                    <td
                                                        className="py-4 px-2 cursor-pointer"
                                                        {...cell.getCellProps()}
                                                    >
                                                        {
                                                            // Render the cell contents

                                                            cell.render('Cell')
                                                        }
                                                    </td>
                                                ))
                                            }
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
}
