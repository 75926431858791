/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Column, useTable, useSortBy } from 'react-table';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../store';
import { fetchBranches } from '../../../modules/branches/features';
import { IBranch } from '../../../modules/branches/interfaces';
import LoadingAnimated from '../../../components/elements/Loading';
import PageHeader from '../../../components/PageHeader';
import { Button } from '../../../components/elements/Buttons';
import { resetBranch } from '../../../modules/branches/features/branchSlice';

// import { fetchBranchesOnUsers } from '../../../modules/branches/features/branchesOnUsersSlice';

// import { resetBranchOnUserDelete } from '../../../modules/branches/features/branchesOnUsersDeleteByIdSlice';

export default function List() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const branches = useSelector((state: RootState) => state.branches);
    // const [showInvite, setShowInvite] = useState(false);

    // let locale = 'en-US';
    // if (i18n.language === 'es') {
    //     locale = 'es-ES';
    // }

    const [showForm /* , setShowForm */] = useState(false);

    useEffect(() => {
        if (branches.status === 'idle') {
            dispatch(fetchBranches());
        }
    }, [branches.status, dispatch]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const columns = useMemo<Column<IBranch>[]>(
        () => [
            // {
            //   Header: t('common.id').toString(),
            //   accessor: 'id',
            // },
            {
                Header: t('branches.name').toString(),
                accessor: 'name',
            },
            {
                Header: t('branches.country').toString(),
                accessor: 'country',
                Cell: ({ cell: { value } }: any) => value.name,
            },
            {
                Header: t('branches.region').toString(),
                accessor: 'region',
                Cell: ({ cell: { value } }: any) => value.name,
            },
            {
                Header: t('branches.comuna').toString(),
                accessor: 'comuna',
                Cell: ({ cell: { value } }: any) => value.name,
            },
            {
                Header: t('branches.address').toString(),
                accessor: 'address',
            },
        ],
        [t]
    );

    const tableInstance = useTable(
        {
            columns,
            data: branches.data,
            defaultColumn: {
                minWidth: 30,
                width: 150,
                maxWidth: 400,
            },
        },
        useSortBy
    );

    // TABLE ROW CLICK ///////////////////////////////////////////////////////////////
    const navigate = useNavigate();

    const tableOnclickHandler = async (branch: IBranch) => {
        navigate(`/branches/${branch.id}`);
        // dispatch(fetchBranchesOnUsers(`branchId=${branch.id}`));
        // dispatch(resetBranchOnUserDelete());
    };
    // TABLE ROW CLICK FINISH ///////////////////////////////////////////////////////////////

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        tableInstance;

    useEffect(() => {
        dispatch(resetBranch());
    }, [dispatch]);

    return (
        <div className="relative mb-40">
            <PageHeader title={t('branches.title')} />
            {/* <BranchesForm show={showForm} toggle={() => setShowForm(false)} /> */}
            <Button
                className="absolute right-0 top-0 z-10 rounded-3xl font-bold"
                label={t('branches.newBranch')}
                type="button"
                onClick={() => {
                    dispatch(resetBranch());
                    navigate('/branches/create');
                }}
            />

            {branches.status === 'loading' && (
                <LoadingAnimated message={t('common.loading')} />
            )}
            {branches.status === 'success' && (
                <div className="overflow-x-auto shadow-md">
                    <table
                        className="table-auto w-full mx-auto"
                        {...getTableProps()}
                    >
                        <thead className="text-white">
                            {
                                // Loop over the header rows

                                headerGroups.map((headerGroup) => (
                                    // Apply the header row props

                                    <tr
                                        className="text-center"
                                        {...headerGroup.getHeaderGroupProps()}
                                    >
                                        {
                                            // Loop over the headers in each row

                                            headerGroup.headers.map(
                                                (column) => (
                                                    // Apply the header cell props

                                                    <th
                                                        className="px-2 py-2  border-white rounded-lg"
                                                        {...column.getHeaderProps(
                                                            column.getSortByToggleProps()
                                                        )}
                                                        style={{
                                                            minWidth: '2%',
                                                        }}
                                                    >
                                                        <div className="flex justify-between border-2 bg-sym-mbe rounded-lg p-2 text-center">
                                                            {
                                                                // Render the header

                                                                column.render(
                                                                    'Header'
                                                                )
                                                            }
                                                            {column.canSort ? (
                                                                <span
                                                                    className={`${column.isSorted &&
                                                                        'text-sym-primary-500'
                                                                        }`}
                                                                >
                                                                    {column.isSortedDesc && (
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            className="h-6 w-6"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                            stroke="currentColor"
                                                                        >
                                                                            <path
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                strokeWidth={
                                                                                    2
                                                                                }
                                                                                d="M19 9l-7 7-7-7"
                                                                            />
                                                                        </svg>
                                                                    )}
                                                                    {!column.isSortedDesc && (
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            className="h-6 w-6"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                            stroke="currentColor"
                                                                        >
                                                                            <path
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                strokeWidth={
                                                                                    2
                                                                                }
                                                                                d="M5 15l7-7 7 7"
                                                                            />
                                                                        </svg>
                                                                    )}
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    </th>
                                                )
                                            )
                                        }
                                    </tr>
                                ))
                            }
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {
                                // Loop over the table rows

                                rows.map((row) => {
                                    // Prepare the row for display

                                    prepareRow(row);

                                    return (
                                        // Apply the row props

                                        <tr
                                            className="odd:bg-sym-mbe-seconday hover:bg-gray-300"
                                            {...row.getRowProps()}
                                            onClick={() =>
                                                !showForm
                                                    ? tableOnclickHandler(
                                                        row.original
                                                    )
                                                    : ''
                                            }
                                        >
                                            {
                                                // Loop over the rows cells

                                                row.cells.map((cell) => (
                                                    // Apply the cell props

                                                    <td
                                                        className="py-4 px-2 cursor-pointer"
                                                        {...cell.getCellProps()}
                                                    >
                                                        {
                                                            // Render the cell contents

                                                            cell.render('Cell')
                                                        }
                                                    </td>
                                                ))
                                            }
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
}
