/* eslint-disable react/jsx-props-no-spreading */
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { ErrorMessage } from '@hookform/error-message';

import PageHeader from '../../../components/PageHeader';
import { Button } from "../../../components/elements/Buttons";
import { ShipmentStatus } from '../../../modules/shipments/enums';
import { statusLabels } from '../../../components/Shipment/Badges';
import { NotificationsType } from '../../../modules/notificationConfig/interfaces';
import {
  createNotificationConfig,
  updateNotificationConfig,
  fetchByIdNotificationConfig,
} from '../../../modules/notificationConfig/features/notificationConfigSlice'
import {
  fetchTagsNotificationConfig,
} from '../../../modules/notificationConfig/features/notificationConfigTagsSlice'
import { RootState, useAppDispatch } from '../../../store';
import LoadingAnimated from '../../../components/elements/Loading';
import AlertSuccess from '../../../components/Alerts/Success';

type FormData = {
  type: string;
  statusToNotify: string;
  textTemplate?: string;
  active: boolean;
  sender: boolean;
  addressee: boolean;
};

export default function CreateNotificationConfig() {
  const { t } = useTranslation();
  const params = useParams<{ id: string }>();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
    setValue,
  } = useForm<FormData>();


  // Se obtienen los datos de una configuración desde el store
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const notificationConfig = useSelector((state: RootState) => state.notificationConfig);
  const dispatch = useAppDispatch();

  const createNotificationConfigHandler = (data: FormData) => {
    if (!data.sender && !data.addressee) {
      setError('addressee', { type: 'custom', message: "Debe eliger una opción" });
      setError('sender', { type: 'custom', message: "Debe eliger una opción" });
      return;
    }
    const payload = {
      statusToNotify: data.statusToNotify,
      type: data.type,
      active: data.active,
      textTemplate: data.textTemplate || "",
      sender: data.sender,
      addressee: data.addressee,
    }
    if (params && params.id) {
      dispatch(updateNotificationConfig({ ...payload, id: params.id }));
    } else {
      dispatch(createNotificationConfig(payload));
    }
  }


  // TAGS
  const notificationConfigTags = useSelector((state: RootState) => state.notificationConfigTags);
  useEffect(() => {
    if (notificationConfigTags.status === 'idle') {
      dispatch(fetchTagsNotificationConfig());
    }

  }, [dispatch, notificationConfigTags]);

  // Se inician los valores del formulario cuando se obtiene el detalle de una configuración para ser editada
  useEffect(() => {

    if (notificationConfig.status === 'getByIdSuccess' && notificationConfig.data) {
      setValue('type', notificationConfig.data.type);
      setValue('statusToNotify', notificationConfig.data.statusToNotify);
      setValue('textTemplate', notificationConfig.data.textTemplate);
      setValue('active', notificationConfig.data.active);
      setValue('sender', notificationConfig.data.sender);
      setValue('addressee', notificationConfig.data.addressee);
    }

    if (notificationConfig.status === 'updateSuccess' || notificationConfig.status === 'createdSuccess') {
      setShowSuccessAlert(true);
      setTimeout(() => {
        setShowSuccessAlert(false)
      }, 3000);
    }

    return () => {
      if (notificationConfig.status === 'getByIdSuccess') {
        reset();
      }
    }

  }, [dispatch, notificationConfig.data, notificationConfig.status, reset, setValue]);

  // Se busca el detalle de una configuración cuando se pasa un ID por parámetro
  useEffect(() => {
    if (params.id) {
      dispatch(fetchByIdNotificationConfig(params.id))
    }
  }, [params, dispatch])

  return (
    <div className="relative  mb-48">
      <PageHeader title={t('notificationsConfig.newConfig')} />
      <div className="text-black shadow-md bg-gray-100 p-6">
        <div className="flex">

          {/* TYPE */}
          <div className="w-full md:w-1/3 px-4 py-4">
            <h3 className="text-lg font-bold">
              {t('notificationsConfig.type')} *
            </h3>
            <div className="mt-4">
              <select
                id="type"
                className="mt-2"
                {...register('type', {
                  required: true,
                })}
              >
                <option value="">
                  {t('notificationsConfig.selectType')}
                </option>
                {Object.values(NotificationsType).map((type) => (
                  <option
                    key={type}
                    value={type}
                  >
                    {type}
                  </option>
                ))}
              </select>

              {errors.type && (
                <p className="text-red-600 ml-2">
                  {errors.type.type === 'required' &&
                    t('common.errors.fieldRequired')}
                </p>
              )}
            </div>
          </div>

          {/* STATUS TO NOTIFY */}
          <div className="w-full md:w-1/3 px-4 py-4">
            <h3 className="text-lg font-bold">
              {t('notificationsConfig.statusToNotify')} *
            </h3>
            <div className="mt-4">

              <select
                id="statusToNotify"
                className="mt-2"
                {...register('statusToNotify', {
                  required: true,
                })}
              >
                <option value="">
                  {t('notificationsConfig.selectstatusToNotify')}
                </option>
                {Object.values(ShipmentStatus).map((status) => (
                  <option
                    key={status}
                    value={status}
                  >
                    {statusLabels(status, t)}
                  </option>
                ))}
              </select>
              {errors.statusToNotify && (
                <p className="text-red-600 ml-2">
                  {errors.statusToNotify.type === 'required' &&
                    t('common.errors.fieldRequired')}
                </p>
              )}
            </div>
          </div>

          {/* ACTIVE */}
          <div className="w-full md:w-1/3 px-4 py-4">
            <h3 className="text-lg font-bold">
              {t('notificationsConfig.active')}
            </h3>
            <div className="mt-4">
              <div className="flex mt-4">
                <div className="form-check form-switch py-4">
                  <input
                    {...register('active', {
                    })}
                    className="form-check-input checkboxactive appearance-none w-9 rounded-full h-5 align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm"
                    type="checkbox"
                    role="switch"
                    id="active" />
                </div>
              </div>
            </div>
          </div>

          {/* SEND TO SENDER */}
          <div className="w-full md:w-1/3 px-4 py-4">
            <h3 className="text-lg font-bold">
              {t('notificationsConfig.sender')}
            </h3>
            <div className="mt-4">
              <div className="flex mt-4">
                <div className="form-check form-switch py-4">
                  <input
                    {...register('sender', {
                    })}
                    className="form-check-input checkboxactive appearance-none w-9 rounded-full h-5 align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm"
                    type="checkbox"
                    role="switch"
                    id="sender" />
                </div>
              </div>
              {notificationConfig.status !== 'loading' && <ErrorMessage
                errors={errors}
                name="sender"
                render={({ message }) => (
                  <p className="text-red-600 ml-2">
                    {message}
                  </p>
                )}
              />}
            </div>
          </div>

          {/* SEND TO RECEIVER */}
          <div className="w-full md:w-1/3 px-4 py-4">
            <h3 className="text-lg font-bold">
              {t('notificationsConfig.addressee')}
            </h3>
            <div className="mt-4">
              <div className="flex mt-4">
                <div className="form-check form-switch py-4">
                  <input
                    {...register('addressee', {
                    })}
                    className="form-check-input checkboxactive appearance-none w-9 rounded-full h-5 align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm"
                    type="checkbox"
                    role="switch"
                    id="addressee" />
                </div>
              </div>
              {notificationConfig.status !== 'loading' && <ErrorMessage
                errors={errors}
                name="addressee"
                render={({ message }) => (
                  <p className="text-red-600 ml-2">
                    {message}
                  </p>
                )}
              />}
            </div>
          </div>

        </div>

        <div className="flex">
          <div className="w-full md:w-1/3 px-4 py-4">
            <Button
              type="submit"
              size="lg"
              className="font-bold w-full"
              onClick={handleSubmit(createNotificationConfigHandler)}
              disabled={notificationConfig.status === 'loading'}
              label={
                notificationConfig.status === 'loading'
                  ? t('wait.message')
                  : `${t('notificationsConfig.button.save')}`
              }
            />
          </div>
          <div className="w-full px-4 py-4">
            {notificationConfig.status === 'loading' && <LoadingAnimated message={t('common.loading')} />}
            {showSuccessAlert && <AlertSuccess
              title={t('notificationsConfig.title')}
              message={t('notificationsConfig.saveSuccess')}
              onClick={() => setShowSuccessAlert(false)}
              fixed={false}
            />}
          </div>
        </div>
      </div>
    </div>
  )
}