/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-console */
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import useAuth from '../../../context/useAuth';

type FormData = {
    email: string;
};

export default function ForgotPassword() {
    const { forgotPassword, loading, error } = useAuth();
    console.log({ error });
    const { t } = useTranslation();
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>();

    const [forgotState, setForgotState] = useState<{
        loading: boolean;
        error: {
            code?: string;
            message?: string;
        } | null;
    }>({
        loading: false,
        error: null,
    });

    const onSubmit = (data: FormData) => {
        setForgotState({ ...forgotState, loading: true });
        console.log('submit');
        console.log({ data });
        forgotPassword(data.email);
        setForgotState({ ...forgotState, loading: false });
        navigate(`/forgot/code?email=${data.email}`, { replace: true });
    };

    const handleSetPasswordError = (code: string) => {
        if (code === 'LimitExceededException') {
            return t('forgotPassword.errors.limitExceeded');
        }

        console.log('Unknown error', code);
        return t('signIn.errors.UnknownError');
    };

    return (
        <div>
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <img
                    className="mx-auto h-36 w-auto"
                    src="/images/mbe-vertical.svg"
                    alt="Logo"
                />
                <h1 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                    {t('forgotPassword.title')}
                </h1>
                <p className="mt-2 text-center text-sm text-gray-600 max-w">
                    {t('forgotPassword.backToSignIn')}
                    <Link
                        to="/"
                        className="font-medium text-sym-primary-600 hover:text-sym-primary-500 focus:outline-none focus:ring-2 focus:ring-sym-primary-500 ml-2"
                    >
                        {t('signIn.title')}
                    </Link>
                </p>
            </div>
            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-6 shadow rounded-lg sm:px-10">
                    <p className="text-slate-600 mb-6">
                        {t('forgotPassword.description')}
                    </p>
                    <div className="text-slate-600 mb-6 font-bold">
                        <Link to="/forgot/code">
                            {t('forgotPassword.haveCode')}
                        </Link>
                    </div>

                    <form
                        className="mb-0 space-y-6"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <div>
                            <label
                                htmlFor="email"
                                className="block text-sm font-medium text-gray-700"
                            >
                                {t('common.email')}
                                <div className="mt-1">
                                    <input
                                        id="email"
                                        type="email"
                                        className={`${
                                            errors.email
                                                ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                : ''
                                        }`}
                                        autoComplete="email"
                                        {...register('email', {
                                            required: true,
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message:
                                                    'Invalid email address',
                                            },
                                        })}
                                    />
                                    {errors.email && (
                                        <p className="text-red-600 ml-2">
                                            {errors.email.type === 'required' &&
                                                t(
                                                    'forgotPassword.errors.emailRequired'
                                                )}
                                            {errors.email.type === 'pattern' &&
                                                t(
                                                    'forgotPassword.errors.emailRequired'
                                                )}
                                        </p>
                                    )}
                                </div>
                            </label>
                        </div>

                        <div>
                            {error && (
                                <p className="text-red-600 mb-2">
                                    {handleSetPasswordError(error)}
                                </p>
                            )}

                            <button
                                disabled={forgotState.loading || loading}
                                type="submit"
                                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-600 hover:bg-sym-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                            >
                                {loading
                                    ? t('wait.message')
                                    : t('forgotPassword.send')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
