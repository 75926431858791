/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
import {
    ClassAttributes,
    InputHTMLAttributes,
    useEffect,
    useState,
    useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { useSelector } from 'react-redux';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import { es, enUS } from 'date-fns/locale';
import { ErrorMessage } from '@hookform/error-message';
import { useNavigate } from 'react-router-dom';
import { validateRut, formatRut, RutFormat } from '@fdograph/rut-utilities';
import 'react-datepicker/dist/react-datepicker.css';

import { AlertSuccess, AlertError } from '../../../components/Alerts';
import PageHeader from '../../../components/PageHeader';
import { RootState, useAppDispatch } from '../../../store';
import { fetchRegions } from '../../../modules/geo/features/regionSlice';
import { fetchComunas } from '../../../modules/geo/features/comunaSlice';
import { IComuna } from '../../../modules/geo/interfaces';
import { fetchClients, resetClient } from '../../../modules/clients/features';
import { Button } from '../../../components/elements/Buttons';
import {
    createQuote,
    resetQuotes,
} from '../../../modules/quotes/features/quoteSlice';
import LoadingAnimated from '../../../components/elements/Loading';
import { IQuoteResponse } from '../../../modules/quotes/interfaces';
import {
    shipmentInitialData,
    createShipment,
    resetShipment,
} from '../../../modules/shipments/features/shipmentCreateSlice';
import { fetchBranches } from '../../../modules/branches/features';
import formatDate from '../../../utils/formatDate';
import { fetchInsurances } from '../../../modules/insurances/features/insurancesSlice';
import diffDate from '../../../utils/diffDate';
import useCouriers from './customHooks/useCouriers';

type FormData = {
    identifier?: string;
    regionOrigin: string;
    comunaOrigin: string;
    regionDestination: string;
    comunaDestination: string;
    originBranchId: string;
    destinationBranchId: string;
    streetOrigin: string;
    streetDestination: string;
    streetNumberDestination: string;
    streetNumberOrigin: string;
    senderNumber: string;
    senderName: string;
    weight: string;
    width: string;
    height: string;
    length: string;
    quantity: string;
    volumetricWeight: string;
    description: string;
    productDescription: string;
    deliveryEstimateDate: Date;
    senderPhone: string;
    senderRut: string;
    senderEmail: string;
    senderSecondLine: string;
    senderComment: string;
    receiverName: string;
    receiverPhone: string;
    receiverRut: string;
    receiverEmail: string;
    selectedSize: string | boolean | undefined | null;
    declaredValue: string;
    receiverSecondLine: string;
    receiverComment: string;
    insurance: number | undefined;
    isPorcent: boolean;
    discount: number | string | undefined;
    calculateInsurance: boolean;
    clientId?: string;
    paymentType?: string;
};

// Create a function that calculates the volumetric weight of a package
const calculateVolumetricWeight = (
    width: number,
    height: number,
    length: number,
    weight: number
): number => {
    const volumetricWeight = (width * height * length) / 5000;
    return volumetricWeight > weight ? volumetricWeight : weight;
};

const declaredValueReplace = (value: string) => {
    const response = value.replace(/[,.$]/g, '');
    return response;
};

const sizesBase = [
    {
        key: 'XS',
        check: false,
        length: 14,
        width: 14,
        height: 10,
        weight: 0.5,
        volumetricWeight: 0.392,
    },
    {
        key: 'S',
        check: false,
        length: 30,
        width: 20,
        height: 20,
        weight: 3,
        volumetricWeight: 2.4,
    },
    {
        key: 'M',
        length: 30,
        width: 40,
        height: 20,
        check: false,
        weight: 6,
        volumetricWeight: 3.8,
    },
    {
        key: 'L',
        length: 30,
        width: 60,
        height: 35,
        check: false,
        weight: 16,
        volumetricWeight: 12.6,
    },
    {
        key: 'Personalizado',
        length: 0,
        width: 0,
        height: 0,
        check: false,
        weight: 0,
        volumetricWeight: 12.6,
    },
];

export default function CreateQuote() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        watch,
        setValue,
        getValues,
        control,
        setError,
    } = useForm<FormData>();

    const [deliveryEstimateDate, setDeliveryEstimateDate] = useState(
        new Date()
    );

    const regions = useSelector((state: RootState) => state.regions);
    const comunas = useSelector((state: RootState) => state.comunas);
    const quotes = useSelector((state: RootState) => state.quotes);
    const branches = useSelector((state: RootState) => state.branches);
    const insurances = useSelector((state: RootState) => state.insurances);

    const shipmentCreate = useSelector(
        (state: RootState) => state.shipmentCreate
    );

    const [comunasByRegionOriginId, setComunasByRegionOriginId] = useState<
        IComuna[]
    >([]);
    const [comunasByRegionDestinationId, setComunasByRegionDestinationId] =
        useState<IComuna[]>([]);
    const [createQuoteState, setCreateQuoteState] = useState('idle');
    const [createShipmentState, setCreateShipmentState] = useState('idle');
    const [alertSucess, setAlertSucess] = useState(false);

    const selectedOriginRegion = watch('regionOrigin');
    const selectedOriginComuna = watch('comunaOrigin');
    const selectedDestinationRegion = watch('regionDestination');
    const currentSize = watch('selectedSize');

    const [selectedQuote, setSelectedQuote] = useState<IQuoteResponse>();
    const [selectedOriginBranch, setSelectedOriginBranch] =
        useState<string>('');
    const [selectedDestinationBranch, setSelectedDestinationBranch] =
        useState<string>('');

    const branchOrigin = watch('originBranchId');
    const branchDestination = watch('destinationBranchId');
    const clientId = watch('clientId');
    const re = /\S+@\S+\.\S+/;

    // SIZE
    const [sizes, setSizes] = useState(sizesBase);

    const lengthSize = watch('length');
    const widthSize = watch('width');
    const heightSize = watch('height');
    const weightSize = watch('weight');
    const selectedSize = watch('selectedSize');

    // RUT
    const [activeValRutSender, setActiveValRutSender] = useState(false);
    const [activeValRutReceiver, setActiveValRutReceiver] = useState(false);

    const handleValidateRut = (e: any, type: string) => {
        const { value } = e.target;
        if ((value && value.trim() !== '') || value.length > 0) {
            if (!validateRut(value)) {
                if (type === 'receiver') {
                    setActiveValRutReceiver(true);
                } else {
                    setActiveValRutSender(true);
                }
            } else if (type === 'receiver') {
                setActiveValRutReceiver(false);
            } else {
                setActiveValRutSender(false);
            }
        } else if (type === 'receiver') {
            setActiveValRutReceiver(false);
        } else {
            setActiveValRutSender(false);
        }
    };

    // EMAIL

    const [activeValReceiver, setActiveValReceiver] = useState(false);

    const handleValidateEmailReceiver = (e: any) => {
        const { value } = e.target;
        if ((value && value.trim() !== '') || value.length > 0) {
            if (!re.test(value)) {
                setActiveValReceiver(true);
            } else {
                setActiveValReceiver(false);
            }
        } else {
            setActiveValReceiver(false);
        }
    };

    // calculateInsurance active
    const calculateInsurance = watch('calculateInsurance');
    const [activeInsureCourier, setActiveInsureCourier] = useState(false);

    useEffect(() => {
        setActiveInsureCourier(calculateInsurance);
    }, [calculateInsurance]);

    // CLIENTS
    const clients = useSelector((state: RootState) => state.clients);
    const [selectedCliente, setSelectedCliente] = useState<string>('');

    useEffect(() => {
        if (clients.data.length === 0 && clients.status === 'idle') {
            dispatch(fetchClients());
        }
    }, [clients, dispatch]);
    // CLIENTS - FINISH
    useEffect(() => {
        dispatch(resetQuotes());
    }, [dispatch]);

    // Insurance
    useEffect(() => {
        if (insurances.status === 'idle') {
            dispatch(fetchInsurances());
        }
    }, [insurances, dispatch]);

    // Insurance
    useEffect(() => {
        if (
            lengthSize !== '' &&
            widthSize !== '' &&
            heightSize !== '' &&
            weightSize !== '' &&
            selectedSize
        ) {
            const searchSize = sizes.find(
                (s) =>
                    lengthSize === s.length.toString() &&
                    widthSize === s.width.toString() &&
                    heightSize === s.height.toString() &&
                    weightSize === s.weight.toString()
            );
            if (!searchSize) {
                if (selectedSize !== 'Personalizado') {
                    setSizes(
                        sizes.map((s) => {
                            if (s.key === 'Personalizado') {
                                return {
                                    ...s,
                                    length: parseFloat(lengthSize),
                                    width: parseFloat(widthSize),
                                    height: parseFloat(heightSize),
                                    weight: parseFloat(weightSize),
                                    check: true,
                                };
                            }
                            return { ...s, check: false };
                        })
                    );
                }
            } else {
                setSizes(
                    sizes.map((s) => {
                        if (s.key === searchSize.key) {
                            return {
                                ...s,
                                check: true,
                            };
                        }
                        return { ...s, check: false };
                    })
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lengthSize, widthSize, heightSize, weightSize]);

    // Insurance
    useEffect(() => {
        register('selectedSize');

        // register('startDate');
    }, [register]);

    useEffect(() => {
        if (i18n.language === 'en') {
            registerLocale('en', enUS);
        } else {
            registerLocale('es', es);
        }
        setDefaultLocale(i18n.language);
    }, [i18n.language]);

    useEffect(() => {
        if (regions.status === 'idle') {
            dispatch(fetchRegions());
        }
    }, [regions, dispatch]);

    useEffect(() => {
        if (comunas.status === 'idle') {
            dispatch(fetchComunas());
        }
    }, [comunas, dispatch]);

    useEffect(() => {
        if (branches.status === 'idle') {
            dispatch(fetchBranches());
        }
    }, [branches, dispatch]);

    useEffect(() => {
        if (
            selectedOriginRegion &&
            comunasByRegionOriginId[0]?.regionId.toString() !==
                selectedOriginRegion
        ) {
            const filteredComunas = comunas.data.filter(
                (c) => c.regionId.toString() === selectedOriginRegion
            );
            setComunasByRegionOriginId(filteredComunas);
        }

        if (
            selectedDestinationRegion &&
            comunasByRegionDestinationId[0]?.regionId.toString() !==
                selectedDestinationRegion
        ) {
            const filteredComunas = comunas.data.filter(
                (c) => c.regionId.toString() === selectedDestinationRegion
            );
            setComunasByRegionDestinationId(filteredComunas);
        }
    }, [
        selectedOriginRegion,
        comunas,
        selectedDestinationRegion,
        comunasByRegionOriginId,
        comunasByRegionDestinationId,
    ]);

    useEffect(() => {
        const newSize = sizes.find((s) => s.key === currentSize);

        if (newSize) {
            setValue('volumetricWeight', newSize.volumetricWeight.toString());
            setValue('length', newSize.length.toString());
            setValue('width', newSize.width.toString());
            setValue('height', newSize.height.toString());
            setValue('weight', newSize.weight.toString());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSize, setValue]);

    useEffect(() => {
        if (branchOrigin && branchOrigin !== selectedOriginBranch) {
            setSelectedOriginBranch(branchOrigin);
            const branch = branches.data.find(
                (b) => b.id.toString() === branchOrigin
            );
            if (branch) {
                setValue('senderName', branch.name);
                setValue('senderEmail', branch.email);
                setValue('senderPhone', branch.phone || '');
                setValue('streetOrigin', branch.address);
                setValue('streetNumberOrigin', branch.addressNumber ?? '');
                setValue('regionOrigin', branch.regionId.toString());
                // Necesario para evitar que NO se seleccione una comuna equivocada la primera vez
                setTimeout(() => {
                    setValue('comunaOrigin', branch.comunaId.toString());
                }, 100);
            }
        }
    }, [branchOrigin, branches, setValue, selectedOriginBranch]);
    useEffect(() => {
        if (
            branchDestination &&
            branchDestination !== selectedDestinationBranch
        ) {
            setSelectedDestinationBranch(branchDestination);
            const branch = branches.data.find(
                (b) => b.id.toString() === branchDestination
            );
            if (branch) {
                setValue('receiverName', branch.name);
                setValue('receiverEmail', branch.email);
                setValue('receiverPhone', branch.phone || '');

                setValue('streetDestination', branch.address);
                setValue('streetNumberDestination', branch.addressNumber ?? '');
                setValue('regionDestination', branch.regionId.toString());
                // Necesario para evitar que NO se seleccione una comuna equivocada la primera vez
                setTimeout(() => {
                    setValue('comunaDestination', branch.comunaId.toString());
                }, 100);
            }
        }
    }, [branchDestination, branches, setValue, selectedDestinationBranch]);

    useEffect(() => {
        if (clientId && clientId !== selectedCliente) {
            setSelectedCliente(clientId);
            const clientBranch = clients.data.find(
                (c) => c.id.toString() === clientId
            );

            if (clientBranch) {
                setValue('streetOrigin', clientBranch.address);
                setValue(
                    'streetNumberOrigin',
                    clientBranch.addressNumber ?? ''
                );
                setValue('regionOrigin', clientBranch.regionId.toString());
                // Necesario para evitar que NO se seleccione una comuna equivocada la primera vez
                setTimeout(() => {
                    setValue('comunaOrigin', clientBranch.comunaId.toString());
                }, 100);
            }
        }
    }, [clientId, clients.data, branches, setValue, selectedCliente]);

    useEffect(() => {
        dispatch(resetShipment());
    }, [dispatch]);

    const [showAlertError, setShowAlertError] = useState(false);

    useEffect(() => {
        if (shipmentCreate) {
            if (shipmentCreate.status === 'success' && shipmentCreate.data) {
                setAlertSucess(true);
                setTimeout(() => {
                    setAlertSucess(false);
                    dispatch(resetShipment());
                    navigate(`/shipments/detail/${shipmentCreate.data?.id}`);
                }, 3000);
            }
            if (shipmentCreate.status === 'error') {
                setShowAlertError(true);
                setTimeout(() => {
                    setShowAlertError(false);
                }, 3000);
            }
        }
    }, [dispatch, navigate, shipmentCreate]);

    const [alertBranch, setAlertBranch] = useState(false);
    const [responseUser, setResponseUser] = useState(false);

    const [formData, setFormData] = useState<FormData>();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleResponse = async (data: FormData) => {
        const volumetricWeight = calculateVolumetricWeight(
            parseFloat(data.width),
            parseFloat(data.height),
            parseFloat(data.length),
            parseFloat(data.weight)
        );

        setValue('volumetricWeight', volumetricWeight.toString());

        const quote = {
            length: parseFloat(data.length),
            width: parseFloat(data.width),
            height: parseFloat(data.height),
            weight: parseFloat(data.weight),
            volumetricWeight: volumetricWeight ?? 0,
            quantity: 1,
            comunaOriginId: parseInt(data.comunaOrigin, 10),
            comunaDestinationId: parseInt(data.comunaDestination, 10),
            addressOrigin: `${data.streetOrigin} ${data.streetNumberOrigin}`,
            addressDestination: `${data.streetDestination} ${data.streetNumberDestination}`,
            declaredValue: Number(declaredValueReplace(data.declaredValue)),
            calculateInsurance: data.calculateInsurance,
            clientId: data.clientId,
        };

        try {
            setCreateQuoteState('loading');
            await dispatch(createQuote(quote)).unwrap();
            setCreateQuoteState('success');
        } catch (error) {
            setCreateQuoteState('error');
        }
    };

    const onSubmit = async (data: FormData) => {
        if (
            !data.streetDestination ||
            !data.streetNumberDestination ||
            !data.streetOrigin ||
            !data.streetNumberOrigin
        ) {
            setFormData(data);
            setAlertBranch(true);
        } else {
            await handleResponse(data);
        }
    };

    useEffect(() => {
        async function response() {
            if (responseUser === true) {
                setResponseUser(false);
                if (formData) {
                    await handleResponse(formData);
                }
            }
        }
        response();
    }, [formData, handleResponse, alertBranch, responseUser]);

    /* eslint-disable @typescript-eslint/no-explicit-any */

    const [maxValueInsurance, setMaxValueInsurance] = useState(0);
    const [errorCero, setErrorCero] = useState(false);
    const [errorInsurance, setErrorInsurance] = useState(false);

    const validateInsurance = () => {
        const coverageValues = insurances.data.map((i) => i.maximumCoverage);
        const minValue = Math.max(...coverageValues);
        setMaxValueInsurance(minValue);
    };
    /* eslint-disable @typescript-eslint/no-explicit-any */

    const handleKeyUp = (e: any) => {
        validateInsurance();
        const declaredValue = Number(declaredValueReplace(e.target.value));
        if (declaredValue && declaredValue <= 0) {
            setErrorCero(true);
        } else {
            setErrorCero(false);
        }
        if (
            maxValueInsurance > 0 &&
            declaredValue > 0 &&
            declaredValue > maxValueInsurance
        ) {
            setErrorInsurance(true);
        } else {
            setErrorInsurance(false);
        }

        let n = e.target.value;
        n = String(n).replace(/\D/g, '');
        setValue(
            'declaredValue',
            n === ''
                ? n
                : Number(n).toLocaleString('es-CL', {
                      style: 'currency',
                      currency: 'CLP',
                  })
        );
    };

    const [priceCourierMax, setPriceCourierMax] = useState(false);

    const uncheckSizes = () => {
        /* eslint-disable @typescript-eslint/no-explicit-any */
        const ele = document.getElementsByName('selectedSize') as any;
        for (let i = 0; i < ele.length; i += 1) ele[i].checked = false;
    };
    const quotesRef = useRef<null | HTMLElement>(null);

    const scrollToBottom = () => {
        quotesRef.current?.scrollIntoView({
            block: 'start',
            behavior: 'smooth',
        });
    };

    const [selectedQuoteId, setSelectedQuoteId] = useState<string>('');
    const declaredValue = watch('declaredValue');
    useEffect(() => {
        if (
            quotes.status === 'success' &&
            declaredValue &&
            activeInsureCourier &&
            insurances.data
        ) {
            const courierInsurance = insurances.data.find(
                (item) => item.courierId === selectedQuoteId
            );
            if (
                declaredValue &&
                courierInsurance &&
                Number(declaredValueReplace(declaredValue)) >
                    courierInsurance.maximumCoverage
            ) {
                setPriceCourierMax(true);
            } else {
                setPriceCourierMax(false);
            }
        }
    }, [
        declaredValue,
        insurances,
        activeInsureCourier,
        quotes.status,
        selectedQuoteId,
    ]);

    const selectQuote = (
        quote: IQuoteResponse,
        idQuote: string | undefined,
        deliveryDate: string
    ) => {
        // Get all values from the form
        const values = getValues();
        if (idQuote) {
            setSelectedQuoteId(idQuote);
            setTimeout(() => {
                scrollToBottom();
            }, 200);
        } else {
            setSelectedQuoteId('');
        }
        const courierInsurance = insurances.data.find(
            (item) => item.courierId === quote.courierId
        );

        if (
            values.declaredValue &&
            courierInsurance &&
            activeInsureCourier &&
            Number(declaredValueReplace(values.declaredValue)) >
                courierInsurance.maximumCoverage
        ) {
            setPriceCourierMax(true);
        } else {
            setPriceCourierMax(false);
        }
        setDeliveryEstimateDate(new Date(deliveryDate));

        const shipment = {
            deliveryEstimateDate: deliveryDate,
            courierId: quote.courierId,
            senderStreet: values.streetOrigin,
            clientId: values.clientId,
            originStreet: values.streetOrigin,
            originStreetNumber: values.streetNumberOrigin,
            originComunaId: parseInt(values.comunaOrigin, 10),
            destinationStreet: values.streetDestination,
            destinationStreetNumber: values.streetNumberDestination,
            destinationComunaId: parseInt(values.comunaDestination, 10),
            weight: parseFloat(values.weight),
            volumetricWeight: parseFloat(values.volumetricWeight),
            length: parseFloat(values.length),
            width: parseFloat(values.width),
            height: parseFloat(values.height),
            quantity: parseInt(values.quantity, 10) ?? 1,
            description: values.description,
            price: quote.price,
            fee: quote.fee,
            courierPrice: quote.courierPrice,
            insurance: Math.round(quote.insurance),
            isPorcent: values.isPorcent,
        };
        setSelectedQuote(quote);

        dispatch(shipmentInitialData(shipment));
    };

    const resetQuote = () => {
        reset({
            declaredValue: '',
            isPorcent: false,
            discount: '',
            calculateInsurance: false,
            length: '',
            width: '',
            height: '',
            weight: '',
            volumetricWeight: '',
            quantity: '',
            comunaOrigin: '',
            comunaDestination: '',
            regionDestination: '',
            clientId: '',
            regionOrigin: '',
            originBranchId: '',
            destinationBranchId: '',
            streetOrigin: '',
            streetDestination: '',
            streetNumberOrigin: '',
            streetNumberDestination: '',
            insurance: 0,
            senderNumber: '',
            senderName: '',
            senderPhone: '',
            senderRut: '',
            senderEmail: '',
            senderSecondLine: '',
            senderComment: '',
            receiverName: '',
            receiverPhone: '',
            receiverRut: '',
            receiverEmail: '',
            receiverSecondLine: '',
            receiverComment: '',
        });
        setSelectedQuote(undefined);
        setSelectedOriginBranch('');
        setSelectedQuoteId('');
        uncheckSizes();
        setSelectedDestinationBranch('');
        dispatch(resetQuotes());
    };

    const getCourierDeliveryType = () => {
        if (!selectedQuote) {
            return '';
        }
        switch (selectedQuote.courier) {
            case 'MINUTOS99':
                return selectedQuote.time;
            default:
                return selectedQuote.description;
        }
    };

    // CustomHook para obtener el listado de coueriers
    const { couriers } = useCouriers();

    const createShip = async (data: FormData) => {
        if (
            selectedQuote &&
            selectedQuote.courier === 'STARKEN' &&
            data.paymentType === undefined
        ) {
            setError('paymentType', { type: 'required' });
            return;
        }
        uncheckSizes();
        const courierData = couriers.find(
            (courier) => courier.id === selectedQuote?.courierId
        );
        let shipmentTypeId = '';
        if (courierData) {
            shipmentTypeId =
                courierData.shipmentTypes?.find(
                    (shipmentType) =>
                        shipmentType.description === selectedQuote?.description
                )?.id || '';
        }
        const shipment = {
            shipmentTypeId,
            courierId: selectedQuote?.courierId ?? '',
            courierDeliveryType: getCourierDeliveryType(),
            serviceType: selectedQuote?.serviceType ?? '',
            deliveryType: selectedQuote?.deliveryType ?? '',
            paymentType: data.paymentType ?? '',
            deliveryEstimateDate,
            price: selectedQuote?.price,
            fee: selectedQuote?.fee,
            courierPrice: selectedQuote?.courierPrice,
            productDescription: data.productDescription,

            // PACKAGE DATA
            weight: parseFloat(data.weight),
            volumetricWeight: parseFloat(data.volumetricWeight),
            length: parseFloat(data.length),
            width: parseFloat(data.width),
            height: parseFloat(data.height),
            quantity: 1,

            // DESTINATION DATA
            destinationComunaId: +data.comunaDestination,
            destinationSecondLine: data.receiverSecondLine,
            destinationComment: data.senderComment,
            destinationStreet: data.streetDestination,
            destinationNumber: data.streetNumberDestination,
            receiverName: data.receiverName,
            receiverPhone: data.receiverPhone.toString(),
            receiverEmail: data.receiverEmail,

            // ORIGIN DATA
            originComunaId: +data.comunaOrigin,
            originSecondLine: data.senderSecondLine,
            originComment: data.receiverComment,
            senderName: data.senderName,
            senderPhone: data.senderPhone.toString(),
            senderEmail: data.senderEmail,
            originStreet: data.streetOrigin,
            originNumber: data.streetNumberOrigin,

            // SENDER DATA
            senderStreet: data.streetOrigin,
            senderNumber: data.streetNumberOrigin,

            // RECEIVER DATA
            receiverStreet: data.streetDestination,
            receiverNumber: data.streetNumberDestination,
            // ADDED VALUE
            declaredValue: Number(declaredValueReplace(data.declaredValue)),
            insurance: Math.round(selectedQuote?.insurance || 0),
            // DISCOUNT
            isPorcent: data.isPorcent,
            clientId: data?.clientId,
            discount: Number(data.discount),
        };

        try {
            setCreateShipmentState('loading');
            await dispatch(createShipment(shipment)).unwrap();
            setCreateShipmentState('success');
            resetQuote();
            dispatch(resetQuotes());
            dispatch(resetClient());
            setSelectedCliente('');
        } catch (error) {
            setCreateShipmentState('error');
            setAlertSucess(false);
        }
    };

    const closeAlertSucess = () => {
        setAlertSucess(false);
    };
    useEffect(() => {
        if (createShipmentState === 'success') {
            setTimeout(() => {
                setCreateShipmentState('idle');
            }, 3000);
        }
    }, [createShipmentState]);

    const handleChangeSize = (size: any) => {
        setValue('selectedSize', size.key);
        sizes.map((s) => {
            if (s.key === size.key) {
                return {
                    ...s,
                    check: true,
                };
            }
            return { ...s, check: false };
        });
    };

    return (
        <div className="relative  mb-48">
            <PageHeader title={t('quotes.create.title')} />
            <div className="flex text-black shadow-md bg-gray-100 p-6">
                <div className="w-full md:w-1/3">
                    <h3 className="text-lg font-bold">
                        1. {t('quotes.create.originAddress')}
                    </h3>

                    {/* START BRANCH ORIGIN */}
                    <div>
                        <div className="mt-4">
                            <label
                                htmlFor="originBranchId"
                                className="block text-sm font-medium text-gray-700"
                            >
                                {t('common.branch')}
                                <select
                                    id="originBranchId"
                                    className="mt-2"
                                    {...register('originBranchId', {
                                        required: false,
                                    })}
                                >
                                    <option value="">
                                        {branches.status === 'loading'
                                            ? t('common.loading')
                                            : t('quotes.create.branchSelect')}
                                    </option>
                                    {branches.status === 'success' ? (
                                        branches.data.map(
                                            (branch) => (
                                                // branch.id !==
                                                // client?.branchId && (
                                                <option
                                                    key={branch.id}
                                                    value={branch.id}
                                                >
                                                    {branch.name}
                                                </option>
                                            )
                                            // )
                                        )
                                    ) : (
                                        <option>{t('loading.message')}</option>
                                    )}
                                </select>
                            </label>
                            {errors.originBranchId && (
                                <p className="text-red-600 ml-2">
                                    {errors.originBranchId.type ===
                                        'required' &&
                                        t('common.errors.fieldRequired')}
                                </p>
                            )}
                        </div>
                        {/* CLIENTS */}
                        <div className="mt-4">
                            <label
                                htmlFor="originBranchId"
                                className="block text-sm font-medium text-gray-700"
                            >
                                {t('common.client')}
                                <select
                                    id="originBranchId"
                                    className="mt-2"
                                    {...register('clientId', {
                                        required: false,
                                        // validate: (value) => value !== '',
                                    })}
                                    // onChange={handleClient}
                                >
                                    <option value="">
                                        {clients.status === 'loading'
                                            ? t('common.loading')
                                            : t('quotes.create.clientSelect')}
                                    </option>
                                    {clients.status === 'success' ? (
                                        clients.data.map(
                                            (branch) => (
                                                // branch.id !==
                                                // client?.branchId && (
                                                <option
                                                    key={branch.id}
                                                    value={branch.id}
                                                >
                                                    {branch.name}
                                                </option>
                                            )
                                            // )
                                        )
                                    ) : (
                                        <option>{t('loading.message')}</option>
                                    )}
                                </select>
                            </label>
                        </div>
                        <div className="w-full text-center text-xl text-black italic my-4">
                            <p>ó {t('quotes.create.originAddressHelper')}</p>
                        </div>
                        <div className="mt-4">
                            <label
                                htmlFor="regionOrigin"
                                className="block text-sm font-medium text-gray-700"
                            >
                                {t('quotes.create.regionOrigin')} *
                                <select
                                    id="regionOrigin"
                                    className="mt-2"
                                    {...register('regionOrigin', {
                                        required: true,
                                        validate: (value) => value !== '',
                                    })}
                                    placeholder={t(
                                        'quotes.create.regionOrigin'
                                    )}
                                >
                                    <option value="">
                                        {regions.status === 'loading'
                                            ? t('common.loading')
                                            : t('quotes.create.regionSelect')}
                                    </option>
                                    {regions.status === 'success' ? (
                                        regions.data.map((region) => (
                                            <option
                                                key={`o-${region.id}`}
                                                value={region.id}
                                            >
                                                {region.name}
                                            </option>
                                        ))
                                    ) : (
                                        <option>{t('loading.message')}</option>
                                    )}
                                </select>
                            </label>
                            {errors.regionOrigin && (
                                <p className="text-red-600 ml-2">
                                    {errors.regionOrigin.type === 'required' &&
                                        t('common.errors.fieldRequired')}
                                </p>
                            )}
                        </div>
                        {/* START COMUNA ORIGIN */}
                        <div className="mt-4">
                            <label
                                htmlFor="comunaOrigin"
                                className="block text-sm font-medium text-gray-700"
                            >
                                {t('quotes.create.comunaOrigin')} *
                                <select
                                    id="comunaOrigin"
                                    disabled={selectedOriginRegion === ''}
                                    // value={client ? client.comunaId : getValues('comunaOrigin')}
                                    className={`mt-2 ${
                                        selectedOriginRegion === ''
                                            ? 'cursor-not-allowed'
                                            : ''
                                    }`}
                                    {...register('comunaOrigin', {
                                        required: true,
                                        validate: (value) => value !== '',
                                    })}
                                    placeholder={t(
                                        'quotes.create.comunaOrigin'
                                    )}
                                >
                                    {comunas.status === 'loading' && (
                                        <option value="">
                                            {t('common.loading')}
                                        </option>
                                    )}

                                    {/* {client && (
                                        <option value={client.comunaId}>
                                            {client.comuna.name}
                                        </option>
                                    )} */}
                                    {!selectedOriginRegion && (
                                        <option value="">
                                            {t(
                                                'quotes.create.firstSelectRegion'
                                            )}
                                        </option>
                                    )}
                                    {selectedOriginRegion &&
                                        selectedOriginComuna === '' && (
                                            <option value="">
                                                {t(
                                                    'quotes.create.comunaSelect'
                                                )}
                                            </option>
                                        )}

                                    {selectedOriginRegion &&
                                    comunasByRegionOriginId.length > 0 ? (
                                        comunasByRegionOriginId.map(
                                            (comuna) => (
                                                // comuna.id !==
                                                // client?.comunaId && (
                                                <option
                                                    key={`o-${comuna.id}`}
                                                    value={comuna.id}
                                                >
                                                    {comuna.name}
                                                </option>
                                            )
                                            // )
                                        )
                                    ) : (
                                        <option>
                                            {t('quotes.create.regionSelect')}
                                        </option>
                                    )}
                                </select>
                            </label>
                            {errors.comunaOrigin && (
                                <p className="text-red-600 ml-2">
                                    {errors.comunaOrigin.type === 'required' &&
                                        t('common.errors.fieldRequired')}
                                </p>
                            )}
                        </div>
                        {/* START STREET ORIGIN */}
                        <div className="mt-4">
                            <label
                                htmlFor="streetOrigin"
                                className="block text-sm font-medium text-gray-700"
                            >
                                {t('shipments.street')}
                                <input
                                    id="streetOrigin"
                                    type="text"
                                    // value={client && client.address}
                                    className={`mt-2 ${
                                        errors.streetOrigin
                                            ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                            : ''
                                    }`}
                                    {...register('streetOrigin', {
                                        required: false,
                                    })}
                                />
                            </label>
                            {errors.streetOrigin && (
                                <p className="text-red-600 ml-2">
                                    {errors.streetOrigin.type === 'required' &&
                                        t('common.errors.fieldRequired')}
                                </p>
                            )}
                        </div>
                        {/* END STREET ORIGIN */}
                        {/* START STREET NUMBER ORIGIN */}
                        <div className="mt-4">
                            <label
                                htmlFor="streetNumberOrigin"
                                className="block text-sm font-medium text-gray-700"
                            >
                                {t('shipments.streetNumber')}
                                <input
                                    id="streetNumberOrigin"
                                    type="text"
                                    // value={client && client.addressNumber}
                                    className={`mt-2 ${
                                        errors.streetNumberOrigin
                                            ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                            : ''
                                    }`}
                                    {...register('streetNumberOrigin', {
                                        required: false,
                                    })}
                                />
                            </label>
                            {errors.streetNumberOrigin && (
                                <p className="text-red-600 ml-2">
                                    {errors.streetNumberOrigin.type ===
                                        'required' &&
                                        t('common.errors.fieldRequired')}
                                </p>
                            )}
                        </div>
                        {/* END STREET NUMBER ORIGIN */}
                    </div>
                    <p className="text-gray-600 text-sm mt-10">
                        * {t('common.requiredFields')}
                    </p>
                </div>
                <div className="w-1/3 flex items-center justify-center text-center">
                    <img src="/images/transport.svg" alt="transport" />
                </div>
                {/* START DESTINATION */}
                <div className="w-full md:w-1/3">
                    <h3 className="text-lg font-bold">
                        2. {t('quotes.create.destinationAddress')}
                    </h3>
                    {/* START BRANCH DESTINATION */}
                    <div>
                        <div className="mt-4">
                            <label
                                htmlFor="destinationBranchId"
                                className="block text-sm font-medium text-gray-700"
                            >
                                {t('common.branch')}
                                <select
                                    id="destinationBranchId"
                                    className="mt-2"
                                    {...register('destinationBranchId', {
                                        required: false,
                                        // validate: (value) => value !== '',
                                    })}
                                    placeholder={t(
                                        'quotes.create.destinationBranchId'
                                    )}
                                >
                                    <option value="">
                                        {branches.status === 'loading'
                                            ? t('common.loading')
                                            : t('quotes.create.branchSelect')}
                                    </option>
                                    {branches.status === 'success' ? (
                                        branches.data.map((branch) => (
                                            <option
                                                key={branch.id}
                                                value={branch.id}
                                            >
                                                {branch.name}
                                            </option>
                                        ))
                                    ) : (
                                        <option>{t('loading.message')}</option>
                                    )}
                                </select>
                            </label>
                            {errors.destinationBranchId && (
                                <p className="text-red-600 ml-2">
                                    {errors.destinationBranchId.type ===
                                        'required' &&
                                        t('common.errors.fieldRequired')}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="w-full text-center text-xl text-black italic my-4">
                        <p>ó {t('quotes.create.destinationAddressHelper')}</p>
                    </div>
                    <div>
                        <div className="mt-4">
                            <label
                                htmlFor="regionDestination"
                                className="block text-sm font-medium text-gray-700"
                            >
                                {t('quotes.create.regionDestination')} *
                                <select
                                    id="regionDestination"
                                    className="mt-2"
                                    {...register('regionDestination', {
                                        required: true,
                                        validate: (value) => value !== '',
                                    })}
                                    placeholder={t(
                                        'quotes.create.regionDestination'
                                    )}
                                >
                                    <option value="">
                                        {regions.status === 'loading'
                                            ? t('common.loading')
                                            : t('quotes.create.regionSelect')}
                                    </option>
                                    {regions.status === 'success' ? (
                                        regions.data.map((region) => (
                                            <option
                                                key={region.id}
                                                value={region.id}
                                            >
                                                {region.name}
                                            </option>
                                        ))
                                    ) : (
                                        <option>{t('loading.message')}</option>
                                    )}
                                </select>
                            </label>
                            {errors.regionDestination && (
                                <p className="text-red-600 ml-2">
                                    {errors.regionDestination.type ===
                                        'required' &&
                                        t('common.errors.fieldRequired')}
                                </p>
                            )}
                        </div>

                        {/* START COMUNA DESTINATION */}
                        <div className="mt-4">
                            <label
                                htmlFor="comunaDestination"
                                className="block text-sm font-medium text-gray-700"
                            >
                                {t('quotes.create.comunaDestination')} *
                                <select
                                    id="comunaDestination"
                                    disabled={selectedDestinationRegion === ''}
                                    className={`mt-2 ${
                                        selectedDestinationRegion === ''
                                            ? 'cursor-not-allowed'
                                            : ''
                                    }`}
                                    {...register('comunaDestination', {
                                        required: true,
                                        validate: (value) => value !== '',
                                    })}
                                    placeholder={t(
                                        'quotes.create.comunaDestination'
                                    )}
                                >
                                    {comunas.status === 'loading' && (
                                        <option value="">
                                            {t('common.loading')}
                                        </option>
                                    )}
                                    {!selectedDestinationRegion && (
                                        <option value="">
                                            {t(
                                                'quotes.create.firstSelectRegion'
                                            )}
                                        </option>
                                    )}
                                    {selectedDestinationRegion && (
                                        <option value="">
                                            {t('quotes.create.comunaSelect')}
                                        </option>
                                    )}

                                    {selectedDestinationRegion &&
                                    comunasByRegionDestinationId.length > 0 ? (
                                        comunasByRegionDestinationId.map(
                                            (comuna) => (
                                                <option
                                                    key={comuna.id}
                                                    value={comuna.id}
                                                >
                                                    {comuna.name}
                                                </option>
                                            )
                                        )
                                    ) : (
                                        <option>
                                            {t('quotes.create.regionSelect')}
                                        </option>
                                    )}
                                </select>
                            </label>
                            {errors.comunaDestination && (
                                <p className="text-red-600 ml-2">
                                    {errors.comunaDestination.type ===
                                        'required' &&
                                        t('common.errors.fieldRequired')}
                                </p>
                            )}
                        </div>
                        {/* END COMUNA DESTINATION */}
                        {/* START STREET DESTINATION */}
                        <div className="mt-4">
                            <label
                                htmlFor="streetDestination"
                                className="block text-sm font-medium text-gray-700"
                            >
                                {t('shipments.street')}
                                <input
                                    id="streetDestination"
                                    type="text"
                                    className={`mt-2 ${
                                        errors.streetDestination
                                            ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                            : ''
                                    }`}
                                    {...register('streetDestination', {
                                        required: false,
                                    })}
                                />
                            </label>
                            {errors.streetDestination && (
                                <p className="text-red-600 ml-2">
                                    {errors.streetDestination.type ===
                                        'required' &&
                                        t('common.errors.fieldRequired')}
                                </p>
                            )}
                        </div>
                        {/* END STREET DESTINATION */}
                        {/* START STREET NUMBER DESTINATION */}
                        <div className="mt-4">
                            <label
                                htmlFor="streetNumberDestination"
                                className="block text-sm font-medium text-gray-700"
                            >
                                {t('shipments.streetNumber')}
                                <input
                                    id="streetNumberDestination"
                                    type="text"
                                    className={`mt-2 ${
                                        errors.streetNumberDestination
                                            ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                            : ''
                                    }`}
                                    {...register('streetNumberDestination', {
                                        required: false,
                                    })}
                                />
                            </label>
                            {errors.streetNumberDestination && (
                                <p className="text-red-600 ml-2">
                                    {errors.streetNumberDestination.type ===
                                        'required' &&
                                        t('common.errors.fieldRequired')}
                                </p>
                            )}
                        </div>
                        {/* END STREET NUMBER DESTINATION */}
                    </div>
                </div>
            </div>
            <div className="text-black shadow-md bg-gray-100 p-6 mt-12 mb-4">
                <div className="flex">
                    <div>
                        <h3 className="text-lg font-bold">
                            3. {t('quotes.create.packageSize')}
                        </h3>
                        <p>{t('quotes.create.packageSizeHelper')}</p>
                    </div>
                </div>
                <div className="mt-4 mb-20">
                    <h3 className="text-lg text-gray-600 font-bold">
                        {t('common.sizes')}
                    </h3>
                    <div className="flex items-center space-x-6">
                        {/* START Sizes */}
                        <div className="mt-2 flex space-x-4">
                            {sizes.map((size) => (
                                <div
                                    key={size.key}
                                    className="flex items-baseline justify-center"
                                >
                                    <div className="mt-1 mr-2">
                                        <input
                                            id="selectedSize"
                                            checked={size.check}
                                            value={size.key}
                                            name="selectedSize"
                                            onChange={() =>
                                                handleChangeSize(size)
                                            }
                                            type="radio"
                                            className={`${
                                                errors.selectedSize
                                                    ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                    : ''
                                            }`}
                                        />
                                    </div>
                                    <label
                                        htmlFor="branchId"
                                        className="block font-medium text-gray-700"
                                    >
                                        <span className="text-lg font-bold">
                                            {size.key}
                                        </span>
                                    </label>
                                </div>
                            ))}
                        </div>

                        {/* END Sizes */}
                        {/* START MEASURES */}
                        <div className="mt-2 flex space-x-4 bg-white p-6">
                            {/* START LENGTH */}
                            <div>
                                <label
                                    htmlFor="length"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    {t('common.length')} ({t('common.cm')}) *
                                    <input
                                        id="length"
                                        type="number"
                                        min="0"
                                        className={`mt-2 ${
                                            errors.length
                                                ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                : ''
                                        }`}
                                        {...register('length', {
                                            required: true,
                                            validate: (value) => value !== '',
                                        })}
                                    />
                                </label>
                                {errors.length && (
                                    <p className="text-red-600 ml-2">
                                        {errors.length.type === 'required' &&
                                            t('common.errors.fieldRequired')}
                                    </p>
                                )}
                            </div>
                            {/* END LENGTH */}
                            {/* START WIDTH */}
                            <div>
                                <label
                                    htmlFor="width"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    {t('common.width')} ({t('common.cm')}) *
                                    <input
                                        id="width"
                                        type="number"
                                        min="0"
                                        className={`mt-2 ${
                                            errors.width
                                                ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                : ''
                                        }`}
                                        {...register('width', {
                                            required: true,
                                            validate: (value) => value !== '',
                                        })}
                                    />
                                </label>
                                {errors.width && (
                                    <p className="text-red-600 ml-2">
                                        {errors.width.type === 'required' &&
                                            t('common.errors.fieldRequired')}
                                    </p>
                                )}
                            </div>
                            {/* END WIDTH */}
                            {/* START HEIGHT */}
                            <div>
                                <label
                                    htmlFor="height"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    {t('common.height')} ({t('common.cm')}) *
                                    <input
                                        id="height"
                                        type="number"
                                        min="0"
                                        className={`mt-2 ${
                                            errors.height
                                                ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                : ''
                                        }`}
                                        {...register('height', {
                                            required: true,
                                            validate: (value) => value !== '',
                                        })}
                                    />
                                </label>
                                {errors.height && (
                                    <p className="text-red-600 ml-2">
                                        {errors.height.type === 'required' &&
                                            t('common.errors.fieldRequired')}
                                    </p>
                                )}
                            </div>
                            {/* END HEIGHT */}
                            {/* START WEIGHT */}
                            <div>
                                <label
                                    htmlFor="weight"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    {t('common.weight')} ({t('common.kg')}) *
                                    <input
                                        id="weight"
                                        type="number"
                                        min="0"
                                        className={`mt-2 ${
                                            errors.weight
                                                ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                : ''
                                        }`}
                                        {...register('weight', {
                                            required: true,
                                            validate: (value) => value !== '',
                                        })}
                                    />
                                </label>
                                {errors.weight && (
                                    <p className="text-red-600 ml-2">
                                        {errors.weight.type === 'required' &&
                                            t('common.errors.fieldRequired')}
                                    </p>
                                )}
                            </div>
                        </div>
                        {/* END MEASURES */}
                    </div>
                </div>
            </div>
            <div className="text-black shadow-md bg-gray-100 p-6 mt-12 mb-4  grid grid-cols-2">
                <div>
                    <h3 className="text-lg font-bold flex">
                        4. Valor Declarado
                    </h3>
                    <div className="grid grid-cols-3">
                        <div className="mt-9 mr-2 flex">
                            <div className=" mt-9 mr-2">CLP</div>
                            <label
                                htmlFor="declaredValue"
                                className="block text-sm font-medium text-gray-700"
                            >
                                <span>Valor Declarado *</span>

                                <div className="mt-1">
                                    <input
                                        type="text"
                                        min="1"
                                        {...register('declaredValue', {
                                            required: true,
                                            onChange: (e) => handleKeyUp(e),
                                            validate: (value) =>
                                                value !== undefined &&
                                                value !== null &&
                                                Number(
                                                    declaredValueReplace(value)
                                                ) > 0,
                                        })}
                                    />
                                    {errors.declaredValue && (
                                        <div className="text-red-600 ml-2">
                                            {errors.declaredValue.type ===
                                                'required' &&
                                                t(
                                                    'common.errors.fieldRequired'
                                                )}
                                        </div>
                                    )}
                                    {errorCero && (
                                        <div className="text-red-600 ml-2">
                                            <p>valor debe ser mayor a 0</p>
                                        </div>
                                    )}
                                    {activeInsureCourier && errorInsurance && (
                                        <div className="text-red-600 ml-2">
                                            <p>
                                                error valor declarado supera la
                                                maxima cobertura por courier
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </label>
                        </div>

                        <div className="mt-9 mr-2 relative">
                            <label
                                htmlFor="declaredValue"
                                className="block text-sm font-medium text-gray-700 ml-5"
                            >
                                <span>Calcular Seguro</span>
                                <div className="mt-1 ">
                                    <input
                                        type="checkbox"
                                        // min="1"
                                        {...register('calculateInsurance', {})}
                                    />
                                </div>
                                <div className="absolute left-12 top-1">
                                    <span className="ml-4 group relative">
                                        <span className="pointer-events-none absolute -top-10 left-1/2 -translate-x-1/2 whitespace-nowrap rounded bg-black px-2 py-1 text-white opacity-0 transition before:absolute before:left-1/2 before:top-full before:-translate-x-1/2 before:border-4 before:border-transparent before:border-t-black before:content-[''] group-hover:opacity-100">
                                            Al marcarlo se calcula el seguro con
                                            el valor declarado
                                        </span>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-6 cursor-pointer"
                                            viewBox="0 0 20 20"
                                            fill="orange"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    </span>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>

                {activeInsureCourier && (
                    <div>
                        <table className="table-auto w-full mx-auto">
                            <thead className="px-2 py-2 bg-slate-500 border-2 border-white rounded-lg text-white">
                                <tr>
                                    <th
                                        colSpan={3}
                                        className="px-2 py-2 bg-slate-500 border-2 border-white rounded-lg"
                                    >
                                        <div className="flex justify-between">
                                            Cobertura máxima por courier
                                        </div>
                                    </th>
                                </tr>
                                <tr>
                                    <th className="px-2 py-2 bg-slate-500 border-2 border-white rounded-lg">
                                        <div className="flex justify-between">
                                            {t('branchFeeByCourier.name')}
                                        </div>
                                    </th>
                                    <th className="px-2 py-2 bg-slate-500 border-2 border-white rounded-lg">
                                        <div className="flex justify-between">
                                            Valor
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {insurances.status === 'success' &&
                                    insurances.data &&
                                    insurances.data.map((i) => (
                                        <tr
                                            key={i.id}
                                            className={
                                                maxValueInsurance ===
                                                i.maximumCoverage
                                                    ? 'bg-orange-300'
                                                    : ''
                                            }
                                        >
                                            <td>{i.courier.name}</td>
                                            <td>
                                                CLP
                                                {i.maximumCoverage &&
                                                    new Intl.NumberFormat(
                                                        'es-CL',
                                                        {
                                                            style: 'currency',
                                                            currency: 'CLP',
                                                        }
                                                    ).format(i.maximumCoverage)}
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>

            <div className="mb-10 w-1/2 flex space-x-4">
                <Button
                    type="submit"
                    size="lg"
                    disabled={
                        createQuoteState === 'loading' &&
                        !errorInsurance &&
                        !errorCero
                    }
                    className={`font-bold w-full ${
                        createQuoteState === 'loading' ?? 'cursor-not-allowed'
                    }`}
                    onClick={handleSubmit(onSubmit)}
                    label={
                        createQuoteState === 'loading'
                            ? t('wait.message')
                            : t('quotes.create.action')
                    }
                />
                <Button
                    type="button"
                    size="lg"
                    variant="link"
                    disabled={createQuoteState === 'loading'}
                    className={`font-bold w-full ${
                        createQuoteState === 'loading' ?? 'cursor-not-allowed'
                    }`}
                    onClick={() => resetQuote()}
                    label={t('common.clear')}
                />
            </div>
            {/* START QUOTES */}
            {quotes.status === 'loading' && (
                <div>
                    <LoadingAnimated message={t('loading.message')} />
                </div>
            )}
            {quotes.status === 'success' &&
                !selectedQuote &&
                quotes.data.filter((q) => q.valid !== false).length === 0 && (
                    <div>{t('quotes.create.noQuotes')}</div>
                )}
            <div className="mb-3" id="quotes">
                {quotes.status === 'success' && quotes.data.length > 0 && (
                    <div>
                        <div className="flex bg-black text-white font-bold">
                            <div className="w-1/3 p-2">Proveedor</div>
                            <div className="w-1/3 p-2">Tipo</div>
                            <div className="w-1/3 p-2 text-right">Precio</div>
                            <div className="w-1/3 p-2 text-right">
                                Fecha estimada entrega
                            </div>
                            <div className="w-1/3 p-2 text-right">
                                Días de entrega
                            </div>
                            <div className="w-1/3 p-2 text-center">
                                Seleccionar
                            </div>
                        </div>
                        {quotes.data
                            .filter((q) => q.valid !== false)
                            .map((quote, index) => (
                                <div
                                    key={quote.courierId?.concat(
                                        index.toString()
                                    )}
                                    className={`flex divide-x-2 border-b-2 border-b-gray-100 ${
                                        selectedQuoteId ===
                                            quote.courierId?.concat(
                                                index.toString()
                                            ) && 'bg-lime-200'
                                    }`}
                                >
                                    <div className="w-1/3 p-2 ">
                                        {quote.courierName}
                                    </div>
                                    <div className="w-1/3 p-2">
                                        {quote.description}
                                    </div>
                                    <div className="w-1/3 p-2 text-right tooltip">
                                        <p>
                                            <strong className="text-xl">
                                                {quote.price.toLocaleString(
                                                    'de-DE',
                                                    {
                                                        style: 'currency',
                                                        currency: 'CLP',
                                                    }
                                                )}
                                            </strong>
                                        </p>
                                        <div className="tooltiptextsm">
                                            <p>
                                                <span className="text-sm ">
                                                    Fee:{' '}
                                                    {quote.fee.toLocaleString(
                                                        'de-DE',
                                                        {
                                                            style: 'currency',
                                                            currency: 'CLP',
                                                        }
                                                    )}
                                                </span>
                                            </p>
                                            <p>
                                                <span className="text-sm">
                                                    Precio courier:{' '}
                                                    {quote.courierPrice.toLocaleString(
                                                        'de-DE',
                                                        {
                                                            style: 'currency',
                                                            currency: 'CLP',
                                                        }
                                                    )}
                                                </span>
                                            </p>

                                            {getValues('calculateInsurance') ===
                                                true &&
                                                (quote.insurance > 0 ? (
                                                    <p>
                                                        <span className="text-sm">
                                                            Seguro:{' '}
                                                            {quote.insurance.toFixed()}{' '}
                                                            CLP
                                                        </span>
                                                    </p>
                                                ) : (
                                                    <span className="text-sm">
                                                        No posee seguro
                                                    </span>
                                                ))}
                                        </div>
                                    </div>
                                    <div className="w-1/3 p-2 text-right tooltip">
                                        <p>
                                            <strong className="text-xl">
                                                {formatDate(
                                                    new Date(
                                                        quote.deliveryEstimateDate
                                                    ),
                                                    'dd/MM/yyyy'
                                                )}
                                            </strong>
                                        </p>
                                    </div>
                                    <div className="w-1/3 p-2 text-right tooltip">
                                        <p>
                                            <strong className="text-xl">
                                                {diffDate(
                                                    new Date(),
                                                    new Date(
                                                        quote.deliveryEstimateDate
                                                    )
                                                )}
                                            </strong>
                                        </p>
                                    </div>
                                    <div className="w-1/3 p-2 text-center text-green-600">
                                        <button
                                            type="button"
                                            className="w-full flex items-center justify-center"
                                            onClick={() =>
                                                selectQuote(
                                                    quote,
                                                    quote.courierId,
                                                    quote.deliveryEstimateDate
                                                )
                                            }
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-6 w-6"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                strokeWidth={2}
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            ))}
                    </div>
                )}
                {selectedQuote && (
                    <div
                        ref={quotesRef as React.RefObject<HTMLDivElement>}
                        className="text-black shadow-md bg-gray-100 p-6 mb-10"
                    >
                        <p className="text-xl text-black">
                            {t('common.completeDataBelow')}
                        </p>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-12 w-12 mx-auto"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M19 13l-7 7-7-7m14-8l-7 7-7-7"
                            />
                        </svg>
                    </div>
                )}
            </div>
            {/* END QUOTES */}
            {selectedQuote && priceCourierMax === false ? (
                <div className="text-black shadow-md bg-gray-100 p-6 mb-22">
                    <h3 className="text-lg font-bold">
                        5. {t('shipments.titleShipmentData')}
                    </h3>
                    <p className="text-2xl text-slate-700">
                        {selectedQuote.courierName}
                    </p>
                    <p className="text-xl">
                        {selectedQuote.price?.toLocaleString('es-CL', {
                            currency: 'CLP',
                            style: 'currency',
                        })}
                    </p>
                    <p className="text-sm">{selectedQuote.description}</p>
                    <div className="mt-6">
                        <div className="flex">
                            <div className="mt-4 w-1/3">
                                <label
                                    htmlFor="startDate"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    <span>
                                        {t(
                                            'shipments.single.deliveryEstimateDate'
                                        )}
                                        *
                                    </span>
                                    <div className="mt-1">
                                        <DatePicker
                                            selectsStart
                                            onChange={(date: Date) => {
                                                setDeliveryEstimateDate(date);
                                            }}
                                            minDate={new Date()}
                                            startDate={new Date()}
                                            selected={deliveryEstimateDate}
                                            dateFormat="P"
                                        />

                                        {errors.deliveryEstimateDate && (
                                            <p className="text-red-600 ml-2">
                                                {errors.deliveryEstimateDate
                                                    .type === 'required' &&
                                                    t(
                                                        'common.errors.fieldRequired'
                                                    )}
                                            </p>
                                        )}
                                    </div>
                                </label>
                            </div>
                            <div className="mt-4 ml-4 w-1/3">
                                <label
                                    htmlFor="productDescription"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Descripción del producto*
                                    <input
                                        id="productDescription"
                                        type="text"
                                        className={`mt-1 ${
                                            errors.productDescription
                                                ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                : ''
                                        }`}
                                        {...register('productDescription', {
                                            required: true,
                                        })}
                                    />
                                </label>
                                {errors.productDescription && (
                                    <p className="text-red-600 ml-2">
                                        {errors.productDescription.type ===
                                            'required' &&
                                            t('common.errors.fieldRequired')}
                                    </p>
                                )}
                            </div>
                            {/* OPTION FOR STARKEN */}
                            {selectedQuote.courier === 'STARKEN' && (
                                <div className="mt-4 ml-4 w-1/3">
                                    <label
                                        htmlFor="productDescription"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Tipo de pago *
                                        <select
                                            id="paymentType"
                                            className="mt-1"
                                            {...register('paymentType', {
                                                required: true,
                                                validate: (value) =>
                                                    value !== '',
                                            })}
                                        >
                                            <option value="">
                                                Seleccione una opción
                                            </option>
                                            <option value="2">
                                                Cuenta Corriente
                                            </option>
                                            <option value="3">
                                                Cobro destino
                                            </option>
                                        </select>
                                    </label>
                                    {errors.paymentType && (
                                        <p className="text-red-600 ml-2">
                                            {errors.paymentType.type ===
                                                'required' &&
                                                t(
                                                    'common.errors.fieldRequired'
                                                )}
                                        </p>
                                    )}
                                </div>
                            )}
                        </div>

                        <div className="mt-6">
                            <h3 className="text-lg font-bold">
                                6. {t('shipments.titleShipmentSender')}
                            </h3>
                            <div className="flex space-x-6">
                                {/* START ORIGIN NAME */}
                                <div className="mt-4 w-1/4">
                                    <label
                                        htmlFor="senderName"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        {t('common.name')} *
                                        <input
                                            id="senderName"
                                            type="text"
                                            className={`mt-2 ${
                                                errors.senderName
                                                    ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                    : ''
                                            }`}
                                            {...register('senderName', {
                                                required: true,
                                                validate: (value) =>
                                                    value !== '',
                                            })}
                                        />
                                    </label>
                                    {errors.senderName && (
                                        <p className="text-red-600 ml-2">
                                            {errors.senderName.type ===
                                                'required' &&
                                                t(
                                                    'common.errors.fieldRequired'
                                                )}
                                        </p>
                                    )}
                                </div>
                                {/* END ORIGIN NAME */}
                                {/* START ORIGIN PHONE */}
                                <div className="mt-4 w-1/4">
                                    <label
                                        htmlFor="senderPhone"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        {t('common.phone')} *
                                        <InputMask
                                            mask="+56 9 9999 9999"
                                            {...register('senderPhone', {
                                                required: true,
                                                validate: (value) =>
                                                    value !== '',
                                            })}
                                        >
                                            {(
                                                inputProps: JSX.IntrinsicAttributes &
                                                    ClassAttributes<HTMLInputElement> &
                                                    InputHTMLAttributes<HTMLInputElement>
                                            ) => (
                                                <input
                                                    id="senderPhone"
                                                    type="text"
                                                    {...inputProps}
                                                    className={`mt-2 ${
                                                        errors.senderPhone
                                                            ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                            : ''
                                                    }`}
                                                />
                                            )}
                                        </InputMask>
                                    </label>
                                    {errors.senderPhone && (
                                        <p className="text-red-600 ml-2">
                                            {errors.senderPhone.type ===
                                                'required' &&
                                                t(
                                                    'common.errors.fieldRequired'
                                                )}
                                        </p>
                                    )}
                                </div>
                                {/* END ORIGIN PHONE */}
                                {/* START ORIGIN EMAIL */}
                                <div className="mt-4 w-1/4">
                                    <label
                                        htmlFor="senderEmail"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        {t('common.email')} *
                                        <input
                                            id="senderEmail"
                                            type="email"
                                            className={`mt-2 ${
                                                errors.senderEmail
                                                    ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                    : ''
                                            }`}
                                            {...register('senderEmail', {
                                                required: 'true',
                                                validate: (value) =>
                                                    re.test(value),
                                            })}
                                        />
                                    </label>
                                    <ErrorMessage
                                        errors={errors}
                                        name="senderEmail"
                                        render={() => (
                                            <p className="text-red-600 ml-2">
                                                {errors &&
                                                    errors.senderEmail &&
                                                    errors.senderEmail.type ===
                                                        'required' &&
                                                    t(
                                                        'common.errors.fieldRequired'
                                                    )}
                                                {errors &&
                                                    errors.senderEmail &&
                                                    errors.senderEmail.type ===
                                                        'validate' &&
                                                    t(
                                                        'common.errors.invalidEmail'
                                                    )}
                                            </p>
                                        )}
                                    />
                                </div>
                                {/* END ORIGIN EMAIL */}
                                {/* START ORIGIN RUT */}
                                <div className="mt-4 w-1/4">
                                    <label
                                        htmlFor="senderRut"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        {t('common.rut')} (
                                        {t('common.optional')}
                                        )
                                        <Controller
                                            control={control}
                                            name="senderRut"
                                            render={({ field: { value } }) => (
                                                <input
                                                    id="senderRut"
                                                    type="text"
                                                    className={`mt-2 ${
                                                        errors.senderRut
                                                            ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                            : ''
                                                    }`}
                                                    {...register('senderRut', {
                                                        required: false,
                                                        value: formatRut(
                                                            value,
                                                            RutFormat.DOTS_DASH
                                                        ),
                                                        onChange: (e) =>
                                                            handleValidateRut(
                                                                e,
                                                                'sender'
                                                            ),
                                                    })}
                                                />
                                            )}
                                        />
                                    </label>
                                    {activeValRutSender && (
                                        <p className="text-red-600 ml-2">
                                            {t('common.errors.invalidRut')})
                                        </p>
                                    )}
                                </div>
                                {/* END ORIGIN RUT */}
                            </div>
                            <div className="w-full flex space-x-6">
                                {/* START ORIGIN SECOND LINE */}
                                <div className="mt-4 w-1/3">
                                    <label
                                        htmlFor="senderSecondLine"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        {t('shipments.reference')} (
                                        {t('common.optional')})
                                        <input
                                            id="senderSecondLine"
                                            type="text"
                                            placeholder="Ej: Dejar en portería"
                                            className={`mt-2 ${
                                                errors.senderSecondLine
                                                    ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                    : ''
                                            }`}
                                            {...register('senderSecondLine', {
                                                required: false,
                                            })}
                                        />
                                    </label>
                                </div>
                                {/* END ORIGIN SECOND LINE */}
                                {/* START ORIGIN COMMENT */}
                                <div className="mt-4 w-1/3">
                                    <label
                                        htmlFor="senderComment"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        {t('common.comments')} (
                                        {t('common.optional')})
                                        <input
                                            id="senderComment"
                                            type="text"
                                            placeholder="Comentarios adicionales"
                                            className={`mt-2 ${
                                                errors.senderComment
                                                    ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                    : ''
                                            }`}
                                            {...register('senderComment', {
                                                required: false,
                                            })}
                                        />
                                    </label>
                                </div>
                                {/* END ORIGIN COMMENT */}
                            </div>
                        </div>

                        <div className="mt-6">
                            <h3 className="text-lg font-bold">
                                7. {t('shipments.titleShipmentRecipient')}
                            </h3>
                            <div className="w-full flex space-x-6">
                                {/* START DESTINATION NAME */}
                                <div className="mt-4 w-1/4">
                                    <label
                                        htmlFor="receiverName"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        {t('common.name')} *
                                        <input
                                            id="receiverName"
                                            type="text"
                                            className={`mt-2 ${
                                                errors.receiverName
                                                    ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                    : ''
                                            }`}
                                            {...register('receiverName', {
                                                required: true,
                                                validate: (value) =>
                                                    value !== '',
                                            })}
                                        />
                                    </label>
                                    {errors.receiverName && (
                                        <p className="text-red-600 ml-2">
                                            {errors.receiverName.type ===
                                                'required' &&
                                                t(
                                                    'common.errors.fieldRequired'
                                                )}
                                        </p>
                                    )}
                                </div>
                                {/* END DESTINATION NAME */}
                                {/* START DESTINATION PHONE */}
                                <div className="mt-4 w-1/4">
                                    <label
                                        htmlFor="receiverPhone"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        {t('common.phone')} *
                                        <InputMask
                                            mask="+56 9 9999 9999"
                                            {...register('receiverPhone', {
                                                required: true,
                                                validate: (value) =>
                                                    value !== '',
                                            })}
                                        >
                                            {(
                                                inputProps: JSX.IntrinsicAttributes &
                                                    ClassAttributes<HTMLInputElement> &
                                                    InputHTMLAttributes<HTMLInputElement>
                                            ) => (
                                                <input
                                                    id="receiverPhone"
                                                    type="text"
                                                    {...inputProps}
                                                    className={`mt-2 ${
                                                        errors.receiverPhone
                                                            ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                            : ''
                                                    }`}
                                                />
                                            )}
                                        </InputMask>
                                    </label>
                                    {errors.receiverPhone && (
                                        <p className="text-red-600 ml-2">
                                            {errors.receiverPhone.type ===
                                                'required' &&
                                                t(
                                                    'common.errors.fieldRequired'
                                                )}
                                        </p>
                                    )}
                                </div>
                                {/* END DESTINATION PHONE */}
                                {/* START DESTINATION EMAIL */}
                                <div className="mt-4 w-1/4">
                                    <label
                                        htmlFor="receiverEmail"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        {t('common.email')} (
                                        {t('common.optional')})
                                        <input
                                            id="receiverEmail"
                                            type="email"
                                            className={`mt-2 ${
                                                errors.receiverEmail
                                                    ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                    : ''
                                            }`}
                                            {...register('receiverEmail', {
                                                onChange: (e) =>
                                                    handleValidateEmailReceiver(
                                                        e
                                                    ),
                                            })}
                                        />
                                    </label>
                                    {activeValReceiver && (
                                        <p className="text-red-600 ml-2">
                                            {t('common.errors.invalidEmail')}
                                        </p>
                                    )}
                                </div>
                                {/* END DESTINATION EMAIL */}
                                {/* START DESTINATION RUT */}
                                <div className="mt-4 w-1/4">
                                    <label
                                        htmlFor="receiverRut"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        {t('common.rut')} (
                                        {t('common.optional')}
                                        )
                                        <Controller
                                            control={control}
                                            name="receiverRut"
                                            render={({ field: { value } }) => (
                                                <input
                                                    id="receiverRut"
                                                    type="text"
                                                    className={`mt-2 ${
                                                        errors.receiverRut
                                                            ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                            : ''
                                                    }`}
                                                    {...register(
                                                        'receiverRut',
                                                        {
                                                            required: false,
                                                            value: formatRut(
                                                                value,
                                                                RutFormat.DOTS_DASH
                                                            ),
                                                            onChange: (e) =>
                                                                handleValidateRut(
                                                                    e,
                                                                    'receiver'
                                                                ),
                                                        }
                                                    )}
                                                />
                                            )}
                                        />
                                        {activeValRutReceiver && (
                                            <p className="text-red-600 ml-2">
                                                {t('common.errors.invalidRut')})
                                            </p>
                                        )}
                                    </label>
                                </div>
                                {/* END DESTINATION RUT */}
                            </div>
                            <div className="w-full flex space-x-6">
                                {/* START DESTINATION SECOND LINE */}
                                <div className="mt-4 w-1/3">
                                    <label
                                        htmlFor="receiverSecondLine"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        {t('shipments.reference')} (
                                        {t('common.optional')})
                                        <input
                                            id="receiverSecondLine"
                                            type="text"
                                            placeholder="Ej: Dejar en portería"
                                            className={`mt-2 ${
                                                errors.receiverSecondLine
                                                    ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                    : ''
                                            }`}
                                            {...register('receiverSecondLine', {
                                                required: false,
                                            })}
                                        />
                                    </label>
                                </div>
                                {/* END DESTINATION SECOND LINE */}
                                {/* START DESTINATION COMMENT */}
                                <div className="mt-4 w-1/3">
                                    <label
                                        htmlFor="receiverComment"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        {t('common.comments')} (
                                        {t('common.optional')})
                                        <input
                                            id="receiverComment"
                                            type="text"
                                            placeholder="Comentarios adicionales"
                                            className={`mt-2 ${
                                                errors.receiverComment
                                                    ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                    : ''
                                            }`}
                                            {...register('receiverComment', {
                                                required: false,
                                            })}
                                        />
                                    </label>
                                </div>

                                {/* END DESTINATION COMMENT */}
                            </div>
                            <div className="w-full flex space-x-6">
                                {/* START DESTINATION SECOND LINE */}
                                <div className="mt-4 w-1/3">
                                    <label
                                        htmlFor="discount"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Aplicar descuento(
                                        {t('common.optional')})
                                        <input
                                            id="discount"
                                            type="number"
                                            min="0"
                                            max={
                                                (getValues('isPorcent') !==
                                                    undefined &&
                                                    getValues('isPorcent') ===
                                                        true &&
                                                    100) ||
                                                selectedQuote?.price
                                            }
                                            className={`mt-2 ${
                                                errors.discount
                                                    ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                    : ''
                                            }`}
                                            {...register('discount', {
                                                required: false,
                                                // onChange: (e) =>
                                                //     handleDiscount(e),
                                                validate: (value) =>
                                                    getValues('isPorcent')
                                                        ? value !== null &&
                                                          value !== undefined &&
                                                          value < 100
                                                        : value !== null &&
                                                          value !== undefined &&
                                                          value <
                                                              selectedQuote?.price,
                                            })}
                                        />
                                        {errors.discount &&
                                            (getValues('isPorcent') === true ? (
                                                <p className="text-red-600 ml-2">
                                                    Debe ser menor al 100% de
                                                    descuento
                                                </p>
                                            ) : (
                                                <p className="text-red-600 ml-2">
                                                    Debe ser menor al precio del
                                                    envío
                                                </p>
                                            ))}
                                    </label>
                                </div>
                                <div className="mt-4 w-1/3">
                                    <label
                                        htmlFor="declaredValue"
                                        className="block text-sm font-medium text-gray-700 ml-5"
                                    >
                                        <span>Tipo De Descuento</span>
                                        <div className="mt-1">
                                            <input
                                                type="checkbox"
                                                // min="1"
                                                {...register('isPorcent', {})}
                                            />
                                            <span> %</span>
                                        </div>
                                    </label>{' '}
                                </div>
                                {/* END DESTINATION SECOND LINE */}
                            </div>
                        </div>
                    </div>
                    <div className="mt-10 mb-10 w-1/2 flex space-x-4">
                        <Button
                            type="submit"
                            size="lg"
                            disabled={createShipmentState === 'loading'}
                            className={`font-bold w-full ${
                                createShipmentState === 'loading' ??
                                'cursor-not-allowed'
                            }`}
                            onClick={handleSubmit(createShip)}
                            label={
                                createShipmentState === 'loading'
                                    ? t('wait.message')
                                    : `${t('shipments.create.title')}`
                            }
                        />
                        {alertSucess && (
                            <AlertSuccess
                                title="Exito!"
                                message={t('shipments.success')}
                                onClick={() => closeAlertSucess()}
                                fixed={false}
                            />
                        )}
                        {showAlertError && (
                            <AlertError
                                title="Ocurrió un error"
                                message="Si el error persiste, contacte al administrador del sistema"
                                onClick={() => setShowAlertError(false)}
                                fixed={false}
                            />
                        )}
                    </div>
                </div>
            ) : (
                selectedQuote && (
                    <p className="text-red-600 ml-2">
                        Error valor declarado es superior al monto de cobertura
                        del courier seleccionado.
                    </p>
                )
            )}

            {alertBranch && (
                <div
                    className="z-50 fixed right-10 top-24  bg-yellow-100 inline-flex w-1/2  border border-yellow-400 text-yellow-700 px-4 py-3 rounded"
                    role="alert"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M20.618 5.984A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016zM12 9v2m0 4h.01"
                        />
                    </svg>
                    <strong className="font-bold mr-2">Warning!</strong>
                    <span className="block sm:inline">
                        99minutos no entregara resultados si la dirección esta
                        en blanco
                        <p>¿Desea continuar?</p>
                        <p className="rounded-full bg-green">
                            <span
                                className="cursor-pointer"
                                role="button"
                                tabIndex={0}
                                onClick={() => {
                                    setResponseUser(true);
                                    setAlertBranch(false);
                                }}
                                // eslint-disable-next-line @typescript-eslint/no-empty-function
                                onKeyPress={() => {}}
                            >
                                Si
                            </span>
                            -
                            <span
                                className="cursor-pointer"
                                role="button"
                                tabIndex={0}
                                onClick={() => {
                                    setResponseUser(false);
                                    setAlertBranch(false);
                                }}
                                // eslint-disable-next-line @typescript-eslint/no-empty-function
                                onKeyPress={() => {}}
                            >
                                No
                            </span>
                        </p>
                    </span>
                    <span
                        className="absolute top-0 bottom-0 right-0 px-4 py-3"
                        onClick={() => closeAlertSucess()}
                        onKeyPress={() => closeAlertSucess()}
                        role="button"
                        tabIndex={0}
                    >
                        <svg
                            className="fill-current h-6 w-6 text-green-500"
                            role="button"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                        >
                            <title>Close</title>
                            <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                        </svg>
                    </span>
                </div>
            )}
        </div>
    );
}
