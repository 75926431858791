/* eslint-disable no-param-reassign */
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import * as XLSX from 'xlsx';
import { t } from 'i18next';
import Joi from 'joi';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import * as dateFns from 'date-fns';
// import { groupByCode } from '../groupBy';
import { RootState, useAppDispatch } from '../../../../store';
import { fetchRegions } from '../../../../modules/geo/features/regionSlice';
import { fetchComunas } from '../../../../modules/geo/features/comunaSlice';
import { IQuote } from '../../../../modules/quotes/interfaces';
import replaceSpecialCharacters from '../../../../utils/replaceSpecialCharacters';

const calculateVolumetricWeight = (
    width: number,
    height: number,
    length: number,
    weight: number
): number => {
    const volumetricWeight = (width * height * length) / 5000;
    return volumetricWeight > weight ? volumetricWeight : weight;
};
export interface IFileType {
    deliveryType: string;
    storePickup: string;
    originRegion: string;
    originRegionId: number;
    originComuna: string;
    originComunaId: number;
    originStreet: string;
    originNumber: string;
    originSecondLine: string;
    destinationRegion: string;
    destinationRegionId: number;
    destinationComuna: string;
    destinationComunaId: number;
    destinationStreet: string;
    destinationNumber: string;
    destinationSecondLine: string;
    length: number;
    width: number;
    height: number;
    weight: number;
    volumetricWeight: number;
    clientId: string;
    originName: string;
    originPhone: string;
    originEmail: string;

    originRut: string;
    destinationName: string;
    destinationPhone: string;
    destinationEmail: string;

    destinationRut: string;
    reference: string;
    shipmentDate: string | number;
    comments: string;
    itemId: string;
    rowId: number;
    insurance?: number;
    price?: number;
    fee?: number;
    courierPrice?: number;
    courier: string;
    trackingNumber?: string;
    guideNumber?: string;
    deliveryEstimateDate: string;
    deliveryDays: number;
    declaredValue: number;
    calculateInsurance: boolean;
    productDescription: string;
    createShipmentResponse?: { id: string; error: boolean };
    // EXTRA DATA - STARKEN
    starkenPaymentType?: string;
}

const schema = Joi.object({
    originRegion: Joi.string().label('Región de origen'),
    originComuna: Joi.string().label('Comuna de origen'),
    originStreet: Joi.string().label('Calle de origen'),
    originNumber: [
        Joi.string().allow('').label('Número de origen'),
        Joi.number().label('Número de origen'),
    ],
    destinationRegion: Joi.string().label('Región de destino'),
    destinationComuna: Joi.string().label('Comuna de destino'),
    destinationStreet: Joi.string().label('Calle de destino'),
    destinationNumber: [
        Joi.string().allow('').label('Número de destino'),
        Joi.number().label('Número de destino'),
    ],
    length: Joi.number().required().label('Largo'),
    width: Joi.number().required().label('Ancho'),
    height: Joi.number().required().label('Alto'),
    weight: Joi.number().required().label('Peso'),
    clientId: Joi.string().optional().allow(null).label('Id Cliente'),
    originName: Joi.string().label('Nombre remitente'),
    originPhone: [
        Joi.string().required().label('Teléfono remitente'),
        Joi.number().required().label('Teléfono remitente'),
    ],
    originEmail: Joi.string()
        .email({ tlds: { allow: false } })
        .label('Email remitente'),
    originRut: Joi.string().allow(null).label('Rut remitente'),
    destinationName: Joi.string().required().label('Nombre destinatario'),
    destinationPhone: [
        Joi.string().required().label('Teléfono destinatario'),
        Joi.number().required().label('Teléfono destinatario'),
    ],
    destinationEmail: Joi.string()
        .required()
        .email({ tlds: { allow: false } })
        .label('Email destinatario'),
    destinationRut: Joi.string().allow(null).label('Rut destinatario'),
    reference: Joi.string().allow(null).label('Referencia'),
    comments: Joi.string().allow(null).label('Comentarios'),
    declaredValue: Joi.number().label('Valor declarado'),
    calculateInsurance: Joi.string().allow('SI', 'NO').label('Con seguro'),
    productDescription: Joi.string()
        .required()
        .label('Descripción del producto'),
    starkenPaymentType: Joi.string()
        .allow('CC', 'CD', '')
        .label('Tipo de pago - Starken'),
    originSecondLine: Joi.string().allow('').label('Referencia origen'),
    destinationSecondLine: Joi.string().allow('').label('Referencia destino'),
    PAQUETE_SOBRE: Joi.string().allow('').label('PAQUETE_SOBRE'),
});

export default function ProcessFile({
    setFileErrors,
    setQuotesList,
    setShipmentsDraft,
}: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setFileErrors: (errors: any) => void;
    setQuotesList: (quotes: IQuote[]) => void;
    setShipmentsDraft: (shipmentDrafts: IFileType[]) => void;
}) {
    const dispatch = useAppDispatch();

    const hiddenFileInput = useRef<HTMLInputElement>(null);

    const [addBuyLotState, setAddBuyLotState] = useState('idle');
    const [errorMessage, setErrorMessage] = useState('');

    const comunas = useSelector((state: RootState) => state.comunas);
    const regions = useSelector((state: RootState) => state.regions);

    useEffect(() => {
        if (regions.status === 'idle') {
            dispatch(fetchRegions());
        }
    }, [regions.status, dispatch]);

    useEffect(() => {
        if (comunas.status === 'idle') {
            dispatch(fetchComunas());
        }
    }, [comunas.status, dispatch]);

    // const lots = useSelector((state: RootState) => state.lots);

    const handleClick = () => {
        if (hiddenFileInput?.current) {
            hiddenFileInput.current.click();
            hiddenFileInput.current.value = '';
            setAddBuyLotState('idle');
            setErrorMessage('');
        }
    };

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return;
        }
        const file = e.target.files[0];
        if (
            file.type !==
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ) {
            setErrorMessage(t('quotes.bulk.invalidFileFormat'));
            setAddBuyLotState('error');
            return;
        }
        const reader = new FileReader();

        reader.onload = (evt) => {
            if (!evt.target) {
                return;
            }

            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const dataJson: IFileType[] = XLSX.utils.sheet_to_json(ws, {
                header: [
                    'clientId',
                    // ORIGIN
                    'originName',
                    'originEmail',
                    'originPhone',
                    'originStreet',
                    'originNumber',
                    'originSecondLine',
                    'originComuna',
                    'originRegion',

                    // DESTINATION
                    'destinationName',
                    'destinationEmail',
                    'destinationPhone',
                    'destinationStreet',
                    'destinationNumber',
                    'destinationSecondLine',
                    'destinationComuna',
                    'destinationRegion',
                    //
                    'PAQUETE_SOBRE',
                    'weight',
                    'length',
                    'width',
                    'height',
                    'productDescription',
                    'declaredValue',
                    'calculateInsurance',
                    'starkenPaymentType',
                ],
                defval: '',
            });

            const mainData = dataJson.slice(5);
            if (mainData.length === 0) {
                setErrorMessage(t('quotes.bulk.emptyFile'));
                setAddBuyLotState('error');
                return;
            }

            const errors: {
                error: Joi.ValidationError;
                value: undefined;
                index: number;
            }[] = [];

            const errorMessages: string[] = [];
            mainData.forEach((r: IFileType, index) => {
                const { error, value } = schema.validate(r);
                if (error) {
                    errors.push({ error, value, index });
                }

                if (!r.originRegion || r.originRegion === '') {
                    errorMessages.push(
                        `La región de origen no puede estar vacia fila ${
                            index + 8
                        }`
                    );
                }

                if (!r.destinationRegion || r.destinationRegion === '') {
                    errorMessages.push(
                        `La región de destino no puede estar vacia fila ${
                            index + 8
                        }`
                    );
                }

                if (
                    r.originRegion &&
                    !regions.data.find(
                        (re) =>
                            replaceSpecialCharacters(
                                re.name
                            ).toLocaleLowerCase() ===
                            replaceSpecialCharacters(
                                r.originRegion
                            ).toLocaleLowerCase()
                    )
                ) {
                    errorMessages.push(
                        `Región de origen "${r.originRegion}" no existe fila  ${
                            index + 8
                        }`
                    );
                }

                if (!r.originComuna || r.originComuna === '') {
                    errorMessages.push(
                        `La comuna de origen no puede estar vacia fila ${
                            index + 8
                        }`
                    );
                }

                if (!r.destinationComuna || r.destinationComuna === '') {
                    errorMessages.push(
                        `La comuna de destino no puede estar vacia fila ${
                            index + 8
                        }`
                    );
                }

                if (
                    r.originComuna &&
                    !comunas.data.find(
                        (c) =>
                            replaceSpecialCharacters(
                                c.name
                            ).toLocaleLowerCase() ===
                            replaceSpecialCharacters(
                                r.originComuna
                            ).toLocaleLowerCase()
                    )
                ) {
                    errorMessages.push(
                        `Comuna de origen "${r.originComuna}" no existe fila ${
                            index + 8
                        }`
                    );
                }
                if (
                    r.destinationRegion &&
                    !regions.data.find(
                        (re) =>
                            replaceSpecialCharacters(
                                re.name
                            ).toLocaleLowerCase() ===
                            replaceSpecialCharacters(
                                r.destinationRegion
                            ).toLocaleLowerCase()
                    )
                ) {
                    errorMessages.push(
                        `Región de destino "${
                            r.destinationRegion
                        }" no existe fila ${index + 8}`
                    );
                }

                if (
                    r.destinationComuna &&
                    !comunas.data.find(
                        (c) =>
                            replaceSpecialCharacters(
                                c.name
                            ).toLocaleLowerCase() ===
                            replaceSpecialCharacters(
                                r.destinationComuna
                            ).toLocaleLowerCase()
                    )
                ) {
                    errorMessages.push(
                        `Comuna de destino "${
                            r.destinationComuna
                        }" no existe fila ${index + 8}`
                    );
                }
            });
            if (errors.length > 0) {
                errors.forEach((er) => {
                    if (er.error.message.includes('is required')) {
                        if (er.value) {
                            const value = er.value[er.error.details[0].path[0]];
                            if (!value) {
                                errorMessages.push(
                                    `Fila ${er.index + 8} - ${
                                        er.error.details[0].context?.label ??
                                        er.error.details[0].path[0]
                                    } es requerido`
                                );
                            }
                        } else {
                            errorMessages.push(
                                `Fila ${er.index + 8} - ${
                                    er.error.details[0].context?.label ??
                                    er.error.details[0].path[0]
                                } valor requerido`
                            );
                        }
                    }
                    if (er.error.message.includes('must be a number')) {
                        if (er.value) {
                            const value = er.value[er.error.details[0].path[0]];
                            if (!value) {
                                errorMessages.push(
                                    `Fila ${er.index + 8} - ${
                                        er.error.details[0].context?.label ??
                                        er.error.details[0].path[0]
                                    } es requerido`
                                );
                            }
                        } else {
                            errorMessages.push(
                                `Fila ${er.index + 8} - ${
                                    er.error.details[0].context?.label ??
                                    er.error.details[0].path[0]
                                } tiene un valor inválido`
                            );
                        }
                    }
                    if (er.error.message.includes('must be a string')) {
                        if (er.value) {
                            const value = er.value[er.error.details[0].path[0]];
                            if (!value || value === '') {
                                errorMessages.push(
                                    `Fila ${er.index + 8} - ${
                                        er.error.details[0].context?.label ??
                                        er.error.details[0].path[0]
                                    } es requerido`
                                );
                            }
                        } else {
                            errorMessages.push(
                                `Fila ${er.index + 8} - ${
                                    er.error.details[0].context?.label ??
                                    er.error.details[0].path[0]
                                } tiene un valor inválido`
                            );
                        }
                    }
                    if (er.error.message.includes('must be a valid email')) {
                        if (er.value) {
                            const value = er.value[er.error.details[0].path[0]];
                            if (!value || value === '') {
                                errorMessages.push(
                                    `Fila ${er.index + 8} - ${
                                        er.error.details[0].context?.label ??
                                        er.error.details[0].path[0]
                                    } es requerido`
                                );
                            } else {
                                errorMessages.push(
                                    `Fila ${er.index + 8} - ${
                                        er.error.details[0].context?.label ??
                                        er.error.details[0].path[0]
                                    } debe ser un email válido`
                                );
                            }
                        } else {
                            errorMessages.push(
                                `Fila ${er.index + 8} - ${
                                    er.error.details[0].context?.label ??
                                    er.error.details[0].path[0]
                                } tiene un valor inválido`
                            );
                        }
                    }
                });
            }

            if (errorMessages.length > 0) {
                setFileErrors(errorMessages);
                setErrorMessage(t('quotes.bulk.invalidFileFormat'));
                setAddBuyLotState('error');
            } else {
                setFileErrors([]);
            }

            const quotesList: IQuote[] = [];
            const shipmentsDraft: IFileType[] = [];

            mainData.forEach((data, index) => {
                console.log({ data });
                const volumetricWeight = calculateVolumetricWeight(
                    data.width,
                    data.height,
                    data.length,
                    data.weight
                );

                const comunaOriginId =
                    comunas.data.find(
                        (c) =>
                            replaceSpecialCharacters(
                                c.name
                            ).toLocaleLowerCase() ===
                            replaceSpecialCharacters(
                                data.originComuna
                            ).toLocaleLowerCase()
                    )?.id ?? 0;
                const regionOriginId =
                    regions.data.find(
                        (c) =>
                            replaceSpecialCharacters(
                                c.name
                            ).toLocaleLowerCase() ===
                            replaceSpecialCharacters(
                                data.originRegion
                            ).toLocaleLowerCase()
                    )?.id ?? 0;
                const comunaDestinationId =
                    comunas.data.find(
                        (c) =>
                            replaceSpecialCharacters(
                                c.name
                            ).toLocaleLowerCase() ===
                            replaceSpecialCharacters(
                                data.destinationComuna
                            ).toLocaleLowerCase()
                    )?.id ?? 0;
                const regionDestinationId =
                    regions.data.find(
                        (c) =>
                            replaceSpecialCharacters(
                                c.name
                            ).toLocaleLowerCase() ===
                            replaceSpecialCharacters(
                                data.destinationRegion
                            ).toLocaleLowerCase()
                    )?.id ?? 0;
                const itemId = uuidv4();
                const quote: IQuote = {
                    itemId,
                    length: data.length,
                    width: data.width,
                    height: data.height,
                    weight: data.weight,
                    volumetricWeight: volumetricWeight ?? 0,
                    quantity: 1,
                    comunaOriginId,
                    comunaDestinationId,
                    addressOrigin: `${data.originStreet} ${
                        data.originNumber || ''
                    }`,
                    addressDestination: `${data.destinationStreet} ${
                        data.destinationNumber || ''
                    }`,
                    clientId: data.clientId ? data.clientId.toString() : '',
                    calculateInsurance:
                        data.calculateInsurance &&
                        data.calculateInsurance.toString() === 'SI',
                    declaredValue: data.declaredValue,
                };
                let deliveryEstimateDate = '';
                if (typeof data.shipmentDate === 'string') {
                    const date = data.shipmentDate.split('/');
                    deliveryEstimateDate = date.reverse().join('-');
                } else if (typeof data.shipmentDate === 'number') {
                    const shipmentDate = new Date(
                        (+data.shipmentDate - (25567 + 1)) * 86400 * 1000
                    );
                    deliveryEstimateDate = dateFns.format(
                        shipmentDate,
                        'yyyy-MM-dd'
                    );
                }
                const row: IFileType = {
                    ...data,
                    originNumber: data.originNumber
                        ? data.originNumber.toString()
                        : '',
                    destinationNumber: data.destinationNumber
                        ? data.destinationNumber.toString()
                        : '',
                    originSecondLine: data.originSecondLine || '',
                    destinationSecondLine: data.destinationSecondLine || '',
                    calculateInsurance:
                        data.calculateInsurance &&
                        data.calculateInsurance.toString() === 'SI',
                    declaredValue: data.declaredValue,
                    deliveryEstimateDate,
                    volumetricWeight,
                    itemId,
                    originComunaId: comunaOriginId,
                    destinationComunaId: comunaDestinationId,
                    originRegionId: regionOriginId,
                    destinationRegionId: regionDestinationId,
                    rowId: index + 8,
                };

                shipmentsDraft.push(row);

                quotesList.push(quote);
            });
            setQuotesList(quotesList);
            setShipmentsDraft(shipmentsDraft);
        };
        reader.readAsBinaryString(file);
    };

    return (
        <div className="text-black">
            <button
                type="button"
                disabled={addBuyLotState === 'loading'}
                className="flex w-full h-full items-center justify-center -mt-1"
                onClick={handleClick}
            >
                <label
                    htmlFor="file"
                    className={`w-full flex items-center px-4 py-1 space-x-4  rounded-lg tracking-wide uppercase border border-blue hover:bg-blue  ${
                        addBuyLotState === 'loading'
                            ? 'bg-slate-500 text-white hover:text-gray-100 cursor-progress'
                            : 'bg-white text-sym-primary-200 hover:text-sym-primary-600'
                    }`}
                >
                    <svg
                        className="w-8 h-8"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                    >
                        <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                    </svg>
                    <span className="text-base leading-normal">
                        {addBuyLotState === 'loading'
                            ? t('wait.message')
                            : t('common.selectFile')}
                    </span>
                    <input
                        name="file"
                        type="file"
                        ref={hiddenFileInput}
                        className="hidden"
                        onChange={onChange}
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    />
                </label>
            </button>
            {addBuyLotState === 'loading' && (
                <div className="bg-orange-800 text-orange-100 font-bold text-center mt-2 p-1">
                    {t('bids.positions.fileUploading')}
                </div>
            )}
            {addBuyLotState === 'success' && (
                <div className="bg-green-800 text-green-100 font-bold text-center mt-2 p-1">
                    {t('bids.positions.fileProcessed')}
                </div>
            )}
            {addBuyLotState === 'error' && (
                <div className="bg-red-800 text-red-100 font-bold text-center mt-2 p-1">
                    {errorMessage}
                </div>
            )}
        </div>
    );
}
