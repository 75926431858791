import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { useSelector } from 'react-redux';

import {
    createBulkShipment,
    IShipmentCreate,
    resetBulkShipment,
} from '../../../../modules/shipments/features/shipmentBulkSlice';
import { IShipmentCreateBulkAttr } from '../../../../modules/shipments/interfaces';
import { RootState, useAppDispatch } from '../../../../store';
import { IFileType } from '../components/ProcessFile';
import { FormData } from './useFormData';

interface UseCreateBulkShipmentProps {
    shipmentsDraft: IFileType[];
    checkList: string[];
    courierSelected: string;
    shipmentType: string;
    setShipmentsDraft: Dispatch<SetStateAction<IFileType[]>>;
    formSetValue: UseFormSetValue<FormData>;
}

export default (props: UseCreateBulkShipmentProps) => {
    const {
        shipmentsDraft,
        checkList,
        courierSelected,
        shipmentType,
        setShipmentsDraft,
        formSetValue,
    } = props;

    const [labelsGenerated, setLabelsGenerated] = useState(0);
    const [labelsFailed, setLabelsFailed] = useState(0);
    const [shipmentsGenerated, setShipmentsGenerated] = useState(false);

    const [shipmentCreateBulkState, setShipmentCreateBulkState] =
        useState('idle');

    const dispatch = useAppDispatch();

    const createShipmentBulk = async (retry = false) => {
        const shipments: IShipmentCreateBulkAttr[] = shipmentsDraft
            .filter((ship) => {
                if (!retry) {
                    return checkList.includes(ship.itemId);
                }
                return true;
            })
            .map((ship) => {
                if (!ship.price || ship.price === 0) {
                    return null;
                }

                let paymentType = '';
                // Esta condición es para el caso de que esten cotizando para starken
                if (ship.starkenPaymentType !== '') {
                    paymentType = ship.starkenPaymentType === 'CC' ? '2' : '3';
                }

                return {
                    courierId: courierSelected,
                    courierDeliveryType: ship.deliveryType,
                    originStreet: ship.originStreet,
                    originNumber: ship.originNumber,
                    originComunaId: ship.originComunaId,
                    originRegionId: ship.originRegionId,
                    originSecondLine: ship.originSecondLine,
                    //
                    destinationName: ship.destinationName,
                    destinationStreet: ship.destinationStreet,
                    destinationNumber: ship.destinationNumber,
                    destinationComunaId: ship.destinationComunaId,
                    destinationRegionId: ship.destinationRegionId,
                    destinationSecondLine: ship.destinationSecondLine || '',
                    //
                    shipmentTypeId: shipmentType,
                    itemId: ship.itemId,
                    declaredValue: ship.declaredValue,
                    rowId: ship.rowId,
                    // SENDER DATA
                    senderName: ship.originName,
                    senderStreet: ship.originStreet.toString(),
                    senderNumber: ship.originNumber,
                    senderPhone: ship.originPhone.toString(),
                    senderEmail: ship.originEmail,

                    // RECEIVER DATA
                    receiverStreet: ship.destinationStreet,
                    receiverNumber: ship.destinationNumber,
                    receiverName: ship.destinationName,
                    receiverPhone: ship.destinationPhone.toString(),
                    receiverEmail: ship.destinationEmail,

                    price: ship.price,
                    fee: ship.fee,
                    courierPrice: ship.courierPrice,
                    insurance: ship.insurance,
                    //
                    deliveryEstimateDate: ship.deliveryEstimateDate,
                    weight: ship.weight,
                    volumetricWeight: ship.volumetricWeight,
                    length: ship.length,
                    width: ship.width,
                    height: ship.height,
                    quantity: 1,
                    clientId: ship.clientId ? ship.clientId.toString() : '',

                    destinyEmail: '',
                    productDescription: ship.productDescription,
                    paymentType,
                };
            })
            .filter((s) => s !== null) as IShipmentCreateBulkAttr[];
        const payload: IShipmentCreate = {
            shipments,
            destinyEmail: '',
        };

        try {
            setShipmentCreateBulkState('loading');
            await dispatch(createBulkShipment(payload)).unwrap();
            setShipmentCreateBulkState('success');
        } catch (error) {
            setShipmentCreateBulkState('error');
        }
    };

    const shipmentsBulk = useSelector(
        (state: RootState) => state.shipmentsBulk
    );

    const resetAll = () => {
        setLabelsGenerated(0);
        setLabelsFailed(0);
        setShipmentsGenerated(false);
    };

    useEffect(() => {
        if (
            shipmentsBulk.status === 'success' &&
            shipmentsBulk.data &&
            shipmentsBulk.data !== null &&
            shipmentsBulk.data.length > 0
        ) {
            const shipmetsResponseMaped: {
                [key: string]: {
                    id: string;
                    error: boolean;
                    itemId: string;
                };
            } = shipmentsBulk.data.reduce(
                (acc, curr) => ({ ...acc, [curr.itemId]: curr }),
                {}
            );
            const shipmentsDraftUpdated = shipmentsDraft.map((item) => ({
                ...item,
                createShipmentResponse: shipmetsResponseMaped[item.itemId],
            }));

            const shimpentsIds = shipmentsDraftUpdated.filter(
                (item) =>
                    item.createShipmentResponse &&
                    item.createShipmentResponse.id !== ''
            );
            const shimpentsFailed = shipmentsDraftUpdated.filter(
                (item) =>
                    item.createShipmentResponse &&
                    item.createShipmentResponse.id === ''
            );

            setLabelsGenerated(shimpentsIds.length);
            setLabelsFailed(shimpentsFailed.length);
            setShipmentsGenerated(true);

            setShipmentsDraft(shipmentsDraftUpdated);
            formSetValue(
                'checkList',
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                checkList.map((_) => '')
            );
            dispatch(resetBulkShipment());
        }
    }, [
        checkList,
        dispatch,
        formSetValue,
        setShipmentsDraft,
        shipmentsBulk,
        shipmentsDraft,
    ]);

    return {
        shipmentCreateBulkState,
        labelsGenerated,
        labelsFailed,
        shipmentsGenerated,
        setShipmentCreateBulkState,
        createShipmentBulk,
        resetAll,
    };
};
