/* eslint-disable @typescript-eslint/no-explicit-any */

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getReportOrdersDeliveriesPercentageOnTime } from '../../../../modules/report/features/reportOrdersDeliveriesPercentageOnTimeSlice';
import { RootState } from '../../../../store';

interface GetDataParams {
    endDate: string;
    startDate: string;
    branches: string[];
    couriers: string[];
}

const percentageDeliveryOnTimeDefaultData: any = {
    chart: {
        type: 'column',
    },
    legend: { enabled: false },
    title: {
        text: '',
    },
    xAxis: [],
    yAxis: [
        {
            labels: {
                format: '{value}%',
            },
            title: {
                text: 'Porcentaje',
            },
        },
    ],
    series: [],
};

export default () => {
    const dispatch = useDispatch();
    const reportOrdersDeliveriesPercentageOnTime = useSelector(
        (state: RootState) => state.reportOrdersDeliveriesPercentageOnTime
    );

    const [percentageDeliveryOnTimeData, setPercentageDeliveryOnTimeData] =
        useState({
            ...percentageDeliveryOnTimeDefaultData,
        });

    const [
        percentageDeliveryOnTimeDataSeted,
        setPercentageDeliveryOnTimeDataSeted,
    ] = useState(false);

    useEffect(() => {
        if (
            reportOrdersDeliveriesPercentageOnTime.status === 'success' &&
            percentageDeliveryOnTimeDataSeted === false
        ) {
            const series = [
                {
                    yAxis: 0,
                    name: 'Órdenes',
                    data: reportOrdersDeliveriesPercentageOnTime.data.map(
                        ({ value, ...rest }) => ({ ...rest, y: value })
                    ),
                },
            ];
            const xAxis = [
                {
                    categories: reportOrdersDeliveriesPercentageOnTime.data.map(
                        ({ name }) => name
                    ),
                    crosshair: true,
                },
            ];
            setPercentageDeliveryOnTimeData({
                ...percentageDeliveryOnTimeData,
                series,
                xAxis,
            });
            setPercentageDeliveryOnTimeDataSeted(true);
        }
    }, [
        percentageDeliveryOnTimeData,
        percentageDeliveryOnTimeDataSeted,
        reportOrdersDeliveriesPercentageOnTime.data,
        reportOrdersDeliveriesPercentageOnTime.status,
    ]);

    const getData = (seacrhParams: GetDataParams) => {
        dispatch(getReportOrdersDeliveriesPercentageOnTime(seacrhParams));
        setPercentageDeliveryOnTimeDataSeted(false);
    };

    return {
        getData,
        data: percentageDeliveryOnTimeData,
        status: reportOrdersDeliveriesPercentageOnTime.status,
    };
};
