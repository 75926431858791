import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { ICourier } from '../interfaces';

export enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface CourierState {
    data: ICourier[];
    status: Status;
    error: string | null;
}

const initialState: CourierState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const fetchCouriers = createAsyncThunk(
    'couriers/fetchCouriers',
    async () => {
        const response = await APIClient.getData('/couriers');
        return response.data;
    }
);

export const couriersSlice = createSlice({
    name: 'couriers',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchCouriers.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(fetchCouriers.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = state.data.concat(action.payload);
            })
            .addCase(fetchCouriers.rejected, (state, action) => {
                state.status = Status.success;
                state.error = action.error.message ?? null;
            });
    },
});
