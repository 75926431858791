import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
    fetchCouriers,
    Status,
} from '../../../../modules/couriers/features/couriersSlice';
import { ICourier } from '../../../../modules/couriers/interfaces';
import { RootState, useAppDispatch } from '../../../../store';

export interface CouriersState {
    couriers: ICourier[];
    status: Status;
}

export default (): CouriersState => {
    const dispatch = useAppDispatch();
    const couriersState = useSelector((state: RootState) => state.couriers);
    const [couriers, setCouriers] = useState<ICourier[]>([]);

    useEffect(() => {
        if (couriersState.status === 'idle') {
            dispatch(fetchCouriers());
        }
        if (couriersState.status === 'success') {
            setCouriers(couriersState.data);
        }
    }, [couriersState, dispatch]);

    return { couriers, status: couriersState.status };
};
