import { SVGProps } from 'react';

const shapes = {
    pill: 'rounded-full',
    circle: 'rounded-full',
    'rounded-sm': 'rounded-sm',
    'rounded-md': 'rounded-md',
    'rounded-lg': 'rounded-lg',
    square: 'rounded-none',
};

const sizes = {
    sm: 'px-2 py-1',
    md: 'px-4 py-2',
    lg: 'px-6 py-4',
};

const imgSizes = {
    sm: 16,
    md: 24,
    lg: 32,
};

const colors = {
    outline:
        'bg-white border-2 border-solid border-gray-600 hover:bg-gray-100 focus:ring-gray-500 focus:ring-offset-gray-200 text-current',
    disabled:
        'bg-gray-600 hover:bg-gray-700 focus:ring-gray-500 focus:ring-offset-gray-200',
    danger: 'bg-red-600 hover:bg-red-700 focus:ring-red-500 focus:ring-offset-red-200',
    warning:
        'bg-yellow-600 hover:bg-yellow-700 focus:ring-yellow-500 focus:ring-offset-yellow-200',
    success:
        'bg-green-500 hover:bg-green-700 focus:ring-green-500 focus:ring-offset-green-200',
    info: 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200',
    primary:
        'bg-sym-primary-600 hover:bg-sym-primary-700 focus:ring-sym-primary-500 focus:ring-offset-sym-primary-200',
    link: 'bg-white hover:bg-gray-300 focus:ring-gray-300 focus:ring-offset-gray-200 text-current',
    secondary:
        'bg-pink-600 hover:bg-pink-700 focus:ring-pink-500 focus:ring-offset-pink-200',
    dark:
        'bg-black hover:bg-pink-700 focus:ring-pink-500 focus:ring-offset-pink-200',
};

export interface ITextButton {
    onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
    onKeyDown?: React.KeyboardEventHandler | undefined;
    className?: string;
    disabled?: boolean;
    label: string;
    type?: 'button' | 'submit' | 'reset';
    size?: 'sm' | 'md' | 'lg';
    variant?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'link'
    | 'dark'
    | 'outline';
    shape?: 'square' | 'rounded-sm' | 'rounded-md' | 'rounded-lg' | 'pill';
    icon?: string | SVGProps<SVGElement> | undefined;
    iconPosition?: 'left' | 'right';
    showLabel?: boolean;
}

function ButtonIcon({
    icon,
    label,
    size = 'md',
}: {
    icon: string | SVGProps<SVGElement>;
    label: string;
    size?: 'sm' | 'md' | 'lg';
}) {
    if (typeof icon === 'string') {
        return (
            <img
                className="w-6 mr-1 symlab-button__icon"
                src={icon}
                alt={label}
                width={imgSizes[size]}
                height={imgSizes[size]}
                style={{
                    height: imgSizes[size],
                    width: imgSizes[size],
                }}
            />
        );
    }
    return (
        <div className="symlab-button__icon symlab-button__icon-svg">
            {icon}
        </div>
    );
}

ButtonIcon.defaultProps = {
    size: 'md',
};

export default function Button({
    onClick,
    onKeyDown,
    className,
    disabled = false,
    label,
    type = 'button',
    size = 'md',
    variant = 'primary',
    shape = 'rounded-md',
    icon,
    iconPosition = 'left',
    showLabel = true,
}: ITextButton) {
    // Create a button with a label and accesibility
    // label: The label of the button
    // onClick: The onClick to be performed when the button is clicked
    if (icon && iconPosition === 'left') {
        return (
            <button
                className={`${className} symlab-btn
                    symlab-btn__${size}
                    symlab-btn__${variant}
                    symlab-btn__icon-left
                    ${!disabled ? colors[variant] : colors.disabled}
                    text-white
                    ${disabled ? 'cursor-not-allowed' : ''}
                    ${shapes[shape]}
                    ${sizes[size]}
                    flex
                    items-center                    
                    
                `}
                // eslint-disable-next-line react/button-has-type
                type={type}
                onClick={onClick}
                onKeyDown={onKeyDown}
                disabled={disabled}
                aria-label={label}
                aria-labelledby="symlab-button__label"
            >
                <ButtonIcon
                    icon={icon}
                    label={label}
                    size={size}
                    aria-hidden="true"
                    aria-labelledby="symlab-button__label"
                />

                <span
                    className="ml-1 symlab-button__label"
                    id="symlab-button__label"
                    hidden={!showLabel}
                >
                    {label}
                </span>
            </button>
        );
    }

    if (icon && iconPosition === 'right') {
        return (
            <button
                className={`${className}
                    symlab-btn
                    symlab-btn__${size}
                    symlab-btn__${variant} 
                    symlab-btn__icon-right
                    ${!disabled ? colors[variant] : colors.disabled}
                    text-white
                    ${disabled ? 'cursor-not-allowed' : ''}
                    ${shapes[shape]}
                    ${sizes[size]}
                    flex
                    items-center                    
                `}
                // eslint-disable-next-line react/button-has-type
                type={type}
                onClick={onClick}
                onKeyDown={onKeyDown}
                disabled={disabled}
                aria-label={label}
                aria-labelledby="symlab-button__label"
            >
                <span
                    className="mr-1 symlab-button__label"
                    id="symlab-button__label"
                    hidden={!showLabel}
                >
                    {label}
                </span>
                <ButtonIcon
                    icon={icon}
                    label={label}
                    size={size}
                    aria-hidden="true"
                />
            </button>
        );
    }

    return (
        <button
            className={`
            ${className}
                symlab-btn
                symlab-btn__${size}
                symlab-btn__${variant} 
                ${!disabled ? colors[variant] : colors.disabled}
                text-white
                ${disabled ? 'cursor-not-allowed' : ''}
                ${shapes[shape]}
                ${sizes[size]}
                ${className}
            `}
            // eslint-disable-next-line react/button-has-type
            type={type}
            onClick={onClick}
            onKeyDown={onKeyDown}
            disabled={disabled}
            aria-label={label}
            aria-labelledby="symlab-button__label"
        >
            <span className="symlab-button__label" id="symlab-button__label">
                {label}
            </span>
        </button>
    );
}

Button.defaultProps = {
    className: '',
    type: 'button',
    size: 'md',
    variant: 'primary',
    shape: 'rounded-md',
    iconPosition: 'left',
    showLabel: true,
};
