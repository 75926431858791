/* eslint-disable react/jsx-props-no-spreading */
import {
    ClassAttributes,
    InputHTMLAttributes,
    useEffect,
    useState,
} from 'react';

import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';

import { ErrorMessage } from '@hookform/error-message';

import { UserRoles } from '../../../../modules/users/enums';
import { RootState } from '../../../../store';
import { fetchBranches } from '../../../../modules/branches/features/branchesSlice';
import { BranchOnUserRole } from '../../../../modules/branches/enums';
import LoadingAnimated from '../../../../components/elements/Loading/index';
import {
    resetUpdate,
    updateUserAction,
} from '../../../../modules/users/features/updateUserSlice';

type FormData = {
    email: string;
    role: string;
    branchId: string;
    branchRole: BranchOnUserRole;
    phone_number: string;
    firstName: string;
    lastName: string;
};

const branchRoles = [{ name: 'OWNER' }, { name: 'MEMBER' }, { name: 'VIEWER' }];
const nameRegex = /^[a-zA-Z ]{2,30}$/;

export default function FormUpdate() {
    const dispatch = useDispatch();
    const userInvite = useSelector((state: RootState) => state.userInvite);
    const branches = useSelector((state: RootState) => state.branches);
    const userupdate = useSelector((state: RootState) => state.userUpdate);
    const [alertSucess, setAlertSucess] = useState(false);

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<FormData>();

    const { t } = useTranslation();
    const { id } = useParams();

    useEffect(() => {
        if (branches.status === 'idle') {
            dispatch(fetchBranches());
        }
    }, [branches.status, dispatch]);

    const onSubmit = (data: FormData) => {
        // dispatch(resetInvite());
        dispatch(resetUpdate());

        dispatch(
            updateUserAction({
                id,
                authProvider: userInvite.user?.authProviderId,
                email: data.email,
                branchId: data.branchId,
                branchRole: data.branchRole,
                attributes: {
                    firstName: data.firstName,
                    lastName: data.lastName,
                    phone_number: data.phone_number.replaceAll(' ', ''),
                    role: data.role,
                },
            })
        );
    };

    useEffect(() => {
        if (userInvite.status === 'success' && userInvite.user) {
            setValue('email', userInvite.user?.email);
            setValue('role', userInvite.user?.role);
            setValue('firstName', userInvite.user?.firstName);
            setValue('phone_number', userInvite.user?.phone_number);

            setValue('lastName', userInvite.user?.lastName);
            setTimeout(() => {
                if (
                    userInvite.user &&
                    userInvite.user.branches &&
                    userInvite.user.branches.length > 0
                ) {
                    setValue('branchId', userInvite.user.branches[0].branch.id);
                    setValue(
                        'branchRole',
                        userInvite.user.branches[0]
                            .role as unknown as BranchOnUserRole
                    );
                }
            }, 2000);
        }
    }, [userInvite.status, dispatch, setValue, userInvite.user]);

    const closeAlertSucess = () => {
        setAlertSucess(false);
    };

    useEffect(() => {
        if (userupdate.status === 'success') {
            setAlertSucess(true);
            dispatch(resetUpdate());
            setTimeout(() => {
                setAlertSucess(false);

                // navigate('/users');
            }, 2000);
            // dispatch(fetchUsers());
        }
    }, [alertSucess, dispatch, userupdate.status]);

    return userInvite.user === null ||
        (userInvite.user === undefined && userInvite.status === 'loading') ? (
        <LoadingAnimated message={t('common.loading')} />
    ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div>
                <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                >
                    {t('common.email')}
                    <div className="mt-1">
                        <input
                            id="email"
                            type="email"
                            className={`${errors.email
                                ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                : ''
                                }`}
                            autoComplete="email"
                            {...register('email', {
                                required: true,
                                disabled: userInvite.status === 'success' && userInvite.user && userInvite.user.id !== '',
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: 'Invalid email address',
                                },
                            })}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="email"
                            render={() => (
                                <p className="text-red-600 ml-2">
                                    {t('common.errors.invalidEmail')}
                                </p>
                            )}
                        />
                    </div>
                </label>
            </div>
            <div className="flex-none w-full md:flex md:justify-evenly md:space-x-4">
                <div className="mt-2 w-full md:w-1/2">
                    <label
                        htmlFor="firstName"
                        className="block text-sm font-medium text-gray-700"
                    >
                        {t('users.firstName')}
                        <div className="mt-1">
                            <input
                                id="firstName"
                                type="text"
                                className={`${errors.firstName
                                    ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                    : ''
                                    }`}
                                autoComplete="firstName"
                                {...register('firstName', {
                                    required: true,
                                    validate: (value) => nameRegex.test(value),
                                })}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="lastName"
                                render={() =>
                                    errors.lastName?.type === 'validate' ? (
                                        <p className="text-red-600 ml-2">
                                            Ingrese un nombre valido
                                        </p>
                                    ) : (
                                        <p className="text-red-600 ml-2">
                                            {t('common.errors.fieldRequired')}
                                        </p>
                                    )
                                }
                            />
                        </div>
                    </label>
                </div>

                <div className="mt-2 w-full md:w-1/2">
                    <label
                        htmlFor="lastName"
                        className="block text-sm font-medium text-gray-700"
                    >
                        {t('users.lastName')}
                        <div className="mt-1">
                            <input
                                id="lastName"
                                type="text"
                                className={`${errors.lastName
                                    ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                    : ''
                                    }`}
                                autoComplete="lastName"
                                {...register('lastName', {
                                    required: true,
                                    validate: (value) => nameRegex.test(value),
                                })}
                            />

                            <ErrorMessage
                                errors={errors}
                                name="lastName"
                                render={() =>
                                    errors.lastName?.type === 'validate' ? (
                                        <p className="text-red-600 ml-2">
                                            Ingrese un apellido valido
                                        </p>
                                    ) : (
                                        <p className="text-red-600 ml-2">
                                            {t('common.errors.fieldRequired')}
                                        </p>
                                    )
                                }
                            />
                        </div>
                    </label>
                </div>
            </div>
            {/* START DESTINATION PHONE */}
            <div className="mt-4 ">
                <label
                    htmlFor="phone_number"
                    className="block text-sm font-medium text-gray-700"
                >
                    {t('common.phone_number')} *
                    <InputMask
                        mask="+569 9999 9999"
                        defaultValue={userInvite.user?.phone_number}
                        {...register('phone_number', {
                            required: true,
                            validate: (value) => value !== '',
                        })}
                    >
                        {(
                            inputProps: JSX.IntrinsicAttributes &
                                ClassAttributes<HTMLInputElement> &
                                InputHTMLAttributes<HTMLInputElement>
                        ) => (
                            <input
                                id="phone_number"
                                type="text"
                                {...inputProps}
                                className={`mt-2 ${errors.phone_number
                                    ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                    : ''
                                    }`}
                            />
                        )}
                    </InputMask>
                </label>
                {errors.phone_number && (
                    <p className="text-red-600 ml-2">
                        {errors.phone_number.type === 'required' &&
                            t('common.errors.fieldRequired')}
                    </p>
                )}
            </div>
            {/* END DESTINATION PHONE */}
            <div className="mt-4">
                <label
                    htmlFor="role"
                    className="block text-sm font-medium text-gray-700"
                >
                    {t('users.role')}
                    <select
                        id="role"
                        className="tooltip"
                        {...register('role', {
                            required: true,
                            value: UserRoles.USER,
                        })}
                    >
                        {Object.values(UserRoles).map((role) => (
                            <option
                                key={role}
                                value={role}
                                // Validar Roles
                                title={
                                    // eslint-disable-next-line no-nested-ternary
                                    role === 'ADMIN'
                                        ? 'Permite administrar la plataforma'
                                        : role === 'BRANCH_MANAGER'
                                            ? 'Los supervisores de tienda pueden cotizar crear envíos y crear tiendas'
                                            : '-'
                                }
                            >
                                {t(`users.roles.${role}`)}
                            </option>
                        ))}
                    </select>
                </label>
            </div>
            {/*  BRANCHES  */}
            <div className="mt-4">
                <label
                    htmlFor="branchId"
                    className="block text-sm font-medium text-gray-700"
                >
                    {t('branches.userBranch')} *
                    <select
                        id="branchId"
                        {...register('branchId', {
                            required: true,
                        })}
                    >
                        <option value="">
                            {branches.status === 'loading'
                                ? t('common.loading')
                                : t('branches.selectBranch')}
                        </option>
                        {branches.data.map((branch) => (
                            <option key={branch.id} value={branch.id}>
                                {branch.name}
                            </option>
                        ))}
                    </select>
                </label>
                <ErrorMessage
                    errors={errors}
                    name="regionId"
                    render={() => (
                        <p className="text-red-600 ml-2">
                            {t('common.errors.fieldRequired')}
                        </p>
                    )}
                />
            </div>

            {/* BRANCHES ROLES */}
            <div className="mt-4">
                <label
                    htmlFor="branchRole"
                    className="block text-sm font-medium text-gray-700"
                >
                    {t('branches.role')} *
                    <select
                        id="branchRole"
                        {...register('branchRole', {
                            required: true,
                        })}
                    >
                        <option value="">
                            {branches.status === 'loading'
                                ? t('common.loading')
                                : t('branches.selectBranchRole')}
                        </option>
                        {branchRoles.map((branch) => (
                            <option key={branch.name} value={branch.name}>
                                {branch.name}
                            </option>
                        ))}
                    </select>
                </label>
                <ErrorMessage
                    errors={errors}
                    name="branchRole"
                    render={() => (
                        <p className="text-red-600 ml-2">
                            {t('common.errors.fieldRequired')}
                        </p>
                    )}
                />
            </div>

            {/*  END BRANCHES */}
            <div className="mt-4">
                {userInvite.status === 'error' && (
                    <ErrorMessage
                        name="User invited"
                        errors={errors}
                        render={() => (
                            <p className="text-red-600 ml-2">
                                {userInvite.error}
                            </p>
                        )}
                    />
                )}
                {/* {userInvite.status === 'success' && (
													<p className="text-green-600 mb-2">
															{t('common.done')}
													</p>
											)} */}
                <button
                    disabled={userupdate.status === 'loading'}
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                >
                    {
                        // eslint-disable-next-line no-nested-ternary
                        userupdate.status === 'loading'
                            ? t('wait.message')
                            : 'Actualizar'
                    }
                </button>
            </div>

            {userupdate.status === 'error' && (
                <p className="text-red-600 ml-2 mt-4">Algo salio mal</p>
            )}
            {alertSucess && (
                <div
                    className="z-50 fixed right-10 top-24  bg-green-100 inline-flex w-1/2  border border-green-400 text-green-700 px-4 py-3 rounded"
                    role="alert"
                >
                    <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="check-circle"
                        className="w-4 h-auto mr-2 fill-current"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                    >
                        <path
                            fill="currentColor"
                            d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
                        />
                    </svg>
                    <strong className="font-bold mr-2">Sucess!</strong>
                    <span className="block sm:inline">Creado exitosamente</span>
                    <span
                        className="absolute top-0 bottom-0 right-0 px-4 py-3"
                        onClick={() => closeAlertSucess()}
                        onKeyPress={() => closeAlertSucess()}
                        role="button"
                        tabIndex={0}
                    >
                        <svg
                            className="fill-current h-6 w-6 text-green-500"
                            role="button"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                        >
                            <title>Close</title>
                            <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                        </svg>
                    </span>
                </div>
            )}
        </form>
    );
}
