import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client2';
import { ReportShipmentsProgress, ReportPostParams } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface ReportOrdersDeliveriesProgress {
    data: ReportShipmentsProgress;
    status: Status;
    error: string | null;
}

const initialState: ReportOrdersDeliveriesProgress = {
    data: {
        shipmentCreated: 0,
        shipmentInTransit: 0,
        shipmentDelivered: 0,
        shipmentFailed: 0,
    },
    status: Status.idle,
    error: null,
};

export const getReportOrdersDeliveriesProgress = createAsyncThunk(
    'report/ordersDeliveriesProgress',
    async (payload: ReportPostParams) => {
        const response = await APIClient.postData(
            `/report/shipments/delivery/progress`,
            payload
        );
        return response.data;
    }
);

export const reportOrdersDeliveriesProgressSlice = createSlice({
    name: 'ordersDeliveriesProgress',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getReportOrdersDeliveriesProgress.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(
                getReportOrdersDeliveriesProgress.fulfilled,
                (state, action) => {
                    state.status = Status.success;
                    // Add any fetched data to the array
                    state.data = action.payload;
                }
            )
            .addCase(
                getReportOrdersDeliveriesProgress.rejected,
                (state, action) => {
                    state.status = Status.error;
                    state.error = action.error.message ?? null;
                }
            );
    },
});
