/* eslint-disable @typescript-eslint/no-unused-vars */
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';

import { IFileType } from '../components/ProcessFile';
import { FormData } from './useFormData';

interface UseChangeCourierSelectedProps {
    courierSelected: string;
    shipmentsDraft: IFileType[];
    setShipmentsDraft: Dispatch<SetStateAction<IFileType[]>>;
    formSetValue: UseFormSetValue<FormData>;
}
export default (props: UseChangeCourierSelectedProps) => {
    const { courierSelected, setShipmentsDraft, shipmentsDraft, formSetValue } =
        props;

    const [currentCourierId, setCurrentCourierId] = useState('');

    useEffect(() => {
        if (courierSelected !== currentCourierId) {
            setShipmentsDraft(
                shipmentsDraft.map(
                    ({
                        createShipmentResponse,
                        price,
                        fee,
                        courierPrice,
                        insurance,
                        ...item
                    }) => ({ ...item, deliveryDays: 0 })
                )
            );
            formSetValue(
                'checkList',
                shipmentsDraft.map((_) => '')
            );

            setCurrentCourierId(courierSelected);
        }
    }, [
        courierSelected,
        currentCourierId,
        formSetValue,
        setShipmentsDraft,
        shipmentsDraft,
    ]);
};
