/* eslint-disable @typescript-eslint/no-explicit-any */

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getReportOrdersGroupByWeight } from '../../../../modules/report/features/reportOrdersGroupByWeightSlice';
import { RootState } from '../../../../store';

interface GetDataParams {
    endDate: string;
    startDate: string;
    branches: string[];
    couriers: string[];
}

const ordersGroupByWeightDefaultData: any = {
    chart: {
        zoomType: 'xy',
    },
    title: {
        text: '',
    },
    legend: { enabled: false },
    xAxis: [],
    yAxis: [
        {
            labels: {
                format: '{value}',
            },
            title: {
                text: '',
            },
        },
    ],
    tooltip: {
        shared: true,
    },
    series: [],
};

export default () => {
    const dispatch = useDispatch();
    const reportOrdersGroupByWeight = useSelector(
        (state: RootState) => state.reportOrdersGroupByWeight
    );

    const [ordersGroupByWeightData, setOrdersGroupByWeightData] = useState({
        ...ordersGroupByWeightDefaultData,
    });

    const [ordersGroupByWeightDataSeted, setOrdersGroupByWeightDataSeted] =
        useState(false);

    useEffect(() => {
        if (
            reportOrdersGroupByWeight.status === 'success' &&
            ordersGroupByWeightDataSeted === false
        ) {
            const series = [
                {
                    name: '',
                    type: 'spline',
                    data: reportOrdersGroupByWeight.data.map(
                        ({ value, ...rest }) => ({ ...rest, y: value })
                    ),
                },
            ];
            const xAxis = [
                {
                    categories: reportOrdersGroupByWeight.data.map(
                        ({ name }) => name
                    ),
                    crosshair: true,
                },
            ];
            setOrdersGroupByWeightData({
                ...ordersGroupByWeightData,
                series,
                xAxis,
            });
            setOrdersGroupByWeightDataSeted(true);
        }
    }, [
        ordersGroupByWeightData,
        ordersGroupByWeightDataSeted,
        reportOrdersGroupByWeight.data,
        reportOrdersGroupByWeight.status,
    ]);

    const getData = (seacrhParams: GetDataParams) => {
        dispatch(getReportOrdersGroupByWeight(seacrhParams));
        setOrdersGroupByWeightDataSeted(false);
    };

    return {
        getData,
        data: ordersGroupByWeightData,
        status: reportOrdersGroupByWeight.status,
    };
};
