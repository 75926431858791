/* eslint-disable react/jsx-props-no-spreading */
// import { useTable, Column, useSortBy } from 'react-table';
import { useSelector } from 'react-redux';

// import { TFunction, useTranslation } from 'react-i18next';

import PageHeader from '../../../components/PageHeader/index';
import { RootState } from '../../../store';
import LoadingAnimated from '../../../components/elements/Loading';
import TrackingListForm from './TrackingListForm';
// import { IShipment } from '../../../modules/shipments/interfaces';
// import { ITrackingShipment } from '../../../modules/trackings/interfaces/ITrackingShipment';
// import TableSearch from '../../../components/Table/TableSearch';

const dateOptions = {
    year: 'numeric' as const,
    month: 'numeric' as const,
    day: 'numeric' as const,
    hour: 'numeric' as const,
    minute: 'numeric' as const,
    second: 'numeric' as const,
    hour12: false,
    timeZone: 'America/Santiago',
};
export default function ListTracking() {
    const trackings = useSelector((state: RootState) => state.trackings);

    // const { t } = useTranslation();

    // const columns = useMemo<Column<ITrackingShipment>[]>(
    //     () => [
    //         {
    //             Header: 'Estados'.toString(),
    //             accessor: 'statusLog',
    //         },
    //     ],
    //     []
    // );

    // const tableInstance = useTable(
    //     {
    //         columns,
    //         data: trackings.data,
    //         defaultColumn: {
    //             minWidth: 30,
    //             width: 150,
    //             maxWidth: 400,
    //         },
    //     },
    //     useSortBy
    // );

    // const {
    //     getTableProps,
    //     getTableBodyProps,
    //     headerGroups,
    //     rows,
    //     prepareRow,
    //     canNextPage,
    //     canPreviousPage,
    //     pageOptions,
    //     pageCount,
    //     gotoPage,
    //     nextPage,
    //     previousPage,
    //     setPageSize,
    //     preGlobalFilteredRows,
    //     setGlobalFilter,
    //     state: { pageIndex, pageSize, globalFilter },
    // } = tableInstance;

    // console.log(trackings);
    return (
        <>
            <PageHeader title="Tracking" />
            <div className="relative text-black shadow-md bg-gray-100 p-6">
                <TrackingListForm />
            </div>

            <div className="mt-5">
                {trackings.status === 'loading' && (
                    <LoadingAnimated message="Cargando..." />
                )}
                {trackings.status === 'success' && trackings.data.length > 0 ? (
                    <div className="mb-12">
                        <div>
                            <div className="flex bg-black text-white font-bold">
                                <div className="w-1/3 p-2">Proveedor</div>
                                <div className="w-1/3 p-2">
                                    Nombre Remitente
                                </div>

                                <div className="w-1/3 p-2">Calle de origen</div>
                                <div className="w-1/3 p-2">Nombre Receptor</div>

                                <div className="w-1/3 p-2 text-right">
                                    Calle de destino
                                </div>
                                <div className="w-1/3 p-2 text-right">
                                    Precio
                                </div>
                                <div className="w-1/3 p-2 text-right">
                                    Status
                                </div>
                            </div>
                            {trackings.data.map((shipment) => (
                                <div
                                    key={shipment.id}
                                    className="flex divide-x-2 border-b-2 border-b-gray-100"
                                >
                                    <div className="w-1/3 p-2 ">
                                        {shipment.courier?.courierCode}
                                    </div>
                                    <div className="w-1/3 p-2">
                                        {shipment.senderName}
                                    </div>

                                    <div className="w-1/3 p-2">
                                        {shipment.originStreet}
                                    </div>

                                    <div className="w-1/3 p-2">
                                        {shipment.receiverName}
                                    </div>
                                    <div className="w-1/3 p-2 text-right">
                                        {shipment.destinationStreet}
                                    </div>
                                    <div className="w-1/3 p-2 text-right">
                                        {shipment.price}
                                    </div>
                                    <div className="w-1/3 p-2 text-right">
                                        {shipment.status}
                                    </div>
                                </div>
                            ))}
                        </div>

                        {trackings.data[0].statusLog &&
                            trackings.data[0].statusLog.length > 0 && (
                                <>
                                    <div className="mt-5">
                                        <PageHeader title="Estados anteriores" />
                                    </div>

                                    <div className="flex bg-black text-white font-bold mt-5">
                                        <div className="w-1/3 p-2">
                                            Fecha Creación
                                        </div>
                                        <div className="w-1/3 p-2">
                                            Fecha Actualización
                                        </div>
                                        <div className="w-1/3 p-2">
                                            Estado anterior
                                        </div>
                                        <div className="w-1/3 p-2">
                                            Nuevo estado
                                        </div>
                                    </div>
                                </>
                            )}

                        {trackings.data.map(
                            (e) =>
                                e.statusLog &&
                                e.statusLog?.length > 0 &&
                                e.statusLog.map((data) => (
                                    <div
                                        key={data.id}
                                        className="flex divide-x-2 border-b-2 border-b-gray-100"
                                    >
                                        <p className="w-1/3 p-2 ">
                                            {Intl.DateTimeFormat(
                                                'es-CL',
                                                dateOptions
                                            ).format(new Date(data.createdAt))}
                                        </p>
                                        <p className="w-1/3 p-2 ">
                                            {Intl.DateTimeFormat(
                                                'es-CL',
                                                dateOptions
                                            ).format(new Date(data.updatedAt))}
                                        </p>
                                        <p className="w-1/3 p-2 ">
                                            {data.lastStatus}
                                        </p>
                                        <p className="w-1/3 p-2 ">
                                            {data.newStatus}
                                        </p>
                                    </div>
                                ))
                        )}
                    </div>
                ) : (
                    trackings.status !== 'loading' &&
                    trackings.status !== 'idle' && (
                        <div className="w-full text-center">
                            No se encontraron datos
                        </div>
                    )
                )}
            </div>
        </>
    );
}
