import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IArticle } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface ArticlesState {
    data: IArticle[];
    status: Status;
    error: string | null;
}

const initialState: ArticlesState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const fetchArticles = createAsyncThunk(
    'articles/fetchArticles',
    async () => {
        const response = await APIClient.getData('/article');
        return response.data;
    }
);

export const articlesFetchSlice = createSlice({
    name: 'articles',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchArticles.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(fetchArticles.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = action.payload;
            })
            .addCase(fetchArticles.rejected, (state, action) => {
                state.status = Status.success;
                state.error = action.error.message ?? null;
            });
    },
});
