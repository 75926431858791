/* eslint-disable react/jsx-props-no-spreading */
import { UseFormRegister, UseFormSetValue } from "react-hook-form";

import { IFileType } from "./ProcessFile"
import { FormData } from '../customHooks/useFormData';
import LoadingAnimated from "../../../../components/elements/Loading";
import PriceDetails from "../../../../components/Shipment/PriceDetails";

interface ShipmentsDraftProps {
  shipmentsDraft: IFileType[];
  createBulkQuoteStatus: string;
  checkList: string[];
  formRegister: UseFormRegister<FormData>;
  formSetValue: UseFormSetValue<FormData>;
}
export default function ShipmentsDraft(props: ShipmentsDraftProps) {
  const { shipmentsDraft, formRegister, createBulkQuoteStatus, formSetValue, checkList } = props;

  return (
    <div className="bg-gray-100 shadow-md my-10 p-4">
      <div className="flex divide-x-2 border-b-2 border-b-gray-100 text-black">
        <div className="w-1/12 text-center p-2 ">
          <input
            type="checkbox"
            onChange={() => {
              if (checkList.filter((item) => item).length > 0) {
                shipmentsDraft.forEach((ship, index) => {
                  if (ship.price !== 0) {
                    formSetValue(`checkList.${index}`, '');
                  }
                });
                return;
              }
              shipmentsDraft.forEach((ship, index) => {
                if (ship.price !== 0) {
                  formSetValue(`checkList.${index}`, ship.itemId);
                }
              });
            }}
            checked={checkList.filter((item) => item).length > 0}
          // disabled={quoteCreateState === 'idle'}
          // className={`${quoteCreateState === 'idle'
          //   ? 'cursor-not-allowed'
          //   : ''
          //   }`}
          />
        </div>
        <div className="font-bold w-1/12 text-center p-2 ">Fila</div>
        <div className="font-bold w-1/6 p-2">Comuna Remitente</div>
        <div className="font-bold w-1/6 p-2">Dirección Remitente</div>
        <div className="font-bold w-1/6 p-2">Comuna Destinatario</div>
        <div className="font-bold w-1/6 p-2">Dirección Destinatario</div>
        <div className="font-bold w-1/6 p-2 text-center">Días de entrega</div>
        <div className="font-bold w-1/6 p-2 text-right">Precio</div>
      </div>
      {shipmentsDraft.map((ship, index) => (
        <div
          key={ship.itemId}
          className="flex divide-x-2 border-b-2 border-b-gray-100"
        >
          <div className="w-1/12 text-center p-2 ">
            <input
              id={ship.itemId}
              value={ship.itemId}
              type="checkbox"
              {...formRegister(`checkList.${index}`)}
            // disabled={
            //   quoteCreateState === 'idle' ||
            //   (quoteCreateState === 'success' &&
            //     ship.price === 0)
            // }
            />
          </div>
          <div className="w-1/12 text-center p-2 ">
            {ship.rowId}
          </div>
          <div className="w-1/6 p-2">{ship.originComuna}</div>
          <div className="w-1/6 p-2">{`${ship.originStreet} ${ship.originNumber}`}</div>
          <div className="w-1/6 p-2">
            {ship.destinationComuna}
          </div>
          <div className="w-1/6 p-2">{`${ship.destinationStreet} ${ship.destinationNumber}`}</div>
          <div className="w-1/6 text-center p-2">{`${ship.deliveryDays || ''}`}</div>
          <div className="w-1/6 p-2 text-right font-bold text-black">

            {(createBulkQuoteStatus === 'loading') && <LoadingAnimated message="" />}

            {
              createBulkQuoteStatus === 'success' &&
              (ship.price === 0 ? (
                <span className="font-normal text-red-700">
                  No disponible
                </span>
              ) : (
                ship.price && ship.createShipmentResponse === undefined && (
                  <PriceDetails
                    price={ship.price}
                    fee={ship.fee || 0}
                    courierPrice={ship.courierPrice || 0}
                    insurance={ship.insurance || 0}
                    declaredValue={
                      ship.declaredValue
                    }
                    discount={0}
                    discountIsPorcent={false}
                  />
                )
              ))
            }



            {
              ship.createShipmentResponse !== undefined &&
              (!ship.createShipmentResponse.error && ship.createShipmentResponse.id !== '' ? (
                <div aria-label="green background badge" className="focus:outline-none bg-green-500 text-white h-8 w-20 mb-4 md:mb-0 rounded-full flex items-center justify-center">
                  <span className="text-xs text-white font-normal">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                    </svg>
                  </span>
                </div>
              ) : (
                (
                  <div aria-label="green background badge" className="focus:outline-none bg-red-500 text-white h-8 w-20 mb-4 md:mb-0 rounded-full flex items-center justify-center">
                    <span className="text-xs text-white font-normal">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
                      </svg>
                    </span>
                  </div>
                )
              ))
            }
          </div>
        </div>
      ))}
    </div>
  )


}