import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { ICourierAPIKey } from '../interfaces/ICourierAPIKey';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface CourierAPIKeyState {
    data: ICourierAPIKey | null;
    status: Status;
    error: string | null;
}

const initialState: CourierAPIKeyState = {
    data: null,
    status: Status.idle,
    error: null,
};

interface IActionsAPIKey {
    id: string;
    name: string;
    value: string;
    expires: Date;
    userId?: string;
    courierId: string;
    branchId: string;
}

export const getCourierAPIKeyById = createAsyncThunk(
    'courierAPIKey/getById',
    async (id: string) => {
        const response = await APIClient.getData(`/couriers/apikey/${id}`);
        return response.data;
    }
);

export const getCourierAPIKeyByBranchId = createAsyncThunk(
    'courierAPIKey/getByBranchId',
    async (id: string) => {
        const response = await APIClient.getData(
            `/couriers/apikey/branch/${id}`
        );
        return response.data;
    }
);

export const createCourierAPIKey = createAsyncThunk(
    'courier/courierAPIKeyCreate',
    async (payload: IActionsAPIKey) => {
        const mapPayload = {
            name: payload.name,
            value: payload.value,
            expires: payload.expires,
            branchId: payload.branchId,
            courierId: payload.courierId,
            userId: payload.userId,
        };
        const response = await APIClient.postData(
            `/couriers/apikey`,
            mapPayload
        );
        return response.data;
    }
);

export const updateCourierAPIKey = createAsyncThunk(
    'courier/courierAPIKeyUpdate',
    async (payload: IActionsAPIKey) => {
        const mapPayload = {
            name: payload.name,
            value: payload.value,
            expires: payload.expires,
            branchId: payload.branchId,
            courierId: payload.courierId,
            userId: payload.userId,
        };
        const response = await APIClient.putData(
            `/couriers/apikey/${payload.id}`,
            mapPayload
        );
        return response.data;
    }
);

export const deleteCourierAPIKey = createAsyncThunk(
    'courierAPIKey/couriersDeleteAPIKey',
    async (id: string) => {
        const response = await APIClient.deleteData(`/couriers/apikey/${id}`);
        return response.data;
    }
);

export const courierAPIKeySlice = createSlice({
    name: 'courierAPIKey',
    initialState,
    reducers: {
        resetCourierAPIKeyById: (state) => {
            state.data = null;
            state.status = initialState.status;
            state.error = initialState.error;
        },
        resetCourierAPIKeyByBranchId: (state) => {
            state.data = null;
            state.status = initialState.status;
            state.error = initialState.error;
        },
        resetDeleteCourierAPIKey: (state) => {
            state.data = null;
            state.status = Status.idle;
            state.error = null;
        },
        resetCreateCourierAPIKey: (state) => {
            state.data = null;
            state.status = Status.idle;
            state.error = null;
        },
        resetUpdateCourierAPIKey: (state) => {
            state.data = null;
            state.status = Status.idle;
            state.error = null;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(getCourierAPIKeyById.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(getCourierAPIKeyById.fulfilled, (state, action) => {
                state.status = Status.success;
                state.data = action.payload.props;
            })
            .addCase(getCourierAPIKeyById.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            })
            .addCase(getCourierAPIKeyByBranchId.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(getCourierAPIKeyByBranchId.fulfilled, (state, action) => {
                state.status = Status.success;
                state.data = action.payload.props;
            })
            .addCase(getCourierAPIKeyByBranchId.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            })
            .addCase(createCourierAPIKey.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(createCourierAPIKey.fulfilled, (state) => {
                state.status = Status.success;
            })
            .addCase(createCourierAPIKey.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            })
            .addCase(updateCourierAPIKey.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(updateCourierAPIKey.fulfilled, (state) => {
                state.status = Status.success;
            })
            .addCase(updateCourierAPIKey.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            })
            .addCase(deleteCourierAPIKey.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(deleteCourierAPIKey.fulfilled, (state, action) => {
                state.status = Status.success;
                state.data = action.payload.props;
            })
            .addCase(deleteCourierAPIKey.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            });
    },
});

export const {
    resetCourierAPIKeyById,
    resetDeleteCourierAPIKey,
    resetCreateCourierAPIKey,
    resetUpdateCourierAPIKey,
    resetCourierAPIKeyByBranchId,
} = courierAPIKeySlice.actions;
