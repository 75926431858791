/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import APIClient from '../../../services/api/client';
import { IQuote } from '../interfaces';
import paginateArray from '../../../utils/paginateArray';
import diffDate from '../../../utils/diffDate';

export interface IQuoteBulkResponse {
    itemId: string;
    quotationId: string;
    quotes: {
        valid: boolean;
        time: string;
        description: string;
        price: number;
        insurance: number;
        courierPrice: number;
        fee: number;
        courier: string;
        courierId: string;
        deliveryEstimateDate: string;
    }[];
}

export type IQuoteBulkItem = {
    itemId: string;
    price: number;
    fee: number;
    courierPrice: number;
    insurance: number;
    deliveryEstimateDate: string;
    deliveryDays: number;
    deliveryType: string;
};

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface QuoteState {
    data: IQuoteBulkItem[];
    status: Status;
    error: string | null;
}

const initialState: QuoteState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const fetchQuotes = createAsyncThunk('quotes/fetchQuotes', async () => {
    const response = await APIClient.getData('/quotes');
    return response.data;
});

export const createBulkQuotes = createAsyncThunk(
    'quotesBulk/createBulkQuotes',
    async (data: {
        courierId: string;
        items: IQuote[];
        shipmentTypeId: string;
    }) => {
        const shipmentsPaginated = paginateArray(data.items, 2);

        const response = [];
        for (const items of shipmentsPaginated) {
            const apiResponses = await APIClient.postData('/quotes/bulk', {
                ...data,
                items,
            });

            const quotes = apiResponses.data.map((r: IQuoteBulkResponse) => {
                const qList = r.quotes;

                const quote = qList.find((q) => q.valid);

                return {
                    itemId: r.itemId,
                    price: quote?.price ?? 0,
                    courierPrice: quote?.courierPrice ?? 0,
                    fee: quote?.fee ?? 0,
                    insurance: quote?.insurance ?? 0,
                    deliveryEstimateDate: quote?.deliveryEstimateDate,
                    deliveryDays: quote?.deliveryEstimateDate
                        ? diffDate(
                              new Date(),
                              new Date(quote?.deliveryEstimateDate)
                          )
                        : 0,
                    deliveryType:
                        quote?.courier === 'MINUTOS99'
                            ? quote?.time
                            : quote?.description,
                };
            });

            response.push(...quotes);
        }
        return response;
    }
);

export const quotesBulkSlice = createSlice({
    name: 'quotesBulk',
    initialState,
    reducers: {
        resetQuotes: (state) => {
            state.data = [];
            state.status = Status.idle;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(createBulkQuotes.pending, (state) => {
                state.status = Status.loading;
                state.data = [];
            })
            .addCase(createBulkQuotes.fulfilled, (state, action) => {
                state.status = Status.success;
                state.data = action.payload;
            })
            .addCase(createBulkQuotes.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            });
    },
});

export const { resetQuotes } = quotesBulkSlice.actions;
