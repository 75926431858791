/* eslint-disable @typescript-eslint/no-explicit-any */

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getReportOrdersCreatedGroupByDay } from '../../../../modules/report/features/reportOrdersCreatedGroupByDaySlice';
import { RootState } from '../../../../store';

interface GetDataParams {
    endDate: string;
    startDate: string;
    branches: string[];
    couriers: string[];
}

const ordersCreatedGroupByDayDefaultData: any = {
    chart: {
        zoomType: 'xy',
    },
    title: {
        text: '',
    },
    legend: { enabled: true },
    xAxis: [],
    yAxis: [
        {
            labels: {
                format: '{value}',
            },
            title: {
                text: '',
            },
        },
    ],
    tooltip: {
        shared: true,
    },
    series: [],
};

export default () => {
    const dispatch = useDispatch();
    const reportOrdersCreatedGroupByDay = useSelector(
        (state: RootState) => state.reportOrdersCreatedGroupByDay
    );

    const [ordersCreatedGroupByDayData, setOrdersCreatedGroupByDayData] =
        useState({
            ...ordersCreatedGroupByDayDefaultData,
        });

    const [
        ordersCreatedGroupByDayDataSeted,
        setOrdersCreatedGroupByDayDataSeted,
    ] = useState(false);

    useEffect(() => {
        if (
            reportOrdersCreatedGroupByDay.status === 'success' &&
            ordersCreatedGroupByDayDataSeted === false &&
            reportOrdersCreatedGroupByDay.data !== null
        ) {
            let series: any[] = [];
            let xAxis: any[] = [];
            if (
                reportOrdersCreatedGroupByDay.data &&
                reportOrdersCreatedGroupByDay.data.length > 0
            ) {
                series = reportOrdersCreatedGroupByDay.data.map(
                    ({ branchName, values }) => ({
                        name: branchName,
                        type: 'spline',
                        data: values.map(({ value, ...rest }) => ({
                            ...rest,
                            y: value,
                        })),
                    })
                );

                xAxis = [
                    {
                        categories:
                            reportOrdersCreatedGroupByDay.data[0].values.map(
                                ({ name }) => name
                            ),
                        crosshair: true,
                    },
                ];
            }
            setOrdersCreatedGroupByDayData({
                ...ordersCreatedGroupByDayData,
                series,
                xAxis,
            });
            setOrdersCreatedGroupByDayDataSeted(true);
        }
    }, [
        ordersCreatedGroupByDayData,
        ordersCreatedGroupByDayDataSeted,
        reportOrdersCreatedGroupByDay.data,
        reportOrdersCreatedGroupByDay.status,
    ]);

    const getData = (seacrhParams: GetDataParams) => {
        dispatch(getReportOrdersCreatedGroupByDay(seacrhParams));
        setOrdersCreatedGroupByDayDataSeted(false);
    };

    return {
        getData,
        data: ordersCreatedGroupByDayData,
        status: reportOrdersCreatedGroupByDay.status,
    };
};
