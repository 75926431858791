import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { BranchOnUserRole } from '../enums/BranchOnUserRole';
import { IBranchOnUser } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface BranchState {
    branchOnUser: IBranchOnUser | null;
    status: Status;
    error: string | null;
}

const initialState: BranchState = {
    branchOnUser: null,
    status: Status.idle,
    error: null,
};

interface ICreateBranchOnUserPayload {
    userId: string;
    branchId: string;
    role: BranchOnUserRole;
}

export const createBranchOnUser = createAsyncThunk(
    'branchOnUser/create',
    async (payload: ICreateBranchOnUserPayload) => {
        const mapPayload = {
            userId: payload.userId,
            branchId: payload.branchId,
            role: payload.role,
        };
        const response = await APIClient.postData('/branches-users', mapPayload);
        return response.data;
    }
);

export const branchesOnUserCreateSlice = createSlice({
    name: 'branchesOnUsers',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(createBranchOnUser.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(createBranchOnUser.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.branchOnUser = action.payload;
            })
            .addCase(createBranchOnUser.rejected, (state, action) => {
                state.status = Status.success;
                state.error = action.error.message ?? null;
            });
    },
});
