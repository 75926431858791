/* eslint-disable @typescript-eslint/no-explicit-any */

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getReportOrdersByRegion } from '../../../../modules/report/features/reportOrdersByRegionSlice';
import { RootState } from '../../../../store';

interface GetDataParams {
    endDate: string;
    startDate: string;
    branches: string[];
    couriers: string[];
}

const ordersByCouriersDefaultData: any = {
    chart: {
        type: 'column',
    },
    title: {
        text: '',
    },
    legend: { enabled: false },
    xAxis: [],
    yAxis: [
        {
            // Secondary yAxis
            title: {
                text: 'Cantidad de órdenes',
            },
            opposite: false,
        },
    ],
    tooltip: {
        shared: false,
    },
    series: [],
};

export default () => {
    const dispatch = useDispatch();
    const reportOrdersByRegion = useSelector(
        (state: RootState) => state.reportOrdersByRegion
    );

    const [ordersByRegionData, setOrdersByRegionData] = useState({
        ...ordersByCouriersDefaultData,
    });

    const [ordersByRegionDataSeted, setOrdersByRegionDataSeted] =
        useState(false);

    useEffect(() => {
        if (
            reportOrdersByRegion.status === 'success' &&
            ordersByRegionDataSeted === false
        ) {
            const series = [
                {
                    yAxis: 0,
                    name: 'Órdenes',
                    data: reportOrdersByRegion.data.map(
                        ({ value, ...rest }) => ({ ...rest, y: value })
                    ),
                },
            ];
            const xAxis = [
                {
                    categories: reportOrdersByRegion.data.map(
                        ({ name }) => name
                    ),
                    crosshair: true,
                },
            ];
            setOrdersByRegionData({ ...ordersByRegionData, series, xAxis });
            setOrdersByRegionDataSeted(true);
        }
    }, [
        ordersByRegionDataSeted,
        ordersByRegionData,
        reportOrdersByRegion.data,
        reportOrdersByRegion.status,
    ]);

    const getData = (seacrhParams: GetDataParams) => {
        dispatch(getReportOrdersByRegion(seacrhParams));
        setOrdersByRegionDataSeted(false);
    };

    return {
        getData,
        data: ordersByRegionData,
        status: reportOrdersByRegion.status,
    };
};
