import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { INotification } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface NotificationsConfigState {
    data: INotification[];
    status: Status;
    error: string | null;
}

const initialState: NotificationsConfigState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const fetchNotificationsConfig = createAsyncThunk(
    'notificationConfig/fetchNotificationsConfig',
    async () => {
        const response = await APIClient.getData('/notify/config');
        return response.data;
    }
);

export const notificationsConfigSlice = createSlice({
    name: 'notificationsConfig',
    initialState,
    reducers: {
        resetNotificationsConfig: (state) => {
            state.data = [];
            state.status = Status.idle;
            state.error = null;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchNotificationsConfig.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(fetchNotificationsConfig.fulfilled, (state, action) => {
                state.status = Status.success;
                state.data = action.payload;
            })
            .addCase(fetchNotificationsConfig.rejected, (state, action) => {
                state.status = Status.success;
                state.error = action.error.message ?? null;
            });
    },
});

export const { resetNotificationsConfig } = notificationsConfigSlice.actions;
