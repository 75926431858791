import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { ISingleShipment } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface ShipmentsState {
    data: ISingleShipment | null;
    status: Status;
    error: string | null;
}

const initialState: ShipmentsState = {
    data: null,
    status: Status.idle,
    error: null,
};

export const fetchShipmentById = createAsyncThunk(
    'shipmentSingle/get',
    async (id: string) => {
        const response = await APIClient.getData(`/shipments/${id}`);
        return response.data;
    }
);

export const shipmentSingleSlice = createSlice({
    name: 'shipmentSingle',
    initialState,
    reducers: {
        setShipmentLabel: (state, action) => {
            if (state.data) {
                const stateData = { ...state.data };
                stateData.shipmentCourie[0].label = action.payload;
                state.data = stateData;
            }
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchShipmentById.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(fetchShipmentById.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = action.payload;
            })
            .addCase(fetchShipmentById.rejected, (state, action) => {
                state.data = null;
                state.status = Status.success;
                state.error = action.error.message ?? null;
            });
    },
});

export const { setShipmentLabel } = shipmentSingleSlice.actions;
