import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client2';
import {
    ReportOrdersDeliveriesPercentageAtTime,
    ReportPostParams,
} from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface ReportOrdersDeliveriesPercentageAtTimeState {
    data: ReportOrdersDeliveriesPercentageAtTime[];
    status: Status;
    error: string | null;
}

const initialState: ReportOrdersDeliveriesPercentageAtTimeState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const getReportOrdersDeliveriesPercentageOnTime = createAsyncThunk(
    'report/ordersDeliveriesPercentage',
    async (payload: ReportPostParams) => {
        const response = await APIClient.postData(
            `/report/shipments/delivery/percentage/ON_TIME`,
            payload
        );
        return response.data;
    }
);

export const reportOrdersDeliveriesPercentageOnTimeSlice = createSlice({
    name: 'ordersDeliveriesPercentage',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(
                getReportOrdersDeliveriesPercentageOnTime.pending,
                (state) => {
                    state.status = Status.loading;
                }
            )
            .addCase(
                getReportOrdersDeliveriesPercentageOnTime.fulfilled,
                (state, action) => {
                    state.status = Status.success;
                    // Add any fetched data to the array
                    state.data = action.payload;
                }
            )
            .addCase(
                getReportOrdersDeliveriesPercentageOnTime.rejected,
                (state, action) => {
                    state.status = Status.error;
                    state.error = action.error.message ?? null;
                }
            );
    },
});
