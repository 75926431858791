import { Outlet, useNavigate, useLocation } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
import useAuth from '../../context/useAuth';
import Layout from '../../components/Layout/Dashboard';

import sideBarItems from '../../config/sideBarItems';
import { UserRoles } from '../../modules/users/enums';

// const lngs = {
//     en: { nativeName: 'En' },
//     es: { nativeName: 'Es' },
// };

export default function Dashboard() {
    const { logout, user } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const currentPath = location.pathname;
    // const { i18n } = useTranslation();

    // const getCurrentLanguageName = () => {
    //     const currentLanguage = i18n.language;
    //     if (currentLanguage === 'en' || currentLanguage === 'es') {
    //         return lngs[currentLanguage].nativeName;
    //     }
    //     return 'En';
    // };
    return (
        <Layout
            title="SymLab Dashboard"
            navigation={navigate}
            currentPath={currentPath}
            routeParams={location.search}
            logo="/images/mbe-horizontal.svg"
            showDevelopmentTools={false}
            headerItems={[
                // {
                //     label: getCurrentLanguageName(),
                //     action: () => {
                //         if (i18n.language === 'en') {
                //             i18n.changeLanguage('es');
                //         } else {
                //             i18n.changeLanguage('en');
                //         }
                //     },
                // },
                {
                    label: 'Logout',
                    action: logout,
                    svgIcon: (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                            />
                        </svg>
                    ),
                    showLabel: false,
                },
            ]}
            headerMobileItems={[
                {
                    className: 'text-red-500',
                    label: 'Logout',
                    action: logout,
                    svgIcon: (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                            />
                        </svg>
                    ),

                    showLabel: true,
                },
            ]}
            sideBarMenu={sideBarItems(user?.role || UserRoles.USER)}
        >
            <Outlet />
        </Layout>
    );
}
