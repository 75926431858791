/* eslint-disable @typescript-eslint/no-explicit-any */

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getReportOrdersGroupByDay } from '../../../../modules/report/features/reportOrdersGroupByDaySlice';
import { RootState } from '../../../../store';

interface GetDataParams {
    endDate: string;
    startDate: string;
    branches: string[];
    couriers: string[];
}

const ordersGroupByDayDefaultData: any = {
    chart: {
        zoomType: 'xy',
    },
    title: {
        text: '',
    },
    legend: { enabled: true },
    xAxis: [],
    yAxis: [
        {
            labels: {
                format: '{value}',
            },
            title: {
                text: '',
            },
        },
    ],
    tooltip: {
        shared: true,
    },
    series: [],
};

export default () => {
    const dispatch = useDispatch();
    const reportOrdersGroupByDay = useSelector(
        (state: RootState) => state.reportOrdersGroupByDay
    );

    const [ordersGroupByDayData, setOrdersGroupByDayData] = useState({
        ...ordersGroupByDayDefaultData,
    });

    const [ordersGroupByDayDataSeted, setOrdersGroupByDayDataSeted] =
        useState(false);

    useEffect(() => {
        if (
            reportOrdersGroupByDay.status === 'success' &&
            ordersGroupByDayDataSeted === false &&
            reportOrdersGroupByDay.data !== null
        ) {
            let series: any[] = [];
            let xAxis: any[] = [];
            if (
                reportOrdersGroupByDay.data &&
                reportOrdersGroupByDay.data.length > 0
            ) {
                series = reportOrdersGroupByDay.data.map(
                    ({ branchName, values }) => ({
                        name: branchName,
                        type: 'spline',
                        data: values.map(({ value, ...rest }) => ({
                            ...rest,
                            y: value,
                        })),
                    })
                );

                xAxis = [
                    {
                        categories: reportOrdersGroupByDay.data[0].values.map(
                            ({ name }) => name
                        ),
                        crosshair: true,
                    },
                ];
            }
            setOrdersGroupByDayData({
                ...ordersGroupByDayData,
                series,
                xAxis,
            });
            setOrdersGroupByDayDataSeted(true);
        }
    }, [
        ordersGroupByDayData,
        ordersGroupByDayDataSeted,
        reportOrdersGroupByDay.data,
        reportOrdersGroupByDay.status,
    ]);

    const getData = (seacrhParams: GetDataParams) => {
        dispatch(getReportOrdersGroupByDay(seacrhParams));
        setOrdersGroupByDayDataSeted(false);
    };

    return {
        getData,
        data: ordersGroupByDayData,
        status: reportOrdersGroupByDay.status,
    };
};
