import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IEmails } from '../interfaces/ISingleShipment';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface ShipmentsEmailState {
    data: IEmails | null;
    status: Status;
    error: string | null;
}

const initialState: ShipmentsEmailState = {
    data: null,
    status: Status.idle,
    error: null,
};

export const sendEmail = createAsyncThunk(
    'shipmentSendEmailSingle/sendEmail',
    async (emails: IEmails) => {
        const response = await APIClient.postData(`/sendEmail`, emails);
        return response.data;
    }
);

export const shipmentSendEmailSingleSlice = createSlice({
    name: 'shipmentSendEmailSingle',
    initialState,
    reducers: {
        shipmentSendEmailClear: (state) => {
            state.status = Status.idle;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(sendEmail.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(sendEmail.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = action.payload;
            })
            .addCase(sendEmail.rejected, (state, action) => {
                state.data = null;
                state.status = Status.success;
                state.error = action.error.message ?? null;
            });
    },
});
export const { shipmentSendEmailClear } = shipmentSendEmailSingleSlice.actions;
