/* eslint-disable react/jsx-props-no-spreading */
import { Dispatch, SetStateAction } from 'react';
import { FormState, UseFormHandleSubmit, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@hookform/error-message';

import { Button } from '../../../../components/elements/Buttons';
import { Status } from '../../../../modules/couriers/features/couriersSlice';
import { ICourier, ICourierShipmentTypes } from '../../../../modules/couriers/interfaces';
import { IQuote } from '../../../../modules/quotes/interfaces';
import { FormData } from '../customHooks/useFormData';

import ProcessFile, { IFileType } from './ProcessFile';


interface BulkFormProps {
  courierDeliveryTypes: ICourierShipmentTypes[];
  couriersStatus: Status;
  couriers: ICourier[];
  shipmentsDraft: IFileType[];
  shipmentsGenerated: boolean;
  formState: FormState<FormData>;
  shipmentCreateBulkState: string;
  createBulkQuoteStatus: string;
  fileErrors: string[];
  formRegister: UseFormRegister<FormData>;
  formSetValue: UseFormSetValue<FormData>;
  setFileErrors: Dispatch<SetStateAction<string[]>>;
  setShipmentsDraft: Dispatch<SetStateAction<IFileType[]>>;
  createBulkQuote: (data: FormData) => Promise<void>;
  handleSubmit: UseFormHandleSubmit<FormData>;
  resetAllUseCreateBulkShipment: () => void;
}

export default function BulkForm(props: BulkFormProps) {
  const {
    courierDeliveryTypes,
    couriersStatus,
    couriers,
    shipmentsDraft,
    formState,
    shipmentsGenerated,
    shipmentCreateBulkState,
    createBulkQuoteStatus,
    fileErrors,
    formRegister,
    setFileErrors,
    formSetValue,
    setShipmentsDraft,
    handleSubmit,
    createBulkQuote,
    resetAllUseCreateBulkShipment
  } = props;
  const { t } = useTranslation();

  return (
    <div className="flex text-black shadow-md bg-gray-100 p-6 mt-4">
      <div className="w-full">
        <h3 className="text-lg font-bold mb-4">
          1. Elige las opciones para este envío
        </h3>
        <div className="flex justify-start items-center space-x-4">
          {/* START COURIER SELECT */}
          <div className="mt-8">
            <ProcessFile
              setFileErrors={setFileErrors}
              setQuotesList={(list: IQuote[]) => {
                formSetValue('quotesList', list);
              }}
              setShipmentsDraft={setShipmentsDraft}
            />
          </div>
          <div >
            <label
              htmlFor="courierId"
              className="block text-sm font-medium text-gray-700"
            >
              {t('couriers.title')} *
              <select
                disabled={
                  shipmentsGenerated ||
                  shipmentCreateBulkState === 'loading' ||
                  createBulkQuoteStatus === 'loading'
                }
                id="courierId"
                className="mt-2"
                {...formRegister('courierId', {
                  required: true,
                  validate: (value) => value !== '',
                })}
              >
                <option value="">
                  {couriersStatus === 'loading'
                    ? t('common.loading')
                    : t('couriers.selectACourier')}
                </option>
                {couriersStatus === 'success' ? (
                  couriers
                    .filter(
                      ({ status: active }) =>
                        active === 'ACTIVE'
                    )
                    .map((item) => (
                      <option
                        key={item.id}
                        value={item.id}
                      >
                        {item.name}
                      </option>
                    ))
                ) : (
                  <option>{t('loading.message')}</option>
                )}
              </select>
            </label>
            <ErrorMessage
              errors={formState.errors}
              name="courierId"
              render={() => (
                <p className="text-red-600 ml-2">
                  {t('common.errors.fieldRequired')}
                </p>
              )}
            />
          </div>
          {/* END COURIER SELECT */}
          {/* START SHIPMENT TYPE */}
          <div >
            <label
              htmlFor="shipmentTypeId"
              className="block text-sm font-medium text-gray-700"
            >
              {t('couriers.shipmentTypes')} *
              <select
                id="shipmentTypeId"
                disabled={
                  shipmentsGenerated ||
                  shipmentCreateBulkState === 'loading' ||
                  createBulkQuoteStatus === 'loading'
                }
                className="mt-2"
                {...formRegister('shipmentTypeId', {
                  required: true,
                  validate: (value) => value !== '',
                })}
              >
                <option value="">
                  {couriersStatus === 'loading'
                    ? t('common.loading')
                    : t('couriers.selectAType')}
                </option>
                {courierDeliveryTypes.map((item) => (
                  <option
                    key={item.id}
                    value={item.id}
                  >
                    {item.description}
                  </option>
                ))}
              </select>
            </label>
            <ErrorMessage
              errors={formState.errors}
              name="shipmentTypeId"
              render={() => (
                <p className="text-red-600 ml-2">
                  {t('common.errors.fieldRequired')}
                </p>
              )}
            />
          </div>
          {/* END SHIPMENT TYPE */}
          <div>
            <Button
              type="submit"
              className="mt-6"
              disabled={
                shipmentsDraft.length === 0 ||
                shipmentsGenerated ||
                shipmentCreateBulkState === 'loading' ||
                fileErrors.length > 0 ||
                createBulkQuoteStatus === 'loading'
              }
              label={t('quotes.create.action')}
              onClick={handleSubmit(createBulkQuote)}
            />
          </div>


          <div>
            <Button
              type="button"
              className="mt-6"
              variant="link"
              label={t('common.clear')}
              disabled={createBulkQuoteStatus === 'loading' || shipmentCreateBulkState === 'loading'}
              onClick={() => {
                setShipmentsDraft([]);
                formSetValue('quotesList', []);
                formSetValue('shipmentTypeId', "");
                formSetValue('courierId', "");
                resetAllUseCreateBulkShipment();
                setFileErrors([]);
              }}
            />
          </div>

        </div>
      </div>
    </div>
  )
}