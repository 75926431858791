/* eslint-disable no-console */
import {
    createContext,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import AuthService from '../services/auth';
import { User } from './interfaces';

// Usefull to simulate a loading state
// import { stall } from '../utils/helpers';

interface AuthContextType {
    // We defined the user type in `index.d.ts`, but it's
    // a simple object with email, name and password.
    user?: User | null;
    loading: boolean;
    initialLoading: boolean;
    error?: string | null;
    login: (email: string, password: string) => void;
    // signUp: (email: string, name: string, password: string) => void;
    logout: () => void;
    forgotPassword: (email: string) => void;
    setForgotPassword: (email: string, code: string, password: string) => void;
    setTemporaryPassword: (
        email: string,
        oldPassword: string,
        newPassword: string
    ) => void;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

// Export the provider as we need to wrap the entire app with it
export function AuthProvider({
    children,
}: {
    children: ReactNode;
}): JSX.Element {
    // const TAG = 'AUTHPROVIDER';
    // console.log(`${TAG} - START`);
    const authService = useMemo(() => new AuthService(), []);
    const [user, setUser] = useState<User | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [initialLoading, setInitialLoading] = useState<boolean>(true);
    const location = useLocation();
    // Check if there is a currently active session
    // when the provider is mounted for the first time.
    useEffect(() => {
        const getUser = async () => {
            try {
                // console.log(`${TAG} - GET USER`);
                setInitialLoading(true);

                const currentUser = await authService.getCurrentUser();
                setUser(currentUser);
                // console.log(`${TAG} - GET USER - SUCCESS`);
            } catch (userError) {
                // console.log(`${TAG} - GET USER - ERROR`);

                if (userError === 'The user is not authenticated') {
                    setError(null);
                    setUser(null);
                    return;
                }
                if (userError instanceof Error) {
                    setError(userError.message);
                    return;
                }
                setError('Unknown error');
            } finally {
                setInitialLoading(false);
            }
        };
        getUser();
    }, [authService]);

    // When the user navigates to a new location, clear the error
    useEffect(() => {
        if (!user) {
            setError(null);
        }
    }, [location.pathname, user]);

    // Flags the component loading state and posts the login
    // data to the server.
    //
    // An error means that the email/password combination is
    // not valid.
    //
    // Finally, just signal the component that loading the
    // loading state is over.
    const login = useCallback(
        async (email: string, password: string) => {
            // console.log(`${TAG} - login user`);
            setLoading(true);

            try {
                // console.log('login');
                const currentUser = await authService.login(email, password);

                console.log({ currentUser });

                setUser(currentUser);
            } catch (loginError) {
                // manage cognito signin errors
                // console.log(`${TAG} - login user - ERROR`);
                // console.log({ loginError });

                if (loginError instanceof Error) {
                    // console.log({ message: loginError.message });
                    if (
                        loginError.message ===
                        'Temporary password has expired and must be reset by an administrator.'
                    ) {
                        setError('TemporaryPasswordExpired');
                        return;
                    }
                    if (loginError.message === 'NEW_PASSWORD_REQUIRED') {
                        setError('NewPasswordRequired');
                        return;
                    }

                    setError(loginError.name);
                    return;
                }
                setError('Unknown error');
            } finally {
                setLoading(false);
            }
        },
        [authService]
    );

    // Sends sign up details to the server. On success we just apply
    // the created user to the state.
    // function signUp(email: string, name: string, password: string) {
    //     setLoading(true);

    //     authService
    //         .signUp({ email, name, password })
    //         .then((user) => {
    //             setUser(user);
    //             history.push('/');
    //         })
    //         .catch((error) => setError(error))
    //         .finally(() => setLoading(false));
    // }

    // Call the logout endpoint and then remove the user
    // from the state.
    const logout = useCallback(async () => {
        // console.log(`${TAG} - logout user`);
        try {
            await authService.logout();
            setUser(null);
            setError(null);
        } catch (logOutError) {
            setUser(null);
            if (logOutError instanceof Error) {
                setError(logOutError.message);
                return;
            }
            setError('Unknown error');
        }
    }, [authService]);

    const forgotPassword = useCallback(
        async (email: string) => {
            // console.log(`${TAG} - forgot password`);
            setLoading(true);
            try {
                await authService.forgotPassword(email);
                setError(null);
            } catch (forgotPasswordError) {
                // console.log({ forgotPasswordError });
                if (forgotPasswordError instanceof Error) {
                    setError(forgotPasswordError.name);
                    return;
                }
                setError('Unknown error');
            } finally {
                setLoading(false);
            }
        },
        [authService]
    );

    const setForgotPassword = useCallback(
        async (email: string, code: string, password: string) => {
            // console.log(`${TAG} - set forgot password`);
            setLoading(true);
            try {
                await authService.changeforgotPassword(email, code, password);
                setError(null);
            } catch (forgotPasswordError) {
                // console.log({ forgotPasswordError });
                if (forgotPasswordError instanceof Error) {
                    setError(forgotPasswordError.name);
                    return;
                }
                setError('Unknown error');
            } finally {
                setLoading(false);
            }
        },
        [authService]
    );

    const setTemporaryPassword = useCallback(
        async (email: string, oldPassword: string, newPassword: string) => {
            // console.log(`${TAG} - set temporary password`);
            setLoading(true);
            try {
                await authService.setTemporaryPassword(
                    email,
                    oldPassword,
                    newPassword
                );
                setError(null);
            } catch (forgotPasswordError) {
                // console.log({ forgotPasswordError });
                if (forgotPasswordError instanceof Error) {
                    setError(forgotPasswordError.name);
                    return;
                }
                setError('Unknown error');
            } finally {
                setLoading(false);
            }
        },
        [authService]
    );

    // Make the provider update only when it should.
    // We only want to force re-renders if the user,
    // loading or error states change.
    //
    // Whenever the `value` passed into a provider changes,
    // the whole tree under the provider re-renders, and
    // that can be very costly! Even in this case, where
    // you only get re-renders when logging in and out
    // we want to keep things very performant.
    const memoedValue = useMemo(
        () => ({
            user,
            loading,
            initialLoading,
            error,
            login,
            logout,
            forgotPassword,
            setForgotPassword,
            setTemporaryPassword,
        }),
        [
            loading,
            initialLoading,
            user,
            error,
            login,
            logout,
            forgotPassword,
            setForgotPassword,
            setTemporaryPassword,
        ]
    );

    // We only want to render the underlying app after we
    // assert for the presence of a current user.
    return (
        <AuthContext.Provider value={memoedValue}>
            {children}
        </AuthContext.Provider>
    );
}

// Let's only export the `useAuth` hook instead of the context.
// We only want to use the hook directly and never the context component.
export default function useAuth() {
    return useContext(AuthContext);
}
