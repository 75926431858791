/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { RootState, useAppDispatch } from '../../../store';

import PageHeader from '../../../components/PageHeader';
import { fetchShipmentById, setShipmentLabel } from '../../../modules/shipments/features/singleShipmentSlice';
import { fetchShipmentLabelById, resetSingleShipmentGetLabel } from '../../../modules/shipments/features/singleShipmentGetLabelSlice';
import LoadingAnimated from '../../../components/elements/Loading';
import PriceDetails from '../../../components/Shipment/PriceDetails';
import { Bagde } from '../../../components/Shipment/Badges';
import { CreateComment } from './components/CreateComment';
import { fetchShipmentsComents } from '../../../modules/shipmentComments/features/ShipmentsCommentsSlice';
import { AlertError } from '../../../components/Alerts';
import { ChangeStatus } from './components/ChangeStatus';

// eslint-disable-next-line react/require-default-props
/* function DataRow({ title, value = '' }: { title: string, value?: string }) {
    return (<p className="mb-2">
        <span className="text-gray-600">{title} :</span>{' '}
        <span className="text-lg">{value}</span>
    </p>)
} */

export default function SingleShipment() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const shipmentsComments = useSelector(
        (state: RootState) => state.shipmentsComments
    );
    const { id } = useParams();

    const shipmentSingleLabel = useSelector((state: RootState) => state.shipmentSingleLabel);
    const shipment = useSelector((state: RootState) => state.shipmentSingle);
    const createComment = useSelector(
        (state: RootState) => state.createShipmentComment
    );

    useEffect(() => {
        if (
            id &&
            ((shipment.data && shipment.data.id !== id) ||
                shipment.status === 'idle')
        ) {
            dispatch(fetchShipmentById(id));
        }
    }, [id, dispatch, shipment]);

    useEffect(() => {
        if (id) {
            dispatch(fetchShipmentsComents(id));
        }
    }, [
        createComment.status,
    ]);

    const [getLabelCalled, setGetLabelCalled] = useState(false);
    const findLabel = (shipmentId: string) => {
        setGetLabelCalled(true);
        dispatch(fetchShipmentLabelById(shipmentId));
    }

    useEffect(() => {
        if (shipmentSingleLabel.status === 'success' && id && getLabelCalled === true) {
            setGetLabelCalled(false);
            dispatch(setShipmentLabel(shipmentSingleLabel.data));
            dispatch(resetSingleShipmentGetLabel());
        }
    }, [shipmentSingleLabel])


    return (
        <>
            {shipmentsComments.status === 'loading' || shipment.status === 'loading' && (
                <LoadingAnimated message={t('common.loading')} />
            )}

            {shipment.status === 'success' &&
                shipmentsComments.status === 'success' &&
                shipment.data && (
                    <>
                        <div className="flex flex-row text-black mt-6">
                            <div className="basis-1/2">
                                <PageHeader
                                    title={`Detalle del envío (${shipment.data?.guideNumber})`}
                                />
                                <PageHeader
                                    title={`${shipment.data?.courier.name} (${shipment.data?.shipmentCourie[0].trackingNumber}) ${shipment.data?.courierDeliveryType}`}
                                />
                            </div>
                            <div className="basis-1/2">
                                <Bagde status={shipment.data.status} />
                            </div>
                        </div>
                        <div className="flex text-black shadow-md bg-gray-100 p-6">
                            {/* ORIGIN */}
                            <div className="w-full md:w-1/3">
                                <h3 className="text-2xl font-bold">
                                    Datos de origen
                                </h3>

                                {/* START BRANCH ORIGIN */}
                                <div className="mt-5">
                                    <div className="mt-4">
                                        {[
                                            `${shipment.data.originStreet} ${shipment.data.originNumber}`,
                                            shipment.data.originComuna.name,
                                            shipment.data.originRegion?.name,
                                        ].join(', ')}
                                    </div>
                                </div>
                                <div className="mt-2">
                                    <p>{shipment.data.senderName} </p>
                                    <p>{shipment.data.senderEmail} </p>
                                    <p>{shipment.data.senderPhoneNumber} </p>
                                </div>
                            </div>
                            {/* ORIGIN - FINISH */}
                            <div className="w-1/3 flex items-center justify-center text-center">
                                <img
                                    src="/images/transport.svg"
                                    alt="transport"
                                />
                            </div>
                            {/* DESTINATION */}
                            <div className="w-full md:w-1/3">
                                <h3 className="text-2xl font-bold">
                                    Datos de destino
                                </h3>
                                {/* <p>{t('quotes.create.destinationAddressHelper')}</p> */}
                                {/* START BRANCH DESTINATION */}
                                <div className="mt-5">
                                    <div className="mt-4">
                                        {[
                                            `${shipment.data.destinationStreet} ${shipment.data.destinationNumber}`,
                                            shipment.data.destinationComuna
                                                .name,
                                            shipment.data.destinationRegion
                                                ?.name,
                                        ].join(', ')}
                                    </div>
                                </div>
                                <div className="mt-2">
                                    <p>{shipment.data.receiverName} </p>
                                    <p>{shipment.data.receiverEmail} </p>
                                    <p>{shipment.data.receiverPhoneNumber} </p>
                                </div>
                            </div>
                            {/* DESTINATION - FINISH */}
                        </div>

                        <div className="flex flex-row text-black shadow-md bg-gray-100 p-6 mt-6 mb-4">
                            <div className="basis-1/4">
                                <PriceDetails
                                    price={shipment.data.finalPrice}
                                    fee={shipment.data.fee}
                                    courierPrice={shipment.data.courierPrice}
                                    insurance={shipment.data.insurance}
                                    declaredValue={shipment.data.declaredValue}
                                    discount={shipment.data.discount}
                                    discountIsPorcent={
                                        shipment.data.discountIsPorcent
                                    }
                                />
                            </div>
                            <div className="basis-1/4">
                                <h3 className="text-lg font-bold">Tienda</h3>
                                <p>{shipment.data.branch.name}</p>
                                <p>{shipment.data.branch.email}</p>
                                <h3 className="text-lg font-bold mt-5">
                                    Cliente
                                </h3>
                                <p>{shipment.data.client?.name}</p>
                            </div>
                            <div className="basis-1/4">
                                <h3 className="text-lg font-bold">Etiqueta</h3>
                                <div className="w-64">
                                    {
                                        shipment.data
                                        && shipment.data.shipmentCourie.length > 0
                                        && shipment.data.shipmentCourie[0].label !== ''
                                        && (
                                            <a
                                                download={`etiqueta-${shipment.data.shipmentCourie[0].trackingNumber}.pdf`}
                                                href={`data:application/pdf;base64,${shipment.data.shipmentCourie[0].label}`}
                                                title="Download pdf document"
                                            >
                                                <button
                                                    type="button"
                                                    className="group relative w-full flex justify-center py-2 px-4 border  text-sm font-medium rounded-md"
                                                >
                                                    <div className="flex space-x-6 items-center">
                                                        <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="h-6 w-6"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke="currentColor"
                                                                strokeWidth={2}
                                                            >
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                                                                />
                                                            </svg>
                                                        </span>
                                                        <span>
                                                            {t(
                                                                'common.downloadShipmentLabel'
                                                            )}
                                                        </span>
                                                    </div>
                                                </button>
                                            </a>
                                        )}
                                    {shipmentSingleLabel.status === 'error' && <AlertError
                                        title="Ocurrió un error"
                                        message="Si el error persiste, contacte al administrador del sistema"
                                        onClick={() => dispatch(resetSingleShipmentGetLabel())}
                                        fixed={false} />}
                                    {
                                        shipment.data
                                        && shipment.data.shipmentCourie.length > 0
                                        && shipment.data.shipmentCourie[0].label === ''
                                        && shipmentSingleLabel.status !== 'loading'
                                        && (<button
                                            onClick={() => {
                                                if (shipment.data !== null) {
                                                    findLabel(shipment.data.id)
                                                }
                                            }}
                                            type="button"
                                            className="group relative w-full flex justify-center py-2 px-4 border  text-sm font-medium rounded-md"
                                        >
                                            <div className="flex space-x-6 items-center">
                                                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                                                    </svg>
                                                </span>
                                                <span>Recuperrar etiqueta </span>
                                            </div>
                                        </button>)}
                                    {shipmentSingleLabel.status === 'loading' && (<LoadingAnimated message={t('common.loading')} />)}
                                </div>

                            </div>


                            <div className="basis-1/4">
                                <h3 className="text-lg font-bold">
                                    {t('quotes.create.packageSize')}
                                </h3>
                                <div className="mt-4 mb-20">
                                    <div className="flex items-center space-x-6">
                                        {/* START MEASURES */}
                                        <div className="mt-2 flex space-x-4 bg-white p-6">
                                            {/* START LENGTH */}
                                            <div className="text-center">
                                                <label
                                                    htmlFor="length"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    {t('common.length')} (
                                                    {t('common.cm')})
                                                </label>
                                                {
                                                    shipment.data
                                                        .packages[0].length
                                                }
                                            </div>
                                            {/* END LENGTH */}
                                            {/* START WIDTH */}
                                            <div className="text-center">
                                                <label
                                                    htmlFor="width"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    {t('common.width')} (
                                                    {t('common.cm')})
                                                </label>
                                                {
                                                    shipment.data
                                                        .packages[0].width
                                                }
                                            </div>
                                            {/* END WIDTH */}
                                            {/* START HEIGHT */}
                                            <div className="text-center">
                                                <label
                                                    htmlFor="height"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    {t('common.height')} (
                                                    {t('common.cm')})
                                                </label>
                                                {
                                                    shipment.data
                                                        .packages[0].height
                                                }
                                            </div>
                                            {/* END HEIGHT */}
                                            {/* START WEIGHT */}
                                            <div className="text-center">
                                                <label
                                                    htmlFor="weight"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    {t('common.weight')} (
                                                    {t('common.kg')})
                                                </label>
                                                {
                                                    shipment.data
                                                        .packages[0].weight
                                                }
                                            </div>
                                        </div>
                                        {/* END MEASURES */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <CreateComment />
                        <ChangeStatus statusChanges={shipment.data.statusLogs} />
                    </>
                )}
        </>
    );
}
