import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IBranch } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface BranchesState {
    data: IBranch[];
    status: Status;
    error: string | null;
}

const initialState: BranchesState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const fetchBranches = createAsyncThunk(
    'branches/fetchBranches',
    async () => {
        const response = await APIClient.getData('/branch');
        return response.data;
    }
);

export const branchesSlice = createSlice({
    name: 'branches',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchBranches.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(fetchBranches.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = action.payload;
            })
            .addCase(fetchBranches.rejected, (state, action) => {
                state.status = Status.success;
                state.error = action.error.message ?? null;
            });
    },
});
