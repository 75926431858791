import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    createShipmentComment,
    resetComment,
} from '../../../../modules/shipmentComments/features/ShipmentCommentSlice';
import { IShipmentComment } from '../../../../modules/shipmentComments/interfaces/IShipmentComment';
import { RootState } from '../../../../store';
import { ListComments } from './ListComments';
import { fetchShipmentsComents } from '../../../../modules/shipmentComments/features/ShipmentsCommentsSlice';

type FormData = {
    description: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    image?: any;
};

export function CreateComment() {
    const { register, handleSubmit, reset } = useForm<FormData>();

    const dispatch = useDispatch();

    const shipmentComment = useSelector(
        (state: RootState) => state.createShipmentComment
    );

    const { id } = useParams();

    function getBase64(file: Blob) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }
    const [createSuccess, setCreateSuccess] = useState(false);

    const onSubmit = async (data: FormData) => {
        const payload: IShipmentComment = {
            description: data.description,
            image:
                data.image && data.image.length > 0
                    ? await getBase64(data.image[0])
                    : // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    ('' as any),
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            shipmentId: id!,
        };

        dispatch(createShipmentComment(payload));
    };

    const navigate = useNavigate();
    useEffect(() => {
        if (shipmentComment.status === 'success' && id) {
            reset();
            setCreateSuccess(true);
            dispatch(resetComment());
            dispatch(fetchShipmentsComents(id));
            setTimeout(() => {
                setCreateSuccess(false);
            }, 2000);
        }
    }, [reset, shipmentComment.status, dispatch, id, navigate]);

    return (
        <div className="flex text-black shadow-md bg-gray-100 p-6 ">
            <div className="w-full md:w-1/3 ">
                <h3 className="text-2xl font-bold">Ingresar Comentario</h3>
                <div className="mt-5">
                    <div className="mt-4">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <label
                                htmlFor="name"
                                className="block text-sm font-medium text-gray-700 mt-5"
                            >
                                Comentario *
                                <textarea
                                    id="description"
                                    autoComplete="description"
                                    maxLength={200}
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...register('description', {
                                        required: true,
                                    })}
                                />
                            </label>
                            {/* <label
                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300 mt-5"
                                htmlFor="large_size"
                            >
                                <input
                                    className="block mb-5 mt-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                                    id="default_size"
                                    type="file"
                                    accept="image/*"
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...register('image', {
                                        required: false,
                                    })}
                                />
                            </label> */}

                            <div className="mt-5">
                                <button
                                    disabled={
                                        shipmentComment.status === 'loading'
                                    }
                                    type="submit"
                                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                                >
                                    {shipmentComment.status === 'loading'
                                        ? 'Loading...'
                                        : 'Ingresar comentario'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="w-full md:w-1/3" />

            <div className="w-full md:w-1/3">
                <ListComments />
            </div>
            {createSuccess && (
                <div
                    className="z-50 fixed right-10 top-24  bg-green-100 inline-flex w-1/2  border border-green-400 text-green-700 px-4 py-3 rounded"
                    role="alert"
                >
                    <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="check-circle"
                        className="w-4 h-auto mr-2 fill-current"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                    >
                        <path
                            fill="currentColor"
                            d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
                        />
                    </svg>
                    <strong className="font-bold mr-2">Sucess!</strong>
                    <span className="block sm:inline">
                        Comentario ingresado
                        {/* {params
															? t('branches.edit.success')
															: t('branches.create.success')} */}
                    </span>
                    <span
                        className="absolute top-0 bottom-0 right-0 px-4 py-3"
                        onClick={() => setCreateSuccess(false)}
                        onKeyPress={() => ''}
                        role="button"
                        tabIndex={0}
                    >
                        <svg
                            className="fill-current h-6 w-6 text-green-500"
                            role="button"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                        >
                            <title>Close</title>
                            <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                        </svg>
                    </span>
                </div>
            )}
        </div>
    );
}
