/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';

import { RootState } from '../../../store';
import { fetchShipments } from '../../../modules/shipments/features';
// import { IShipment } from '../../../modules/shipments/interfaces';
import ShipmentListTable from './ShipmentListTable';
import { ShipmentStatus } from '../../../modules/shipments/enums';
import { statusLabels } from '../../../components/Shipment/Badges';
import PageHeader from '../../../components/PageHeader';
import { fetchCouriers } from '../../../modules/couriers/features/couriersSlice';
import useQuery from '../../../hooks/useQuery';
import { fetchClients } from '../../../modules/clients/features';

type FilterData = {
    status: string;
    date: string;
    courier: string;
    search: string;
    clientId: string;
};
registerLocale('es', es);

export default function List() {
    const dispatch = useDispatch();
    const queryParams = useQuery();
    const { t, i18n } = useTranslation();
    const shipments = useSelector((state: RootState) => state.shipments);
    const couriers = useSelector((state: RootState) => state.couriers);
    const clients = useSelector((state: RootState) => state.clients);
    const [sortData, setSortData] = useState<any>({
        order: '',
        by: '',
    });

    const [sort, setSort] = useState('desc');
    const [head, setHead] = useState('');

    const [deliveryEstimateDate, setDeliveryEstimateDate] = useState<Date>();
    let locale = 'en-US';
    if (i18n.language === 'es') {
        locale = 'es-ES';
    }

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<FilterData>({
        defaultValues: {
            status: '',
            date: '',
            courier: '',
            search: '',
            clientId: '',
        },
    });

    const filterByIds = queryParams.get('ids');
    useEffect(() => {
        if (shipments.status === 'idle') {
            if (filterByIds !== null) {
                dispatch(
                    fetchShipments({
                        ids: filterByIds,
                        limit: filterByIds.split(',').length.toString(),
                    })
                );
            } else {
                dispatch(fetchShipments());
            }
        }
    }, [shipments.status, dispatch, filterByIds]);

    useEffect(() => {
        if (couriers.status === 'idle') {
            dispatch(fetchCouriers());
        }
    }, [couriers.status, dispatch]);

    useEffect(() => {
        if (clients.status === 'idle') {
            dispatch(fetchClients());
        }
    }, [clients.status, dispatch]);

    const [searchParams, setSearchParams] = useState<{
        page: string;
        limit: string;
        search: string;
        courierId: string;
        status: string;
        dateFilter: string;
    }>({
        page: '0',
        limit: '10',
        search: '',
        courierId: '',
        status: '',
        dateFilter: '',
    });

    const [isSearch, setIsSearch] = useState(false);

    useEffect(() => {
        if (deliveryEstimateDate) {
            const d = new Date(deliveryEstimateDate);
            const mm = d.getMonth() + 1;
            const dd = d.getDate();
            const yy = d.getFullYear();
            dispatch(
                fetchShipments({
                    ...searchParams,
                    deliveryEstimateDate: `${yy}-${mm < 10 ? `0${mm}` : mm}-${
                        dd < 10 ? `0${dd}` : dd
                    }`,
                })
            );
        }
    }, [deliveryEstimateDate, dispatch, locale, searchParams]);

    const paginateData = (page: string, limit: string) => {
        dispatch(fetchShipments({ ...searchParams, ...sortData, page, limit }));
        setSearchParams({ ...searchParams, page, limit });
    };

    const submitSearch = (data: FilterData) => {
        setSearchParams({ ...searchParams, ...data });
        dispatch(
            fetchShipments({
                ...searchParams,
                search: data.search,
                courierId: data.courier,
                status: data.status,
            })
        );
        setIsSearch(true);
    };

    const mapSort = (sortValue: string) => {
        switch (sortValue) {
            case 'Guía':
                return 'guideNumber';
            case 'Origen':
                return 'originStreet';
            case 'Destino':
                return 'destinationStreet';
            case 'Tienda':
                return `branch`;
            case 'Couriers':
                return 'courier';
            case 'Precio':
                return 'finalPrice';
            case 'F.Entrega':
                return 'deliveryEstimateDate';
            case 'F.Fin':
                return 'endDate';
            case 'F.Creación':
                return 'createdAt';
            case 'Tracking':
                return 'startDate';
            case 'Cliente':
                return 'client';
            default:
                return 'tracking';
        }
    };

    const clickHeader = (header: string | undefined) => {
        setHead(header || '');
        if (sort === 'desc' || !sort) {
            setSort('asc');
        } else {
            setSort('desc');
        }
        const mapData = mapSort(header || '');
        setSortData({ order: mapData, by: sort });
    };

    useEffect(() => {
        if (sort && sortData && sortData.by !== '' && sortData.order !== '') {
            dispatch(fetchShipments(sortData));
        }
    }, [dispatch, sort, sortData]);

    const clearSearch = () => {
        setSearchParams({
            page: '0',
            limit: '10',
            search: '',
            courierId: '',
            status: '',
            dateFilter: '',
        });
        reset();
        dispatch(
            fetchShipments({
                page: '0',
                limit: '10',
                search: '',
                courierId: '',
                status: '',
                dateFilter: '',
            })
        );
        setIsSearch(false);
    };

    return (
        <div>
            {/* {shipments.status === 'loading' && (
                <LoadingAnimated message={t('common.loading')} />
            )} */}
            {shipments.status === 'success' && shipments.data.length === 0 && (
                <h3>No hay envíos creados</h3>
            )}
            {/* {shipments.status === 'success' && shipments.data.length > 0 && ( */}
            <div>
                <PageHeader title={t('shipments.title')} />
                <div className="bg-sym-mbe-primary text-white p-4 my-4 rounded-2xl ">
                    <div className="mb-4 text-lg">
                        <h3 className="font-bold">Filtros:</h3>
                    </div>
                    <div className="md:flex w-full md:space-x-2 items-center">
                        <div className="w-full md:w-1/6">
                            <label
                                htmlFor="status"
                                className="block text-base font-medium "
                            >
                                <span className="ml-2">Estado</span>
                                <select
                                    className="text-black"
                                    id="status"
                                    {...register('status', {
                                        required: false,
                                    })}
                                >
                                    <option value="">Todos</option>
                                    {[
                                        ...Object.keys(ShipmentStatus),
                                        'DELIVERY_DELAY',
                                    ].map((status) => (
                                        <option key={status} value={status}>
                                            {statusLabels(status, t)}
                                        </option>
                                    ))}
                                </select>
                            </label>
                            {errors.status && (
                                <p className="text-red-600 ml-2">
                                    {errors.status.type === 'required' &&
                                        t('common.errors.fieldRequired')}
                                </p>
                            )}
                        </div>
                        <div className="w-full md:w-1/6">
                            <label
                                htmlFor="courier"
                                className="block text-base font-medium"
                            >
                                <span className="ml-2">Courier</span>
                                <select
                                    className="text-black"
                                    id="courier"
                                    {...register('courier', {
                                        required: false,
                                    })}
                                >
                                    <option value="">Todos</option>
                                    {couriers.data.map((courier) => (
                                        <option
                                            key={courier.id}
                                            value={courier.id}
                                        >
                                            {courier.name}
                                        </option>
                                    ))}
                                </select>
                            </label>
                        </div>
                        <div className="w-full md:w-1/6">
                            <label
                                htmlFor="clientId"
                                className="block text-base font-medium"
                            >
                                <span className="ml-2">Cliente</span>
                                <select
                                    className="text-black"
                                    id="clientId"
                                    {...register('clientId', {
                                        required: false,
                                    })}
                                >
                                    <option value="">Todos</option>
                                    {clients.data.map((client) => (
                                        <option
                                            key={client.id}
                                            value={client.id}
                                        >
                                            {client.name}
                                        </option>
                                    ))}
                                </select>
                            </label>
                        </div>
                        <div className="w-full md:w-1/6 ">
                            <span className="ml-2 text-base font-medium">
                                Fecha Entrega
                            </span>
                            <div className="text-black">
                                <DatePicker
                                    onChange={(date: Date) =>
                                        setDeliveryEstimateDate(date)
                                    }
                                    selected={deliveryEstimateDate}
                                    dateFormat="dd/MM/yyyy"
                                    locale="es"
                                />
                            </div>
                        </div>
                        <div className="w-full md:w-1/4">
                            <label htmlFor="table-search">
                                <span className="ml-2 text-base font-medium">
                                    Buscar
                                </span>
                                <input
                                    className="text-black"
                                    type="text"
                                    id="search"
                                    {...register('search', {
                                        required: false,
                                    })}
                                />
                            </label>
                        </div>
                        <div className="w-full md:w-1/6">
                            <button
                                onClick={handleSubmit(submitSearch)}
                                // disabled={isFilter && false}
                                type="button"
                                className="h-16 pt-6 px-4 text-sm font-medium rounded-md align-middle"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                    />
                                </svg>
                            </button>
                            {isSearch && (
                                <button
                                    onClick={() => clearSearch()}
                                    // disabled={isFilter && false}
                                    type="button"
                                    className="h-16 pt-6 px-4 text-sm font-medium rounded-md align-middle text-gray-200"
                                >
                                    Limpiar búsqueda
                                </button>
                            )}
                        </div>
                    </div>
                </div>
                <ShipmentListTable
                    head={head}
                    sort={sort}
                    clickHeader={clickHeader}
                    loading={shipments.status === 'loading'}
                    paginateData={paginateData}
                    data={shipments.data}
                    count={shipments.count}
                />
            </div>
            {/* )} */}
        </div>
    );
}
