import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IArticle } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface ArticleState{
    data: IArticle | null;
    status: Status;
    error: string | null;
}

const initialState: ArticleState = {
    data: null,
    status: Status.idle,
    error: null,
};


export const getArticleById = createAsyncThunk(
    'article/getById',
    async (id: string) => {
        const response = await APIClient.getData(`/article/${id}`);
        return response.data;
    }
);


export const createArticle = createAsyncThunk(
    'article/createArticle',
    async (payload: IArticle) => {
        const mapPayload = {
            name: payload.name,
			categoryId: payload.categoryId,
			description: payload.description,
        };
        const response = await APIClient.postData(
            `/article`,
            mapPayload
        );
        return response.data;
    }
);

export const updateArticle = createAsyncThunk(
    'article/updateArticle',
    async (payload: IArticle) => {
        const mapPayload = {
            name: payload.name,
            description: payload.description,
            categoryId: payload.categoryId,
        };
        const response = await APIClient.putData(
            `/article/${payload.id}`,
            mapPayload
        );
        return response.data;
    }
);

export const deleteArticle = createAsyncThunk(
    'article/deleteArticle',
    async (id: string) => {
        const response = await APIClient.deleteData(`/article/${id}`);
        return response.data;
    }
);

export const articleSlice = createSlice({
    name: 'article',
    initialState,
    reducers: {
        resetArticleById: (state) => {
            state.data = null;
            state.status = initialState.status;
            state.error = initialState.error;
        },
        resetDeleteArticle: (state) => {
            state.data = null;
            state.status = Status.idle;
            state.error = null;
        },
        resetCreateArticle: (state) => {
            state.data = null;
            state.status = Status.idle;
            state.error = null;
        },
        resetUpdateArticle: (state) => {
            state.data = null;
            state.status = Status.idle;
            state.error = null;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(getArticleById.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(getArticleById.fulfilled, (state, action) => {
                state.status = Status.success;
                state.data = action.payload.props;
            })
            .addCase(getArticleById.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            })
            .addCase(createArticle.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(createArticle.fulfilled, (state) => {
                state.status = Status.success;
            })
            .addCase(createArticle.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            })
            .addCase(updateArticle.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(updateArticle.fulfilled, (state) => {
                state.status = Status.success;
            })
            .addCase(updateArticle.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            })
            .addCase(deleteArticle.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(deleteArticle.fulfilled, (state, action) => {
                state.status = Status.success;
                state.data = action.payload.props;
            })
            .addCase(deleteArticle.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            });
    },
});

export const {
	resetArticleById,
	resetDeleteArticle,
	resetCreateArticle,
	resetUpdateArticle,
} = articleSlice.actions;
