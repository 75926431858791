
interface OrdersInProcessProps {
  value: number
  title: string
}

export default function OrdersInProcess(props: OrdersInProcessProps) {
  const { value, title } = props
  return (<div className="grid lg:grid-cols-1 md:grid-cols-1 grid-cols-1 gap-x-1 gap-y-1 lg:gap-x-6 md:gap-x-6 md:gap-y-6 md:gap-y-6 justify-center">
    <div>{" "}</div>
    <div className="flex justify-center flex-col items-center w-36 h-36 md:w-44 md:h-48 lg:w-56 lg:h-56 bg-white shadow rounded-2xl">
      <h2 className="lg:text-5xl md:text-4xl text-2xl font-extrabold leading-10 text-center text-gray-800">{value}</h2>
      <p className="mt-4 text-sm md:text-base lg:text-lg leading-none text-center text-gray-600">{title}</p>
    </div>
    <div>{" "}</div>
  </div>)
}