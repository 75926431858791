import { TFunction, useTranslation } from 'react-i18next';

const color = (status: string) => {
    switch (status) {
        case 'DELIVERED':
            return 'bg-green-500 text-white';
        case 'PENDING':
            return 'bg-yellow-300 text-black';
        case 'IN_TRANSIT':
            return 'bg-orange-600 text-white';
        case 'FAILED':
            return 'bg-red-700 text-white';
        case 'CANCELLED':
            return 'bg-red-300 text-black';

        // case 'QUOTE':
        //     return 'bg-red-300 text-black';
        default:
            return 'bg-gray-500 text-white';
    }
};

export const statusLabels = (
    status: string,
    t: TFunction<'translation', undefined>
) => {
    switch (status) {
        case 'DELIVERED':
            return t('shipments.status.delivered');
        case 'PENDING':
            return t('shipments.status.pending');
        case 'IN_TRANSIT':
            return t('shipments.status.inTransit');
        case 'DELIVERY_DELAY':
            return t('shipments.status.deliveryDelay');
        case 'FAILED':
            return t('shipments.status.failed');
        case 'FAILED_NOT_FINISHED':
            return t('shipments.status.failedNotFinished');
        case 'CANCELLED':
            return t('shipments.status.cancelled');
        case 'UNKNOWN':
            return t('shipments.status.unknown');
        default:
            return 'Sin Estado';
    }
};

export function Bagde({ status }: { status: string }) {
    const { t } = useTranslation();

    return (
        <div
            className={`text-center text-sm w-36 px-2 py-1 rounded-full mx-auto ${color(
                status
            )}`}
        >
            {statusLabels(status, t)}
        </div>
    );
}

export function BagdeFilter({
    status,
    filterData,
}: {
    status: string;
    filterData: (status: string) => void;
}) {
    const { t } = useTranslation();

    return (
        <button
            type="button"
            className={`text-center text-sm w-36 px-2 py-1 rounded-full mx-auto ${color(
                status
            )}`}
            onClick={() => {
                filterData(status);
            }}
        >
            {statusLabels(status, t)}
        </button>
    );
}
