import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import { usersSlice, inviteUserSlice } from './modules/users/features';
import {
    shipmentsSlice,
    shipmentCreateSlice,
    shipmentSingleSlice,
    shipmentBulkSlice,
    shipmentMergeSlice,
    singleShipmentGetLabelSlice,
} from './modules/shipments/features';
import { regionsSlice, comunasSlice } from './modules/geo/features';
import { quotesSlice, quotesBulkSlice } from './modules/quotes/features';
import { branchesOnUserGetByIgSlice } from './modules/branches/features/branchesOnUsersGetByIdSlice';
import { branchesOnUsersDeleteByIdgSlice } from './modules/branches/features/branchesOnUsersDeleteByIdSlice';
import {
    branchesSlice,
    branchSlice,
    branchesOnUserSlice,
    branchesOnUserCreateSlice,
} from './modules/branches/features';

import { trackingSlice } from './modules/trackings/features/trackingsSlice';

import { clientsSlice, singleClientSlice } from './modules/clients/features';
import { couriersSlice } from './modules/couriers/features';

import { configSystemSlice } from './modules/configSystem/features';
import { couriersAPIkeyFetchSlice } from './modules/courierAPIKey/features/courierAPIKeysFetchSlice';
import { courierAPIKeySlice } from './modules/courierAPIKey/features/courierAPIKeySlice';
import { insurancesSlice } from './modules/insurances/features/insurancesSlice';
import { shipmentsCommentsSlice } from './modules/shipmentComments/features/ShipmentsCommentsSlice';
import { shipmentCommentSlice } from './modules/shipmentComments/features/ShipmentCommentSlice';
import { updateUserSlice } from './modules/users/features/updateUserSlice';

import { notificationsConfigSlice } from './modules/notificationConfig/features/notificationsConfigSlice';
import { notificationConfigSlice } from './modules/notificationConfig/features/notificationConfigSlice';
import { notificationConfigTagsSlice } from './modules/notificationConfig/features/notificationConfigTagsSlice';
import { categoriesFetchSlice } from './modules/category/features/categoriesSlice';
import { categorySlice } from './modules/category/features/categorySlice';
import { articlesFetchSlice } from './modules/article/features/articlesSlice';
import { articleSlice } from './modules/article/features/articleSlice';
import { shipmentSendEmailSingleSlice } from './modules/shipments/features/shipmentSendEmailSlice';
// REPORTS
import { reportOrdersByCourierSlice } from './modules/report/features/reportOrdersByCourierSlice';
import { reportOrdersDeliveriesPercentageOnTimeSlice } from './modules/report/features/reportOrdersDeliveriesPercentageOnTimeSlice';
import { reportOrdersDeliveriesPercentageIssuesSlice } from './modules/report/features/reportOrdersDeliveriesPercentageOnTimeIssues';
import { reportOrdersDeliveriesProgressSlice } from './modules/report/features/reportOrdersDeliveriesProgressSlice';
import { reportOrdersByRegionSlice } from './modules/report/features/reportOrdersByRegionSlice';
import { reportOrdersByComunaSlice } from './modules/report/features/reportOrdersByComunaSlice';
import { reportOrdersGroupByWeightSlice } from './modules/report/features/reportOrdersGroupByWeightSlice';
import { reportOrdersGroupByDaySlice } from './modules/report/features/reportOrdersGroupByDaySlice';
import { reportGeneralDataSlice } from './modules/report/features/reportGeneralDataSlice';
import { reportOrdersCreatedGroupByDaySlice } from './modules/report/features/reportOrdersCreatedGroupByDaySlice';

export const store = configureStore({
    reducer: {
        // USER
        users: usersSlice.reducer,
        userInvite: inviteUserSlice.reducer,
        userUpdate: updateUserSlice.reducer,
        // SHIPMENT
        shipments: shipmentsSlice.reducer,
        shipmentsComments: shipmentsCommentsSlice.reducer,
        createShipmentComment: shipmentCommentSlice.reducer,
        shipmentCreate: shipmentCreateSlice.reducer,
        shipmentSingle: shipmentSingleSlice.reducer,
        shipmentSingleLabel: singleShipmentGetLabelSlice.reducer,
        shipmentsBulk: shipmentBulkSlice.reducer,
        shipmentsLabelsMerged: shipmentMergeSlice.reducer,
        // GEO
        regions: regionsSlice.reducer,
        comunas: comunasSlice.reducer,
        quotes: quotesSlice.reducer,
        // BRANCHES //
        branches: branchesSlice.reducer,
        branch: branchSlice.reducer,
        // BRANCHES ON USER //
        branchesOnUserGetByIgSlice: branchesOnUserGetByIgSlice.reducer,
        branchesOnUsers: branchesOnUserSlice.reducer,
        branchesOnUsersCreate: branchesOnUserCreateSlice.reducer,
        branchesOnUsersDeleteByIdgSlice:
            branchesOnUsersDeleteByIdgSlice.reducer,
        // CLIENTS //

        // Tracking //
        trackings: trackingSlice.reducer,

        clients: clientsSlice.reducer,
        client: singleClientSlice.reducer,
        // COURIERS //
        couriers: couriersSlice.reducer,
        quotesBulk: quotesBulkSlice.reducer,
        // COURIERS APIKEYS //
        couriersFetchAPIKeys: couriersAPIkeyFetchSlice.reducer,
        couriersAPIKeys: courierAPIKeySlice.reducer,

        // CONFIG_SYSTEM //
        configSystem: configSystemSlice.reducer,

        // INSURANCES //
        insurances: insurancesSlice.reducer,
        // NOTIFICATIONS CONFIG //
        notificationsConfig: notificationsConfigSlice.reducer,
        notificationConfig: notificationConfigSlice.reducer,
        notificationConfigTags: notificationConfigTagsSlice.reducer,
        // FAQ //
        categories: categoriesFetchSlice.reducer,
        category: categorySlice.reducer,
        articles: articlesFetchSlice.reducer,
        article: articleSlice.reducer,
        // SEND EMAIL //
        sendEmail: shipmentSendEmailSingleSlice.reducer,
        // REPORTS //
        reportOrdersByCourier: reportOrdersByCourierSlice.reducer,
        reportOrdersDeliveriesPercentageOnTime:
            reportOrdersDeliveriesPercentageOnTimeSlice.reducer,
        reportOrdersDeliveriesPercentageIssues:
            reportOrdersDeliveriesPercentageIssuesSlice.reducer,
        reportOrdersDeliveriesProgressIssues:
            reportOrdersDeliveriesProgressSlice.reducer,
        reportOrdersByRegion: reportOrdersByRegionSlice.reducer,
        reportOrdersByComuna: reportOrdersByComunaSlice.reducer,
        reportOrdersGroupByWeight: reportOrdersGroupByWeightSlice.reducer,
        reportOrdersGroupByDay: reportOrdersGroupByDaySlice.reducer,
        reportGeneralData: reportGeneralDataSlice.reducer,
        reportOrdersCreatedGroupByDay:
            reportOrdersCreatedGroupByDaySlice.reducer,
    },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
