/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Column,
    useTable,
    useSortBy,
    usePagination,
    useFilters,
    useGlobalFilter,
} from 'react-table';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../store';
import { fetchUsers } from '../../../modules/users/features';
// import { IUser } from '../../../modules/users/interfaces';
import LoadingAnimated from '../../../components/elements/Loading';

import Invite from './components/Invite';
import PageHeader from '../../../components/PageHeader';
import { Button } from '../../../components/elements/Buttons';
import TableSearch from '../../../components/Table/TableSearch';
import { IUserBadge, IUser } from '../../../modules/users/interfaces/index';

function Bagde({ status }: { status: string }) {
    const { t } = useTranslation();
    const color = useMemo(() => {
        switch (status) {
            case 'ACTIVE':
                return 'bg-green-500 text-white';
            case 'INACTIVE':
                return 'bg-gray-300 text-black';
            case 'INVITED':
                return 'bg-orange-600 text-white';
            case 'DELETED':
                return 'bg-red-300 text-black';
            default:
                return 'bg-gray-500 text-white';
        }
    }, [status]);

    const label = useMemo(() => {
        switch (status) {
            case 'ACTIVE':
                return t('users.status.active');
            case 'INACTIVE':
                return t('users.status.inactive');
            case 'INVITED':
                return t('users.status.invited');
            case 'DELETED':
                return t('users.status.deleted');
            default:
                return t('common.unknown');
        }
    }, [status, t]);

    return (
        <div
            className={`text-center text-sm w-36 px-2 py-1 rounded-full mx-auto ${color}`}
        >
            {label}
        </div>
    );
}

function BagdeRole({ role }: { role: string }) {
    const { t } = useTranslation();
    const color = useMemo(() => {
        switch (role) {
            case 'USER':
                return 'bg-green-500 text-white';
            case 'ADMIN':
                return 'bg-blue-500 text-white';
            case 'PROVIDER':
                return 'bg-orange-600 text-white';
            case 'BRANCH_MANAGER':
                return 'bg-yellow-500	 text-white';
            case 'SHOPPING_LEADER':
                return 'bg-red-300 text-black';
            default:
                return 'bg-gray-500 text-white';
        }
    }, [role]);

    const label = useMemo(() => {
        switch (role) {
            case 'USER':
                return t('users.roles.USER');
            case 'ADMIN':
                return t('users.roles.ADMIN');
            case 'PROVIDER':
                return t('users.roles.PROVIDER');
            case 'BRANCH_MANAGER':
                return t('users.roles.BRANCH_MANAGER');
            case 'SHOPPING_LEADER':
                return t('users.roles.SHOPPING_LEADER');
            default:
                return t('common.unknown');
        }
    }, [role, t]);

    return (
        <div
            className={`text-center text-sm w-36 px-2 py-1 rounded-full mx-auto ${color} `}
            title={
                // eslint-disable-next-line no-nested-ternary
                role === 'ADMIN'
                    ? 'Permite administrar la plataforma'
                    : role === 'BRANCH_MANAGER'
                    ? 'Los supervisores de tienda pueden cotizar crear envíos y crear tiendas'
                    : '-'
            }
        >
            {label}
        </div>
    );
}

export default function List() {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const users = useSelector((state: RootState) => state.users.users);
    const usersStatus = useSelector((state: RootState) => state.users.status);
    const [showInvite, setShowInvite] = useState(false);

    let locale = 'en-US';
    if (i18n.language === 'es') {
        locale = 'es-ES';
    }
    useEffect(() => {
        if (usersStatus === 'idle') {
            dispatch(fetchUsers());
        }
    }, [usersStatus, dispatch]);

    const columns = useMemo<Column<IUserBadge>[]>(
        () => [
            {
                Header: t('users.firstName').toString(),
                accessor: 'firstName',
            },
            {
                Header: t('users.lastName').toString(),
                accessor: 'lastName',
            },
            {
                Header: t('common.email').toString(),
                accessor: 'email',
            },
            {
                Header: t('common.status').toString(),
                accessor: 'status',
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Cell: ({ cell: { value } }: any) => <Bagde status={value} />,
            },
            {
                Header: t('common.branch').toString(),
                accessor: 'branches',

                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Cell: ({ cell: { value } }: any) => {
                    if (
                        value !== undefined &&
                        value !== null &&
                        value.length > 0
                    ) {
                        return value[0].branch.name;
                    }
                    return ' - ';
                },
            },
            {
                Header: t('users.role').toString(),
                accessor: 'role',
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Cell: ({ cell: { value } }: any) => <BagdeRole role={value} />,
            },
            {
                Header: t('users.lastLogin').toString(),
                accessor: 'lastLogin',
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Cell: ({ cell: { value } }: any) => {
                    if (value) {
                        return new Date(value).toLocaleDateString(locale);
                    }
                    return '';
                },
            },
            {
                Header: t('users.userSince').toString(),
                accessor: 'createdAt',
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Cell: ({ cell: { value } }: any) =>
                    new Date(value).toLocaleDateString(locale),
            },
        ],
        [t, locale]
    );

    const tableInstance = useTable(
        {
            columns,
            data: users as IUserBadge[],
            defaultColumn: {
                minWidth: 30,
                width: 150,
                maxWidth: 400,
            },
            initialState: {
                pageSize: 100,
                pageIndex: 0,
            },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,

        preGlobalFilteredRows,
        setGlobalFilter,
        state: { globalFilter },
    } = tableInstance;

    // TABLE ROW CLICK ///////////////////////////////////////////////////////////////
    const navigate = useNavigate();

    const tableOnclickHandler = (user: IUser) => {
        // setUserId(user.id);
        navigate(`/users/${user.id}`);
    };

    // TABLE ROW CLICK FINISH ///////////////////////////////////////////////////////////////
    return (
        <div className="relative mb-40">
            <Invite show={showInvite} toggle={() => setShowInvite(false)} />
            <PageHeader title={t('users.title')} />
            <div className="mb-2">
                <TableSearch
                    title={t('common.search').toString()}
                    placeholder={t('common.search').toString()}
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={globalFilter}
                    setGlobalFilter={(filter: string) =>
                        setGlobalFilter(filter)
                    }
                />
            </div>
            <Button
                className="absolute right-0 top-0 z-10 rounded-3xl font-bold"
                label={t('users.inviteUser')}
                type="button"
                onClick={() => setShowInvite(!showInvite)}
            />

            {usersStatus === 'loading' && (
                <LoadingAnimated message={t('common.loading')} />
            )}
            {usersStatus === 'success' && (
                <div className="overflow-x-auto shadow-md">
                    <table
                        className="table-auto w-full mx-auto"
                        {...getTableProps()}
                    >
                        <thead className="text-white ">
                            {
                                // Loop over the header rows

                                headerGroups.map((headerGroup) => (
                                    // Apply the header row props

                                    <tr
                                        className="text-center"
                                        {...headerGroup.getHeaderGroupProps()}
                                    >
                                        {
                                            // Loop over the headers in each row

                                            headerGroup.headers.map(
                                                (column) => (
                                                    // Apply the header cell props

                                                    <th
                                                        className="px-2 py-2  border-2 border-white rounded-lg text-center"
                                                        {...column.getHeaderProps(
                                                            column.getSortByToggleProps()
                                                        )}
                                                        style={{
                                                            minWidth: '2%',
                                                        }}
                                                    >
                                                        <div className="flex justify-between bg-sym-mbe rounded-lg p-2 text-center">
                                                            {
                                                                // Render the header

                                                                column.render(
                                                                    'Header'
                                                                )
                                                            }
                                                            {column.canSort ? (
                                                                <span
                                                                    className={`${
                                                                        column.isSorted &&
                                                                        'text-sym-primary-500'
                                                                    }`}
                                                                >
                                                                    {column.isSortedDesc && (
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            className="h-6 w-6"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                            stroke="currentColor"
                                                                        >
                                                                            <path
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                strokeWidth={
                                                                                    2
                                                                                }
                                                                                d="M19 9l-7 7-7-7"
                                                                            />
                                                                        </svg>
                                                                    )}
                                                                    {!column.isSortedDesc && (
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            className="h-6 w-6"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                            stroke="currentColor"
                                                                        >
                                                                            <path
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                strokeWidth={
                                                                                    2
                                                                                }
                                                                                d="M5 15l7-7 7 7"
                                                                            />
                                                                        </svg>
                                                                    )}
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    </th>
                                                )
                                            )
                                        }
                                    </tr>
                                ))
                            }
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {
                                // Loop over the table rows

                                rows.map((row) => {
                                    // Prepare the row for display

                                    prepareRow(row);

                                    return (
                                        // Apply the row props

                                        <tr
                                            className="odd:bg-sym-mbe-seconday hover:bg-gray-300"
                                            {...row.getRowProps()}
                                            onClick={() =>
                                                tableOnclickHandler(
                                                    row.original
                                                )
                                            }
                                        >
                                            {
                                                // Loop over the rows cells

                                                row.cells.map((cell) => (
                                                    // Apply the cell props
                                                    <td
                                                        className="py-4 px-2 cursor-pointer"
                                                        {...cell.getCellProps()}
                                                    >
                                                        {
                                                            // Render the cell contents

                                                            cell.render('Cell')
                                                        }
                                                    </td>
                                                ))
                                            }
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
}
