import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IConfigSystem, IConfigSystemUpdatePayload } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface ConfigSystemState {
    data: IConfigSystem[];
    status: Status;
    error: string | null;
}

const initialState: ConfigSystemState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const fetchConfigSystem = createAsyncThunk(
    'fetch/config/system',
    async () => {
        const response = await APIClient.getData('/config/system');
        return response.data;
    }
);

export const updateConfigSystem = createAsyncThunk(
    'put/config/system',
    async (payload: IConfigSystemUpdatePayload[]) =>
        APIClient.putData('/config/system', payload)
);

export const configSystemSlice = createSlice({
    name: 'configSystem',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            // FETCH DATA
            .addCase(fetchConfigSystem.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(fetchConfigSystem.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = action.payload;
            })
            .addCase(fetchConfigSystem.rejected, (state, action) => {
                state.status = Status.success;
                state.error = action.error.message ?? null;
            })
            // UPDATE
            .addCase(updateConfigSystem.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(updateConfigSystem.fulfilled, (state) => {
                state.status = Status.success;
                // Add any fetched data to the array
            })
            .addCase(updateConfigSystem.rejected, (state, action) => {
                state.status = Status.success;
                state.error = action.error.message ?? null;
            });
    },
});
