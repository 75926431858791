import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import Box from './Home/components/Box';

export default function Home() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <div>
            <div className="flex flex-col justify-center items-center ">
                <h1 className="text-sym-mbe font-bold text-4xl">
                    {t('app.welcome')}
                </h1>
            </div>
            <div className="flex my-16 space-x-20 justify-center items-center">
                <Box
                    title={t('home.quote')}
                    actions={[
                        {
                            action: () => {
                                navigate('/quotes');
                            },
                            label: t('home.actions.makeAQuote'),
                        },
                        {
                            action: () => {
                                navigate('/quotes/bulk');
                            },
                            label: 'Cotización en lote',
                        },
                    ]}
                    image="/images/shipping.png"
                    alt="Caja y moneda"
                />
                <Box
                    title={t('home.tracking')}
                    actions={[
                        {
                            action: () => {
                                navigate('/tracking');
                            },
                            label: t('home.actions.trackShipment'),
                        },
                    ]}
                    image="/images/tracking.png"
                    alt="Mapa con marcadores"
                />
            </div>
        </div>
    );
}
