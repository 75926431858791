/* eslint-disable react/jsx-props-no-spreading */
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';

import { Button } from '../../../components/elements/Buttons';
import PageHeader from '../../../components/PageHeader';
import {
  OrdersByCouriers,
  PercentageDeliveryOnTime,
  IncidentPercentage,
  DeliveriesPerDay,
  OrdersInProcess,
  DestinationRegions,
  PackageWeights,
} from './components';
import { fetchCouriers } from '../../../modules/couriers/features/couriersSlice';
import { fetchBranches } from '../../../modules/branches/features';

import { RootState } from '../../../store';
import LoadingAnimated from '../../../components/elements/Loading';
import useOrdersByCouriers from './customHooks/useOrdersByCouriers';
import useOrdersDeliveriesPercentageOnTime from './customHooks/useOrdersDeliveriesPercentageOnTime';
import useOrdersDeliveriesPercentageIssues from './customHooks/useOrdersDeliveriesPercentageIssues';
import useOrdersProgressIssues from './customHooks/useOrdersProgressIssues';
import useOrdersByRegion from './customHooks/useOrdersByRegion';
import useOrdersGroupByWeight from './customHooks/useOrdersGroupByWeight';
import useOrdersGroupByDay from './customHooks/useOrdersGroupByDay';
import useReportGeneralData from './customHooks/useReportGeneralData';
import useOrdersCreatedGroupByDay from './customHooks/useOrdersCreatedGroupByDay';
import ToolTip from '../../../components/ToolTip';

type FormData = {
  startDate: Date;
  endDate: Date;
  couriersSelected: string[]
  branchesSelected: string[]
};

const defaultStartDate = new Date()
defaultStartDate.setDate(defaultStartDate.getDate() - 5);
const defaultEndDate = new Date();
const defaultFormData = {
  startDate: defaultStartDate,
  endDate: defaultEndDate,
  couriersSelected: [],
  branchesSelected: [],
}

export default function Reports() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Custom hook to manage orders by couriers data
  const ordersByCouriers = useOrdersByCouriers();
  const ordersDeliveriesPercentageOnTime = useOrdersDeliveriesPercentageOnTime();
  const ordersDeliveriesPercentageIssues = useOrdersDeliveriesPercentageIssues();
  const ordersProgressIssues = useOrdersProgressIssues();
  const ordersByRegion = useOrdersByRegion();
  const ordersGroupByWeight = useOrdersGroupByWeight();
  const ordersGroupByDay = useOrdersGroupByDay();
  const reportGeneralData = useReportGeneralData();
  const ordersCreatedGroupByDay = useOrdersCreatedGroupByDay();

  // Declare form hook
  const {
    register,
    handleSubmit,
    setValue,
    watch
  } = useForm<FormData>({ defaultValues: defaultFormData });
  const startDateSelected = watch('startDate');
  const endDateSelected = watch('endDate');

  // Get couriers
  const couriers = useSelector((state: RootState) => state.couriers);
  useEffect(() => {
    if (couriers.status === 'idle') {
      dispatch(fetchCouriers())
    }
  }, [couriers.status, dispatch]);

  // Get branches
  const branches = useSelector((state: RootState) => state.branches);
  useEffect(() => {
    if (branches.status === 'idle') {
      dispatch(fetchBranches())
    }
  }, [branches.status, dispatch]);

  // Form submit handler
  const onSubmit = (data: FormData) => {
    const { branchesSelected, couriersSelected, endDate, startDate } = data;
    const seacrhParams = {
      endDate: endDate.toISOString().slice(0, 10),
      startDate: startDate.toISOString().slice(0, 10),
      branches: branchesSelected.filter((branch) => branch),
      couriers: couriersSelected.filter((branch) => branch),
    };

    // Se obtienen los datos para el gráfico de cantidad de órdenes por courier
    ordersByCouriers.getData(seacrhParams);

    // Se obtienen los datos para el gráfico % de ordenes entregadas a tiempo
    ordersDeliveriesPercentageOnTime.getData(seacrhParams);

    // Se obtienen los datos para el gráfico % de ordenes con alguna incidencia
    ordersDeliveriesPercentageIssues.getData(seacrhParams);

    // // Se obtienen los datos para el gráfico cantidad de ordenes en progreso y con incidencias
    ordersProgressIssues.getData(seacrhParams);

    // Se obtienen los datos para el gráfico cantidad de ordenes por region
    ordersByRegion.getData(seacrhParams);

    // Se obtienen los datos para el gráfico cantidad de ordenes agrupadas por peso
    ordersGroupByWeight.getData(seacrhParams);

    // Se obtienen los datos para el gráfico cantidad de ordenes entregadas agrupadas por días
    ordersGroupByDay.getData(seacrhParams);

    // Se obtienen los datos para el gráfico cantidad de ordenes agrupadas por días
    ordersCreatedGroupByDay.getData(seacrhParams);
  };

  const formValues = watch(['startDate', 'endDate', 'couriersSelected', 'branchesSelected'])
  const exportData = () => {
    const [startDate, endDate, branchesSelected, couriersSelected] = formValues;
    const seacrhParams = {
      endDate: endDate.toISOString().slice(0, 10),
      startDate: startDate.toISOString().slice(0, 10),
      branches: branchesSelected.filter((branch) => branch),
      couriers: couriersSelected.filter((branch) => branch),
    };
    reportGeneralData.exportData(seacrhParams);
  }


  return <div className="relative  mb-20">
    <PageHeader title={t('report.title')} />
    <h1>Reportes</h1>
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-3 gap-3">

        <div className="shadow-md bg-white text-darjk  my-4 rounded-t-lg">
          <div className="bg-black text-white text-center py-2 rounded-t-lg">Fechas</div>
          <div className="grid grid-cols-2 gap-2 m-2">
            <div>

              <label
                htmlFor="startDate"
                className="block text-sm font-bold text-gray-700"
              >
                <span>
                  {t('report.startDate')}*
                </span>
                <div className="mt-1">
                  <DatePicker
                    id="startDate"
                    selected={startDateSelected}
                    onChange={
                      (date: Date) => {
                        setValue('startDate', date)
                      }
                    }
                    maxDate={new Date()}
                    startDate={new Date()}
                    dateFormat="dd/MM/yyyy"
                  />
                </div>
              </label>
            </div>
            <div>
              <label
                htmlFor="endDate"
                className="block text-sm font-bold text-gray-700"
              >
                <span>
                  {t('report.endDate')}*
                </span>
                <div className="mt-1">
                  <DatePicker
                    id="endDate"
                    selected={endDateSelected}
                    onChange={
                      (date: Date) => {
                        setValue('endDate', date)
                      }
                    }
                    maxDate={new Date()}
                    startDate={new Date()}
                    dateFormat="dd/MM/yyyy"
                  />
                </div>
              </label>
            </div>
          </div>

          <div className="grid grid-cols-2 mt-8">

            <div>


              <Button
                variant="dark"
                shape="square"
                iconPosition="right"
                type="submit"
                disabled={reportGeneralData.status === 'loading'}
                className="w-full"
                onClick={undefined}
                label="Graficar"
                icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605" />
                </svg>}
              />
            </div>


            <div>

              <Button
                shape="square"
                iconPosition="right"
                type="button"
                disabled={reportGeneralData.status === 'loading'}
                className="w-full"
                onClick={exportData}
                label="Exportar"
                icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                </svg>
                }
              />


            </div>
          </div>

          {(
            ordersDeliveriesPercentageOnTime.status === 'loading' ||
            ordersDeliveriesPercentageIssues.status === 'loading' ||
            ordersProgressIssues.status === 'loading' ||
            ordersByCouriers.status === 'loading' ||
            ordersGroupByWeight.status === 'loading' ||
            ordersGroupByDay.status === 'loading' ||
            reportGeneralData.status === 'loading'
          ) && <LoadingAnimated message={t('common.loading')} />}



        </div>

        <div className="shadow-md bg-white text-darjk my-4 rounded-t-lg">
          <div className="bg-black text-white text-center py-2 rounded-t-lg">Courier</div>
          <div className="w-full pl-3 overscroll-contain overflow-auto h-48 space-y-4 text-slate-700 dark:text-slate-400 text-sm sm:text-base sm:leading-7 pt-4">
            <div className="grid grid-cols-2 gap-2">

              {couriers.data.map((courier, index) => (
                <div key={courier.id} className="h-10">
                  <td
                    className="pr-6 text-left whitespace-no-wrap text-sm tracking-normal leading-4">
                    <label
                      htmlFor={courier.id}
                      className="block text-sm font-medium text-gray-700 cursor-pointer"
                    >
                      <input
                        id={courier.id}
                        value={courier.id}
                        type="checkbox"
                        role="switch"
                        {...register(`couriersSelected.${index}`, {})}
                        className="cursor-pointer mr-4 relative w-5 h-5 border rounded border-gray-400 bg-white  focus:outline-none focus:outline-none focus:ring-2  focus:ring-gray-400"
                      />
                      {courier.name}
                    </label>
                  </td>
                </div>
              ))}


            </div>
            {couriers.status === 'loading' && <LoadingAnimated message={t('common.loading')} />}
          </div>
        </div>


        <div className="shadow-md bg-white text-darjk my-4 rounded-t-lg">
          <div className="bg-black text-white text-center py-2 rounded-t-lg">Tiendas</div>
          <div className="w-full pl-3 overscroll-contain overflow-auto h-48 space-y-4 text-slate-700 dark:text-slate-400 text-sm sm:text-base sm:leading-7 pt-4">

            <div className="grid grid-cols-2 gap-2">
              {branches.data.map((branch, index) => (
                <div key={branch.id} className="h-10">

                  <label
                    htmlFor={branch.id}
                    className="block text-sm font-medium text-gray-700 cursor-pointer"
                  >
                    <input
                      id={branch.id}
                      value={branch.id}
                      type="checkbox"
                      role="switch"
                      {...register(`branchesSelected.${index}`, {})}
                      className="cursor-pointer mr-4 relative w-5 h-5 border rounded border-gray-400 bg-white  focus:outline-none focus:outline-none focus:ring-2  focus:ring-gray-400"
                    />
                    {branch.name}
                  </label>
                </div>
              ))}
            </div>



            {branches.status === 'loading' && <LoadingAnimated message={t('common.loading')} />}
          </div>
        </div>
      </div>
    </form >

    <div className="grid grid-cols-1 gap-1 shadow-md text-darjk my-4 rounded-t-lg">
      <div className="bg-black text-white text-center py-2 rounded-t-lg">
        <ToolTip title="Órdenes creadas por courier" message="Órdenes acumuladas por courier en el rango de tiempo según fecha de creación" />
      </div>
      <OrdersByCouriers options={ordersByCouriers.data} type="chart" />
    </div>

    <div className="grid grid-cols-2 gap-2 shadow-md text-darjk my-4 rounded-t-lg">
      <div>
        <div className="bg-black text-white text-center py-2 rounded-t-lg">
          <ToolTip title="Porcentaje entregas a tiempo" message="Porcentaje de entregas a tiempo vs total de órdenes creadas en el rango de tiempo según fecha de creación" />
        </div>
        <PercentageDeliveryOnTime options={ordersDeliveriesPercentageOnTime.data} type="chart" />
      </div>
      <div>
        <div className="bg-black text-white text-center py-2 rounded-t-lg">
          <ToolTip title="Porcentaje incidencias por courier" message="Porcentaje de entregas en incidencia vs total de órdenes creadas en el rango de tiempo según fecha de creación" />
        </div>
        <IncidentPercentage options={ordersDeliveriesPercentageIssues.data} type="chart" />

      </div>
    </div>

    <div className="grid grid-cols-1 gap-1 shadow-md text-darjk my-4 rounded-t-lg">
      <div className="bg-black text-white text-center py-2 rounded-t-lg">
        <ToolTip title="Entregas por día" message="Acumulado de órdenes entregadas según fecha de creación" />
      </div>
      <DeliveriesPerDay options={ordersGroupByDay.data} type="chart" />
    </div>

    <div className="grid grid-cols-1 gap-1 shadow-md text-darjk my-4 rounded-t-lg">
      <div className="bg-black text-white text-center py-2 rounded-t-lg">
        <ToolTip title="Creadas por día" message="Acumulado de órdenes creadas según fecha de creación" />
      </div>
      <DeliveriesPerDay options={ordersCreatedGroupByDay.data} type="chart" />
    </div>

    <div className="grid grid-cols-1 gap-1 hadow-md text-darjk my-4 rounded-t-lg justify-items-center">
      <div className="bg-black text-white text-center py-2 rounded-t-lg w-full">
        <ToolTip title="Órdenes por status" message="Estado de órdenes total en el rango de tiempo según fecha de creación" />
      </div>
      <div className="grid grid-cols-4 gap-4 hadow-md text-darjk p-4 my-4 rounded-t-lg justify-items-center w-full">

        <OrdersInProcess title="Creadas" value={ordersProgressIssues.data.shipmentCreated} />
        <OrdersInProcess title="En transito" value={ordersProgressIssues.data.shipmentInTransit} />
        <OrdersInProcess title="Entregadas" value={ordersProgressIssues.data.shipmentDelivered} />
        <OrdersInProcess title="Fallidas" value={ordersProgressIssues.data.shipmentFailed} />

      </div>
    </div>

    <div className="grid grid-cols-1 gap-1 hadow-md text-darjk my-4 rounded-2xl">
      <div>
        <div className="bg-black text-white text-center py-2 rounded-t-lg">
          <ToolTip title="Regiones de destino" message="Frecuencia de regiones de destino de órdenes" />
        </div>
        <DestinationRegions options={ordersByRegion.data} type="chart" />
      </div>
    </div>

    <div className="grid grid-cols-1 gap-1 hadow-md text-darjk p-4 rounded-t-lg">
      <div className="bg-black text-white text-center py-2 rounded-t-lg">
        <ToolTip title="Peso de paquete" message="Frecuencia de pesos volumétricos en el rango de tiempo según fecha de creación" />
      </div>
      <PackageWeights options={ordersGroupByWeight.data} type="chart" />
    </div>

  </div >
}