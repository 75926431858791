import { useSelector } from "react-redux"
import { Outlet } from "react-router-dom";
import LoadingAnimated from '../../../components/elements/Loading/index';
import PageHeader from '../../../components/PageHeader/index';
import { RootState } from '../../../store';
import TrackingWidgetForm from './TrackingWidgetForm';




export default function ListWidget() {
    const trackings = useSelector((state: RootState) => state.trackings);

    return (
        <>
        
        <div className="w-full">
            <PageHeader title="Tracking" />
            <div className="relative text-black shadow-md bg-gray-100 p-6">
                <TrackingWidgetForm />
            </div>

            <div className="mt-5">
                {trackings.status === 'loading' && (
                    <LoadingAnimated message="Cargando..." />
                )}
                {trackings.status === 'success' && trackings.data.length > 0 ? (
                    <div className="mb-12">
                        <div>
                            <div className="flex bg-black text-white font-bold">
                                <div className="w-1/3 p-2">Proveedor</div>
                                <div className="w-1/3 p-2">
                                    Nombre Remitente
                                </div>

                                <div className="w-1/3 p-2">Calle de origen</div>
                                <div className="w-1/3 p-2">Nombre Receptor</div>

                                <div className="w-1/3 p-2 text-right">
                                    Calle de destino
                                </div>
                                <div className="w-1/3 p-2 text-right">
                                    Precio
                                </div>
                                <div className="w-1/3 p-2 text-right">
                                    Status
                                </div>
                            </div>
                            {trackings.data.map((shipment) => (
                                <div
                                    key={shipment.id}
                                    className="flex divide-x-2 border-b-2 border-b-gray-100"
                                >
                                    <div className="w-1/3 p-2 ">
                                        {shipment.courier?.courierCode}
                                    </div>
                                    <div className="w-1/3 p-2">
                                        {shipment.senderName}
                                    </div>

                                    <div className="w-1/3 p-2">
                                        {shipment.originStreet}
                                    </div>

                                    <div className="w-1/3 p-2">
                                        {shipment.receiverName}
                                    </div>
                                    <div className="w-1/3 p-2 text-right">
                                        {shipment.destinationStreet}
                                    </div>
                                    <div className="w-1/3 p-2 text-right">
                                        {shipment.price}
                                    </div>
                                    <div className="w-1/3 p-2 text-right">
                                        {shipment.status}
                                    </div>
                                </div>
                            ))}
                        </div>

                        {trackings.data[0].statusLog &&
                            trackings.data[0].statusLog.length > 0 && (
                                <>
                                    <div className="mt-5">
                                        <PageHeader title="Estados anteriores" />
                                    </div>

                                    <div className="flex bg-black text-white font-bold mt-5">
                                        <div className="w-1/3 p-2">
                                            Fecha Creación
                                        </div>
                                        <div className="w-1/3 p-2">
                                            Fecha Actualización
                                        </div>
                                        <div className="w-1/3 p-2">
                                            Estado anterior
                                        </div>
                                        <div className="w-1/3 p-2">
                                            Nuevo estado
                                        </div>
                                    </div>
                                </>
                            )}

                        {trackings.data.map(
                            (e) =>
                                e.statusLog &&
                                e.statusLog?.length > 0 &&
                                e.statusLog.map((data) => (
                                    <div
                                        key={data.id}
                                        className="flex divide-x-2 border-b-2 border-b-gray-100"
                                    >
                                        <p className="w-1/3 p-2 ">
                                            {data.createdAt}
                                        </p>
                                        <p className="w-1/3 p-2 ">
                                            {data.updatedAt}
                                        </p>
                                        <p className="w-1/3 p-2 ">
                                            {data.lastStatus}
                                        </p>
                                        <p className="w-1/3 p-2 ">
                                            {data.newStatus}
                                        </p>
                                    </div>
                                ))
                        )}
                    </div>
                ) : (
                    trackings.status !== 'loading' &&
                    trackings.status !== 'idle' && (
                        <div className="w-full text-center">
                            No se encontraron datos
                        </div>
                    )
                )}
            </div>
        </div>
                    <Outlet />
        </>

    );
}
