/* eslint-disable react/jsx-props-no-spreading */
import {
    useEffect,
    useState,
} from 'react';

import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ErrorMessage } from '@hookform/error-message';
import SideCreate from '../../../../components/elements/SideCreate';

import { RootState } from '../../../../store';
import { Button } from '../../../../components/elements/Buttons';
import { fetchArticles } from '../../../../modules/article/features/articlesSlice';
import { fetchCategories } from '../../../../modules/category/features/categoriesSlice';
import { createArticle, resetCreateArticle } from '../../../../modules/article/features/articleSlice';

type FormData = {
    name: string;
	description: string;
	categoryId: string
};

const nameRegex = /^[a-zA-Z ]{2,30}$/;

export default function CreateArticle({
    show = false,
    toggle,
}: {
    show: boolean;
    toggle: () => void;
}) {
    const dispatch = useDispatch();
    const article = useSelector((state: RootState) => state.article);
    const categories = useSelector((state: RootState) => state.categories);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<FormData>();

    const { t } = useTranslation();
    const [alertSucess, setAlertSucess] = useState(false);

    const [success, setSuccess] = useState(false);

	useEffect(() => {
	  if(categories.status === 'idle'){
		dispatch(fetchCategories())
	  }

	}, [dispatch, categories.status])
	
    const onSubmit = (data: FormData) => {
        dispatch(
            createArticle({
                name: data.name,
				description: data.description,
				categoryId: data.categoryId
            })
        );
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onHide = () => {
        reset();
        toggle();
    };

    const navigate = useNavigate();

    useEffect(() => {
        if (article.status === 'success') {
            setSuccess(true);
        } else {
            setSuccess(false);
        }
    }, [alertSucess, navigate, article.status, dispatch, onHide]);

    const closeAlertSucess = () => {
        setAlertSucess(false);
    };

    return (
        <SideCreate show={show} title='Crear Artículo' toggle={onHide}>
            {success && (
                <div className="text-center">
                    <p className="text-xl text-green-700 font-bold mb-4">
                        Artículo creado exitosamente
                    </p>
                    <div className="flex space-x-6 justify-center">
                        <Button
                            label={t('common.done')}
                            icon={
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M5 13l4 4L19 7"
                                    />
                                </svg>
                            }
                            onClick={() => {
                                setSuccess(false);
                                reset();
								dispatch(resetCreateArticle());
								dispatch(fetchArticles());
                                toggle();
                            }}
                            variant="primary"
                        />
                        <Button
                            label='Crear otro artículo'
                            onClick={() => {
                                setSuccess(false);
                                dispatch(resetCreateArticle());
                                dispatch(fetchArticles());
                                reset();
                            }}
                            variant="info"
                        />
                    </div>
                </div>
            )}

            {!success && (
                <form onSubmit={handleSubmit(onSubmit)}>

                    <div className="flex-none w-full md:flex md:justify-evenly md:space-x-4">
                        <div className="mt-2 w-full">
                            <label
                                htmlFor="name"
                                className="block text-sm font-medium text-gray-700"
                            >
                                Nombre de artículo
                                <div className="mt-1">
                                    <input
                                        id="name"
                                        type="text"
                                        className={`${
                                            errors.name
                                                ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                : ''
                                        }`}
                                        autoComplete="name"
                                        {...register('name', {
                                            required: true,
                                            validate: (value) =>
                                                nameRegex.test(value),
                                        })}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="name"
                                        render={() =>
                                            errors.name?.type ===
                                            'validate' ? (
                                                <p className="text-red-600 ml-2">
                                                    Ingrese un nombre valido
                                                </p>
                                            ) : (
                                                <p className="text-red-600 ml-2">
                                                    {t(
                                                        'common.errors.fieldRequired'
                                                    )}
                                                </p>
                                            )
                                        }
                                    />
                                </div>
                            </label>
                        </div>
                    </div>

                    <div className="flex-none w-full md:flex md:justify-evenly md:space-x-4">
                        <div className="mt-2 w-full">
                            <label
                                htmlFor="description"
                                className="block text-sm font-medium text-gray-700"
                            >
                                Descripción *
                                <div className="mt-1">
                                    <textarea
                                        id="description"
                                        rows={4}
										cols={50}
                                        className={`${
                                            errors.name
                                                ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                : ''
                                        }`}
                                        autoComplete="description"
                                        {...register('description', {
                                            required: true,
                                        })}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="description"
										render={() => (
											<p className="text-red-600 ml-2">
												{article.error}
											</p>
										)}
                                    />
                                </div>
                            </label>
                        </div>
                    </div>

           
					<div
                        className="block text-sm font-medium text-gray-700 mt-5"
                    >
                        Categoria *
                        <select
                            id="category"
                            {...register('categoryId', {
                                required: true,
                            })}
                        >
                            <option value=''>
                                Seleccione una categoría
                            </option>
                            {categories.data.length > 0 ? (
                                categories.data.map(
                                    (c) =>
                                        
										categories.data.length > 0 && (
                                            <option
                                                key={c.id}
                                                value={c.id}
                                            >
                                                {c.name}
                                            </option>
                                        )
                                )
                            ) : (
                                <option>No se encontraron resultados</option>
                            )}
                        </select>
                    </div>
					
                    <div className="mt-4">
                        {article.status === 'error' && (
                            <ErrorMessage
                                name="category invited"
                                errors={errors}
                                render={() => (
                                    <p className="text-red-600 ml-2">
                                        {article.error}
                                    </p>
                                )}
                            />
                        )}
                        {/* {userInvite.status === 'success' && (
                            <p className="text-green-600 mb-2">
                                {t('common.done')}
                            </p>
                        )} */}
                        <button
                            disabled={article.status === 'loading'}
                            type="submit"
                            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                        >
                            {
                                // eslint-disable-next-line no-nested-ternary
                                article.status === 'loading'
                                    ? t('wait.message')
                                    : 'Crear'
                            }
                        </button>
                    </div>

                    {article.status === 'error' && (
                        <p className="text-red-600 ml-2 mt-4">Algo salio mal</p>
                    )}
                </form>
            )}

            {alertSucess && (
                <div
                    className="z-50 fixed right-10 top-24  bg-green-100 inline-flex w-1/2  border border-green-400 text-green-700 px-4 py-3 rounded"
                    role="alert"
                >
                    <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="check-circle"
                        className="w-4 h-auto mr-2 fill-current"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                    >
                        <path
                            fill="currentColor"
                            d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
                        />
                    </svg>
                    <strong className="font-bold mr-2">Sucess!</strong>
                    <span className="block sm:inline">Creado exitosamente</span>
                    <span
                        className="absolute top-0 bottom-0 right-0 px-4 py-3"
                        onClick={() => closeAlertSucess()}
                        onKeyPress={() => closeAlertSucess()}
                        role="button"
                        tabIndex={0}
                    >
                        <svg
                            className="fill-current h-6 w-6 text-green-500"
                            role="button"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                        >
                            <title>Close</title>
                            <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                        </svg>
                    </span>
                </div>
            )}
        </SideCreate>
    );
}
