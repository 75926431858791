/* eslint-disable no-restricted-syntax */
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

import { RootState, useAppDispatch } from '../../../../store';
import { IFileType } from '../components/ProcessFile';
import {
    shipmentMergeLabels,
    clearShipmentMergeLabel,
} from '../../../../modules/shipments/features';

interface UseDownloadLabelsProps {
    shipmentsDraft: IFileType[];
}

export default (props: UseDownloadLabelsProps) => {
    const { shipmentsDraft } = props;
    const dispatch = useAppDispatch();

    // DOWNLOAD LABELS
    const downloadLabels = () => {
        const shipmentIds: string[] = [];
        for (const item of shipmentsDraft) {
            if (item.createShipmentResponse && item.createShipmentResponse.id) {
                shipmentIds.push(item.createShipmentResponse.id);
            }
        }
        dispatch(shipmentMergeLabels(shipmentIds));
    };
    const shipmentsLabelsMerged = useSelector(
        (state: RootState) => state.shipmentsLabelsMerged
    );
    useEffect(() => {
        if (shipmentsLabelsMerged.status === 'success') {
            const a = document.createElement('a');
            a.href = `data:application/pdf;base64,${shipmentsLabelsMerged.data}`;
            a.download = 'etiquetas.pdf';
            a.click();
            dispatch(clearShipmentMergeLabel());
        }
    }, [shipmentsLabelsMerged, dispatch]);

    return { downloadLabels, shipmentsLabelsMerged };
};
