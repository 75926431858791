import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IBranch } from '../interfaces';
import { BranchOnUserRole } from '../enums/BranchOnUserRole';
import { IUser } from '../../users/interfaces/index';

enum Status {
    idle = 'idle',
    loading = 'loading',
    loadingAction = 'loading_action',
    successGet = 'success_get',
    successAction = 'success_action',
    error = 'error',
}

interface IBranchPayload {
    id: string;
    name: string;
    email: string;
    phone: string;
    address: string;
    addressNumber: string;
    city: string;
    comunaId: string;
    regionId: string;
    userId: IUser[];
    users: {
        userId: string;
        role: BranchOnUserRole.OWNER;
        // branchId: string;
    }[];
    couriersFee: {
        id?: string;
        courierId: string;
        fee: number;
        percentage: number;
    }[];
    courierAPIKey: {
        courierId: string;
        apiKeys: {
            id?: string;
            name: string;
            description: string;
            value: string;
        }[];
    }[];
}

export interface BranchState {
    data: IBranch | null;
    status: Status;
    error: string | null;
}

const initialState: BranchState = {
    data: null,
    status: Status.idle,
    error: null,
};

export const fetchBranchById = createAsyncThunk(
    'branch/getById',
    async (id: string) => {
        const response = await APIClient.getData(`/branch/${id}`);
        return response.data;
    }
);

export const updateBranch = createAsyncThunk(
    'branch/update',
    async (payload: IBranchPayload) => {
        const mapPayload = {
            name: payload.name,
            email: payload.email,
            phone: payload.phone,
            address: payload.address,
            addressNumber: payload.addressNumber,
            city: payload.city,
            comunaId: +payload.comunaId,
            regionId: +payload.regionId,
            countryId: 1,
            branchFeeByCourier: payload.couriersFee,
            courierAPIKey: payload.courierAPIKey,
            users: payload.users,
        };
        const response = await APIClient.putData(
            `/branch/${payload.id}`,
            mapPayload
        );
        // payload.userId.forEach(async (user) => {
        //     const mapPayloadBranchOnUser = {
        //         userId: user.id,
        //         branchId: response.data.id.toString(),
        //         role: BranchOnUserRole.OWNER,
        //     };
        //     await APIClient.postData('/branches-users', mapPayloadBranchOnUser);
        // });
        return response.data;
    }
);

export const createBranch = createAsyncThunk(
    'branch/create',
    async (payload: IBranchPayload) => {
        const mapPayload = {
            name: payload.name,
            email: payload.email,
            phone: payload.phone,
            address: payload.address,
            city: payload.city,
            comunaId: +payload.comunaId,
            regionId: +payload.regionId,
            countryId: 1,
            branchFeeByCourier: payload.couriersFee,
            addressNumber: payload.addressNumber,
            courierAPIKey: payload.courierAPIKey,
            users: payload.users,
        };
        const response = await APIClient.postData('/branch', mapPayload);

        // payload.userId.forEach(async (user) => {
        //     const mapPayloadBranchOnUser = {
        //         userId: user.id,
        //         branchId: response.data.id.toString(),
        //         role: BranchOnUserRole.OWNER,
        //     };
        //     await APIClient.postData('/branches-users', mapPayloadBranchOnUser);
        // });
        return response.data;
    }
);

export const branchSlice = createSlice({
    name: 'branch',
    initialState,
    reducers: {
        resetBranch: (state) => {
            state.data = null;
            state.status = Status.idle;
            state.error = null;
        },
    },
    extraReducers(builder) {
        builder
            // GET BY ID
            .addCase(fetchBranchById.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(fetchBranchById.fulfilled, (state, action) => {
                state.status = Status.successGet;
                state.data = action.payload;
            })
            .addCase(fetchBranchById.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            })
            // UPDATE
            .addCase(updateBranch.pending, (state) => {
                state.status = Status.loadingAction;
            })
            .addCase(updateBranch.fulfilled, (state, action) => {
                state.status = Status.successAction;
                state.data = action.payload;
            })
            .addCase(updateBranch.rejected, (state, action) => {
                state.status = Status.successAction;
                state.error = action.error.message ?? null;
            })
            // CREATE
            .addCase(createBranch.pending, (state) => {
                state.status = Status.loadingAction;
            })
            .addCase(createBranch.fulfilled, (state, action) => {
                state.status = Status.successAction;
                // Add any fetched data to the array
                state.data = action.payload;
            })
            .addCase(createBranch.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            });
    },
});
export const { resetBranch } = branchSlice.actions;
