import formatCurrency from "../../utils/formatCurrency";

interface IPriceDetailsProps {
  price: number,
  fee: number,
  courierPrice: number,
  insurance: number,
  declaredValue: number,
  discount: number,
  discountIsPorcent: boolean
}

export default function PriceDetails(props: IPriceDetailsProps) {
  const { price, fee, courierPrice, insurance, declaredValue = 0, discount = 0, discountIsPorcent } = props;
  return (<>
    <p>
      <strong className="text-xl">
        {formatCurrency(price)}
      </strong>
    </p>
    <span className="tooltiptext">

      <p>
        <span className="text-sm">
          Valor declarado: {" "}
          {formatCurrency(declaredValue)}
        </span>
      </p>

      <p>
        <span className="text-sm">Fee: {" "}
          {formatCurrency(fee)}
        </span>
      </p>

      <p>
        <span className="text-sm">
          Seguro: {" "}
          {formatCurrency(insurance)}
        </span>
      </p>

      <p>
        <span className="text-sm">
          Descuento: {" "}
          {discountIsPorcent ? `${discount}%` : formatCurrency(discount)}
        </span>
      </p>

      <p>
        <span className="text-sm">
          Precio courier: {" "}
          {formatCurrency(courierPrice)}
        </span>
      </p>
    </span>
  </>);
}