import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client2';
import { ReportOrdersByCourier, ReportPostParams } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface ReportOrdersByCourierState {
    data: ReportOrdersByCourier[];
    status: Status;
    error: string | null;
}

const initialState: ReportOrdersByCourierState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const getReportOrdersByCourier = createAsyncThunk(
    'report/reportOrdersByCourier',
    async (payload: ReportPostParams) => {
        const response = await APIClient.postData(
            '/report/shipments/courier',
            payload
        );
        return response.data;
    }
);

export const reportOrdersByCourierSlice = createSlice({
    name: 'reportOrdersByCourier',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getReportOrdersByCourier.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(getReportOrdersByCourier.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = action.payload;
            })
            .addCase(getReportOrdersByCourier.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            });
    },
});
