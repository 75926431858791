import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IBranchOnUser } from '../interfaces/IBranchOnUser';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface BranchOnUserState {
    data: IBranchOnUser[] | null;
    branchOnUser: IBranchOnUser[] | null;
    status: Status;
    error: string | null;
}

const initialState: BranchOnUserState = {
    data: null,
    branchOnUser: [],
    status: Status.idle,
    error: null,
};

export const fetchBranchOnUserById = createAsyncThunk(
    'branchOnUser/getById',
    async (id: string) => {
        const response = await APIClient.getData(`/branches-users/${id}`);
        return response;
    }
);

export const branchesOnUserGetByIgSlice = createSlice({
    name: 'branchesOnUsers',
    initialState,
    reducers: {
        resetBranchOnUser: (state) => {
            state.branchOnUser = [];
            state.data = [];
            state.status = Status.idle;
            state.error = null;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchBranchOnUserById.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(fetchBranchOnUserById.fulfilled, (state, action) => {
                state.status = Status.success;
                state.branchOnUser = action.payload.branchOnUser;
            })
            .addCase(fetchBranchOnUserById.rejected, (state, action) => {
                state.status = Status.success;
                state.error = action.error.message ?? null;
            });
    },
});
export const { resetBranchOnUser } = branchesOnUserGetByIgSlice.actions;
