import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IClient } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface BranchClientById {
    data: IClient | null;
    status: Status;
    error: string | null;
}

const initialState: BranchClientById = {
    data: null,
    status: Status.idle,
    error: null,
};

export const fetchClientById = createAsyncThunk(
    'clients/fetchClientById',
    async (id: string): Promise<IClient> => {
        const response = await APIClient.getData(`/clients/${id}`);
        return response.data;
    }
);

export const singleClientSlice = createSlice({
    name: 'client',
    initialState,
    reducers: {
        resetClient: (state) => {
            state.status = initialState.status;
            state.error = initialState.error;
            state.data = initialState.data;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchClientById.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(fetchClientById.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = action.payload;
            })
            .addCase(fetchClientById.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            });
    },
});

export const { resetClient } = singleClientSlice.actions;
