/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unstable-nested-components */
import { useSelector } from "react-redux";
import { useEffect, useMemo } from "react";
import { useTranslation } from 'react-i18next';
import { Column, useTable } from "react-table";
import { useNavigate } from "react-router-dom";

import { fetchNotificationsConfig, resetNotificationsConfig } from '../../../modules/notificationConfig/features/notificationsConfigSlice';
import { RootState, useAppDispatch } from "../../../store";
import PageHeader from '../../../components/PageHeader';
import { INotification } from "../../../modules/notificationConfig/interfaces";
import { Button } from "../../../components/elements/Buttons";
import { Bagde } from '../../../components/Shipment/Badges';
import { BagdeActive } from '../../../components/Notify/Badges';
import LoadingAnimated from '../../../components/elements/Loading';

export default function RegionAndComuna() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  // GET REGIONS AND COMUNA FROM REDUX
  const notificationsConfig = useSelector((state: RootState) => state.notificationsConfig);

  useEffect(() => {

    if (notificationsConfig.status === 'idle') {
      dispatch(fetchNotificationsConfig());
    }
    return () => {
      if (notificationsConfig.status === 'success') {
        dispatch(resetNotificationsConfig());
      }
    }
  }, [notificationsConfig, dispatch]);
  // FETCH REGION AND COMUNA DATA FROM API - FINISH

  // CONFIG TABLE

  const columns = useMemo<Column<INotification>[]>(
    () => [
      {
        Header: t('notificationsConfig.statusToNotify').toString(),
        accessor: 'statusToNotify',
        Cell: ({ cell: { value } }: any) => {
          if (value) {
            return <Bagde status={value} />;
          }
          return '';
        },
      },
      {
        Header: t('notificationsConfig.type').toString(),
        accessor: 'type',
        Cell: ({ cell: { value } }: any) => {
          if (value) {
            return value;
          }
          return '';
        },
      },
      {
        Header: t('notificationsConfig.activeTitle').toString(),
        accessor: 'active',
        Cell: ({ cell: { value } }: any) => <BagdeActive status={value} />,
      },
    ],
    [t]
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows
  } = useTable(
    {
      columns,
      data: notificationsConfig.data,

      initialState: {
        pageSize: 100,
        pageIndex: 0,
      },
    }
  );

  const navigate = useNavigate();
  const tableOnclickHandler = (data: INotification) => {
    navigate(`/notify/update/${data.id}`);
  }


  return <div className="relative  mb-20">
    <PageHeader title={t('notificationsConfig.title')} />
    <Button
      className="absolute right-0 top-0 z-10 rounded-3xl font-bold"
      label={t('notificationsConfig.newConfig')}
      type="button"
      onClick={() => navigate('/notify/create')}
    />
    <div className="shadow-md mb-10">

      <table
        className="table-auto w-full mx-auto"
        {...getTableProps()}
      >
        <thead className="text-white ">
          {headerGroups.map((headerGroup) => (
            <tr
              className="text-left "
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column) => (
                <th
                  className="px-2 py-2 border-2 border-white rounded-lg  text-center"
                  style={{ minWidth: '2%' }}
                >
                  <div className="bg-sym-mbe rounded-lg p-2 text-center">
                    {column.render('Header')}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            // Prepare the row for display

            prepareRow(row);

            return (
              // Apply the row props

              <tr
                className="odd:bg-sym-mbe-seconday hover:bg-gray-300 cursor-pointer"
                {...row.getRowProps()}
                onClick={() =>
                  tableOnclickHandler(
                    row.original
                  )
                }
              >
                {
                  // Loop over the rows cells

                  row.cells.map((cell) => (
                    // Apply the cell props
                    <td
                      className="py-4 px-2 "
                      {...cell.getCellProps()}
                    >
                      {
                        // Render the cell contents

                        cell.render('Cell')
                      }
                    </td>
                  ))
                }
              </tr>
            );
          })}
          <tr className="odd:bg-sym-mbe-seconday hover:bg-gray-300">
            <td colSpan={10000}>
              {notificationsConfig.status === 'loading' && <LoadingAnimated message={t('common.loading')} />}
            </td>
          </tr>
        </tbody>
      </table>

    </div>
  </div>
}