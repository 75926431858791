import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IBranch } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface BranchOnUserState {
    data: IBranch | null;
    status: Status;
    error: string | null;
}

const initialState: BranchOnUserState = {
    data: null,
    status: Status.idle,
    error: null,
};

export const branchesOnUsesrDeleteById = createAsyncThunk(
    'branchOnUser/deleteById',
    async (id: string) => {
        const mapPayload = {
            id
        };
        const response = await APIClient.postData(`/branches-users/${id}`, mapPayload);
        return response;
    }
);

export const branchesOnUsersDeleteByIdgSlice = createSlice({
    name: 'branchesOnUsersDelete',
    initialState,
    reducers: {
        resetBranchOnUserDelete: (state) => {
            state.data = null;
            state.status = Status.idle;
            state.error = null;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(branchesOnUsesrDeleteById.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(branchesOnUsesrDeleteById.fulfilled, (state, action) => {
                state.status = Status.success;
                state.data = action.payload.data;
            })
            .addCase(branchesOnUsesrDeleteById.rejected, (state, action) => {
                state.status = Status.success;
                state.error = action.error.message ?? null;
            });
    },
});
export const { resetBranchOnUserDelete } = branchesOnUsersDeleteByIdgSlice.actions;
