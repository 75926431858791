import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { ITrackingShipment } from '../interfaces/ITrackingShipment'


enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface TrackingState {
    data: ITrackingShipment[];
    status: Status;
    error: string | null;
}

const initialState: TrackingState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const fetchTracking = createAsyncThunk(
    'tracking/fetchTracking',
    async (params: string | undefined) => {
        const response = await APIClient.getData(`/shipments/tracking/courier/${params}`);
        return response.data;
    }
);

export const fetchTrackingAny = createAsyncThunk(
    'tracking/fetchTrackingAny',
    async (params: string | undefined) => {
        const response = await APIClient.getDataAny(`/shipments/tracking/courier/${params}`);
        return response.data;
    }
);

export const trackingSlice = createSlice({
    name: 'trackingSlice',
    initialState,

    reducers: {
        resetTracking: (state) => {
            state.data = [];
            state.status = Status.idle;
            state.error = null;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchTracking.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(fetchTracking.fulfilled, (state, action) => {
                state.status = Status.success;
                state.data = action.payload;
            })
            .addCase(fetchTracking.rejected, (state, action) => {
                state.status = Status.success;
                state.error = action.error.message ?? null;
            })
            .addCase(fetchTrackingAny.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(fetchTrackingAny.fulfilled, (state, action) => {
                state.status = Status.success;
                state.data = action.payload;
            })
            .addCase(fetchTrackingAny.rejected, (state, action) => {
                state.status = Status.success;
                state.error = action.error.message ?? null;
            });
    },
});

export const { resetTracking } = trackingSlice.actions;
