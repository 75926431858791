import { TFunction, useTranslation } from 'react-i18next';

const color = (status: boolean) => {
    switch (status) {
        case true:
            return 'bg-green-500 text-white';
        default:
            return 'bg-red-700 text-white';
    }
};

export const statusLabels = (
    status: boolean,
    t: TFunction<'translation', undefined>
) => {
    switch (status) {
        case true:
            return t('notificationsConfig.active');
        default:
            return t('notificationsConfig.inActive');
    }
};

export function BagdeActive({ status }: { status: boolean }) {
    const { t } = useTranslation();
    return (
        <div
            className={`text-center text-sm w-36 px-2 py-1 rounded-full mx-auto ${color(
                status
            )}`}
        >
            {statusLabels(status, t)}
        </div>
    );
}
