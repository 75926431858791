import { useTranslation } from 'react-i18next';

import PageHeader from '../../../components/PageHeader';
import BulkForm from './components/BulkForm'
import ShipmentsDraft from './components/ShipmentsDraft'
import ButtonActions from './components/ButtonActions'

import useCreateBulkShipment from './customHooks/useCreateBulkShipment';
import useCouriers from './customHooks/useCouriers';
import useFormData from './customHooks/useFormData';
import useCreateBulkQuote from './customHooks/useCreateBulkQuote';
import useDownloadLabels from './customHooks/useDownloadLabels';
import useDownloadquoteResult from './customHooks/useDownloadquoteResult';
import useChangeCourierSelected from './customHooks/useChangeCourierSelected';

export default function CreateBulkQuote() {

  const { t } = useTranslation();


  // CustomHook para obtener el listado de coueriers
  const { couriers, status } = useCouriers();

  // CustomHook para controlar el formulario donde se selecciona el archivo, el courier y el tipo de envío
  const {
    courierDeliveryTypes,
    shipmentsDraft,
    formState,
    fileErrors,
    checkList,
    courierSelected,
    shipmentType,
    setFileErrors,
    formRegister,
    formSetValue,
    setShipmentsDraft,
    handleSubmit,
  } = useFormData({ couriers });

  // CustomHook para controlar el cambio de selección del courier, para obligar al usuario que vuelva a cotizar
  useChangeCourierSelected({ courierSelected, setShipmentsDraft, shipmentsDraft, formSetValue })

  // CustomHook para enviar la petición de cotizar los envíos cargados en el archivo de excel
  const { createBulkQuote, createBulkQuoteStatus } = useCreateBulkQuote({
    setShipmentsDraft,
    shipmentsDraft,
  });

  // CustomHook para descargar el excel con el resultado de las cotizaciones
  const { setDownloadQuotesResults } = useDownloadquoteResult({ checkList, shipmentsDraft });

  // CustomHook para crear los envíos de las cotizaciones seleccionadas
  const {
    createShipmentBulk,
    setShipmentCreateBulkState,
    shipmentCreateBulkState,
    labelsGenerated,
    labelsFailed,
    shipmentsGenerated,
    resetAll: resetAllUseCreateBulkShipment
  } =
    useCreateBulkShipment({
      checkList,
      courierSelected,
      shipmentsDraft,
      shipmentType,
      setShipmentsDraft,
      formSetValue
    });

  // CustomHook para descargar las etiquetas de los envío generados correctamente
  const { downloadLabels, shipmentsLabelsMerged } = useDownloadLabels({ shipmentsDraft });

  const retryCreateShipments = () => {
    const shipmentsDraftCopy = shipmentsDraft
      .filter((item) => item.createShipmentResponse && item.createShipmentResponse.error === true)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map(({ createShipmentResponse, ...rest }) => ({ ...rest }));

    formSetValue('checkList', shipmentsDraftCopy.map(({ itemId }) => itemId));
    setShipmentsDraft(shipmentsDraftCopy);
    resetAllUseCreateBulkShipment();
  }

  return (
    <div className='mb-20'>
      <div className="grid grid-cols-3 gap-3">
        <div>
          <PageHeader title={t('quotes.create.title')} />
          <a
            className="mt-6"
            href="/cotizacion-masiva.xlsx"
            title="Download pdf document"
          >
            <button
              type="button"
              className="group relative flex justify-center py-2 px-4 border  text-sm font-medium rounded-md bg-white"
            >
              <div className="flex space-x-6 items-center">
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                    />
                  </svg>
                </span>
                <span>
                  Descargar plantilla de cotización
                </span>
              </div>
            </button>
          </a>
        </div>
      </div>

      <BulkForm
        {...{
          formRegister,
          courierDeliveryTypes,
          setFileErrors,
          formSetValue,
          setShipmentsDraft,
          couriersStatus: status,
          couriers,
          createBulkQuote,
          handleSubmit,
          shipmentsDraft,
          formState,
          resetAllUseCreateBulkShipment,
          shipmentsGenerated,
          shipmentCreateBulkState,
          fileErrors,
          createBulkQuoteStatus

        }}
      />
      {fileErrors.length > 0 && (
        <div className="bg-red-100 shadow-md my-10 p-4">
          <h3 className="text-lg">{t('common.errors.title')}</h3>
          {fileErrors.map((e) => (
            <p key={e} className="text-black">
              {e}
            </p>
          ))}
        </div>
      )}
      {shipmentsDraft.length > 0 && <ShipmentsDraft
        {...{
          shipmentsDraft, formRegister, createBulkQuoteStatus, checkList, formSetValue
        }}
      />}
      {shipmentsDraft.length > 0 && <ButtonActions {...{
        createShipmentBulk,
        shipmentCreateBulkState,
        checkList,
        shipmentsDraft,
        setShipmentCreateBulkState,
        shipmentsLabelsMerged,
        downloadLabels,
        setDownloadQuotesResults,
        labelsGenerated,
        labelsFailed,
        retryCreateShipments
      }} />}
    </div>
  );
}