import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../../../components/elements/Buttons"
import LoadingAnimated from "../../../../components/elements/Loading"
import { ShipmentMergeLabelsState } from "../../../../modules/shipments/features/shipmentMergeLabelsSlice";
import { IFileType } from "./ProcessFile";
import { AlertSuccess, AlertError } from '../../../../components/Alerts'

interface ButtonActionsProps {
  shipmentCreateBulkState: string;
  checkList: string[];
  shipmentsDraft: IFileType[];
  shipmentsLabelsMerged: ShipmentMergeLabelsState;
  labelsGenerated: number;
  labelsFailed: number;
  createShipmentBulk: () => Promise<void>;
  setShipmentCreateBulkState: Dispatch<SetStateAction<string>>;
  downloadLabels: () => void;
  setDownloadQuotesResults: Dispatch<SetStateAction<boolean>>;
  retryCreateShipments: () => void;
}

export default function ButtonActions(props: ButtonActionsProps) {
  const {
    shipmentCreateBulkState,
    shipmentsDraft,
    checkList,
    shipmentsLabelsMerged,
    labelsGenerated,
    labelsFailed,
    createShipmentBulk,
    setShipmentCreateBulkState,
    downloadLabels,
    setDownloadQuotesResults,
    retryCreateShipments
  } = props;

  const { t } = useTranslation();
  const labelsGeneratedMessage = `Se generaron correctamente (${labelsGenerated}) orde${labelsGenerated > 1 ? 'nes.' : 'n.'}`
  const labelsFailedMessage = `Falló la generación de (${labelsFailed}) orde${labelsFailed > 1 ? 'nes.' : 'n.'}`

  if (labelsGenerated > 0 || labelsFailed > 0) {
    return (
      <div className="grid grid-cols-2 justify-items-stretch">

        {labelsGenerated > 0 && <div className="px-4">
          <AlertSuccess message={labelsGeneratedMessage} fixed={false} title="" fullWidth >
            <Button
              type="submit"
              className="ml-4 mt-6"
              label={shipmentsLabelsMerged.status === 'loading' ? 'Generando etiquetas . . .' : `Descargar etiqueta${labelsGenerated > 1 ? 's' : ''}`}
              onClick={downloadLabels}
              disabled={shipmentsLabelsMerged.status === 'loading'}
              variant="success"
              icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
              </svg>
              }
            />
          </AlertSuccess>
        </div>
        }
        {labelsFailed > 0 &&
          <div className="px-4">
            <AlertError title="" message={labelsFailedMessage} fixed={false} fullWidth >
              <Button
                type="submit"
                className="ml-4 mt-6"
                label="Intentar de nuevo"
                onClick={retryCreateShipments}
                disabled={shipmentsLabelsMerged.status === 'loading'}
                variant="danger"
                icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                </svg>
                }
              />
            </AlertError>
          </div>
        }
      </div>
    )
  }

  return (
    <div className="grid grid-cols-2 justify-items-stretch">
      <div className="justify-self-start">
        {shipmentCreateBulkState === 'loading' && <LoadingAnimated message="Creando envíos . . ." />}
        {(shipmentCreateBulkState !== 'loading' && labelsGenerated === 0) && <Button
          type="submit"
          className="mt-6"
          disabled={
            shipmentsDraft.filter((item) => item.price !== undefined && item.price !== 0).length === 0 ||
            checkList.filter((item) => item).length === 0
          }
          label={t('shipments.bulkCreate')}
          onClick={() => {
            setShipmentCreateBulkState('loading');
            createShipmentBulk();
          }}
        />}

      </div>
      <div className="justify-self-end">
        {labelsGenerated === 0 && shipmentCreateBulkState !== 'loading' && <Button
          type="button"
          className="mt-6"
          disabled={
            shipmentsDraft.filter(
              (item) => item.price !== undefined && item.price !== 0
            ).length === 0 ||
            checkList.filter((item) => item).length === 0


          }
          label={t('common.downloadResults')}
          onClick={() => setDownloadQuotesResults(true)}
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
              />
            </svg>
          }
        />}
      </div>
    </div>
  );
}