/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { MouseEventHandler } from "react";

interface AlertSuccessProps {
  title: string,
  message: string,
  onClick?: MouseEventHandler<HTMLSpanElement>,
  fixed: boolean,
  children?: React.ReactNode,
  fullWidth?: boolean
}

export default function AlertSuccess({ title, message, onClick, fixed, children, fullWidth = false }: AlertSuccessProps) {
  return (<div
    className={`z-50 ${fixed ? 'fixed right-10 top-24' : ''}  bg-green-100 inline-flex ${fullWidth ? 'w-full' : 'w-1/2'}  border border-green-400 text-green-700 px-4 py-3 rounded`}
    role="alert"
  >
    <strong className="font-bold mr-2">{title}</strong>
    <span className="block sm:inline">
      {message}
      {children}
    </span>
    {onClick && <span
      className="absolute top-0 bottom-0 right-0 px-4 py-3"
      onClick={onClick}
      role="button"
      tabIndex={0}
    >
      <svg
        className="fill-current h-6 w-6 text-green-500"
        role="button"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <title>Cerrar</title>
        <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
      </svg>
    </span>}
  </div>)
}