import * as Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

interface OrdersByCouriersProps {
  options: unknown
  type: string
}

export default function DeliveriesPerDay(props: OrdersByCouriersProps) {
  const { options, type } = props;

  Highcharts.setOptions({
    lang: {
      decimalPoint: ',',
      thousandsSep: '.',
      loading: 'Espere...',
      months: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agostp',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ],
      weekdays: ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sábado'],
      shortMonths: ['En', 'Febr', 'Mzo', 'Abr', 'My', 'Jun', 'Jul', 'Agto', 'Sep', 'Oct', 'Nov', 'Dic'],
      rangeSelectorFrom: 'Desde',
      rangeSelectorTo: 'Hasta',
      rangeSelectorZoom: 'Periodo',
      downloadPNG: 'Download imagem PNG',
      downloadJPEG: 'Download imagem JPEG',
      downloadPDF: 'Download documento PDF',
      downloadSVG: 'Download imagem SVG',
      resetZoom: 'Reiniciar',
      resetZoomTitle: 'Reiniciar',
    },
  })

  return (<HighchartsReact highcharts={Highcharts} options={options} constructorType={type} />)
}