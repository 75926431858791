import * as Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'



interface OrdersByCouriersProps {
  options: unknown
  type: string
}
export default function OrdersByCouriers(props: OrdersByCouriersProps) {
  const { options, type } = props
  return (
    <HighchartsReact highcharts={Highcharts} options={options} constructorType={type} />
  )
}