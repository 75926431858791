import { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';

import { IFileType } from '../components/ProcessFile';

interface UseDownloadquoteResultProps {
    shipmentsDraft: IFileType[];
    checkList: string[];
}

export default (props: UseDownloadquoteResultProps) => {
    const { shipmentsDraft, checkList } = props;

    const [downloadQuotesResults, setDownloadQuotesResults] = useState(false);

    useEffect(() => {
        if (!downloadQuotesResults || shipmentsDraft.length === 0) return;
        const mapData = shipmentsDraft
            .filter((ship) => checkList.includes(ship.itemId))
            .map((ship) => ({
                'Región Origen	': ship.originRegion,
                'Comuna Origen	': ship.originComuna,
                'Calle Origen	': ship.originStreet,
                'Número Casa/Edificio Origen	': ship.originNumber,
                'Región Destino	': ship.destinationRegion,
                'Comuna Destino	': ship.destinationComuna,
                'Calle Destino	': ship.destinationStreet,
                'Número Casa/Edificio Destino	': ship.destinationNumber,
                'Largo (cm)	': ship.length,
                'Ancho (cm)	': ship.width,
                'Altura (cm)	': ship.height,
                'Peso (kg)	': ship.weight,
                'ID Cliente	': ship.clientId,
                'Nombre remitente	': ship.originName,
                'Email Remitente	': ship.originEmail,
                'Teléfono Remitente	': ship.originPhone,
                'RUT Remitente	': ship.originRut,
                'Nombre Destinatario	': ship.destinationName,
                'Email Destinatario	': ship.destinationEmail,
                'Teléfono Destinatario	': ship.destinationPhone,
                'RUT Destinatario': ship.destinationRut,
                Precio: ship.price,
            }));
        const worksheet = XLSX.utils.json_to_sheet(mapData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Dates');
        XLSX.writeFile(workbook, 'test.xlsx');
        setDownloadQuotesResults(false);
    }, [checkList, downloadQuotesResults, shipmentsDraft]);

    return { downloadQuotesResults, setDownloadQuotesResults };
};
