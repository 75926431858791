import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { INotification, INotificationCreateUpdatePayload } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    createdSuccess = 'createdSuccess',
    updateSuccess = 'updateSuccess',
    getByIdSuccess = 'getByIdSuccess',
    error = 'error',
}

export interface NotificationsConfigState {
    data: INotification | null;
    status: Status;
    error: string | null;
}

const initialState: NotificationsConfigState = {
    data: null,
    status: Status.idle,
    error: null,
};

export const createNotificationConfig = createAsyncThunk(
    'notificationConfig/create',
    async (data: INotificationCreateUpdatePayload) => {
        const response = await APIClient.postData('/notify/config', data);
        return response.data;
    }
);

export const updateNotificationConfig = createAsyncThunk(
    'notificationConfig/update',
    async (data: INotificationCreateUpdatePayload) => {
        const response = await APIClient.putData(
            `/notify/config/${data.id}`,
            data
        );
        return response.data;
    }
);
export const fetchByIdNotificationConfig = createAsyncThunk(
    'notificationConfig/getById',
    async (id: string) => {
        const response = await APIClient.getData(`/notify/config/${id}`);
        return response.data;
    }
);

export const notificationConfigSlice = createSlice({
    name: 'notificationConfig',
    initialState,
    reducers: {
        resetNotificationConfig: (state) => {
            state.data = null;
            state.status = Status.idle;
            state.error = null;
        },
    },
    extraReducers(builder) {
        builder
            // CREATE
            .addCase(createNotificationConfig.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(createNotificationConfig.fulfilled, (state, action) => {
                state.status = Status.createdSuccess;
                state.data = action.payload;
            })
            .addCase(createNotificationConfig.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            })

            // UPDATE
            .addCase(updateNotificationConfig.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(updateNotificationConfig.fulfilled, (state, action) => {
                state.status = Status.updateSuccess;
                state.data = action.payload;
            })
            .addCase(updateNotificationConfig.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            })

            // GET BY ID
            .addCase(fetchByIdNotificationConfig.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(fetchByIdNotificationConfig.fulfilled, (state, action) => {
                state.status = Status.getByIdSuccess;
                state.data = action.payload;
            })
            .addCase(fetchByIdNotificationConfig.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            });
    },
});

export const { resetNotificationConfig } = notificationConfigSlice.actions;
