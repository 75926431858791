import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IShipment, IShipmentCreateAttr } from '../interfaces';
import {
    IUpdateShipmentStatus,
    IUpdateShipmentStatusBulk,
} from '../interfaces/IShipment';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface ShipmentsState {
    data: IShipment | null;
    status: Status;
    error: string | null;
}

const initialState: ShipmentsState = {
    data: null,
    status: Status.idle,
    error: null,
};

export const createShipment = createAsyncThunk(
    'shipmentCreate/create',
    async (shipment: IShipmentCreateAttr) => {
        const response = await APIClient.postData('/shipments', shipment);
        return response.data;
    }
);
export const updateShipment = createAsyncThunk(
    'shipmentCreate/update',
    async (shipment: IUpdateShipmentStatus) => {
        const response = await APIClient.putData(
            `/shipments/${shipment.id}`,
            shipment
        );
        return response.data;
    }
);
export const updateShipmentBulk = createAsyncThunk(
    'shipmentCreate/updateBulk',
    async (shipment: IUpdateShipmentStatusBulk) => {
        const response = await APIClient.putData(
            `/shipments/status/bulk`,
            shipment
        );
        return response.data;
    }
);

export const shipmentCreateSlice = createSlice({
    name: 'shipmentCreate',
    initialState,
    reducers: {
        shipmentInitialData: (state, action) => {
            state.data = action.payload;
        },
        resetShipment: (state) => {
            state.data = null;
            state.status = Status.idle;
            state.error = null;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(createShipment.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(createShipment.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = action.payload;
            })
            .addCase(createShipment.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            })
            .addCase(updateShipment.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(updateShipment.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = action.payload;
            })
            .addCase(updateShipment.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            })
            .addCase(updateShipmentBulk.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(updateShipmentBulk.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = action.payload;
            })
            .addCase(updateShipmentBulk.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            });
    },
});

export const { shipmentInitialData } = shipmentCreateSlice.actions;

export const { resetShipment } = shipmentCreateSlice.actions;
