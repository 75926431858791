/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IShipmentCreateBulkAttr } from '../interfaces';
import paginateArray from '../../../utils/paginateArray';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface ShipmentsState {
    data: { id: string; error: boolean; itemId: string }[];
    status: Status;
    error: string | null;
}

export interface IShipmentCreate {
    shipments: IShipmentCreateBulkAttr[];
    destinyEmail: string;
}

const initialState: ShipmentsState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const createBulkShipment = createAsyncThunk(
    'shipmentCreate/create',
    async (payload: IShipmentCreate) => {
        const { shipments } = payload;
        const shipmentsPaginated = paginateArray(
            shipments,
            2
        ) as IShipmentCreateBulkAttr[][];
        const response = [];
        for (const items of shipmentsPaginated) {
            const apiResponses = await APIClient.postData('/shipments/bulk', {
                shipments: items,
            });
            response.push(...apiResponses.data);
        }
        return response;
    }
);

export const shipmentBulkSlice = createSlice({
    name: 'shipmentCreate',
    initialState,
    reducers: {
        resetBulkShipment: (state) => {
            state.data = [];
            state.status = Status.idle;
            state.error = null;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(createBulkShipment.pending, (state) => {
                state.status = Status.loading;
                state.data = [];
            })
            .addCase(createBulkShipment.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = action.payload;
            })
            .addCase(createBulkShipment.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            });
    },
});

export const { resetBulkShipment } = shipmentBulkSlice.actions;
