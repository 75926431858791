import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import {
    IClient,
    ICreateClientPayload,
    IUpdateClientPayload,
} from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface BranchClient {
    data: IClient[];
    status: Status;
    error: string | null;
}

const initialState: BranchClient = {
    data: [],
    status: Status.idle,
    error: null,
};

export const fetchClients = createAsyncThunk(
    'clients/fetchClients',
    async (): Promise<IClient[]> => {
        const response = await APIClient.getData('/clients');
        return response.data;
    }
);

export const createClient = createAsyncThunk(
    'clients/create',
    async (payload: ICreateClientPayload) => {
        const response = await APIClient.postData('/clients', payload);

        return response.data;
    }
);

export const updateClient = createAsyncThunk(
    'clients/update',
    async (payload: IUpdateClientPayload) => {
        await APIClient.putData(`/clients/${payload.id}`, payload);

        return payload;
    }
);

export const clientsSlice = createSlice({
    name: 'clients',
    initialState,
    reducers: {
        resetCreateClient: (state) => {
            state.status = initialState.status;
            state.error = initialState.error;
            state.data = initialState.data;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchClients.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(fetchClients.fulfilled, (state, action) => {
                state.status = Status.success;
                state.data = action.payload;
            })
            .addCase(fetchClients.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            })
            .addCase(createClient.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(createClient.fulfilled, (state) => {
                state.status = Status.success;
            })
            .addCase(createClient.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            })
            .addCase(updateClient.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(updateClient.fulfilled, (state) => {
                state.status = Status.success;
            })
            .addCase(updateClient.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            });
    },
});

export const { resetCreateClient } = clientsSlice.actions;
