interface IPageHeader {
    title: string;
}

export default function PageHeader({ title }: IPageHeader) {
    return (
        <div className="w-full text-left mb-4 text-sym-mbe-primary">
            <h1 className="font-bold text-2xl">{title}</h1>
        </div>
    );
}
