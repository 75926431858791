/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */

import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PageHeader from '../../../components/PageHeader';
import { findUserByIdAction } from '../../../modules/users/features/inviteUserSlice';
import FormUpdate from './components/formUpdate';

export default function CreateUpdate() {
    const dispatch = useDispatch();
    const params = useParams<{ id: string }>();

    useEffect(() => {
        if (params.id) {
            dispatch(findUserByIdAction(params.id));
        }
    }, [params, dispatch]);

    return (
        <div className="relative">
            <PageHeader title="Usuarios" />
            <FormUpdate />
        </div>
    );
}
