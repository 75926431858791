/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import { useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Column, useTable } from "react-table";

import { fetchRegions } from '../../../modules/geo/features/regionSlice';
import { fetchComunas } from '../../../modules/geo/features/comunaSlice';
import { RootState, useAppDispatch } from "../../../store";
import PageHeader from '../../../components/PageHeader';
import { IComuna } from "../../../modules/geo/interfaces";

export default function RegionAndComuna() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  // GET REGIONS AND COMUNA FROM REDUX
  const regions = useSelector((state: RootState) => state.regions);
  const comunas = useSelector((state: RootState) => state.comunas);

  // FETCH REGION AND COMUNA DATA FROM API
  useEffect(() => {
    if (regions.status === 'idle') {
      dispatch(fetchRegions());
    }
    if (comunas.status === 'idle') {
      dispatch(fetchComunas());
    }
  }, [regions, comunas, dispatch]);
  // FETCH REGION AND COMUNA DATA FROM API - FINISH

  // COMUNA FILTERED
  const [selectedRegion, setSelectedRegion] = useState<string | null>(null);
  const [comunasFiltered, setComunasFiltered] = useState<IComuna[]>([]);
  useEffect(() => {
    if (selectedRegion !== null && comunas.status === 'success' && comunas.data.length > 0) {
      setComunasFiltered(comunas.data.filter(({ regionId }) => regionId === +selectedRegion))
    }
  }, [comunas.data, comunas.status, selectedRegion])
  // COMUNA FILTERED - FINISH

  // CONFIG TABLE

  const columns = useMemo<Column<IComuna>[]>(
    () => [
      {
        Header: t('geo.comuna').toString(),
        accessor: 'name',
        Cell: ({ cell: { value } }: any) => {
          if (value) {
            return value;
          }
          return '';
        },
      }
    ],
    [t]
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows
  } = useTable(
    {
      columns,
      data: comunasFiltered,

      initialState: {
        pageSize: 100,
        pageIndex: 0,
      },

    }
  );




  return <div className="relative  mb-48">
    <PageHeader title={t('geo.regionComunaList')} />
    <div className="flex text-black shadow-md p-6">
      <div className="w-full md:w-1/3">
        {" "}
      </div>
      <div className="w-1/3 flex items-center justify-center text-center">
        <table
          className="table-auto w-full mx-auto"
          {...getTableProps()}
        >
          <thead className="text-white ">
            {headerGroups.map((headerGroup) => (
              <tr
                className="text-left "
                {...headerGroup.getHeaderGroupProps()}
              >
                <th
                  className="py-2 border-2 border-white rounded-lg  text-center"
                  style={{ minWidth: '2%' }}
                >
                  <div className="bg-sym-mbe rounded-lg p-2 text-center">
                    <select
                      id="regionOrigin"
                      className="mt-2 text-black"
                      disabled={regions.status === 'loading' || comunas.status === 'loading'}
                      placeholder={t(
                        'quotes.create.regionOrigin'
                      )}
                      onChange={(e) => setSelectedRegion(e.target.value)}
                    >
                      <option value="">
                        {regions.status === 'loading'
                          ? t('common.loading')
                          : t('quotes.create.regionSelect')}
                      </option>
                      {regions.status === 'success' && comunas.status === 'success' ? (
                        regions.data.map(
                          (region) => (
                            // region.id !==
                            // client?.regionId && (
                            <option
                              key={`o-${region.id}`}
                              value={region.id}
                            >
                              {region.name}
                            </option>
                          )
                          // )
                        )
                      ) : (
                        <option>{t('loading.message')}</option>
                      )}
                    </select>
                  </div>
                </th>
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              // Prepare the row for display

              prepareRow(row);

              return (
                // Apply the row props

                <tr
                  className="odd:bg-sym-mbe-seconday hover:bg-gray-300"
                  {...row.getRowProps()}
                >
                  {
                    // Loop over the rows cells

                    row.cells.map((cell) => (
                      // Apply the cell props
                      <td
                        className="py-4 px-2 text-left"
                        {...cell.getCellProps()}
                      >
                        {
                          // Render the cell contents

                          cell.render('Cell')
                        }
                      </td>
                    ))
                  }
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="w-full md:w-1/3">
        {" "}

      </div>
    </div>
  </div>
}