import { useSelector } from 'react-redux';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { FormData } from './useFormData';
import { createBulkQuotes } from '../../../../modules/quotes/features/quoteBulkSlice';
import { RootState, useAppDispatch } from '../../../../store';
import { IFileType } from '../components/ProcessFile';

interface UseCreateBulkQuoteProps {
    shipmentsDraft: IFileType[];
    setShipmentsDraft: Dispatch<SetStateAction<IFileType[]>>;
}

export default (props: UseCreateBulkQuoteProps) => {
    const { setShipmentsDraft, shipmentsDraft } = props;

    const dispatch = useAppDispatch();
    const [quotesResponseSuccess, setQuotesResponseSuccess] = useState(false);
    const quotesBulk = useSelector((state: RootState) => state.quotesBulk);

    const createBulkQuote = async (data: FormData) => {
        const payLoad = {
            courierId: data.courierId,
            items: data.quotesList,
            shipmentTypeId: data.shipmentTypeId,
            destinyEmail: data.destinyEmail,
        };
        await dispatch(createBulkQuotes(payLoad)).unwrap();
        setQuotesResponseSuccess(false);
    };

    useEffect(() => {
        if (quotesBulk.status === 'success' && !quotesResponseSuccess) {
            const newValues = shipmentsDraft.map((s) => {
                const shipment = quotesBulk.data.find(
                    (q) => q.itemId === s.itemId
                );
                if (shipment) {
                    return {
                        ...s,
                        price: shipment.price ?? 0,
                        fee: shipment.fee ?? 0,
                        courierPrice: shipment.courierPrice ?? 0,
                        insurance: shipment.insurance ?? 0,
                        deliveryEstimateDate: shipment.deliveryEstimateDate,
                        deliveryDays: shipment.deliveryDays,
                        deliveryType: shipment.deliveryType,
                    };
                }
                return s;
            });
            setShipmentsDraft(newValues);
            setQuotesResponseSuccess(true);
        }
    }, [
        quotesBulk.data,
        quotesBulk.status,
        quotesResponseSuccess,
        setShipmentsDraft,
        shipmentsDraft,
    ]);

    return { createBulkQuote, createBulkQuoteStatus: quotesBulk.status };
};
