import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { ICategory } from '../interfaces/ICategory';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface CategoryState{
    data: ICategory | null;
    status: Status;
    error: string | null;
}

const initialState: CategoryState = {
    data: null,
    status: Status.idle,
    error: null,
};


export const getCategoryById = createAsyncThunk(
    'category/getById',
    async (id: string) => {
        const response = await APIClient.getData(`/category/${id}`);
        return response.data;
    }
);


export const createCategory = createAsyncThunk(
    'category/createCategory',
    async (payload: ICategory) => {
        const mapPayload = {
            name: payload.name,
        };
        const response = await APIClient.postData(
            `/category`,
            mapPayload
        );
        return response.data;
    }
);

export const updateCategory = createAsyncThunk(
    'category/updateCategory',
    async (payload: ICategory) => {
        const mapPayload = {
            name: payload.name,
        };
        const response = await APIClient.putData(
            `/category/${payload.id}`,
            mapPayload
        );
        return response.data;
    }
);

export const deleteCategory = createAsyncThunk(
    'category/deleteCategory',
    async (id: string) => {
        const response = await APIClient.deleteData(`/category/${id}`);
        return response.data;
    }
);

export const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {
        resetCategoryById: (state) => {
            state.data = null;
            state.status = initialState.status;
            state.error = initialState.error;
        },
        resetDeleteCategory: (state) => {
            state.data = null;
            state.status = Status.idle;
            state.error = null;
        },
        resetCreateCategory: (state) => {
            state.data = null;
            state.status = Status.idle;
            state.error = null;
        },
        resetUpdateCategory: (state) => {
            state.data = null;
            state.status = Status.idle;
            state.error = null;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(getCategoryById.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(getCategoryById.fulfilled, (state, action) => {
                state.status = Status.success;
                state.data = action.payload.props;
            })
            .addCase(getCategoryById.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            })
            .addCase(createCategory.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(createCategory.fulfilled, (state) => {
                state.status = Status.success;
            })
            .addCase(createCategory.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            })
            .addCase(updateCategory.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(updateCategory.fulfilled, (state) => {
                state.status = Status.success;
            })
            .addCase(updateCategory.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            })
            .addCase(deleteCategory.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(deleteCategory.fulfilled, (state, action) => {
                state.status = Status.success;
                state.data = action.payload.props;
            })
            .addCase(deleteCategory.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            });
    },
});

export const {
	resetCategoryById,
	resetDeleteCategory,
	resetCreateCategory,
	resetUpdateCategory,
} = categorySlice.actions;
