export default function Dialog({ children }: { children: React.ReactNode }) {
  return (
    <div
      className="py-12 bg-slate-200/80 transition duration-150 ease-in-out z-10 absolute top-0 right-0 bottom-0 left-0"
      id="modal"
    >
      <div
        role="alert"
        className="container mx-auto w-11/12 md:w-4/5 max-w-4xl"
      >
        {children}
      </div>
    </div>
  );
}
