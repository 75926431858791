import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface ShipmentMergeLabelsState {
    data: string | null;
    status: Status;
    error: string | null;
}

const initialState: ShipmentMergeLabelsState = {
    data: null,
    status: Status.idle,
    error: null,
};

export const shipmentMergeLabels = createAsyncThunk(
    'shipments/mergeLabels',
    async (shipmentIds: string[]) => {
        const response = await APIClient.postData(
            '/shipments/merge/labels',
            shipmentIds
        );
        return response.data;
    }
);

export const shipmentMergeSlice = createSlice({
    name: 'shipments',
    initialState,
    reducers: {
        clearShipmentMergeLabel: (state) => {
            state.data = null;
            state.status = Status.idle;
            state.error = null;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(shipmentMergeLabels.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(shipmentMergeLabels.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = action.payload;
            })
            .addCase(shipmentMergeLabels.rejected, (state, action) => {
                state.status = Status.success;
                state.error = action.error.message ?? null;
            });
    },
});

export const { clearShipmentMergeLabel } = shipmentMergeSlice.actions;
