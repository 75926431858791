import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { INotificationTags } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface NotificationsConfigState {
    data: INotificationTags[] | null;
    status: Status;
    error: string | null;
}

const initialState: NotificationsConfigState = {
    data: null,
    status: Status.idle,
    error: null,
};

export const fetchTagsNotificationConfig = createAsyncThunk(
    'notificationConfig/getTags',
    async () => {
        const response = await APIClient.getData('/notify/config/tags');
        return response.data;
    }
);

export const notificationConfigTagsSlice = createSlice({
    name: 'notificationConfigTags',
    initialState,
    reducers: {
        resetNotificationConfigTags: (state) => {
            state.data = null;
            state.status = Status.idle;
            state.error = null;
        },
    },
    extraReducers(builder) {
        builder
            // CREATE
            .addCase(fetchTagsNotificationConfig.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(fetchTagsNotificationConfig.fulfilled, (state, action) => {
                state.status = Status.success;
                state.data = action.payload;
            })
            .addCase(fetchTagsNotificationConfig.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            });
    },
});

export const { resetNotificationConfigTags } =
    notificationConfigTagsSlice.actions;
