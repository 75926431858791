/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { RootState } from '../../../store';
// import { IUser } from '../../../modules/users/interfaces';
import LoadingAnimated from '../../../components/elements/Loading';

import PageHeader from '../../../components/PageHeader';
import { fetchArticles } from '../../../modules/article/features/articlesSlice';
import { fetchCategories } from '../../../modules/category/features/categoriesSlice';

export default function ListFAQ() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const articles = useSelector((state: RootState) => state.articles);
    const categories = useSelector((state: RootState) => state.categories);

    useEffect(() => {
        if (articles.status === 'idle' && categories.status === 'idle') {
            dispatch(fetchArticles());
            dispatch(fetchCategories());
        }
    }, [articles.status, categories.status, dispatch]);

    const [showFAQ, setShowFAQ] = useState(false);
    const [showArticle, setShowArticle] = useState(false);
    const [id, setId] = useState('');
    const [idArticle, setIdArticle] = useState('');


    const handleClick = (idFaq: string) => {
        setShowFAQ(!showFAQ);
        setId(idFaq);
    };
    const handleClickArticle = (idArt: string) => {
        setShowArticle(!showArticle);
        setIdArticle(idArt);
    };
    // TABLE ROW CLICK FINISH ///////////////////////////////////////////////////////////////
    return (
        <div className="relative mb-40">
            <PageHeader title="FAQ" />

            {articles.status === 'loading' && (
                <LoadingAnimated message={t('common.loading')} />
            )}
            {articles.status === 'success' &&
                articles.data.length === 0 &&
                categories.status === 'success' &&
                categories.data.length === 0 && (
                    <div className="w-full text-center">
                        No se encontraron datos
                    </div>
                )}

            {categories.status === 'success' && categories.data.length > 0 && (
                <div className="accordion">
                    {categories.data.map((c) => (
						<>
						
                        <div
                            key={c.id}
                            className="accordion-item bg-white border border-gray-200"
                        >
							
                            <h2
                                className="accordion-header mb-0"
                                id="headingOne"
                            >
                                <button
                                    className="
									flex
									w-full
									py-4
									px-5
									text-base text-gray-800 text-left
									bg-white
									border-0
									rounded-none
									transition
									focus:outline-none
									flex
									"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne"
                                    aria-expanded="true"
                                    aria-controls="collapseOne"
                                    onClick={() => handleClick(c.id || '')}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                        />
                                    </svg>
                                    <div className="ml-2">{c.name}</div>
								<div className='justify-end'>
								<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
									<path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
									</svg>
								</div>
                                </button>
                            </h2>
                            {id === c.id &&
                                showFAQ === true && articles.data && 
                                articles.data.map(
                                    (a) =>
                                        c.id === a.categoryId &&  (
                                            <div
                                                key={a.id}
                                                id={a.id}
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="headingOne"
                                                data-bs-parent="#accordionExample"
                                            >
												
                                                <div
                                                    className="accordion-item bg-white border border-gray-200"
                                                >
                                                    <h2
                                                        className="accordion-header mb-0"
                                                        id="headingOne"
                                                    >
                                                        <button
                                                            className="
																accordion-button
																relative
																flex
																items-center
																w-full
																py-4
																px-5
																ml-10
																text-base text-gray-800 text-left
																bg-white
																border-0
																rounded-none
																transition
																focus:outline-none"
                                                            type="button"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target="#collapseOne"
                                                            aria-expanded="true"
                                                            aria-controls="collapseOne"
                                                            onClick={() =>
                                                                handleClickArticle(a.id || '	')
                                                            }
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="h-6 w-6"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke="currentColor"
                                                                strokeWidth={2}
                                                            >
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    d="M16 4v12l-4-2-4 2V4M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                                                                />
                                                            </svg>
                                                            <div className="ml-2">
                                                                {a.name}
                                                            </div>
                                                        </button>
                                                    </h2>
                                                    {showArticle === true && idArticle === a.id && (
                                                        <div className="accordion-body ml-20 py-4 px-5">
                                                            <p>
                                                                {a.description}
                                                            </p>
                                                       
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )
											

                                )}
                        </div>

						<br/>
					</>

                    ))}
                </div>
            )}
        </div>
    );
}
