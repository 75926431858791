import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
    ICourier,
    ICourierShipmentTypes,
} from '../../../../modules/couriers/interfaces';
import { IQuote } from '../../../../modules/quotes/interfaces';
import { IFileType } from '../components/ProcessFile';

export type FormData = {
    courierId: string;
    shipmentTypeId: string;
    quotesList: IQuote[];
    checkList: string[];
    destinyEmail: string;
};

export default (props: { couriers: ICourier[] }) => {
    const { couriers } = props;
    const { register, formState, watch, setValue, handleSubmit } =
        useForm<FormData>({
            defaultValues: {
                checkList: [],
            },
        });
    const courierSelected = watch('courierId');
    const checkList = watch('checkList');
    const shipmentType = watch('shipmentTypeId');

    const [courierDeliveryTypes, setCourierDeliveryTypes] = useState<
        ICourierShipmentTypes[]
    >([]);
    useEffect(() => {
        if (courierSelected && couriers.length) {
            const courierSelectedData = couriers.find(
                (courier) => courier.id === courierSelected
            );
            if (courierSelectedData && courierSelectedData.shipmentTypes) {
                setCourierDeliveryTypes(courierSelectedData.shipmentTypes);
                setValue('shipmentTypeId', '');
            }
        }
    }, [courierSelected, couriers, setValue]);

    const [fileErrors, setFileErrors] = useState<string[]>([]);
    const [shipmentsDraft, setShipmentsDraft] = useState<IFileType[]>([]);
    return {
        fileErrors,
        shipmentsDraft,
        courierDeliveryTypes,
        formState,
        checkList,
        courierSelected,
        shipmentType,
        setFileErrors,
        setShipmentsDraft,
        formRegister: register,
        formSetValue: setValue,
        handleSubmit,
    };
};
